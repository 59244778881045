import React from "react";
import { Grid } from "@material-ui/core";

import UploadImageVideo from "./upload";
import UploadImageVideo2 from "./upload2";

const RecogUploadImageVideo = ({ model, uploadAgain }) => {
  const [message, setMessage] = React.useState("Add an image for reference")
  const [load, setLoad] = React.useState(true)
  const [target, setTarget] = React.useState(null)
  const [processed, setProcessed] =  React.useState(false)
  const loadHandler = (txt) => {
    setLoad(false);
    setMessage(txt)
    setTimeout(() => {
      setLoad(true);
    }, 500);
  };

  const processedHandler = () => {
    setLoad(false)
    setProcessed(true)
  }

  const setTargetHandler = (file) => setTarget(file)
  return (
    <Grid container spacing={3} justify="center" alignItems='center'>
      <Grid item md={processed? 0: 6}>
        {load && <UploadImageVideo2 model={model} uploadAgain={loadHandler} uploadFile={setTargetHandler} />}
        {/* {message} */}
      </Grid>
      <Grid item md={processed? 12: 6}>
        <UploadImageVideo model={model} uploadAgain={uploadAgain} target={target} removeRefer={processedHandler}/>
      </Grid>
    </Grid>
  );
};

export default RecogUploadImageVideo;
