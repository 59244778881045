import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from "@material-ui/core";

const employeeData = [
  {
    id: 1,
    name: "Amanda Merced",
    position: "Legal Counsin (USA)",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "https://www.linkedin.com/in/amanda-merced-5ba15687/",
  },

  {
    id: 2,
    name: "Ankush Bedi",
    position: "Legal Counsin (USA)",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "https://www.linkedin.com/in/ankush-bedi-9524611/",
  },

  {
    id: 3,
    name: "Devan Friday",
    position: "Chartered Accountant (USA)",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "https://www.linkedin.com/in/devan-friday-8553a2102/",
  },

  {
    id: 4,
    name: "Ronny Tai",
    position: "Accountant (USA)",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "https://www.linkedin.com/in/ronny-tai-aa22431a6/",
  },

  {
    id: 5,
    name: "Nimisha Parashar",
    position: "HR Business Partner",
    description:
      "Nimisha Parashar is an experienced HR Business Partner with a passion for supporting organizational growth and development. She has a proven track record of driving change, developing talent, and implementing successful HR initiatives. As an HRBP, Nimisha partners with senior leaders and managers to identify business needs and provide HR solutions that align with strategic objectives. She is highly skilled in employee relations, performance management, talent acquisition, and diversity and inclusion. Nimisha's strengths lie in her ability to build strong relationships, coach and mentor employees, and foster a culture of collaboration and innovation.",
    linkedin_url: "https://www.linkedin.com/in/nimishaparashar/",
  },

  {
    id: 44,
    name: "Rocio Novo",
    position: "HR (USA)",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "",
  },

  {
    id: 6,
    name: "Serhii Chkana",
    position: "Sales Account Manager",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "https://www.linkedin.com/in/serhii-chkana-35bb25153/",
  },

  {
    id: 7,
    name: "Laxmi Priya Paila",
    position: "Marketing Executive",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "",
  },

  {
    id: 48,
    name: "Robert Schmermund ",
    position: "Experienced Operations and Business Development Manager",
    description: "Experienced Operations and Business Development Manager at Early-Stage Startup",
    linkedin_url: "https://www.linkedin.com/in/robert-schmermund-616ab763/",
  },

  {
    id: 8,
    name: "Laxmi Priya Paila",
    position: "Business Development Executive - 1, Procurement",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "",
  },

  {
    id: 10,
    name: "Som Sachan",
    position: "Business Development Executive - 1",
    description:
      "Som is a business development executive who has a background as an engineer. With his technical knowledge and analytical skills, he approaches his role in a proactive manner, always seeking innovative solutions to drive growth for his company. Som understands the importance of strategy and planning in achieving long-term success and works tirelessly to develop and implement effective business growth strategies. He is constantly looking for new opportunities and trends to capitalise on, ensuring his company stays ahead of the competition. Som is a valuable asset to the organization looking to expand its reach and drive revenue growth. His dedication to innovation and commitment to strategic planning make him a top-performing business development executive.",
    linkedin_url: "https://www.linkedin.com/in/som-sachan-39384414b/",
  },

  {
    id: 11,
    name: "Khalid Ansari",
    position: "Business Development Manger",
    description:
      "Khalid is a highly accomplished Business Development Executive with extensive managerial experience in driving revenue growth for technology startups. Currently working at Skylark Labs, who previously worked at BYJU'S, generating 4 crore rupees in 2.5 years for the company alone. His exceptional sales and marketing skills helped him to lead BYJU'S to become one of the top educational tech companies in India.",
    linkedin_url: "https://www.linkedin.com/in/khalid-ansari-4a63601a1/",
  },

  {
    id: 12,
    name: "Philip Calk",
    position: "Head of Federal Sales (USA)",
    description:
      "Philip Calk is a retired Federal law enforcement officer, with over 28 years of experience across the southwest border of the United States, as well as assignments in Canada, before his retirement as Patrol Agent in Charge, Del Rio, TX. During his career, Calk conducted law enforcement duties in both rural and urban areas, worked multi-agency task force, lead prosecution units, held assignments in intelligence, deployed remotely monitored covert cameras and sensors and operated various scope and technology platforms. Calk has extensive experience working with Federal, State and local agencies. During his tenure, Calk served the Border Patrol’s Enforcement and Information Technology Division in Washington, D.C., working side by side with software developers, defining a new computer system, overseeing its development and delivery to the field. Calk was instrumental in opening dialog with the Department of Justice on the electronic exchange of data and biometrics between government agencies. His diverse career has provided him with the hands-on knowledge and understanding to address both operational and legal challenges faced by law enforcement in a variety of environments.",
    linkedin_url: "",
  },

  {
    id: 13,
    name: "Ashish Kumar Karir",
    position: "Head of Operations",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "",
  },

  {
    id: 15,
    name: "Dhruv Santoshwar",
    position: "ML Team Lead",
    description:
      "Computer Vision Engineer and Team Lead who is passionate about developing cutting-edge technology solutions that address real-time problems using computer vision.Expertise in developing computer vision algorithms and models that can analyze, interpret, and make decisions based on visual data in real-time.",
    linkedin_url: "",
  },

  {
    id: 16,
    name: "Premkumar Sitaram Varma",
    position: "SDE-1",
    description: "---",
    linkedin_url: "https://www.linkedin.com/in/premkumar-varma-5142m/",
  },

  {
    id: 17,
    name: "Kamlesh Kumar",
    position: "ML Engineering Intern",
    description:
      "Meet Kamlesh, a final year Engineering student with a passion for applying artificial intelligence to solve real-world problems. Currently working as an ML Engineering intern at SkyLark Labs for the last three months. Apart from being an intern, Kamlesh is also a prolific inventor and researcher. He has two Indian patents to his name, and has authored several research papers that have been published by top-tier publishers such as IEEE and MDPI. Driven by a fascination for the real-time applications of computer vision using AI, Kamlesh is constantly exploring new ways to harness the power of machine learning and deep learning algorithms to solve complex problems in areas such as healthcare, transportation, and security.",
    linkedin_url: "https://www.linkedin.com/en/kamlesh364",
  },

  {
    id: 18,
    name: "Dixit Prajapati",
    position: "Machine Learning Intern, Computer Vision Engineer",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "",
  },

  {
    id: 19,
    name: "N B Sairam",
    position: " Director of Engineering",
    description:
      "I am an experienced Full Stack Developer with a passion for building scalable and efficient web applications. My expertise in both front-end and back-end development, as well as system design and microservices architecture, has enabled me to build efficient and scalable web applications. I am a dedicated and detail-oriented developer who is committed to delivering high-quality code that meets the needs of end-users.",
    linkedin_url: "https://www.linkedin.com/in/sai-nimmakayala-284834169/",
  },

  {
    id: 21,
    name: "Gaurav Sharma",
    position: "Full Stack Engineer",
    description:
      "A Full Stack Engineer working on several product lines and contributing to the public security through my expertise in Python/Django and Next.js/React. Always curious for solving complex problems and having good understanding of complex algorithms and problem-solving.",
    linkedin_url: "https://www.linkedin.com/in/mnamegaurav/",
  },

  // {
  //   id: 22,
  //   name: "Burhan Kapdawala",
  //   position: "ML Team Lead",
  //   description:
  //     "Computer Vision Engineer and Team Lead who is passionate about developing cutting-edge technology solutions that address real-time problems using computer vision.Expertise in developing computer vision algorithms and models that can analyze, interpret, and make decisions based on visual data in real-time.",
  //   linkedin_url: "https://www.linkedin.com/in/dhruv-santoshwar-288455140/",
  // },

  {
    id: 24,
    name: "Amarjot Singh",
    position: "Founder & CEO",
    description:
      "Dr Amarjot Singh is the founder and chief executive officer (CEO) of Skylark Labs Inc., that creates advanced AI systems to solve today's far-reaching physical security challenges. Dr Singh is also a research fellow at Stanford University and part of the L2M DARPA program, USA, where he is attempting to develop a framework for Artificial General Intelligence (AGI). Before launching Skylark and joining Stanford, Singh received his doctorate in Artificial Intelligence (Deep Learning) from the University of Cambridge, the UK in 2018. He has also been associated with several other institutions including Massachusetts Institute of Technology (MIT), Harvard University, National University of Singapore (NUS), INRIA Sophia Antipolis, France, University of Bonn, Germany, Simon Fraser University, Canada, Indian Institute of Science (IISc), Bangalore, India, and NIT Warangal, India. Singh has made several breakthrough contributions towards the areas of artificial intelligence and computer vision with over 50 international journal and conference publications.",
    linkedin_url: "",
  },

  {
    id: 25,
    name: "Saurabh Suri",
    position: "President",
    description:
      "Saurabh Suri is the Managing Partner and Chief Investment Officer at CerraCap Ventures. He has over 20 years of experience in the technology industry focused on creating, growing, and sustaining technology products and solutions in a global operations environment; having lived and worked on 4 continents. Prior to co-founding CerraCap, he served as Vice President, Emerging Solutions at UST Global where he conceived & led UST's investment arm and launched its Cyber Security subsidiary. Saurabh has pioneered several models for startup growth and investing centered around unique value-driven approaches; producing significant returns. Saurabh has also helped large Fortune 500 organizations establish and run highly successful innovation units by creating an ecosystem that fosters turning ideas into value. With a specific focus on investing in Cyber Security, Enterprise AI, and Healthcare, he has several published articles, whitepapers, and talks on the applications of these technologies in the enterprise space. Saurabh has a degree in Neuro-computation and a research background in High-Performance Computing from the UK. Saurabh has helped mentor and grow several successful tech companies where he serves as an advisor and board member.",
    linkedin_url: "https://www.linkedin.com/in/spsuri/",
  },

  {
    id: 26,
    name: "Alec Johnson",
    position: "Chief Development Officer",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "",
  },

  {
    id: 27,
    name: "Philip Calk",
    position: "Head of Federal Sales",
    description:
      "Philip Calk is a retired Federal law enforcement officer, with over 28 years of experience across the southwest border of the United States, as well as assignments in Canada, before his retirement as Patrol Agent in Charge, Del Rio, TX. During his career, Calk conducted law enforcement duties in both rural and urban areas, worked multi-agency task force, lead prosecution units, held assignments in intelligence, deployed remotely monitored covert cameras and sensors and operated various scope and technology platforms. Calk has extensive experience working with Federal, State and local agencies. During his tenure, Calk served the Border Patrol’s Enforcement and Information Technology Division in Washington, D.C., working side by side with software developers, defining a new computer system, overseeing its development and delivery to the field. Calk was instrumental in opening dialog with the Department of Justice on the electronic exchange of data and biometrics between government agencies. His diverse career has provided him with the hands-on knowledge and understanding to address both operational and legal challenges faced by law enforcement in a variety of environments.",
    linkedin_url: "",
  },

  {
    id: 29,
    name: "Ashish Kumar Karir",
    position: "Head of Operations",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "",
  },

  {
    id: 30,
    name: "Ketan Bansal",
    position: "Vice President of Technology",
    description:
      "Driven VP of Technology with a passion for innovation and a focus on delivering exceptional results. At Skylark Labs, I lead the technology team in developing and implementing innovative solutions that drive business growth and enhance customer experience. With experience in several domains, I bring a deep understanding of software development methodologies, architecture, and security best practices. I stay ahead of industry trends and leverage the latest technologies to drive success for the company.",
    linkedin_url: "https://www.linkedin.com/in/ketan-bansal/",
  },

  {
    id: 31,
    name: "N B Sairam",
    position: "SDE-II",
    description:
      "I am an experienced Full Stack Developer with a passion for building scalable and efficient web applications. My expertise in both front-end and back-end development, as well as system design and microservices architecture, has enabled me to build efficient and scalable web applications. I am a dedicated and detail-oriented developer who is committed to delivering high-quality code that meets the needs of end-users.",
    linkedin_url: "https://www.linkedin.com/in/sai-nimmakayala-284834169/",
  },

  {
    id: 32,
    name: "John Cushman​",
    position: "Board Member ​",
    description:
      "John C. Cushman, III, Chairman of Global Transactions for Cushman & Wakefield, is a leader in the commercial real estate industry on a global basis. Cushman & Wakefield, Inc. was founded in 1917 by his grandfather and great-uncle. Mr. Cushman negotiated the largest office lease in the world – the world headquarters of Merrill Lynch & Company involving 4 million square feet (initial occupancy) at the World Financial Center in New York City. He also completed headquarters relocations for many of America’s largest corporations and institutions. Mr. Cushman served a two-year term (2004-2006) as National President of the Boy Scouts of America. He is formerly a director of the National Park Foundation and a member of the National Park System Advisory Board in Washington, DC. Additionally, Mr. Cushman serves as Trustee Emeritus of Colgate University where he previously served as a trustee for 13 years. He is a member and former director of the Real Estate Roundtable. In May 2008, he received an honorary doctorate degree from Colgate University, and in November 2008, he received the Lifetime Achievement Award from Commercial Property News. He was named as the first recipient of the Cushman & Wakefield Hall of Fame Award in April 2017. In February 2019, the CIA Officers Memorial Foundation honored him with the Patriot Award. In September 2022, William J. Burns, the Director of the Central Intelligence Agency, presented Mr. Cushman with the George Washington Award. He has been active in philanthropic organizations across the world. Directorships include: Callaway Golf Company (Director Emeritus), Skylark Labs Inc. Board of Directors (Chairman), WCO Spectrum, LLC Advisory Board and CerraCap Ventures Advisory Board (Chairman). He also serves as Chairman and Director of Zaca Mesa Winery in Santa Barbara County, California. Over the years, Mr. Cushman has served on the boards of 14 public corporations.",
    linkedin_url: "https://www.linkedin.com/in/john-cushman-06194933/",
  },

  {
    id: 33,
    name: "Jay McCann​",
    position: "Advisory Board Member ​",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "",
  },

  {
    id: 34,
    name: "David Lyon​",
    position: "Advisory Board Member ​",
    description:
      "David Lyon is a global security executive with wide-ranging experience in risk management, business intelligence, geopolitical advisory, and cybersecurity. He previously worked as a Managing Director at the Crumpton Group (now Crumpton Global), an intelligence-led strategic advisory firm founded by Ambassador Hank Crumpton. Mr. Lyon served for over a decade as an undercover intelligence operative with the Central Intelligence Agency (CIA). He was also a uniformed intelligence officer with the US Navy and an Assistant Country Director for China within the Office of the Secretary of Defense. Mr. Lyon has worked out of multiple embassies, the Pentagon, Langley, and the White House.",
    linkedin_url: "https://www.linkedin.com/in/david-l-b4739013/",
  },

  {
    id: 49,
    name: "Brad Boyd​",
    position: "Advisory Board Member ​",
    description:
      "I am a detail-oriented attorney and leader with strong organizational and process management acumen, a growth mindset and adaptability. I bring a synthesis of business and legal insights and a solutions-driven approach as a legal advisor and business partner. I am passionate about equipping companies and people with the legal expertise to mitigate risk while executing on ambitious business goals.",
    linkedin_url: "https://www.linkedin.com/in/bradboyd/",
  },
  {
    id: 35,
    name: "Douglas Drakeley​",
    position: "Advisory Board Member ​",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "https://www.linkedin.com/in/douglasdrakeley/",
  },

  {
    id: 36,
    name: "Chris Sang​",
    position: "Director ​",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "https://www.linkedin.com/in/csang/",
  },

  {
    id: 37,
    name: "Emlyn Scott​",
    position: "Board Observers ​",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "https://www.linkedin.com/in/emlynscott/",
  },

  {
    id: 38,
    name: "Raj Arora​",
    position: "Advisory Board Member ​",
    description:
      "Dr Raj Arora is a former head of Global Center for Counter Terrorism and Cyber Security, Jaipur with three decades of experience with Law enforcement. Advisor in the field of Cyber Security, Information Security and technology implantation to IITB.",
    linkedin_url: "",
  },

  {
    id: 39,
    name: "Sukhbir Singh​​",
    position: "Advisory Board Member ​",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    linkedin_url: "",
  },
  {
    id: 40,
    name: "Revati Biskunde​​",
    position: "Business Development Executive ​",
    description:
      "Marketing professional with 5 years of experience in client communication and account management. excels at building strong client relationships, understanding their needs, and developing effective marketing strategies. An expert in managing multiple accounts and proactively addressing potential issues.i am a quick learner, dedicated, and always stays current with industry trends. My skills in client communication and account management, combined with my professionalism and dedication, make me an ideal candidate for any marketing role.marketing professional with 5 years of experience in client communication and account management. excels at building strong client relationships, understanding their needs, and developing effective marketing strategies. An expert in managing multiple accounts and proactively addressing potential issues.i am a quick learner, dedicated, and always stays current with industry trends. My skills in client communication and account management, combined with my professionalism and dedication, make me an ideal candidate for any marketing role.",
    linkedin_url: "https://www.linkedin.com/in/revati-biskunde-66a6b4218/",
  },
];

const Bloglanding = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = "Skylark Labs: About";
    return () => {
      console.log("title changed");
    };
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };
  const [expanded, setExpanded] = React.useState("panel2");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [open, setOpen] = React.useState(false);
  const [profileDialogData, setProfileDialogData] = React.useState(null);

  const handleClickOpen = (id) => {
    setOpen(true);
    const currentDialogData = employeeData.find((item) => item.id === id);

    setProfileDialogData(currentDialogData);
  };

  const handleClose = () => {
    setOpen(false);
    setProfileDialogData(null);
  };
  return (
    <>
      <div className="bloglanding">
        <div className="bloglanding-subblock">
          <img src="../images/productSlider/gradient-circle.svg" alt="" className="uk-blur-large leftimg rightimgblur-img" />
          <img src="../images/productSlider/gradient-circle.svg" alt="" className="uk-blur-large rightimg leftimgblur-img" />
        </div>
        <div className="bloglanding-subblock bloglanding-subblocktwo">
          <div className="topgradint"></div>
        </div>

        <div className="container menuconteaner">
          <div className="dismanbannertopblock">
            <h1>USE CASES</h1>

            {/* <h2>AI that adapts with changing landscape to identify new unknown threats</h2> */}
          </div>
          <div className="blogfiltermain">
            <div className="filterleft-top">
              <ul>
                <li className="active">
                  <a href="#/">Platform</a>{" "}
                </li>{" "}
                <li>
                  <a href="#/">Platform</a>{" "}
                </li>{" "}
                <li>
                  <a href="#/">Tactical Applications</a>{" "}
                </li>{" "}
                <li>
                  <a href="#/">Intelligence Applications</a>{" "}
                </li>{" "}
                <li>
                  <a href="#/">Geography</a>{" "}
                </li>{" "}
                <li>
                  <a href="#/">Community</a>{" "}
                </li>{" "}
              </ul>
            </div>
            <div className="filterright-top">
              <label class="filter-dropdown">
                <div class="dd-button">
                  <img src="../images/productSlider/filter.svg" alt="" />
                  <span> Filter</span>
                </div>

                <input type="checkbox" class="dd-input" id="test" />

                <ul class="dd-menu">
                  <li>Recent Post</li>
                  <li>Popular</li>
                </ul>
              </label>
            </div>
          </div>

          <div className="Subscribe_form">
            <input type="email" placeholder="search" required="" />
            <button>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="black">
                <path d="M16.837 5.755l.123.092 6.063 5.055.038.036-.1-.092.071.063.099.103.074.092.067.099.08.146.038.086c.071.174.11.365.11.565s-.039.39-.11.564l-.092.188-.06.095-.086.113-.079.088-.087.082-6.026 5.022a1.5 1.5 0 0 1-2.033-2.201l.113-.104 2.815-2.348H10a1.5 1.5 0 0 1-.144-2.993L10 10.5h7.857L15.04 8.152a1.5 1.5 0 0 1 1.669-2.475l.129.078zM4 10.5a1.5 1.5 0 0 1 .144 2.993L4 13.5H2a1.5 1.5 0 0 1-.144-2.993L2 10.5h2z"></path>
              </svg>
            </button>
          </div>
        </div>

        {/* <section className="blogjome-one">
          <div className="container">
            <div className="blogbox-one">
              <div className="blogbox-one-leftchild">
                <div className="textlanding-title">Use Case</div>
                <div className="textlanding">
                  Try it now for free on iOS, Android, PC, Web - whatever your
                  flavor, we’ve got you covered.
                </div>
                <a href="#/" title=""><span>Learn More  <img src="/images/productSlider/nextarrow.svg" alt=""/></span> </a>
 
              </div>

              <div className="blogbox-one-leftchild">
                <div className="usecasedashboar">
                  <img
                    src="../images/productSlider/dashboard-right.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
         
        </section> */}
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="eventand-news eventand-newstwo">
                <div className="eventboxleft">
                  <img src="../images/productSlider/usecases-01.png" alt="" />
                  <h3 className="pt-3">USE CASE</h3>
                  <p>Try it now for free on iOS, Android, PC, Web - whatever your flavor, we’ve got you covered.</p>
                  {/* using static article type because everything is yet static here */}
                  <a href="/blog?article_type=1" title="">
                    <span>
                      Learn More <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </a>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="eventand-news">
                <div className="eventboxleft">
                  <img src="../images/productSlider/car-event.jpg" alt="" />
                  <h3 className="pt-3">Events</h3>
                  <p>
                    Foreign Object Debris (FOD) refers to any object that does not belong in or around an aircraft and can potentially cause damage.
                    These objects can be as small as a screw, or as large as a piece of luggage, and their presence at an airport or on an airplane
                    can have serious consequences.
                  </p>

                  <a href="/blog?article_type=2" title="">
                    <span>
                      Learn More <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </a>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="eventand-news">
                <div className="eventboxleft">
                  <img src="../images/productSlider/car-event.jpg" alt="" />

                  <h3 className="pt-3">News</h3>
                  <p>
                    Foreign Object Debris (FOD) refers to any object that does not belong in or around an aircraft and can potentially cause damage.
                    These objects can be as small as a screw, or as large as a piece of luggage, and their presence at an airport or on an airplane
                    can have serious consequences.
                  </p>

                  <a href="/blog?news-blog=3" title="">
                    <span>
                      Learn More <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Bloglanding;
