import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const Privacyaustralia = () => {
    const [expanded, setExpanded] = React.useState("panel2");
    const navOption1 = React.useRef(null);
    const navOption2 = React.useRef(null);
    const navOption3 = React.useRef(null);
    const navOption4 = React.useRef(null);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    useEffect(() => {
        const title = document.getElementById("mainTitle");
        title.innerText = "Skylark Labs Privacy Policy"
        return () => {
            console.log('title changed');
        }
    }, []);
    return (
        <>
       <div className="privacybanner">
       <div className="container">
       <div className="dismanbanner">
        <div className="privacyimg">
        <img src="../images/privacy.png" alt=""/>
        </div>
              <h1>Skylark Labs Privacy Policy</h1>

              <ul>
                <li ><a href="/privacy">Privacy</a> </li>
                <li><a href="/privacy-us">US Privacy </a> </li>
                <li><a href="/privacy-canada">Canada Privacy</a> </li>
                <li  className="active"><a href="/privacy-australia">Australia Privacy</a> </li>
                <li><a href="/privacy-europe">Europe Privacy</a> </li>
               
                </ul>
              </div>
            </div>
        
       
        
      </div>

            <section className="termsprivacy">
                <div className="container">
                <div className="termsprivacycontent">
       <h2>Laws Regarding Face Recognition Technology (For Australia)</h2>
       <p>Australia Regulations regarding usage of FRT</p>
       <p>Govt. Use is allowed in regards to FRT.</p>
       <h3>
       Bill in Progress

       </h3>
       <p>A bill named Identity Matching Services Bill is in work that will allow the Govt to share FRT data between different Govt Agencies and in some cases even with the private organizations. Identity Matching is set to become the norm in the near future in Australia. The bill is currently being redrafted after significant opposition.  Despite this, Queensland, Victoria, South Australia and Tasmania have all uploaded information to the as-of-yet non-operational database, which will store the FRT data.[1] Most states have now agreed to provide biometric info to the database. The Bill can, after redrafting limit the usage of FRT in public sphere.
</p>
<p>There is also a call for moratorium of usage of FRT in public sphere, by the Australian Human Rights Commission, as long as the aforementioned bill is not passed, but the moratorium has not been officiated.
</p>
<h3>Police Use of FRT
</h3>
<p>Australian Police is known to use FRT.[2] Queensland and Western Australia are some of the states that are publicly known to have used real-time facial recognition through CCTV cameras. New South Wales has used the FRT.[3] It also has a Privacy Plan in place to describe how the privacy of the people will be protected even when using the FRT.[4] The technology is already being used by Police in Victoria.[5] FRT shall only be used at the request of a legal authority. Perth also uses FRT cameras for public surveillance.
</p>
<h4>The real status of Law
</h4>
<h3>Central</h3>
<p>As stated earlier, there are no legal framework allowing or disallowing its use at the moment. Also, there are no such regulation to ensure that the use of facial recognition technology is necessary, proportionate, and protects human rights in its application. [7]
There are also no regulations regarding Threat Prediction, and Gait Detection.
The Privacy Act does not specifically talk about the FRT. Though it does talk about personal information. The Law though does not prohibit the usage of FRT, in public or private spaces.
In some states like NSW, there are privacy centric legislations, like Privacy and Personal Information Act 1998 (NSW) which is the Privacy Law of NSW. Health Record and Information Privacy Act 2002 is also a privacy centric law in NSW. Privacy Management Plan explains how the NSW Police Force (NSWPF) complies with obligations under the Privacy and Personal Information Protection Act 1998 (PPIPA) and the Health Records and Information Privacy Act 2002 (HRIPA). NSW states that the mentioned Privacy Principles in the aforementioned laws does not apply to the Police Force in carrying out functions other than administrative or executive functions.
</p>
<h3>Effect on Business
</h3>
<p>Australia is conducive for the usage of FRT. In fact, the government actively uses FRT, and has used it during Covid for ensuring home quarantine. Private companies are also allowed to use FRT. Rather, the government has even mandated gambling establishments to use FRT to prevent thieving.
</p>
<p>Though this has to be noted that mass surveillance is still not allowed.
</p>
<h3>Usage of FRT in Private Property
</h3>
<p>In case of private property, as long as the visitors are informed that the cameras are being used through appropriate signs or posters etc., there is no legal restriction in the usage of FRT. Consent would be a prudent measure that should be taken when using FRT.[9] Some examples of use of FRT would be 7-Eleven Australia deploying facial recognition technology in its 700 stores nationwide for what it says is customer feedback. They have put up notices indicating the same. It has also been used in airports, sporting venues, banks etc. Filing someone covertly is not legal though, unless there are some public interests involved in the same.

</p>
<p>In certain cases, where there is a demonstrated public interest in FR based surveillance in a covert manner, then the FRT can be used without such notice or sign indicating its usage.
</p>
<p><strong>Overall, in Australia, there is a lack of law for FRT, and demonstrated use of FRT both by the Police, as well as Private Entities. In case no moratorium arises due to the recommendations of the Australian Human Rights Commission, the use of FRT is allowed.
</strong></p>
<h3>
 PLEASE GET IN TOUCH WITH US

       </h3>
       <p>Email us at<a href="mailto:info@skylarklabs.ai">info@skylarklabs.ai</a> if you have any questions about this Privacy Statement, the procedures we use to manage the information collected through the Website, or if you would like to modify or delete your information from our records.
</p>
        
     </div>

               
                </div>
            </section>
        </>
    );
};

export default Privacyaustralia;
