import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import $ from "jquery";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import TopBar from "./TopBar1";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "react-circular-progressbar/dist/styles.css";
import Divider from "@material-ui/core/Divider";
import { Icon } from "@material-ui/core";
import { easeQuadInOut } from "d3-ease";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import AnimatedProgressProvider from "./AnimatedProgressProvider";

const videoConstraints = {
  width: 1280,
  height: 600,
  facingMode: "user",
};
const useStyles = makeStyles(({ palette, ...theme }) => ({
  fab: {
    width: "100%",
    background: "white",
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  section: {
    padding: "20px",
  },
  sectionHeader: {
    textAlign: "center",
    fontSize: "40px",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginBottom: "50px",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "normal !important",
  },
  crop_result: {
    height: "300px",
    marginBottom: "60px",
    marginLeft: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "60px",

    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "0px",
      width: "100%",
      height: "auto",
    },
  },
}));

const WebcamComponent = () => {
  const classes = useStyles();
  const webcamRef = React.useRef(null);

  var pollVar;
  const [source, setSource] = useState(0);
  const [similarity, setSimilarity] = useState(0);
  var initialValues = {
    image: "./assets/images/face_mesh.jpg",
    blob: "",
  };
  const [referenceImage, setReferenceImage] = useState(initialValues);
  const [isProcessing, setIsProcessing] = useState(false);

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setSource(imageSrc);
  }, [webcamRef]);

  const toggle = React.useCallback(
    () => setIsProcessing(!isProcessing),
    [isProcessing, setIsProcessing]
  );

  useEffect(() => {
    // setIsProcessing(false);
    // if (referenceImage !== 0) capture();
    if (referenceImage !== initialValues) {
      capture();
      toggle();
    }
    // eslint-disable-next-line
  }, [similarity]);
  useEffect(() => {
    console.log("changed..." + isProcessing);
  }, [isProcessing]);

  useEffect(() => {
    if (source !== 0 && referenceImage !== 0) {
      //hit model here...
      setTimeout(() => {
        if (isProcessing === false) {
          console.log("processing ......: " + isProcessing);
          toggle();
          console.log("inside");
          var formData = new FormData();
          formData.append("target_image", referenceImage.blob);
          console.log(dataURLtoFile(source));
          formData.append("query_image", dataURLtoFile(source, "myfile.png"));

          formData.append(
            "key",
            "c3d0c6336b2318391a8f9c1e8b72f083586559dff2f0c1a75195056d871c25bf"
          );
          callService(
            formData,
            "https://api.skylarklabs.ai/face-recognition/",
            "image"
          );
        }
      }, 1000);
    }
    // eslint-disable-next-line
  }, [source]);

  function callService(formData, url, mode) {
    if (isProcessing === false)
      $.ajax({
        url: url,
        type: "post",
        cors: true,
        data: formData,
        contentType: false,
        processData: false,
        // headers: {
        //     'Authorization': "token 17f2b2b5ca72f9b128d5709ce2129c93fe8c1e98",
        // },
        success: function (response) {
          if (mode === "image") {
            // document.getElementById('response_result').value = "Loading Results..";
            // document.getElementById('simi').innerHTML = "Waiting for Results..";
            console.log("polling");
            if (isProcessing === false) {
              poll(this.url, response.id, mode);
            }
          } else {
            document.getElementById("response_result").value = JSON.stringify(
              response,
              ["status", "response_json", "video", "output_video"]
            );
            console.log("reacehd here");
            poll(this.url, response.id, mode);
          }
        },
      });
  }
  var pollCount = 0;
  function poll(url, id, mode) {
    if (isProcessing === false) {
      console.log(isProcessing);
      console.log("Got url" + url);

      if (mode === "image") {
        var new_url = url.toString() + id + "/";
        console.log(new_url);
        pollVar = setInterval(function () {
          pollCount++;
          if (pollCount >= 5) {
            clearInterval(pollVar);
            console.log("failed");
            //   alert("some error occurred while fetching data!");
          }
          startPolling(new_url, mode);
        }, 1000);
      } else {
        console.log("mode is video");
        document.getElementById("img_to_upload").src =
          "https://wpamelia.com/wp-content/uploads/2018/11/sliding_square_loader_view.gif";
        document.getElementById("video_to_upload").style.display = "none";
        document.getElementById("img_to_upload").style.display = "block";
        // var new_url = url.toString() + id + "/";
        console.log(new_url);
        pollVar = setInterval(function () {
          startPolling(new_url, mode);
        }, 5000);
      }
    }
  }
  function startPolling(url, mode) {
    console.log("Polling");
    $.ajax({
      url: url,
      type: "get",
      cors: true,
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data: {
        key: "c3d0c6336b2318391a8f9c1e8b72f083586559dff2f0c1a75195056d871c25bf",
      },
      // headers: {
      //     'Authorization': "token 17f2b2b5ca72f9b128d5709ce2129c93fe8c1e98",
      // },
      success: function (response) {
        var newObject = {};

        if (response.status === "success") {
          console.log("successhere");
          if (mode === "image") {
            var downloadingImage = new Image();
            downloadingImage.onload = function () {
              console.log("response received", response);
              var response_json = response["response_json"];
              var responseObj = JSON.parse(response_json);
              response["response_json"] = responseObj;
              var newResponseObj = {};
              var faces = [];

              //   var suspect = responseObj.query.faces[0].coordinates;

              var similarity = "";
              for (var i = 0; i < responseObj.query.count; i++) {
                var x = {};
                x.similarity = responseObj.query.faces[i].similarity;
                similarity = responseObj.query.faces[i].similarity;
                x.coordinates = responseObj.query.faces[i].coordinates;
                faces.push(x);
              }
              newResponseObj.faces = faces;

              newObject.response_json = newResponseObj;
              //newObject.target_image = response["target_image"];
              //newObject.query_image = response["query_image"];
              //newObject.output_image = response["output_image"];
              // newObject.status = response.status;
              // newObject.mask = mask;
              // newObject.no_mask = no_mask;
              // newObject.output_image = response.output_image;
              // image.src = this.src;
              // newObject.persons = response_json;
              if (similarity.length > 4) {
                similarity = similarity.substring(0, 3);
              }
              console.log(similarity);
              var res = parseFloat(similarity);
              res = res * 100;
              res = res.toFixed(1);
              console.log(res);

              if (res < 0) {
                res = 0;
              }
              console.log(res);
              setSimilarity(res);

              //   console.log("reached here" + response_json);
            };
            downloadingImage.src = response.output_image;
          }

          clearInterval(pollVar);
        }
      },
      fail: function (xhr, textStatus, errorThrown) {
        console.log("falied response");
      },
    });
  }

  const handleUpload1 = (event) => {
    console.log("called");

    try {
      console.log("onchange input 1");
      // var file1 = event.target.files[0];

      setReferenceImage({
        image: URL.createObjectURL(event.target.files[0]),
        blob: event.target.files[0],
      });
      $("#btn-upload")[0].value = "";
      console.log("changed");

      // document.getElementById("response_json").value = "Choose Query Image";
    } catch (error) {
      //   openNotif("Some error occurred" + error, false);
    }
  };

  return (
    <>
      <section className={classes.section}>
        <TopBar />
        <Grid container spacing={3} style={{ marginTop: "100px" }}>
          <Grid item md={9} sm={4} style={{ padding: "20px" }}>
            <div>
              <h2 className={classes.sectionHeader}>Face Recognition Demo</h2>
            </div>
            <Webcam
              audio={false}
              height="auto"
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width="auto"
              screenshotQuality={0.1}
              videoConstraints={videoConstraints}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Fab
                variant="extended"
                size="large"
                color="primary"
                onClick={capture}
                aria-label="Buy"
                className="px-20 py-10 text-24 m-2"
              >
                <Icon className="mr-4">laptop-code</Icon>
                Start
              </Fab>
            </div>
          </Grid>
          <Grid item md={3}>
            <Grid container spacing={3}>
              <Grid item md={12} sm={4}>
                <Card className="card" style={{ height: "375px" }}>
                  <img
                    className="w-full img-fluid"
                    id="result1"
                    style={{ height: "200px", objectFit: "contain" }}
                    src={referenceImage.image}
                    alt="portfolio"
                  />
                  <div className="flex-column justify-between">
                    <div className="px-4 pt-4">
                      <h5 className="m-0 text-16 font-bold">Reference Image</h5>
                      <p className="mb-4">Image should have single face</p>
                      <Divider />
                    </div>
                    <div className="px-4 py-2">
                      <label htmlFor="btn-upload">
                        <input
                          id="btn-upload"
                          name="btn-upload"
                          style={{ display: "none" }}
                          type="file"
                          accept="image/*"
                          onChange={handleUpload1}
                        />
                        <Fab
                          variant="extended"
                          component="span"
                          aria-label="Delete"
                          className={classes.fab}
                        >
                          <Icon style={{ padding: "5px" }}>link</Icon>
                          Upload
                        </Fab>
                      </label>
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item md={12} sm={4} style={{ justifyContent: "center" }}>
                <div className="section__header">
                  <h2 style={{ textAlign: "center" }}>Similarity</h2>
                </div>
                <AnimatedProgressProvider
                  valueStart={0}
                  valueEnd={similarity}
                  justify="center"
                  duration={3}
                  easingFunction={easeQuadInOut}
                >
                  {(value) => {
                    const roundedValue = Math.round(value);
                    return (
                      <div
                        style={{
                          width: "50%",
                          height: "250px",
                          marginLeft: "100px",
                          justifyContent: "center",
                          marginTop: "100px",
                        }}
                      >
                        <CircularProgressbar
                          value={value}
                          text={`${roundedValue}%`}
                          styles={buildStyles({
                            pathTransition: similarity === 0 ? "none" : "none",
                          })}
                        />
                      </div>
                    );
                  }}
                </AnimatedProgressProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* <img src={source}></img> */}
        {/* <img src={referenceImage.image}></img>
      <label htmlFor="btn-upload">
        <input
          id="btn-upload"
          name="btn-upload"
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          onChange={handleUpload1}
        />
        <Fab
          variant="extended"
          component="span"
          aria-label="Delete"
          className={classes.fab}
        >
          <Icon style={{ padding: "5px" }}>link</Icon>
          Upload
        </Fab>

       
      </label> */}
      </section>
    </>
  );
};
export default WebcamComponent;
