import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef } from "react";

const Mercedes = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Mercedes`;
  }, []);

  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);

  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "Guria NGO",
      desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
      url: "Guria-varanasi-human-trafficking",
      img: "images/cases-guria.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);

  return (
    <>
      <div className="plateformsub-page clientpage-outer">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>

        <div className="container">
          <div className="clientnavgations">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <h3>Mercedes</h3>
                <div className="clientnavgationssubtitle"><span>Bengaluru,</span>  <span>India</span> </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                lg={8}
                className="customer-page-nav-links-grid"
              >
                <div className="customer-page-nav-links-wrap">
                  <ul>
                    <li>
                      <a href="#overview">Overview</a>{" "}
                    </li>
                    <li>
                      <a href="#problem">Problem</a>{" "}
                    </li>
                    <li>
                      <a href="#solution">Solution</a>{" "}
                    </li>
                    <li>
                      <a href="#result">Result</a>{" "}
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <div className="text-left logoclient">
                <img src="images/productSlider/mercedesbenz.png" alt="" />
              </div>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client" id="overview">
                <h3>Overview</h3>
                <p>
                  Mercedes, a global leader in the automotive industry, is
                  renowned for its commitment to excellence and innovation. With
                  a rich history of engineering prowess, Mercedes continually
                  seeks to incorporate cutting-edge technologies to enhance
                  their vehicles. Their recent venture into artificial
                  intelligence (AI) and innovative tech solutions marks a
                  significant stride towards revolutionizing the automotive
                  world. Skylark Labs is proud to collaborate with Mercedes in
                  this transformative journey, bringing our expertise in AI and
                  technology to create smarter, safer, and more efficient
                  automobiles.
                </p>
                {/* <img src="images/Customers/Mercedes/mercedes_overview.png" alt="" style={{
                  borderRadius: "40px",
                }} /> */}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="problem">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Problem</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  Mercedes faces key challenges in enhancing road safety and
                  automating production processes. The focus lies on accurately
                  detecting accident hotspots and streamlining the Xentry system
                  for production and testing, ensuring the brand's commitment to
                  safety and operational excellence is upheld in the
                  ever-evolving automotive landscape.
                </p>
                <div className="customerstwocal" style={{ padding: "0" }}>
                  <h4 style={{ padding: "0" }}>Key areas of focus include:</h4>
                </div>
                <ul>
                  <li>
                    <strong>Accident Hotspot Detection:</strong> To improve road
                    safety, Mercedes aims to alert drivers about areas prone to
                    accidents. This involves identifying these hotspots and
                    analyzing individual driving behaviors to provide effective
                    and timely warnings.
                  </li>
                  <li>
                    <strong>Xentry - Automating Production and Testing:</strong>{" "}
                    Mercedes strives to automate its production and testing
                    workflows. The objective is to make these processes more
                    efficient and precise, maintaining their high-quality
                    standards and enhancing operational excellence.
                  </li>
                </ul>
                <br />
                <img
                  src="images\Customers\Mercedes\-Accident Hotspot Detectionproblem.png"
                  alt=""
                  style={{
                    borderRadius: "40px",
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="solution">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Solutions</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  Skylark Labs addresses these challenges with tailored
                  solutions: an AI-based system for real-time accident hotspot
                  alerts and a Sensor Fusion technology for automating the
                  Xentry system. These innovations are designed to elevate road
                  safety and production efficiency, aligning with Mercedes'
                  pursuit of excellence in automotive engineering.
                </p>
                <div className="customerstwocal">
                  <h4>The solutions developed are:</h4>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <img
                        src="images\Customers\Mercedes\Accident Solution_.png"
                        alt=""
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                      }}
                    >
                      <h4
                        style={{
                          fontWeight: "500",
                          margin: "0",
                        }}
                      >
                        <span className="spanfirstword">Solution</span> for
                        Accident Hotspot Detection
                      </h4>
                      <p className="pt-0 abouttext-pblock">
                        Skylark Labs is developing a cutting-edge solution for real-time driver alerts about accident-prone conditions, now enhanced with a self-learning capability to detect new curves, patterns, and evolving risk factors. This advanced system leverages real-time road condition data and historical incident analysis, continually adapting and updating its algorithms. This dynamic adaptation ensures drivers receive the most relevant and timely warnings, tailored to the constantly changing factors that contribute to accident risks on the road. This innovative approach significantly boosts safety by proactively identifying and responding to new and emerging threats in driving environments.
                      </p>
                    </Grid>
                  </Grid>
                </div>
                <div className="customerstwocal aboutbgblock">
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                      }}
                    >
                      <h4
                        style={{
                          fontWeight: "500",
                          margin: "0",
                        }}
                      >
                        <span className="spanfirstword">Solution</span> for
                        Xentry
                      </h4>
                      <p className="pt-0 abouttext-pblock">
                        Our approach to automating Mercedes' Xentry system involves a sophisticated solution that integrates Sensor Fusion technology, now enhanced with self-learning capabilities. This advanced technology analyzes and integrates data from multiple sensors, continuously learning and adapting to optimize the development and testing processes. Our solution aims to enhance efficiency, reduce errors, and uphold the high-quality standards synonymous with Mercedes, with the added benefit of evolving intelligence for improved performance and accuracy over time.
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <img
                        src="images\Customers\Mercedes\mercedes2sol_.png"
                        alt=""
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="result">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Conclusion</h3>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  The collaboration between Mercedes and Skylark Labs epitomizes
                  the fusion of automotive engineering and AI technology,
                  leading to groundbreaking advancements in vehicle safety and
                  production efficiency. This partnership is a testament to
                  Mercedes' commitment to innovation and Skylark Labs' prowess
                  in AI, together driving the future of the automotive industry.
                </p>
              </div>
              <Grid
                container
                spacing={3}
                className="customer-page-key-features"
              >
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img src="images/productSlider/Play.svg" alt="" />
                    </div>
                    <h3>90% Accurate Real-Time Alerts</h3>
                    <p>
                      Highly accurate in alerting drivers about accident-prone
                      conditions in real-time.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img src="images/productSlider/Analytics.svg" alt="" />
                    </div>
                    <h3>80% Reduced Production Line Errors</h3>
                    <p>
                      Significantly lowers errors and enhances early error
                      detection.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* 
      <div className="plateformsub-page">
        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Related</span> More Cases
                  </h2>
                </div>
              </Grid>
            </Grid>

            <div className="industriesListblock pt-5">
              <ul>
                {otherCases.map((ele, idx) => (
                  <li>
                    <a href="#contact" className="industriesListcol">
                      <div className="solutionListimg">
                        <img src={ele.img} alt="" />
                      </div>
                      <div className="industriesListcoltext">
                        <h4>{ele.title} </h4>
                        <p>{ele.desc}</p>
                        <div className="quicklinks casesquicklinks">
                          {" "}
                          View full case{" "}
                          <img src="images/right-arrow.png" alt="" />{" "}
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div> */}
    </>
  );
};

export default Mercedes;
