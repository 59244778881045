import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Etostestimonial from "home/sections/Etos-testimonial";

const Socialmediaintel = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Social Media Analysis`;
  }, []);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>

                Social Media Intel
              </h1>
              <p>
                Revealing critical intelligence from diverse social media sources to enhance safety and security for intelligence operations.
              </p>
              <div className="subtages">
                <a href="#/">Data Variety </a> <a href="#/">Custom Pipelines </a>{" "}
                <a href="#/">AI-Driven Insights </a>{" "}
                <a href="#/">Threat Prevention</a>
              </div>
            </div>
          </div>
        </div>

        <div className="imgcenter bannerimgblock ">
          <img src="../images/productSlider/social-media-analysis.jpg" alt="" />
        </div>
      </div>

      {/* <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>Social Media Intel</h2>
            <p>
              Revealing critical intelligence from diverse social media sources to enhance safety and security for intelligence operations.

            </p>
          </div>
        </div>
      </div> */}

      <div className="plateformsub-page">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>
        <div className="container pt-5">
          <div className="plateformabout  mt-5">
            <Grid container spacing={3} className="socialmediaintel-about-container">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="aboutimgblock" style={{
                  padding: "0"
                }}>
                  {" "}
                  <img src="images/echo-insight-d.png" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 pb-0">
                      <span className="spanfirstword">About </span>Social Media Intel
                    </h2>
                    <p className="pt-0 abouttext-pblock">
                      In today's digital age, social media platforms have become the epicenter of global communication, trends, and sentiments. The Social Media Intel module by Turing empowers organizations to delve deep into platforms like Twitter, Facebook, YouTube, etc. By leveraging advanced AI algorithms, Social Media Intel provides real-time insights, helping organizations understand emerging trends, gauge public sentiments, and identify potential threats in the vast digital landscape.

                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>


        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container">
            <div className="seactionblocktitles">
              <h2 className="seactiontitles">Unlocking Social Media Insights for Safer Intelligence
              </h2>
              <p>
                Integrate text, audio, video, and images, create custom pipelines, and extract valuable trends.

              </p>
            </div>
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Public Safety</span>{" "}
                      and Emergency Response
                    </h4>
                    <p>
                      Utilize social media intelligence to enhance public safety, emergency responsiveness, and disaster management.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Public Safety Monitoring
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Disaster Response and Management

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Counter-Terrorism and National Security
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Public Health Surveillance
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/Real-time Social Media Scrutiny.png" />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/Customizale dashboards.png" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Business Optimization</span>{" "} and Societal Insights

                    </h4>
                    <p>
                      Drive business optimization and gain societal insights by analyzing social media dynamics and trends.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Customer Service and Engagement

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Influencer Marketing and Engagement

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Legal and Compliance Monitoring


                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Environmental Monitoring and Advocacy


                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>

            </Grid>
          </div>
        </section>
        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Strategic Communications</span> and Market Intelligence
                    </h4>
                    <p>
                      Refine communication strategies and gain market insights through advanced social media analysis.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Market Research and Consumer Insights

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Political Campaigns and Public Opinion Analysis

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Brand Reputation Management
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Crisis Communication and Management
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/Predictive Supply Chain Analytics.png" alt="" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>


        {/* <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/In-depth Sentiment Analysis.png" alt="" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">In-depth </span>  Sentiment Analysis

                    </h4>
                    <p>
                      Dive deep into the sentiments expressed across social media platforms. Understand public opinion, gauge brand perception, and derive actionable insights from user-generated content.

                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Advanced Sentiment Algorithms

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Positive/Negative Sentiment Gauge


                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Entity Perception Analysis

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Actionable Insight Generation

                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section> */}


        <section className="configurations">
          <div className="container">
            <div className="">
              <h3>Turing: Next-gen Features for Modern Intelligence
              </h3>
              {/* <p>
            ADIVS boasts a range of innovative features tailored to meet the dynamic needs of Intelligence Organizations.

            </p> */}
            </div>

            <div className="boxouterproduct">


              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt="" /> </div>
                <h4>Semantics Analysis</h4>
                <p>
                  Unlock the power of semantics with our intuitive platform. Seamlessly analyze text using advanced semantic techniques, providing you with valuable insights and a deeper understanding of your data.
                </p>
              </div>
              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt="" /> </div>

                <h4>Data Filtering</h4>
                <p>
                  Efficiently sift through extensive datasets using advanced customizable filtering techniques. Tailor your filters based on criticality, hashtags, and more, giving you precise control over the information you need.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt="" /> </div>

                <h4>Unknown Threats</h4>
                <p>
                  The Platform possesses the capability to analyze data sources and accurately identify both known and previously unidentified new threats. This ensures comprehensive threat detection and protection.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt="" /> </div>

                <h4>Relationship Analysis</h4>
                <p>
                  This feature performs in-depth analysis to uncover various connections and relationships associated with the identified targets of interest. It provides valuable insights into networks and associations within the monitored data.
                </p>
              </div>


              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt="" /> </div>

                <h4>Intelligent Threat Verification</h4>
                <p>
                  Leverage our platform's integration of physical sensors and online data for swift and precise threat detection. Navigate with ease using our user-friendly UI, ensuring top-tier security.

                </p>
              </div>


              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt="" /> </div>

                <h4>Future Risk Anticipation</h4>
                <p>
                  Harness the power of our advanced UI's entity profiling to anticipate potential threats. Through meticulous future simulations, we elevate an entity's risk profile, equipping you with insights into probable threats down the road. Stay a step ahead and prepare effectively.


                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Other</span> System
                    Configurations
                  </h2>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured productfeatured_advis">
                  <a href="news-watch">

                    <h5>NewsWatch

                    </h5>

                    <div className="imgblockfeatured">
                      <img src="images/productSlider/02.png" alt="" />

                    </div>
                    <div className="readmore-btns"><span>Learn More <img src="/images/productSlider/nextarrow.svg" alt="" /></span> </div>

                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured productfeatured_advis">
                  <a href="supply-intel">
                    <h5>SupplyIntel</h5>
                    <div className="imgblockfeatured">
                      <img src="images/productSlider/03.png" alt="" />
                    </div>
                    <div className="readmore-btns"><span>Learn More <img src="/images/productSlider/nextarrow.svg" alt="" /></span>  </div>
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured productfeatured_advis">
                  <a href="financial-flux">
                    <h5>FinancialFlux</h5>
                    <div className="imgblockfeatured">
                      <img src="images/productSlider/04.png" alt="" />
                    </div>
                    <div className="readmore-btns"><span>Learn More <img src="/images/productSlider/nextarrow.svg" alt="" /></span> </div>
                  </a>
                </div>
              </Grid>

            </Grid>
          </div>
        </section>

      </div>

      <Etostestimonial />
    </>
  );
};

export default Socialmediaintel;
