import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef } from "react";

const Indianarmy = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Indian Army`;
  }, []);

  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);

  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "Guria NGO",
      desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
      url: "Guria-varanasi-human-trafficking",
      img: "images/cases-guria.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);

  return (
    <>
      <div className="plateformsub-page clientpage-outer">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>

        <div className="container">
          <div className="clientnavgations">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <h3>Indian Army</h3>
                <div className="clientnavgationssubtitle"><span>Delhi,</span>  <span>India</span> </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                lg={8}
                className="customer-page-nav-links-grid"
              >
                <div className="customer-page-nav-links-wrap">
                  <ul>
                    <li>
                      <a href="#overview">Overview</a>{" "}
                    </li>
                    <li>
                      <a href="#problem">Problem</a>{" "}
                    </li>
                    <li>
                      <a href="#solution">Solution</a>{" "}
                    </li>
                    <li>
                      <a href="#result">Result</a>{" "}
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <div className="text-left logoclient">
                <img src="images/productSlider/indian-army.png" alt="" />
              </div>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client" id="overview">
                <h3>Overview</h3>
                <p>
                  The Indian Army, an integral part of India's defense forces, is renowned for its versatility and adaptability across diverse operational scenarios. With a steadfast commitment to national security, the Army operates in various terrains, from rugged high-altitude landscapes to dense urban areas. In recent years, the Army has increasingly focused on countering cyber threats and misinformation in the digital domain, recognizing the significance of digital warfare in contemporary security dynamics. This shift necessitates advanced technological solutions to keep pace with the rapidly evolving nature of digital threats and to maintain India's strategic edge.
                </p>
                {/* <img src="images/Customers/Indian-Army/indian army overview.jpg" alt="" style={{
                  borderRadius: "40px",
                }} /> */}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="problem">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Problem</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  The Indian Army is tackling the vital task of monitoring and analyzing social media on a large scale to identify potential threats and misinformation. In today's digital world, this effort is key to national security and managing information warfare. With digital platforms growing rapidly and information spreading quickly, the Army needs a powerful, real-time monitoring system. This system is essential for processing large amounts of data, allowing the Army to proactively detect and respond to cyber threats, keeping it ahead in the realm of information warfare.
                </p>
                <img src="images/Customers/Indian-Army/indianarmyproblem.png" alt="" style={{
                  borderRadius: "40px",
                }} />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="solution">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Solutions</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  In response to the Army's cybersecurity needs, Skylark Labs has leveraged Turing's Social Media Intel to develop a GUI-Based Social Media Monitoring Platform. This AI-powered platform is specifically designed for efficient social media surveillance, providing real-time data analysis to preempt and counteract cyber threats. Incorporating Turing's module enhances the system's ability to rapidly collect, process, and interpret vast social media data. Its intuitive user interface and sophisticated analytics tools are key in detecting emerging cyber threats swiftly, marking a significant advancement in the Army's digital intelligence capabilities. This integration of Turing's Social Media Intel module is pivotal in modernizing and strengthening the Army's proactive cybersecurity posture.
                </p>
                <img src="images/Customers/Indian-Army/indianarmysolution.png" alt="" style={{
                  borderRadius: "40px",
                }} />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="result">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Conclusion</h3>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  The collaboration between Skylark Labs and the Indian Army marks a significant advancement in military cybersecurity. This state-of-the-art social media monitoring platform enhances the Army's digital intelligence and security capabilities. It reflects the Army's dedication to technological innovation, ensuring India's robust defense against evolving digital threats.
                </p>
              </div>
              <Grid container spacing={3} className="customer-page-key-features">
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img
                        src="images/productSlider/Play.svg"
                        alt=""
                      /></div>
                    <h3>
                      90% Faster Real-Time Data Analysis
                    </h3>
                    <p>Social media data analysis efficiency drastically improved compared to previous methods.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img
                        src="images/productSlider/Analytics.svg"
                        alt=""
                      /></div>
                    <h3>
                      85% Enhanced Threat Detection Speed
                    </h3>
                    <p>Value: "Quick detection of emerging known and unknown threats."</p>
                  </div>
                </Grid>

              </Grid>
            </Grid>
          </Grid>

        </div>
      </div>
      {/* 
      <div className="plateformsub-page">
        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Related</span> More Cases
                  </h2>
                </div>
              </Grid>
            </Grid>

            <div className="industriesListblock pt-5">
              <ul>
                {otherCases.map((ele, idx) => (
                  <li>
                    <a href="#contact" className="industriesListcol">
                      <div className="solutionListimg">
                        <img src={ele.img} alt="" />
                      </div>
                      <div className="industriesListcoltext">
                        <h4>{ele.title} </h4>
                        <p>{ele.desc}</p>
                        <div className="quicklinks casesquicklinks">
                          {" "}
                          View full case{" "}
                          <img src="images/right-arrow.png" alt="" />{" "}
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div> */}
    </>
  );
};

export default Indianarmy;