import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  intro: {
    padding: "180px 0 0 !important",
    overflow: "visible !important",

    [theme.breakpoints.down("sm")]: {
      padding: "100px 0 0 !important",
    },
  },
  product: {
    textAlign: "center",
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    margin: "auto",
    bottom: -80,
    maxWidth: 780,
    marginBottom: "5rem",
    "& img": {
      borderRadius: "0.5rem",
      boxShadow: "0 10px 16px rgba(0, 0, 0, 0.2)",
    },

    [theme.breakpoints.down("sm")]: {
      bottom: -63,
    },

    [theme.breakpoints.down("xs")]: {
      bottom: -39,
    },
  },
  title: {
    textShadow: "0 4px 4px rgba(0, 0, 0, 0.22)",
  },
}));

const MediCoverage = () => {
  var settings = {
    dots: false,
    infinite: true,
         autoplay: true,
         autoplaySpeed: 0,
         cssEase: 'linear',
         speed: 2500,
         swipeToSlide: true,
         slidesToShow: 5.8,
        //  slidesToScroll: -3,
        initialSlide: 0,
        responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2.5
        }
      }
    ]
  };
  
  var rtl = {
    ...settings,
    slidesToScroll:1,
  }

  var ltr = {
    ...settings,
    slidesToScroll: -1,
  }

  const classes = useStyles();

  return (
    <section
      className={clsx("mediacoverage_section")}


    >
      <div className="container">
        <h2 className="">Media Coverage</h2>
      </div>

      <div className="container">
        <div className="mediacoverage_outer">
        <Slider {...rtl}>
        <a href="https://www.newscientist.com/article/2146703-even-a-mask-wont-hide-you-from-the-latest-face-recognition-tech/" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/the_new_scientist.png" alt="" /> </div></a>
        <a href="https://www.economist.com/leaders/2017/09/09/what-machines-can-tell-from-your-face" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/economist.png" alt="" /></div></a>
        <a href="https://www.seeker.com/tech/artificial-intelligence/this-artificial-intelligence-system-can-id-faces-even-if-they-are-disguised" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/seeker.png" alt="" /></div></a>
        <a href="https://www.technologyreview.com/2017/09/08/149250/facial-recognition-is-getting-incredibly-powerful-and-ever-more-controversial/" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/MIT_technology_review.png" alt="" /></div></a>
        <a href="https://mashable.com/article/facial-recognition-masks-protesters#H3x0ArbSKaqp" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/businesswire.png" alt="" /></div></a>
        <a href="https://www.vice.com/en/article/mbby88/ai-will-soon-identify-protesters-with-their-faces-partly-concealed" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/vice.png" alt="" /> </div></a>
        <a href="https://www.digitaltrends.com/cool-tech/facial-recognition-in-disguise/" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/digital_rrends.png" alt="" /></div></a>
        <a href="https://www.theverge.com/2017/9/6/16254476/facial-recognition-masks-diguises-ai" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/theverge.png" alt="" /></div></a>

        <a href="https://qz.com/1069756/using-artificial-intelligence-to-identify-protestors-wearing-hats-or-scarves-is-entirely-possible/" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/quartz.png" alt="" /></div></a>
        <a href="https://futurism.com/are-engineers-responsible-for-the-consequences-of-their-algorithms" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/futurism.png" alt="" /> </div></a>
        <a href="https://www.france24.com/fr/20170907-une-intelligence-artificielle-permet-didentifier-personnes-meme-lorsquelles-portent-masque-lunettes" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/france24.png" alt="" /></div></a>
        <a href="https://www.inverse.com/article/36138-facial-recognition-and-disguise" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/inverse.png" alt="" /></div></a>

        </Slider>
        <Slider {...ltr}>
        <a href="https://expressdigest.com/facial-recognition-software-will-soon-id-covered-faces/" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/expressdigest.png" alt="" /> </div></a>
        <a href="https://jack-clark.net/2017/09/04/import-ai-issue-58-ai-makes-facial-identification-systems-see-through-masks-creating-yelp-foolin-fake-reviews-and-automated-creativity-with-pix2pix/" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/IMPORTAI.png" alt="" /></div></a>
        <a href="https://www.biometricupdate.com/201709/researchers-developing-ai-powered-facial-recognition-to-identify-concealed-persons" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/biometricupdate.png" alt="" /></div></a>
        <a href="https://techxplore.com/news/2017-09-disguised-deep-convolutional-network.html" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/techx.b-cdn.png" alt="" /></div></a>
        <a href="https://www.techgenyz.com/2017/09/07/new-ai-disguised-protesters-rallies/" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/gearsofbiz.png" alt="" /></div></a>
        <a href="https://www.cbinsights.com/research/facial-recognition-privacy-ai/" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/cbInsights.png" alt="" /> </div></a>
        <a href="https://www.businessinsider.com/facial-recognition-controversy-improvement-2017-9?IR=T" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/Insider.png" alt="" /></div></a>
        <a href="https://www.dailymail.co.uk/sciencetech/article-4860908/Facial-recognition-software-soon-ID-covered-faces.html#comments" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/dailymail.png" alt="" /></div></a>
       
        <a href="https://soundcloud.com/amarjot-singh-436023899/dr-singhs-ceo-skylark-labs-interview-with-bbc-radio-about-their-drone-surveillance-system" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/BBC_radio.png" alt="" /></div></a>
        <a href="https://soundcloud.com/amarjot-singh-436023899/dr-singhs-ceo-skylark-labs-interview-with-abc-australia-about-their-drone-surveillance-system" target="_blank" rel="noopener noreferrer"><div className="medialogo"> <img src="images/radio_australia.png" alt="" /></div></a>
       
        
        </Slider>
        </div>
      </div>

    </section>
  );
};

export default MediCoverage;
