import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import videobanner from "../videofiles/aries-banner.mp4";
import PropTypes from 'prop-types';
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import Small from "../videofiles/small-aries.mp4";
import Miras from "../videofiles/home-video-mission.mp4";
import etosvideo from "../videofiles/etos-page-banner.mp4";
import mirasvideo from "../videofiles/miras-main-banner.mp4";
import satellitevideo from "../videofiles/dros-banner.mp4";

import Ariestestimonial from "home/sections/Aries-testimonial";

const Aries = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Land Systems:  Land to Air System -Aerial Reconnaissance & Elimination System`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [expanded, setExpanded] = React.useState("");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>
                <span className="mainsubtitle">ARIES</span>
                Aerial Reconnaissance & Elimination System
              </h1>
              <p>
                ARIES detect Aerial objects and their movement to detect unauthorized flying objects to predict potential critical threats.
              </p>
              <div className="subtages"><a>Suspicious Drones </a>  <a>Military Air Objects</a><a>Large Drones</a> </div>


            </div>
          </div>
        </div>

        <div className="imgcenter bannerimgblock ">
          <ReactPlayer
            controls={true}
            volume={0}
            width="100%"
            height="100%"
            loop={true}
            playsinline
            muted={false}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}

            url="https://d1nbq27kwttg28.cloudfront.net/blog/aries-banner.3cee2558.mp4"

          />
        </div>
      </div>

      {/* <div className="platform_banner_dis banner_dis_mob  pt-5 ">
        <div className="container">
          <div className="dismanbanner">
            <h2> Aerial Reconnaissance & Elimination System  </h2>
            <p>
              ARIES detect Aerial objects and their movement to detect unauthorized flying objects to predict potential critical threats.

            </p>

          </div>
        </div>
      </div> */}



      <div className="plateformsub-page">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>
        <div className="container pt-5">
          <div className="plateformabout  mt-5">
            <Grid container spacing={3} className="aries-about-container">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="aboutimgblock">
                  {" "}
                  <img src="images/infographics-arioes.png" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 pb-0">
                      <span className="spanfirstword">Detect </span> Aerial Threats </h2>
                    <p className="pt-0 abouttext-pblock">
                    ARIES (Aerial Reconnaissance & Interception Evolved System) is an advanced counter-UAS solution that provides comprehensive airspace security. The system consists of airborne and ground-based components working together to detect, track, and neutralize hostile UAVs and other aerial threats. ARIES employs advanced sensors, electronic warfare capabilities, and kinetic counter-measures to ensure early warning, rapid response, and effective protection of critical infrastructure against the growing threat of airborne attacks.                      </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container">
            <div className="seactionblocktitles">
              <h2 className="seactiontitles">Safeguarding the Skies</h2>
              <p>
                Advanced surveillance and rapid response for total airspace security.
              </p>
            </div>
          </div>

          <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>

          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Border Monitoring</span>{" "}
                    </h4>
                    <p>
                    Vigilant aerial security system to detect and mitigate threats over national borders.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          UAV Detection ("Border Intrusion Scout")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Behavioral Analysis ("Cross-Border Movement Profiler")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Alert Generation and Threat Prioritization ("Border Alert Commander")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Identification of Coordinated Threats ("Intrusion Coordination Analyst")
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">

                  <img src="images/Platforms/aries/border_monitoring.png" />
                </div>
              </Grid>
            </Grid>
          </div>

        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>

          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/Platforms/aries/military_base.png" />

                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Military Base Defense</span>
                    </h4>
                    <p>
                    Advanced airspace monitoring to protect military personnel and assets from aerial threats.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Military Aircraft Monitoring ("Base Airwatch Defender")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Flight Pattern Analysis ("Tactical Flight Analyzer")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Enhanced Situational Awareness ("Base Command Visualizer")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Support in Countermeasure Planning ("Strategic Defense Planner")
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          
        </section>


        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>

          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Government Facility Security</span>
                    </h4>
                    <p>
                    Ensures the integrity and security of government facilities from overhead risks.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Advanced Object Recognition ("Gov Facility Sky Guard")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Real-Time Airspace Mapping ("Capital Airspace Mapper")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Automated Reporting System ("Official Security Reporter")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Alert Generation and Threat Prioritization ("Civic Alert Distributor")
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/Platforms/aries/government_facility.png" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>

          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/Platforms/aries/airport_airspace.jpeg" />

                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Airport Airspace Protection</span>
                    </h4>
                    <p>
                    Protects airports by continuously monitoring and neutralizing potential aerial disruptions.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          UAV Pattern and Formation Analysis ("Airport UAV Tracker")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Flight Pattern Analysis ("Airport Traffic Coordinator")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Enhanced Situational Awareness ("Airfield Safety Enhancer")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Automated Reporting System ("Flight Security Logger")
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>

          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Critical Infrastructure Protection</span>
                    </h4>
                    <p>
                    Guards essential services against aerial threats through cutting-edge surveillance technology.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Advanced Object Recognition ("Infrastructure Air Shield")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Real-Time Airspace Mapping ("Utility Airspace Monitor")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Identification of Coordinated Threats ("Infrastructure Threat Synergist")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Support in Countermeasure Planning ("Infrastructure Defense Strategist")
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/Platforms/aries/critical_infra.png" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/Platforms/aries/port_and_harbour.png" />

                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Port and Harbor Security</span>
                    </h4>
                    <p>
                    Secures maritime operations by monitoring and addressing unauthorized aerial activities.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          UAV Detection ("Harbor UAV Watch")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Behavioral Analysis ("Port Activity Analyst")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Alert Generation and Threat Prioritization ("Maritime Alert System")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          UAV Pattern and Formation Analysis ("Dock Formation Supervisor")
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>

          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Public Event Airspace Management</span>
                    </h4>
                    <p>
                    Provides aerial surveillance to ensure public safety during large-scale events.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Enhanced Situational Awareness ("Event Crowd Overlook")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Behavioral Analysis ("Crowd Dynamics Investigator")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Identification of Coordinated Threats ("Event Threat Coordinator")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Support in Countermeasure Planning ("Public Safety Planner")
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/Platforms/aries/public_event.png" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          
        </section>
        </section>


        <section className="configurations">
          <div className="container">
            <div className="">
              <h3>ARIES: Enhanced Airspace Security System</h3>
              <p>
              ARIES is a cutting-edge counter-UAS solution designed to ensure airspace security. Combining advanced sensors, electronic warfare, and kinetic countermeasures, ARIES excels in detecting, tracking, and neutralizing aerial threats, providing comprehensive protection for critical infrastructures.
              </p>
            </div>

            <div className="boxouterproduct">
              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Real-time Processing</h4>
                <p>
                ARIES uses efficient algorithms to process data from multiple sensors simultaneously in real-time, enabling swift threat detection and response, essential for dynamic environments.
                </p>
              </div>
              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4> BVLOS (Beyond Visual Line of Sight) Threat Detection</h4>
                <p>
                ARIES detects threats beyond visual range, providing extensive security in diverse operational contexts, from urban areas to remote settings.                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4> Identification Friend or Foe (IFF) System</h4>
                <p>
                ARIES uses an advanced IFF system that electronically identifies friend from foe among aircraft or UAVs using coded replies, minimizing friendly fire and engaging only confirmed threats.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Layered Defense Capabilities</h4>
                <p>
                ARIES can be integrated with other air defense systems and assets, such as surface-to-air missiles or manned interceptor aircraft, to provide a comprehensive, layered defense against aerial threats.
                </p>
              </div>

              {/* <div className="boxouterproductItem">
            <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt=""/> </div>

              <h4>Stress Free Environment</h4>
              <p>
              Receiving only pertinent alerts relieves soldiers from constant video wall monitoring, allowing them to focus when an audio-visual alert is triggered.
              </p>
            </div> */}

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Integration with Command and Control</h4>
                <p>
                The IFF system is integrated with the command and control unit, allowing for the sharing of identification data and enabling coordinated responses to threats.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Hardware Agnostic</h4>
                <p>
                The solution can integrate with a wide range of sensors without specific hardware requirements, making it adaptable to different environments and existing infrastructure.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Interoperability with NATO and Allied Standards</h4>
                <p>
                The IFF system is designed to be compatible with NATO and allied standards, ensuring seamless integration and coordination with other air defense assets during joint operations.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Assisted Border Planning</h4>
                <p>
                The platform identifies areas with recurring alerts, aiding in strategic troop deployment and fortification of perimeter infrastructure, enhancing overall border security.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Economical & Configurable</h4>
                <p>
                ARIES can adapt to existing hardware with low computational demands and simple configuration, making it cost-effective and easy to deploy.
                </p>
              </div>

            </div>
          </div>
        </section>



        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Other</span> System
                    Configurations
                  </h2>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured">
                  <a href="/bros">
                    <h5>
                      BorderSentinel<br /><br />
                    </h5>
                    <div className="imgblockfeatured">
                      <ReactPlayer
                        playsinline
                        controls={true}
                        volume={0}
                        width="100%"
                        height="100%"
                        loop={true}
                        muted={false}
                        playing={true}
                        stopOnUnmount={true}
                        config={{
                          file: {
                            attributes: {
                              autoplay: true,
                              controls: false,
                              loop: true,
                              muted: true,
                            },
                          },
                        }}
                        url={etosvideo}
                      />
                      <div className="readmore-btns">
                        <span>
                          Learn More{" "}
                          <img src="/images/productSlider/nextarrow.svg" alt="" />
                        </span>{" "}
                      </div>
                    </div>
                  </a>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured">
                  <a href="miras">
                    <h5>
                      Marine Reconnaissance <br></br>System
                    </h5>
                    <div className="imgblockfeatured">
                      <ReactPlayer
                        controls={true}
                        playsinline
                        volume={0}
                        width="100%"
                        height="100%"
                        loop={true}
                        muted={false}
                        playing={true}
                        stopOnUnmount={true}
                        config={{
                          file: {
                            attributes: {
                              autoplay: true,
                              controls: false,
                              loop: true,
                              muted: true,
                            },
                          },
                        }}
                        url={mirasvideo}
                      />
                    </div>
                    <div className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}
                    </div>
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured">
                  <a href="dros">
                    <h5>
                      Drone Reconnaissance and <br></br> Observation System
                    </h5>

                    <div className="imgblockfeatured">
                      <ReactPlayer
                        controls={true}
                        playsinline
                        volume={0}
                        width="100%"
                        height="100%"
                        loop={true}
                        muted={false}
                        playing={true}
                        stopOnUnmount={true}
                        config={{
                          file: {
                            attributes: {
                              autoplay: true,
                              controls: false,
                              loop: true,
                              muted: true,
                            },
                          },
                        }}
                        url={satellitevideo}
                      />
                    </div>
                    <div className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}
                    </div>
                  </a>
                </div>
              </Grid>

            </Grid>
          </div>
        </section>


      </div>







      <Ariestestimonial />
    </>
  );
};

export default Aries;
