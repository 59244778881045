import React, { useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import videobanner from "../videofiles/Self-learling.mp4";

const Selflearingai = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Product Page:  Self Learing AI - Turing is a cutting-edge AI suite tailored for Intelligence Organizations`;

    window.addEventListener("scroll", scrollCheck);
  }, []);

  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  return (
    <>
      <div className="platform_banner productbanner newproductbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>What Is Self-learning AI ?</h1>
              <p className="graycolors">
                "Traditional software evolved into AI, and now self-learning AI
                is the next big leap."
              </p>
            </div>
          </div>
        </div>

        <div className="imgcenter bannerimgblock selflearning-video">
          <ReactPlayer
            controls={true}
            playsinline
            volume={0}
            width="100%"
            height="100%"
            loop={true}
            muted={false}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url="https://d1nbq27kwttg28.cloudfront.net/blog/Self-learling.mp4"
          />
        </div>
      </div>

      {/* <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>
              Self learning AI: Turing is a cutting-edge AI suite tailored
              for Intelligence Organizations{" "}
            </h2>
            <p>
              It efficiently searches data from various online platforms,
              offering proactive alerts based on comprehensive risk assessments
            </p>
          </div>
        </div>
      </div> */}

      <div className="topplatformsblock pbottom">
        <section className="productoverviewblock section-productoverviewblock section-productoverviewblockfirstbox">
          <div className="container aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoleftblock bordernone p-5">
                  <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/K0nzQoT6Ymw?si=IA10l8er0KgO74P4"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew pt-3">
                  <div>
                    <h2 className="mb-0 mb-0 pb-0 pt-5">
                      <span className="spanfirstword">Self-learning</span> AI
                    </h2>
                    <p className="pt-0 textlinks">
                      {" "}
                      Software was initially built to address specific, narrow
                      tasks, operating within well-defined parameters. However,
                      AI technology has significantly enhanced this capability,
                      allowing for more dynamic problem-solving. Despite these
                      advancements, AI systems still struggle when faced with
                      new environments and unforeseen threats, particularly in
                      unconstrained, often hostile settings. Leveraging our
                      experience from <a href="#darpalogo"> DARPA programs,</a> <i>
                        we've developed variants of <a href="https://patents.google.com/?q=(amarjot)&inventor=singh&oq=amarjot+singh" target="_blank">patented</a> commercially viable, self-learning AI systems.</i> This
                      technology represents a major advancement, offering
                      autonomous and adaptable AI solutions for various
                      industries.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div className="pricing-one__shape-2"></div>
          </div>
        </section>
        <div className="container">
          <h2 className="maintitle-seleflearning text-center">
            <span className="spanfirstword">Evolution</span> of Traditional
            Software into AI
          </h2>
        </div>
        <div className="container pb-5">
          <div className="customer-filter-main mb-5 pt-0">
            <div className="filter-top">
              <ul>
                <li className="active">
                  <a href="#1945">1945: Software</a>{" "}
                </li>{" "}
                <li className="active">
                  <a href="#1984">1984: Artificial Intelligence</a>{" "}
                </li>{" "}
                <li className="active">
                  <a href="#2018">
                    2018: Latest AI Products and their Limitations
                  </a>{" "}
                </li>{" "}
                <li className="active">
                  <a href="#2021">2021: Self-Learning AI</a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container" id="1945">
          <Grid container spacing={3}>
            <Grid item xs={2} sm={2} md={1} lg={1}>
              <div className="circle mx-auto"></div>
              <div className="campaignline mx-auto"></div>
            </Grid>
            <Grid item xs={10} sm={10} md={11} lg={11}>
              <div className="rightblock-learning">
                <div className="pagetitle-self mb-5">
                  <h2 className="pb-0 mb-0">1945</h2>

                  <h3 className="colorthree mt-0 pt-0">
                    First Commercial Software
                  </h3>
                  <p className="laxtbloc-bottomspace-40"></p>
                </div>
                {/* <a href="#/" className="eyebrow-banner">
                  <div className="eyebrow-banner-sub">
                    <div className="titleselflearning-link">
                      <h4>GitHub Universe: AI. Security. DevEx</h4>
                      <p>Register for free and join us on Nov 8-9</p>
                    </div>
                    <span>
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>
                  </div>
                </a> */}
                <div className="pagetitle-self mb-5">
                  <div className="learningcontnetboloc01 bottommargin-space aboutbgblock">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className=" solutions-two__content-two-img">
                          <h3>
                            Grace Hopper, Rear Admiral US Navy, operating
                            software
                          </h3>
                          <p className="laxtbloc-bottomspace">
                            The official first piece of software to be written
                            was created with punch cards to complete
                            calculations! This achievement led to a boom in
                            software development, including the creation of the
                            first programming language. <br></br> <br></br>
                            <span className="itsame">
                              Software was mainly a set of if-else statements
                              used to execute a fixed and narrow task
                            </span>
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className=" ">
                          <div className="learningtwo">
                            <img
                              src="images/productSlider/Grace-Hopper-UNIVAC.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  {/* <p className="pb-5 logo-selflearnings-title">
                    Trusted by the world’s leading organizations
                  </p>
                  <div className="logo-selflearnings laxtbloc-bottomspace pt-5">
                    <img src="images/productSlider/001.svg" alt="" />
                    <img src="images/productSlider/002.svg" alt="" />
                    <img src="images/productSlider/003.svg" alt="" />
                    <img src="images/productSlider/004.svg" alt="" />
                    <img src="images/productSlider/005.svg" alt="" />

                    <img src="images/productSlider/006.svg" alt="" />
                  </div> */}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="container" id="1984">
          <Grid container spacing={3}>
            <Grid item xs={2} sm={2} md={1} lg={1}>
              <div className="mx-auto">
                <div className="codeicons">
                  <span>
                    <svg
                      aria-hidden="true"
                      height="24"
                      viewBox="0 0 24 24"
                      version="1.1"
                      width="24"
                      data-view-component="true"
                      class="octicon octicon-code"
                    >
                      <path d="M15.22 4.97a.75.75 0 0 1 1.06 0l6.5 6.5a.75.75 0 0 1 0 1.06l-6.5 6.5a.749.749 0 0 1-1.275-.326.749.749 0 0 1 .215-.734L21.19 12l-5.97-5.97a.75.75 0 0 1 0-1.06Zm-6.44 0a.75.75 0 0 1 0 1.06L2.81 12l5.97 5.97a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215l-6.5-6.5a.75.75 0 0 1 0-1.06l6.5-6.5a.75.75 0 0 1 1.06 0Z"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div className="campaignline linetwo mx-auto hline-94"></div>
            </Grid>
            <Grid item xs={10} sm={10} md={11} lg={11}>
              <div className="rightblock-learning">
                <div className="pagetitle-self mb-5">
                  <h2>1984</h2>

                  <h3 className="colortwo"> First Commercial AI Software</h3>
                  {/* <p className="laxtbloc-bottomspace-40">
                    Monitors and detects suspicious moving objects and alerts
                    the authorities in real-time.
                  </p> */}
                </div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="learningcontnetboloc01 towcallsblock">
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div className="learningtwo imgblowtwocol">
                            <img
                              src="images/productSlider/block02.jpg"
                              alt=""
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div className=" ">
                            <h3>
                              Douglas Lenat at Microelectronics and Computer
                              Technology Corporation
                            </h3>
                            <p className="laxtbloc-bottomspace pb-4">
                              CYC was an ambitious project aimed at developing
                              AI systems that understood real-world contexts. It
                              later evolved into a commercial venture, Cycorp.{" "}
                              <br></br> <br></br>{" "}
                              <span className="itsame">
                                This AI software is designed to generalize
                                beyond the limitations of these if-else
                                statements, expanding the operation's capability
                              </span>
                            </p>
                          </div>
                        </Grid>
                      </Grid>
                      {/* <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div className="dashboar-learning mt-5">
                            <img
                              src="images/productSlider/self-learning.png"
                              alt=""
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div className="dashboar-learning mt-5">
                            <img
                              src="images/productSlider/self-learning.png"
                              alt=""
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div className="dashboar-learning mt-5">
                            <img
                              src="images/productSlider/self-learning.png"
                              alt=""
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div className="dashboar-learning mt-5">
                            <img
                              src="images/productSlider/self-learning.png"
                              alt=""
                            />
                          </div>
                        </Grid>
                      </Grid>
                       */}
                      <div className="solutions-two__shape-1"></div>
                      <div className="collaboration-one__shape-1"></div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="container" id="2018">
          <Grid container spacing={3}>
            <Grid item xs={2} sm={2} md={1} lg={1}>
              <div className="campaignline linetwo mx-auto"></div>

              <div className="roundshaps">
                <img
                  src="images/productSlider/git-branch-security-2.svg"
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={10} sm={10} md={11} lg={11}>
              <div className="rightblock-learning">
                <div className="pagetitle-self mb-5 outsideshare-title">
                  <h2>2018-2023</h2>

                  <h3 className="colorthree laxtbloc-bottomspace-40">
                    Latest AI Products and their Limitations
                  </h3>
                  {/* <p className="laxtbloc-bottomspace-40">
                    Identifies suspicious behavior and actions that have the
                    potential to evolve into major threats.
                  </p> */}
                </div>
                <div className="dashboar-learning">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="learningcontnetboloc01 towcallsblock pt-5">
                        <div className="pb-4 ">
                          <h3 className="line-height-none">Regular AI</h3>
                        </div>
                        <div className="learningtwo solutions-two__content-two-img ">
                          <img src="images/productSlider/010.png" alt="" />
                        </div>
                        <p className="laxtbloc-bottomspace pb-0 mb-0">
                          Regular AI, reliant on large server setups for data
                          insights, is less suited for defense due to its need
                          for extensive datasets and high computational power,
                          which are not always available in such contexts.
                        </p>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="learningcontnetboloc01 towcallsblock pt-4">
                        <div className="pb-4 ">
                          <h3 className=" line-height-none">Edge AI</h3>
                        </div>
                        <div className="learningtwo solutions-two__content-two-img">
                          <img src="images/productSlider/008.png" alt="" />
                        </div>
                        <p className="laxtbloc-bottomspace pb-0  mb-0">
                          Edge AI excels in real-time concept detection on local
                          devices with efficient models but falters in defense
                          due to limited adaptability to new threats, hindering
                          quick updates and learning against novel challenges.
                        </p>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="learningcontnetboloc01 towcallsblock pt-5">
                        <div className="pb-4 ">
                          <h3 className=" line-height-none">Generative AI</h3>
                        </div>
                        <div className="learningtwo solutions-two__content-two-img">
                          <img src="images/productSlider/009.png" alt="" />
                        </div>
                        <p className="laxtbloc-bottomspace pb-0  mb-0">
                          Generative AI excels in content creation but falters
                          in defense due to reliance on known data, struggling
                          with unique scenarios and facing limitations in
                          computational demands and dataset size.
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={2} sm={2} md={2} lg={1} style={{ paddingTop: "0" }}>
              <div className="campaignline linetwo mx-auto "></div>

              <div className="roundshaps">
                {/* <img src="images/productSlider/productivity.svg" alt="" /> */}
              </div>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={11}>
              <div className="rightblock-learning">
                <div
                  className="pagetitle-self mb-5 outsideshare-title"
                  style={{ padding: "50px 0 20px 0" }}
                >
                  {/* <h2>2019-2023</h2> */}

                  <h3 className="colorthree">
                    {" "}
                    AI Failed for New Threats & Environments
                  </h3>
                  <p className="laxtbloc-bottomspace-40">
                    AI faces difficulties in dealing with unexpected threats in
                    open environments, particularly those that are often
                    undetected, posing significant challenges to effective
                    protection.
                  </p>
                </div>
                <div className="dashboar-learning">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className="learningcontnetboloc01 towcallsblock">
                        <div className=" ">
                          <h3>New Threats</h3>
                          <div className="learningtwo imgblowtwocol">
                            <img
                              src="images/productSlider/new_threats.png"
                              alt=""
                            />
                          </div>
                          <p className="laxtbloc-bottomspace pb-0">
                            Different Shapes of UAVs, Unknown suspicious
                            Behaviours, New Payload Types, New Unknown Vessels
                          </p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className="learningcontnetboloc01 towcallsblock">
                        <div className=" ">
                          <h3>New Environments</h3>
                          <div className="learningtwo">
                            <img
                              src="images/productSlider/new_environments.png"
                              alt=""
                            />
                          </div>
                          <p className="laxtbloc-bottomspace pb-0">
                            Heavy Rain and Snow, Dense Fog & Strong Wind,
                            Visibility (line of Sight), Range and Sensor
                            Limitations
                          </p>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="container" id="2021">
          <Grid container spacing={3} id="darpalogo">
            <Grid item xs={2} sm={2} md={2} lg={1}>
              <div className="campaignline linetwo mx-auto"></div>

              <div className="roundshaps">
                <img
                  src="images/productSlider/git-branch-security-2.svg"
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={11}>
              <div className="rightblock-learning">
                <div className="pagetitle-self mb-5 outsideshare-title">
                  <h2>2021-2023</h2>

                  <h3 className="colorthree ">
                    DARPA's Self-learning AI Programs
                  </h3>
                  <p className=" ">
                    DARPA emphasized fostering research to solve the above
                    mentioned problems through the following programs.
                  </p>
                </div>
                <div className="dashboar-learning">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className="learningcontnetboloc01 towcallsblock pt-5 pb-5">
                        <div className="pb-4 ">
                          <h3 className="line-height-none">
                            Lifelong Learning Machines (L2M)
                          </h3>
                        </div>
                        <div className="learningtwo solutions-two__content-two-img ">
                          <img
                            src="images/productSlider/Shared-Experience.png"
                            alt=""
                          />
                        </div>
                        <p className="laxtbloc-bottomspace  pb-0 mb-0">
                          The L2M program emphasizes continuously learning new
                          knowledge, focusing solely on acquiring and retaining
                          new information over time
                        </p>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className="learningcontnetboloc01 towcallsblock pt-4  pb-5">
                        <div className="pb-4 ">
                          <h3 className=" line-height-none">
                            Shared-Experience Lifelong Learning (ShELL)
                          </h3>
                        </div>
                        <div className="learningtwo solutions-two__content-two-img">
                          <img
                            src="images/productSlider/Shared-Experience-Lifelong.png"
                            alt=""
                          />
                        </div>
                        <p className="laxtbloc-bottomspace  pb-0 mb-0">
                          This program is a continuation of the L2M initiative,
                          which concentrated on acquiring new knowledge from
                          collective experiences while retaining previously
                          learned information
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={11} lg={11}>
                    <div className="rightblock-learning">
                      <div
                        className="pagetitle-self mb-5 outsideshare-title"
                        style={{ padding: "50px 0 20px 0px" }}
                      >
                        <h3 className="colorthree">
                          Skylark Labs Proprietary Self-learning AI Technology
                        </h3>
                        <p className=" ">
                          Dr. Amarjot Singh founded Skylark Labs based on his
                          L2M program experiences, leading to the creation of
                          the first commercial self-learning technology, now
                          utilized in defense sectors across the US, India, and
                          Australia.
                        </p>
                      </div>
                      <div className="dashboar-learning">
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className="learningcontnetboloc01 towcallsblock">
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={8}>
                                  <div className="learningtwo imgblowtwocol">
                                    <img
                                      src="images/productSlider/Self-identify.png"
                                      alt=""
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                  <div className=" ">
                                    <h3>Self-identify</h3>
                                    <p className="laxtbloc-bottomspace pb-4">
                                      Various proprietary mechanisms developed
                                      to identify new concepts of interest
                                    </p>
                                  </div>
                                </Grid>
                              </Grid>

                              <div className="solutions-two__shape-1"></div>
                              <div className="collaboration-one__shape-1"></div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="spacetop"
                          >
                            <div className="learningcontnetboloc01 towcallsblock">
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={8}>
                                  <div className="learningtwo imgblowtwocol">
                                    <img
                                      src="images/productSlider/Self-Labels_01.png"
                                      alt=""
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                  <div className=" ">
                                    <h3>Self-Labels</h3>
                                    <p className="laxtbloc-bottomspace pb-4">
                                      Proprietary mechanisms used to assign
                                      labels to identified concepts
                                      automatically.
                                    </p>
                                  </div>
                                </Grid>
                              </Grid>

                              <div className="solutions-two__shape-1"></div>
                              <div className="collaboration-one__shape-1"></div>
                            </div>
                          </Grid>
                        </Grid>

                        <Grid container spacing={3}>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className="spacetop"
                          >
                            <div className="learningcontnetboloc01 towcallsblock">
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={8}>
                                  <div className="learningtwo imgblowtwocol">
                                    <img
                                      src="images/productSlider/Self-Learns.png"
                                      alt=""
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                  <div className=" ">
                                    <h3>Self-Learns</h3>
                                    <p className="laxtbloc-bottomspace pb-4">
                                      Self-learns the unknown identified
                                      concepts temporarily from few experiences
                                      and reidentifies if seen again.
                                    </p>
                                  </div>
                                </Grid>
                              </Grid>

                              <div className="solutions-two__shape-1"></div>
                              <div className="collaboration-one__shape-1"></div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={11} lg={11}>
                    <div className="rightblock-learning pt-4">
                      {/* <div className="pagetitle-self mb-5 pt-5 mt-5 outsideshare-title pl-0">
                        <p className=" ">
                          Dr. Amarjot Singh founded Skylark Labs based on his
                          L2M program experiences, leading to the creation of
                          the first commercial self-learning technology, now
                          utilized in defense sectors across the US, India, and
                          Australia.
                        </p>
                      </div> */}

                      <div className="dashboar-learning">
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className="learningcontnetboloc01 towcallsblock">
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={8}>
                                  <div className="learningtwo imgblowtwocol">
                                    <img
                                      src="images/productSlider/solutionblock.jpg"
                                      alt=""
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4}>
                                  <div className=" ">
                                    <h3> Model Consolidation</h3>
                                    <p className="laxtbloc-bottomspace pb-4">
                                      we have developed proprietary methods
                                      using which we are able to consolidate the
                                      model parameters learned at two site and
                                      deploy the consolidated model without data
                                      transfer at new site so it can benefit
                                      from the learned knowledge.
                                    </p>
                                  </div>
                                </Grid>
                              </Grid>

                              <div className="solutions-two__shape-1"></div>
                              <div className="collaboration-one__shape-1"></div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="container mt-5 pt-5">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div className="learningcontnetboloc01 towcallsblock pt-5 mt-5 solutions-two__content-two-img">
              <div className="pb-4 ">
                <h3 className=" line-height-none">
                  {" "}
                  Advancing Defense Applications:<br></br> The Essential Role of
                  Self-Learning AI Technologies
                </h3>
              </div>

              <p className="laxtbloc-bottomspace pb-3">
                We have developed self-learning AI for defense, overcoming the
                limitations of traditional AI, which struggles with
                unpredictable environments and high computational demands. Our
                systems, tailored for edge deployment, are efficient and operate
                on low-end hardware. They autonomously adapt to new threats,
                enhancing defense capabilities with minimal human input. This
                advancement is crucial for modern security challenges, offering
                necessary adaptability and efficiency where traditional AI falls
                short.
              </p>
              {/* <p className="laxtbloc-bottomspace  pb-3">
              We are addressing these challenges by developing proprietary self-learning AI technologies specifically tailored for intelligence and tactical communities. These advanced systems, designed for edge deployment, are not only data and computationally efficient but also capable of operating on low-end hardware. Their ability to autonomously detect, classify, and adapt to new threats in real-time, with minimal human intervention, marks a significant leap forward. These technologies ensure proactive responses, continuous improvement in threat detection, and cost-effective operations with reduced downtime.
              </p>
              <p className="laxtbloc-bottomspace  pb-0">
              Hence, in defense and security contexts, the shift towards self-learning AI systems is not just beneficial but essential. Such systems represent the future of AI in defense, offering the flexibility, efficiency, and adaptability necessary to meet the dynamic demands of modern security challenges. Traditional AI approaches, limited by their need for large datasets and inability to adapt to novel scenarios, are increasingly inadequate for these critical applications.
              </p> */}
            </div>
          </Grid>
        </div>

        <div className="imgplatformsbg  ">
          <img src="images/productSlider/bg_plateform.png" alt="" />
        </div>
      </div>
    </>
  );
};

export default Selflearingai;
