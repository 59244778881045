import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Rollcounting from "../../videofiles/kinship_analysis.mp4";
import Ppeviolation from "../../videofiles/sketch_analysis.mp4";
import ageinvariantanalysis from "../../videofiles/age_invariant_analysis.mp4";
import Industrialbanner from "../../videofiles/censer.mp4"; 
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
const Censer = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Censer`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [showTrial, setShowTrial] = React.useState(false);
  const [expanded, setExpanded] = React.useState("panel2");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };
  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "D.S. Jeweller",
      desc: "The incidents happened in the locality where robbers used to come at night and loot the jewellery shops.",
      url: "ds-jewellers",
      img: "images/cases-jewellers.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      // if (section4.current && offsetY >= section4.current.offsetTop)
      //   addActiveClass(navOption4);
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChangenew = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>Censer</h1>
              <p>
              system uses Advanced AI and deep convolutional neural networks  to
learn high-level features to perform age-invariant classification for all three age, kin, and sketch cases. 

              </p>
              <div className="subtages"><a href="#/">Age Invariant Analysis </a>  <a href="#/"> Kinship Analysis  </a> <a href="#/">Sketch Analysis
 </a>  
</div>
     
            </div>
          </div>
        </div>

        <ReactPlayer
          style={{
            zIndex: "-1",
            pointerEvents: "none",
          }}
          controls={false}
          volume={0}
          playsinline
          width="100%"
          height="100%"
          loop={true}
          muted={true}
          playing={true}
          stopOnUnmount={true}
          config={{
            file: {
              attributes: {
                autoplay: true,
                controls: false,
                loop: true,
                muted: true,
              },
            },
          }}
          url={Industrialbanner}
        />
      </div>

      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>Censer</h2>
            <p>
            system uses Advanced AI and deep convolutional neural networks  to
learn high-level features to perform age-invariant classification for all three age, kin, and sketch cases. 
            </p>
          </div>
        </div>
      </div>

      <div className="plateformsub-page">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>

        <div className="container pt-5">
          <div className="plateformabout  mt-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="aboutimgblock">
                  {" "}
                  <img src="images/industrial-Parks.png" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 pb-0">
                      <span className="spanfirstword">Children </span>  Safety 
                    </h2>
                    <p className="pt-0 abouttext-pblock">
                    The CENSER system establishes a match with the database of missing children. Since the facial features of children have changed significantly since being kidnapped, the proposed system can perform age-invariant face recognition. A majority of the time, the image of the child is not available at the age when he/she was kidnapped. In that case, the CENSER system uses kinship analysis as well as matches with the sketch of the child.

                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <section className="homeprodctlist blackrarkoutr"> 
        <div className="container">
          <Box sx={{ width: "100%" }}>
            <TabPanel value={value} index={0}>
              <div className="productitembox ">
              <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={ageinvariantanalysis}
                />

                <div className="productitemboxdis">
                  <h4> Age Invariant Analysis</h4>
                  <p>
                  The CENSER system performs age-invariant face recognition by comparing the images when they were retrieved and when they were originally kidnapped and also predict if a child is minor

                  </p>
                
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Rollcounting}
                />

                <div className="productitemboxdis">
                  <h4>Kinship Analysis</h4>
                  <p>
                  Our system performs kinship analysis by matching a child's images from a recorded data to their parents' images. Once a match is established by our system, the parents could take this information to the police.

                  </p>
                  
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Ppeviolation}
                />

                <div className="productitemboxdis">
                  <h4>Sketch Analysis</h4>
                  <p>
                  CENSER system use advanced AI  searching for children photos in the database based on a query sketch drawn and helps the law enforcement agencies to find the missing child.

                  </p>
                 
                </div>
              </div>
            </TabPanel>

            <div className="poductlisttabs">
              <Box sx={{ borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChangenew}
                  aria-label="basic tabs example"
                >
                  <Tab label="Age Invariant Analysis" {...a11yProps(0)} />
                  <Tab label="Kinship Analysis" {...a11yProps(1)} />
                  <Tab label="Sketch Analysis" {...a11yProps(2)} />
                </Tabs>
              </Box>
            </div>
          </Box>
        </div>
      </section>

      <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
         
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Child</span>{" "}
                       Retrieval
                    </h4>
                    <p>
                    The system uses advanced AI and recognizes missing children through Age Invariant, kinship, and sketch analysis, which helps the police to retrieve the children from brothels, red light areas, etc.

                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Age Invariant  Analysis
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Kinship  Analysis  
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Sketch   Analysis  
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                <img src="images/child_retrieval.png" alt=""/>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>



</div>
 
     
 
{/*      
      <section
        className="benefitmain industriesuses bggray latticeos_faq lightgray"
        id="admincontrol"
      >
        <div className="container">
          <div className="benefitssection  ">
            <h2 className="mb-0 text-center">Key Features</h2>
          </div>

          <div className="listofindust skylarksuite">
            <ul>
              <li className="text-center">
                <div className="featuresblock featuresblockbgthree">
                  <span>
                    <img src="images/object-detection.svg" alt="" />
                  </span>
                  <h5>Actionable insights</h5>
                  <p>
                    Sends real-time actionable alerts to the concerned
                    authorities through mobile and web. These alerts help them
                    to respond quickly to the incident and prevent them.
                  </p>
                </div>
              </li>

              <li className="text-center">
                <div className="featuresblock ">
                  <span>
                    <img src="images/identity-detection.svg" alt="" />
                  </span>
                  <h5>Computationally Efficient</h5>
                  <p>
                    Less resource consumption, requires less amount of time and
                    space.
                  </p>
                </div>
              </li>
              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/icon-threat-detection.svg" alt="" />
                  </span>
                  <h5>Stress Free Environment</h5>
                  <p>
                    Getting only relevant alerts creates a stress-free
                    environment for security officers as they don’t have to
                    manually monitor the video wall regularly and can only turn
                    their attention to it when there is an audio-visual alert.
                    (edited)
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section> */}

      <section className="caseList readmorecsases ">
        <div className="container">
          <div className="platformCamera_dis">
            <h2>Read More Cases</h2>
          </div>

          <div className="industriesListblock pt-5">
            <ul>
              {otherCases.map((ele, idx) => (
                <li>
                  <a href="#contact" className="industriesListcol">
                    <div className="solutionListimg">
                      <img src={ele.img} alt="" />
                    </div>
                    <div className="industriesListcoltext">
                      <h4>{ele.title} </h4>
                      <p>{ele.desc}</p>
                      <div className="quicklinks casesquicklinks">
                        {" "}
                        View full case{" "}
                        <img src="images/right-arrow.png" alt="" />{" "}
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Censer;
