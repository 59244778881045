import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const Privacycanada = () => {
    const [expanded, setExpanded] = React.useState("panel2");
    const navOption1 = React.useRef(null);
    const navOption2 = React.useRef(null);
    const navOption3 = React.useRef(null);
    const navOption4 = React.useRef(null);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    useEffect(() => {
        const title = document.getElementById("mainTitle");
        title.innerText = "Skylark Labs Privacy Policy"
        return () => {
            console.log('title changed');
        }
    }, []);
    return (
        <>
       <div className="privacybanner">
       <div className="container">
       <div className="dismanbanner">
        <div className="privacyimg">
        <img src="../images/privacy.png" alt=""/>
        </div>
              <h1>Skylark Labs Privacy Policy</h1>

              <ul>
                <li ><a href="/privacy">Privacy</a> </li>
                <li><a href="/privacy-us"> US Privacy</a> </li>
                <li className="active"><a href="/privacy-canada">Canada Privacy</a> </li>
                <li><a href="/privacy-australia">Australia Privacy</a> </li>
                <li><a href="/privacy-europe">Europe Privacy</a> </li>
               
                </ul>
              </div>
            </div>
        
       
        
      </div>

            <section className="termsprivacy">
                <div className="container">
                <div className="termsprivacycontent">
       <h2>Laws Regarding Face Recognition Technology (For Canada only)
</h2>
<h2>Federal Laws</h2>
       <h3>
       Use of FRT is allowed.

       </h3>
       <p>PIPEDA (Personal Information Protection and Electronic Documents Act)
Organisations covered by PIPEDA must generally obtain an individual’s consent when they collect, use or disclose that individual’s personal information (if the same is done for commercial purpose). It further sets out the requirements for valid consent and requires organisations to take into account the sensitivity of the information when deciding the information and format to seek consent. Consent is generally a criteria that should be fulfilled before engaging in FRT.
</p>
<p>Consent not required if it was produced by the individual in the course of their employment, business or profession and the collection is consistent with the purposes for which the information was produced.
</p>
<p>Federally regulated Organisations like airports, aircraft and airlines; banks and authorized foreign banks; inter-provincial or international transportation companies; telecommunications companies; offshore drilling operations; and radio and television broadcasters are also subject to PIPEDA.
</p>
<p>Under PIPEDA, Art 7.3, the usage can be without consent if a federal work, undertaking or business uses it in the following manner (a) the collection, use or disclosure is necessary to establish, manage or terminate an employment relationship between the federal work, undertaking or business and the individual; and (b) the federal work, undertaking or business has informed the individual that the personal information will be or may be collected, used or disclosed for those purposes.
</p>
<p>Further, Personal information that is no longer required to fulfil the identified purposes should be destroyed, erased, or made anonymous. Organisations shall develop guidelines and implement procedures to govern the destruction of personal information.
</p>
<h3>Privacy Act</h3>
<p>Under Section 4 of the Privacy Act specifies that “no personal information shall be collected by a government institution unless it relates directly to an operating program or activity of the institution.” operating programs and activities of an institution must be limited to activities which fall within the institution’s legal authority to conduct and which respect the general rule of law.
</p>
<p>This not per se bans the usage of collection of FR Data. Also the FR data has to be disposed, in accordance with the regulations and in accordance with any directives or guidelines issued by the designated minister in relation to the disposal of that information.
A Bill is in place that seeks to will modify the aforementioned privacy legislations, but it is still in the early stages so its final effect cannot be ascertained yet. 
Usage of FRT by Police.
</p>
<p>Police are allowed to use FRT. Though Lawful authority must exist in every process of use of FRT. In essence, a government institution cannot collect personal information from a third party agent if that third party agent collected the information unlawfully.
</p>
<p>If third part is used by police to take services, police need to ensure that the third party suppliers have the lawful authority to collect and use the personal information contained in their services. (Applicable if the FRT Company also collects the data).
</p>
<p>For example, police can use the software for identification of convicts and accused, using mugshot pics.
</p>
<p>Police Agencies need to ensure that the usage is allowed by the current privacy legislations. Need to follow the principles of necessity and proportionality. (Whether the usage of FR is necessary, and if the usage if proportional to the act in anticipation/ response of which the FRT is to be used)
</p>
<p>In the near future, Draft privacy guidance on facial recognition for police agencies, if approved, will regulate such usage of FRT.
Further, reasonable expectations regarding privacy in public spaces is not defined in Canadian Laws, though it has been alluded that the same is present in public spaces, limiting the use of FRT.
</p>
<p>As Skylark does not maintain the database or collects FR Data, and provide only FR identification software that uses the database provided by the service user, thus, it can provide the software to Police without issues. The issue would pertain regarding creation and maintenance of FR Database in a legal manner.
</p>
<h3>Case Studies</h3>
<p>Case of Cadillac Fairview Corporation Limited (CFCL)
Non Consensual capture of facial data in shopping mall. The captured images were not used for identification purpose.
</p>
<p>Held,<br></br>
Clearview AI Case<br></br>
Usage of Data Scrapped from sites and present in Public Domain.<br></br>
Given, recommendations by the Privacy Commissioners that the practice is against the law. Concluded that Clearview should have obtained express opt-in consent before it collected the images of any individual in Canada.
In both the cases, it is clearly visible that the concept of Consent plays a big role in case of usage of FRT.
</p>
<p>Canadian Laws have ‘Guidelines for obtaining meaningful consent’, which can be used to obtain consent before obtaining FR Data.

The Guidelines can be broadly said to have the following rules for the Organisations to follow.
</p>
<ol>
  <li>Organizations must identify for individuals what personal information is being, or may be, collected about them.
</li>
<li>Disclosures to third parties must be clearly explained, including the types of information being shared.</li>
<li> Individuals should be made aware of all purposes for which information is collected, used or disclosed
</li>
<li>Individuals should be made to understand the consequences of the collection, use or disclosure to which they are consenting.
</li>
<li>Information must be provided to individuals in manageable and easily-accessible ways (potentially including layers) and individuals should be able to control how much more detail they wish to obtain, and when.
</li>
<li> Individuals cannot be required to consent to the collection, use or disclosure of personal information beyond what is necessary to provide the product or service – they must be given a choice.
</li>
<li>Organizations should design and/or adopt innovative consent processes that can be implemented just-in-time, are specific to the context, and are appropriate to the type of interface used.
</li>
</ol>
<p>Still consent will not allow for indiscriminate collection of personal data. Principles like purpose limitation, collection limitation still apply. Further, the data subject is allowed to withdraw consent.
</p>
<h4>Canadian States Laws</h4>
<h3>Quebec</h3>
<p>Has enacted laws specifically about Biometrics
Requires organizations to notify the Commission d’accès à l’information before implementing a biometrics database.
</p>
<p>For Businesses collecting FR data from employees- Template exists for those gathering biometric information- Canada Quebec [9] Legally allowed by employers to take FR data but restrictions are present[10]
</p>
<p>Disclosure of such information to privacy commissioner is needed to be done
Data subject must be made aware- need for biometric collection taken into consideration
The regulator may then prohibit such a database from coming into service, order changes to the project, or order the destruction of the database.
</p>
<h3>Alberta</h3>
<p>Organizations must follow the Personal Information Protection Act (PIPA) when it collects personal employee information. Consent is required to capture personal data (which includes FR Data). In certain circumstances, the data can be collected and used without any consent. The cases where the same is possible are 
</p>
<ul>
  <li> If such collection of the information is for the Data Subjects benefit and consent cannot be obtained in a timely way
</li>
<li> The collection of personal data is allowed by the Govt. Bodies/ Legal Proceeding.[11]
</li>
</ul>
<p>An organization can also collect employee’s personal information without consent for reasons like (a) the information is collected solely for the purposes of 
</p>
<ol>
  <li>establishing, managing or terminating an employment or volunteer‑work relationship, or (ii) managing a post‑employment or post‑volunteer‑work relationship,  between the organization and the individual 
</li>
<li>Such a collection of information is reasonable ( As in the collection is justified for the purpose to be fulfilled) 
</li>
<li>In case of current employee, if sufficient information has been given. (For example, can install FRT based attendance system)
</li>
</ol>
<p>Nonetheless, in Alberta, personal information extends to the captured images of faces and the numerical representation assigned to each face and the assessment of age range and gender. Organizations should exercise caution when collecting, using, or disclosing information that they believe is publicly available and is less sensitive.
</p>
<p>Further, there must be a reasonable need for collection of FR data, as is evident from the case of Babylon Health App. In the case, the Privacy Commissioner argued that Babylon does not need to be using facial biometrics to provide health services, and that the terms of service do not make enough of a case to justify the practice of the app’s use of document and facial recognition, which is used for identity verification.
</p>
<h4>British Columbia
</h4>
<p>British Columbia also has privacy legislations that highlight the importance of Consent. Subsection 7(1) of PIPA BC states that an individual has not consented unless they have been given notice. The legislation is otherwise very similar to the Privacy Legislation of Alberta. So similar rules regarding Consent for usage of FR applies.
</p>
 

 
<h3>
 PLEASE GET IN TOUCH WITH US

       </h3>
       <p>Email us at<a href="mailto:info@skylarklabs.ai">info@skylarklabs.ai</a>  if you have any questions about this Privacy Statement, the procedures we use to manage the information collected through the Website, or if you would like to modify or delete your information from our records.
</p>
     </div>

               
                </div>
            </section>
        </>
    );
};

export default Privacycanada;
