import React, { useEffect } from "react";
import Banner from "./home/sections/Banner";
import Aboutskylak from "home/sections/Aboutskylak";
import Whoweare from "home/sections/Whoweare";
import Homeproductlist from "home/sections/Homeproductlist";
import Client from "home/sections/Client";
import Homemedia from "home/sections/Homemedia";
import Bannerindia from "home/sections/Bannerindia";
import FeatureSection from "home/sections/FeatureSection";

const Homepage = () => {

  const [isInIndia, setIsInIndia] = React.useState(false)

  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = "Skylark Labs: Self-Learning AI to Foresee Emerging Threats";

    fetch("https://ipapi.co/json/").then((res) => res.json()).then((json) => {
      if (json['country_name'] === "India") {
        setIsInIndia(true)
      }
    }).catch((err) => { console.log(err) })
  }, [])

  return (
    <>
      {
        isInIndia ? <Bannerindia /> : <Banner />}

      <div className="topplatformsblock homelanding">
        <Client />
        <Aboutskylak />
        <FeatureSection />
        <Homeproductlist />
        <Homemedia />
        <div className="imgplatformsbg  ">
          <img src="images/productSlider/bg_plateform.png" alt="" />
        </div>
      </div>
    </>
  );
};

export default Homepage;