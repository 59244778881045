import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import Spinner from "./Spinner";

const Blogsubcategory = (props) => {
  const [categoryData, setCategoryData] = useState(null);
  const [subCategories, setSubCategories] = useState(null);
  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const id = query.get("id");
    console.log(id);
    if (!id) {
      window.location.pathname = "/blog-home";
      return;
    }
    fetch(`https://prodserver.skylarklabs.ai/skylarkblog/getsubcategory/?category_id=${id}`)
      .then((res) => res.json())
      .then((data) => {
        setSubCategories(data);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
    fetch(`https://prodserver.skylarklabs.ai/skylarkblog/getcategory/?id=${id}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data[0]);
        setCategoryData(data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = "Skylark Labs: About";
    return () => {
      console.log("title changed");
    };
  }, []);
  const [expanded, setExpanded] = React.useState("");
  const navOption1 = React.useRef(null);
  const navOption2 = React.useRef(null);
  const navOption3 = React.useRef(null);
  const navOption4 = React.useRef(null);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (!categoryData || !subCategories) return <Spinner />;

  return (
    <>
      <div className="blogouter">
        <section className="main-top">
          <div className="container">
            <div className="main-page-title">
              <span>BLOG</span>
              <h1>{categoryData["title"]}</h1>
              <p>{categoryData["description"]}</p>
            </div>
            {subCategories.length === 0 ? (
              <div>No Records</div>
            ) : (
              <Grid container spacing={3} className="bottom-pb-6">
                {subCategories.map((subCategory) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={4} xl={4} className="bottomspace-item" key={subCategory["id"]}>

                      <div className="item-box-shadow">
                        <div className="blog-item-img">
                          <a href={`/single-blog-page?id=${subCategory["id"]}`}>
                            <img src={subCategory["image"]} alt="" />
                          </a>
                        </div>
                        <div className="p-4">
                          <div className="authore">
                            <ul>
                              <li>
                                Author:<span>skylarklabs</span>
                              </li>
                              <li>
                                Post :<span>July 08, 2022</span>
                              </li>
                            </ul>
                          </div>
                          <div className="blog-title">
                            <h2>{subCategory["title"]}</h2>{" "}
                          </div>
                          <div className="blog-short-dis">
                            {/* <p>{subCategory["description"]}</p> */}
                            <div dangerouslySetInnerHTML={{ __html: subCategory["description"] }} />
                          </div>
                          <div className="related-tags">
                            <ul>
                              <li>
                                {" "}
                                <a href="/">ETOS</a>{" "}
                              </li>
                              <li>
                                {" "}
                                <a href="/">ARIES</a>{" "}
                              </li>
                              <li>
                                {" "}
                                <a href="/">MIRAS</a>{" "}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Blogsubcategory;
