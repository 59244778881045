import React, { useState, useEffect } from "react";
import { Box, makeStyles, Button } from "@material-ui/core";
import LinearProgressWithLabel from "@material-ui/core/LinearProgress";
import API from "./useApi";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReactPlayer from "react-player";

const useStyle = makeStyles({
  uploadSection: {
    border: "1px solid #ffffff",
    height: "440px",
    maxWidth: "780px",
    width: "100%",
    margin: "0px",
    borderRadius: "6px",
  },
  flexCol: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadedFileSection: {
    width: "100%",
    maxWidth: "780px",
    minHeight: "440px",
    margin: "0px",
    padding: "0px",
    borderRadius: "6px",
    border: "1px solid #ffffff",
    position: "relative",
  },
  uploadedFileBox: {
    // padding: "40px",
    display: "flex",
    justifyContent: "space-between",
    "& .fileName": {
      fontSize: "20px",
    },
  },
  dialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // position: "absolute",
    // right: "0px",
    // bottom: "0px",
  },
});

const UploadImageVideo = ({
  model,
  uploadAgain,
  target,
  removeRefer,
  click,
  setClick,
}) => {
  const classes = useStyle();
  const [fileList, setFileList] = useState([]);
  const [progress, setProgress] = useState(10);
  const [processedImage, setProcessedImage] = useState(null);
  const [extraImage, setExtraImage] = useState(null);
  const [processedVideo, setProcessedVideo] = useState(null);
  const [processed, setProcessed] = useState(false);
  const [skipTime, setSkipTime] = useState({ min: 0, sec: 0 });
  const [message, setMessage] = useState("");
  const [messagecls, setMessagecls] = useState("red");
  const [id, setId] = useState(null);
  const accept =
    "image/png, image/gif, image/jpeg, video/mp4,video/x-m4v,video/*";

  useEffect(() => {
    if (click !== null && click !== undefined) {
      setFileList([click]);
      ProcessHandler(true, click);
    }
  }, [click]);
  useEffect(() => {
    if (!processed) {
      setMessage("Processing");
      setMessagecls("blue");
    }
  }, [processed]);

  useEffect(() => {
    if (progress === 100) setMessage("Wait for reponse!");
    else {
      setMessage("Uploading File!");
      setMessagecls("blue");
    }
  }, [progress]);

  const dropZone = () => {
    const inputElement = document.querySelector(".drop-zone__input");
    const dropZoneElement = inputElement.closest(".drop-zone");

    dropZoneElement.addEventListener("click", (e) => {
      inputElement.click();
      if (inputElement.files.length !== 0) inputElement.value = null;
    });

    inputElement.addEventListener("change", (e) => {
      if (inputElement.files.length) {
        setFileList([inputElement.files[0]]);
        updateThumbnail(dropZoneElement, inputElement.files[0]);
      }
    });

    dropZoneElement.addEventListener("dragover", (e) => {
      e.preventDefault();
      dropZoneElement.classList.add("drop-zone--over");
    });

    ["dragleave", "dragend"].forEach((type) => {
      dropZoneElement.addEventListener(type, (e) => {
        dropZoneElement.classList.remove("drop-zone--over");
      });
    });

    dropZoneElement.addEventListener("drop", (e) => {
      e.preventDefault();

      if (e.dataTransfer.files.length) {
        setFileList([e.dataTransfer.files[0]]);
        updateThumbnail(dropZoneElement, e.dataTransfer.files[0]);
      }

      dropZoneElement.classList.remove("drop-zone--over");
    });

    function updateThumbnail(dropZoneElement, file) {
      let thumbnailElement = dropZoneElement.querySelector(".drop-zone__thumb");

      if (dropZoneElement.querySelector(".drop-zone__prompt")) {
        dropZoneElement.querySelector(".drop-zone__prompt").remove();
      }

      if (!thumbnailElement) {
        thumbnailElement = document.createElement("div");
        thumbnailElement.classList.add("drop-zone__thumb");
        dropZoneElement.appendChild(thumbnailElement);
      }

      thumbnailElement.dataset.label = file.name;

      if (file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
          thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
        };
      } else {
        thumbnailElement.style.backgroundImage = null;
      }
    }
  };

  useEffect(() => {
    dropZone();
  }, []);

  const hitToGetApi = (route, data, options) => {
    console.log('get req')
    API.get(route, data, options)
    .then((res) => {
      console.log(res.data);
      if (res.data && res.data.status === "pending") {
        // if (model.title === "lurking-detection") {
          setTimeout(() => {
            console.log('hit get')
            hitToGetApi(route, data, options);
          }, 7000)
        // }
        setMessage("Process Pending");
        setMessagecls("blue");
      } else if (res.data && res.data.status === "failed") {
        setMessage("Process Failed");
        setMessagecls("red");
      } else if (res.data && res.data.status === "success") {
        setMessage("Process Successful!");
        setMessagecls("green");
      } else {
        setTimeout(() => {
          console.log('hit get')
          hitToGetApi(route, data, options);
        }, 7000)
        // setMessage("No Response");
        // setMessagecls("red");
      }
      if (res.data.output_image !== undefined)
        setProcessedImage(res.data.output_image);
      if (res.data.query_image) setExtraImage(res.data.query_image);
      if (res.data.output_video !== undefined) {
        setProcessedVideo(res.data.output_video);
        // console.log(res.data.video)
      }
    })
    .catch((error) => {
      console.log(error.message);
      setMessage("An Error occured please refresh the page");
      setMessagecls("red");
    });;
  };

  const hitApi = (route, data, options) => {
    API.post(route, data, options)
      .then((res) => {
        console.log(res.data);
        if (res.data && res.data.status === "pending") {
          console.log(res.data.id)
          // if (model.title === "lurking-detection") {
            hitToGetApi(route + `${res.data.id}/`, data, options);
          // }
          setMessage("Process Pending");
          setMessagecls("blue");
        } else if (res.data && res.data.status === "failed") {
          setMessage("Process Failed");
          setMessagecls("red");
        } else if (res.data && res.data.status === "success") {
          setMessage("Process Successful!");
          setMessagecls("green");
        } else {
          setMessage("No Response");
          setMessagecls("red");
        }
        if (res.data.output_image !== undefined)
          setProcessedImage(res.data.output_image);
        if (res.data.query_image) setExtraImage(res.data.query_image);
        if (res.data.output_video !== undefined) {
          setProcessedVideo(res.data.output_video);
          // console.log(res.data.video)
        }
        // if(res.output) setMessage(res.output +' in the video clip')

        setProcessed(true);
      })
      .catch((error) => {
        console.log(error.message);
        setMessage("An Error occured please refresh the page");
        setMessagecls("red");
      });
  };

  const formatBytes = (bytes) => {
    var marker = 1024;
    var decimal = 2;
    var kiloBytes = marker;
    var megaBytes = marker * marker;
    var gigaBytes = marker * marker * marker;
    var teraBytes = marker * marker * marker * marker;

    if (bytes < kiloBytes) return bytes + " Bytes";
    else if (bytes < megaBytes)
      return (bytes / kiloBytes).toFixed(decimal) + " KB";
    else if (bytes < gigaBytes) {
      if (Number((bytes / megaBytes).toFixed(decimal)) > 5) return true;
      return (bytes / megaBytes).toFixed(decimal) + " MB";
    }
  };

  const setFileInfo = () => {
    var video = document.createElement("video");
    video.preload = "metadata";
    var duration;
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      duration = video.duration;
      fileList[0].duration = duration;
      console.log(fileList[0].duration);
    };

    return duration;
  };

  const recogFileHandler = (query) => {
    if (target && query.type.split("/").shift() == "image") {
      console.log(query, target);
      let formData = new FormData();
      formData.append("target_image", target);
      formData.append("query_image", query);
      const options = {
        onUploadProgress: (progressEvent) => {
          setProgress(
            Math.floor((progressEvent.loaded * 100) / progressEvent.total)
          );
        },
      };
      hitApi(model.apis.image.route, formData, options);
    } else {
      alert("File should be image");
    }
  };

  const onFileChangeHandler = (file) => {
    console.log(file)
    if (model.recog) {
      recogFileHandler(file);
      return;
    }
    // try {
    if (file.type.split("/").shift() == "image") {
      let formData = new FormData();
      formData.append("image", file);
      const options = {
        onUploadProgress: (progressEvent) => {
          setProgress(
            Math.floor((progressEvent.loaded * 100) / progressEvent.total)
          );
        },
      };
      hitApi(model.apis.image.route, formData, options);
    } else if (file.type.split("/").shift() == "video") {
      console.log(file);
      if (formatBytes(file.size) === true) {
        setMessage("File size should be less than 5MB");
        setMessagecls("red");
        return;
      }
      if (setFileInfo() > 20) {
        setMessage("Duration of the video should be less than or equal to 20 seconds");
        setMessagecls("red");
        return;
      }
      let formData = new FormData();
      formData.append("video", file);
      // formData.set('key', 'abcd')
      formData.set("skipTime", skipTime.min * 60 + skipTime.sec);
      const options = {
        onUploadProgress: (progressEvent) => {
          setProgress(
            Math.floor((progressEvent.loaded * 100) / progressEvent.total)
          );
        },
      };
      hitApi(model.apis.video.route, formData, options);
    }
    // } catch (error) {
    // console.log(error.message);
    // setMessage("Server Error");
    // }
  };

  function ProcessHandler(dummy, file) {
    if (dummy) {
      // console.log(file)

      onFileChangeHandler(file);
      setProcessed(true);
      return;
    }
    if (fileList.length === 0) {
      alert("Select a file first!");
      return;
    }
    setProcessed(true);
    if (model.recog) {
      removeRefer();
    }
    onFileChangeHandler(fileList[0]);
  }

  const ResponseImage = ({ img }) => {
    const [loading, setLoading] = useState(true);
    return (
      <div className="result-img">
        <span style={{ display: loading ? "block" : "none" }}>
          Please wait response is loading...
        </span>
        <img src={img} alt="" onLoad={() => setLoading(false)} />
      </div>
    );
  };

  return (
    <>
      {processed ? (
        <Box className={classes.uploadedFileSection}>
          <Box className={classes.uploadedFileBox}>
            <div className="uploadfiles">
              <Box className="fileName">
                {fileList[0].name} &nbsp;&nbsp;{formatBytes(fileList[0].size)}
              </Box>
              <Box className="progressBar">
                {progress !== 100 ? (
                  <progress
                    id="file-progress"
                    value={progress}
                    max="100"
                  ></progress>
                ) : (
                  "Uploaded"
                )}
              </Box>
            </div>
          </Box>
          {fileList[0].type.split("/").shift() === "video" && (
            <div className="videoupload">
              <div className="choosetitle"> Choose Start Time </div>
              <div className="videotime">
                <div className="chooseinput">
                  <span> Minute: </span>
                  <input
                    type="text"
                    value={skipTime.min}
                    onChange={(e) =>
                      setSkipTime({ ...skipTime, min: e.target.value })
                    }
                  />{" "}
                </div>
                <div className="chooseinput">
                  <span>Seconds: </span>

                  <input
                    type="text"
                    value={skipTime.sec}
                    onChange={(e) =>
                      setSkipTime({ ...skipTime, sec: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
          )}
          {message !== "" && (
            <div className={`${messagecls}info`}>{message}</div>
          )}
          {processedImage && processed && (
            <ResponseImage img={processedImage} />
          )}
          {processedImage && extraImage && processed && (
            <ResponseImage img={extraImage} />
          )}
          {processedVideo && processed && (
            <ReactPlayer
              Playsinline
              // style={{
              //   zIndex: "-1",
              //   pointerEvents: "none",
              // }}
              controls={true}
              // volume={0}
              // loop={true}
              // muted={true}
              playing={true}
              // stopOnUnmount={true}
              url={processedVideo}
            />

            // <a href={processedVideo} download>
            //   <GetAppIcon /> Click to download
            // </a>
          )}
        </Box>
      ) : (
        <div className="drop-zone">
          {fileList.length === 0 && (
            <FileCopyIcon style={{ fontSize: "120px", color: "#ffffff" }} />
          )}
          <span className="drop-zone__prompt" style={{ color: "#ffffff" }}>
            <br />
            Drop file here or click to upload
          </span>
          <input
            type="file"
            name="myFile"
            accept={accept}
            hidden
            className="drop-zone__input"
          />
        </div>
      )}
      {!processed &&
        fileList.length > 0 &&
        fileList[0].type.split("/").shift() === "video" && (
          <div className="videoupload">
            <div className="choosetitle"> Choose Start Time:</div>
            <div className="videotime">
              <div className="chooseinput">
                <span> Minute: </span>
                <input
                  type="text"
                  value={skipTime.min}
                  onChange={(e) =>
                    setSkipTime({ ...skipTime, min: e.target.value })
                  }
                />
              </div>
              <div className="chooseinput">
                <span>Seconds: </span>
                <input
                  type="text"
                  value={skipTime.sec}
                  onChange={(e) =>
                    setSkipTime({ ...skipTime, sec: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
        )}
      <Box className={classes.dialog}>
        {processed && (
          <Button
            variant="contained"
            className="btn btnsuploads uploadnewbtn"
            onClick={() => {
              // setClick(null)
              return uploadAgain();
            }}
          >
            Upload New
          </Button>
        )}
        <Button
          variant="contained"
          className="btn btnsuploads processbtn"
          onClick={() => ProcessHandler(false, null)}
        >
          Process
        </Button>
      </Box>
    </>
  );
};

export default UploadImageVideo;
