import $ from "jquery";

const getWebsocketToken = () => {
  var key = "";
  console.log("called for token");
  $.ajax({
    url: "http://65.2.106.30:8000/web-socket-token/",
    type: "post",
    cors: true,
    async: false,
    contentType: false,
    processData: false,
    headers: {
      Authorization: "token 33863d9b751b7bfdcfd1af8735b15b210626c6c6",
    },
    success: function (response) {
      key = response["key"];
    },
  });
  return key;
};

export default getWebsocketToken;
