import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import MediaCoverage from "../home/sections/MediaCoverage";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppBar from "@material-ui/core/AppBar";
import { useParams } from "react-router-dom";
import Spinner from "./Spinner";

const Blogcategory = (props) => {
  const [categories, setCategories] = useState(null);
  const [mainCategoryData, setMainCategoryData] = useState(null);
  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const id = query.get("id");
    console.log(id);
    if (!id) {
      window.location.pathname = "/blog-home";
      return;
    }
    fetch(`https://prodserver.skylarklabs.ai/skylarkblog/getcategory/?maincategory_id=${id}`)
      .then((res) => res.json())
      .then((data) => {
        setCategories(data);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
    fetch(`https://prodserver.skylarklabs.ai/skylarkblog/getmaincategory/?id=${id}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data[0]);
        setMainCategoryData(data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = "Skylark Labs: About";
    return () => {
      console.log("title changed");
    };
  }, []);
  const [expanded, setExpanded] = React.useState("");
  const navOption1 = React.useRef(null);
  const navOption2 = React.useRef(null);
  const navOption3 = React.useRef(null);
  const navOption4 = React.useRef(null);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (!categories || !mainCategoryData) return <Spinner />;

  return (
    <>
      <div className="blogouter">
        <section className="main-top">
          <div className="container">
            <div className="main-page-title">
              <span>BLOG</span>
              <h1>All {mainCategoryData && mainCategoryData["title"]}</h1>
              <p>{mainCategoryData && mainCategoryData["description"]}</p>
            </div>
            <Grid container spacing={3} className="bottom-pb-6">
              {categories.map((category) => {
                return (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="bottomspace-item" key={category["id"]}>
                    <div className="item-box-shadow">
                      <div className="blog-item-img">
                        <a href={`/blog-sub-category?id=${category["id"]}`}>
                          <img src={category["image"]} alt="" />
                        </a>
                      </div>
                      <div className="p-4">
                        <div className="authore">
                          <ul>
                            <li>
                              Author:<span>skylarklabs</span>
                            </li>
                            <li>
                              Post :<span>July 08, 2022</span>
                            </li>
                          </ul>
                        </div>
                        <div className="blog-title">
                          <h2>{category["title"]}</h2>{" "}
                        </div>
                        <div className="blog-short-dis">
                          <p>{category["description"]} </p>
                        </div>
                        <div className="related-tags">
                          <ul>
                            <li>
                              {" "}
                              <a href="/">ETOS</a>{" "}
                            </li>
                            <li>
                              {" "}
                              <a href="/">ARIES</a>{" "}
                            </li>
                            <li>
                              {" "}
                              <a href="/">MIRAS</a>{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </section>
      </div>
    </>
  );
};

export default Blogcategory;
