import React from "react";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: "0px",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {},
}));
const FacesGrid = (props) => {
  const facesList = props.faces;

  const hasFaceMessage = props.hasFaceMessage;

  // const colCount = facesList ? facesList.length : 0;

  // console.log("in component:" + colCount);

  const classes = useStyles();
  return (
    // <div classname="row feature_inner justify-content-center mt-10">{cols}</div>

    <div
      className={classes.root}
      style={{
        paddingTop: "10px",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "100%" }}>
        <Alert
          variant="filled"
          severity="success"
          style={{
            fontSize: "12px",
            padding: "2px",
            textAlign: "center",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          {hasFaceMessage}
        </Alert>
      </div>
      <GridList
        className={classes.gridList}
        cols={1}
        style={{
          cellHeight: "150px",
          // , maxHeight: "450px"
        }}
      >
        {facesList.map((tile) => (
          <GridListTile
            key={tile.id}
            cols={tile.cols || 1}
            style={{
              justifyContent: "center",
              padding: "10px",
              margin: "10px",
            }}
          >
            <img
              onClick={(e) => props.faceClick(e, tile.value)}
              style={{
                width: "130px",
              }}
              src={tile.value}
              alt={tile.id}
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};

export default FacesGrid;
