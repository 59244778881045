import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef } from "react";

const Artrabia = () => {
    useEffect(() => {
        const title = document.getElementById("mainTitle");
        title.innerText = `Skylark Labs: Artrabia`;
    }, []);

    const navOption1 = useRef(null);
    const navOption2 = useRef(null);
    const navOption3 = useRef(null);
    const section1 = useRef(null);
    const section2 = useRef(null);
    const section3 = useRef(null);

    let otherCases = [
        {
            title: "D1 Security",
            desc: "Previously, everyone had to get their ID card checked before entering the park.",
            url: "d1-security",
            img: "images/cases-hotel.png",
        },

        {
            title: "AH1 Hotel",
            desc: "The basic CCTV cameras were unable to detect any violence incidents.",
            url: "ah1-hotel",
            img: "images/ah1-hotel.jpg",
        },

        {
            title: "Guria NGO",
            desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
            url: "Guria-varanasi-human-trafficking",
            img: "images/cases-guria.png",
        },
    ];
    const scrollCheck = (e) => {
        try {
            const addActiveClass = (option) => {
                if (option === undefined) return;
                [navOption1, navOption2, navOption3]
                    .filter((ele) => ele !== option)
                    .map((ele) => ele.current.classList.remove("active"));
                option.current.classList.add("active");
            };

            const offsetY = window.scrollY + 200;
            if (section3.current && offsetY >= section3.current.offsetTop)
                addActiveClass(navOption3);
            else if (section2.current && offsetY >= section2.current.offsetTop)
                addActiveClass(navOption2);
            else if (section1.current && offsetY >= section1.current.offsetTop) {
                addActiveClass(navOption1);
            }
        } catch (e) { }
    };

    useEffect(() => {
        // window.scrollTo({
        //   top: 0,
        // });
        window.addEventListener("scroll", scrollCheck);
    }, []);

    return (
        <>
            <div className="plateformsub-page clientpage-outer">
                <div className="noise-bg"></div>
                <div className="main-shape"></div>

                <div className="container">
                    <div className="clientnavgations">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <h3>Artrabia</h3>
                                <div className="clientnavgationssubtitle"><span>Riyadh, </span>  <span>Saudi Arabia</span> </div>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={8}
                                md={8}
                                lg={8}
                                className="customer-page-nav-links-grid"
                            >
                                <div className="customer-page-nav-links-wrap">
                                    <ul>
                                        <li>
                                            <a href="#overview">Overview</a>{" "}
                                        </li>
                                        <li>
                                            <a href="#problem">Problem</a>{" "}
                                        </li>
                                        <li>
                                            <a href="#solution">Solution</a>{" "}
                                        </li>
                                        <li>
                                            <a href="#result">Result</a>{" "}
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3} md={3} lg={4}>
                            <div className="text-left logoclient">
                                <img src="images/productSlider/ATRABIA_FULL_LOGO.png" alt="" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9} lg={8}>
                            <div className="client" id="overview">
                                <h3>Overview</h3>
                                <p>
                                    Artrabia, a leader in the Drone industry, is embracing the cutting-edge technology of drones for infrastructure monitoring on construction sites. This innovative approach marks a significant shift towards enhancing efficiency, safety, and accuracy in their construction projects. By integrating drone technology, Artrabia is not only streamlining its operational processes but also setting new standards in construction site management. The use of drones for site surveillance and data collection reflects Artrabia's commitment to adopting advanced technological solutions to meet the evolving demands of the construction sector.
                                </p>
                                {/* <img src="images/Customers/Artrabia/artrabia-overview.jpeg" alt="" style={{
                                    borderRadius: "40px",
                                }} /> */}
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className="pt-5 mt-5" id="problem">
                        <Grid item xs={12} sm={3} md={3} lg={4}>
                            <h3>Problem</h3>
                        </Grid>

                        <Grid item xs={12} sm={9} md={9} lg={8}>
                            <div className="client">
                                <p>
                                    Artrabia faced significant challenges in monitoring their construction sites. Their main issue was efficiently managing and analyzing a large amount of resources and work progress. Traditional methods were falling short, causing inefficiencies, safety risks, and project delays. For Artrabia, it was crucial to accurately track resource allocation and monitor work progress in real-time. This would not only boost operational efficiency but also ensure safety compliance and enhance overall project management at their large construction sites.
                                </p>
                                <img src="images/Customers/Artrabia/a - prob.jpg" alt="" style={{
                                    borderRadius: "40px",
                                }} />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className="pt-5 mt-5" id="solution">
                        <Grid item xs={12} sm={3} md={3} lg={4}>
                            <h3>Solutions</h3>
                        </Grid>

                        <Grid item xs={12} sm={9} md={9} lg={8}>
                            <div className="client">
                                <p>
                                    Skylark Labs addressed Artrabia's challenges by deploying advanced drone technology enhanced with Kepler's DROS, featuring an infrastructure monitoring capability. These drones, equipped with sophisticated cameras and sensors from the DROS, were instrumental in precise real-time monitoring and logging of resources and work progress on construction sites. This integration allowed for pinpoint accuracy in tracking, rapid issue identification, and efficient resource management. Skylark Labs' innovative solution, incorporating the DROS, transformed Artrabia's site management practices, leading to more effective decision-making, minimized manual inspection risks, and notably boosted project efficiency and safety.
                                </p>
                                <img src="images/Customers/Artrabia/a - sol.png" alt="" style={{
                                    borderRadius: "40px",
                                }} />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className="pt-5 mt-5" id="result">
                        <Grid item xs={12} sm={3} md={3} lg={4}>
                            <h3>Conclusion</h3>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9} lg={8}>
                            <div className="client">
                                <p>
                                    The collaboration between Artrabia and Skylark Labs in implementing drone technology has markedly enhanced construction site monitoring. This solution not only addressed Artrabia's specific challenges but also set a new standard in construction project management, showcasing Skylark Labs' capability to deliver innovative and effective technological solutions to its customers.
                                </p>
                            </div>
                            <Grid container spacing={3} className="customer-page-key-features">
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <div className="key-features">
                                        <div className="iconsfeatucher">
                                            <img
                                                src="images/productSlider/Play.svg"
                                                alt=""
                                            /></div>
                                        <h3>
                                            40% Reduction in Manual Inspections
                                        </h3>
                                        <p>Precise monitoring reduces manual checks significantly for enhanced site oversight.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <div className="key-features">
                                        <div className="iconsfeatucher">
                                            <img
                                                src="images/productSlider/Analytics.svg"
                                                alt=""
                                            /></div>
                                        <h3>
                                            30% Increase in Resource Utilization Efficiency
                                        </h3>
                                        <p>Accurate tracking maximizes material and manpower use, streamlining project execution.</p>
                                    </div>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                </div>
            </div>
            {/* 
            <div className="plateformsub-page">
                <section
                    ref={section1}
                    className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
                >
                    <div className="container pb-5 mb-5">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div className="">
                                    <h2>
                                        <span className="spanfirstword">Related</span> More Cases
                                    </h2>
                                </div>
                            </Grid>
                        </Grid>

                        <div className="industriesListblock pt-5">
                            <ul>
                                {otherCases.map((ele, idx) => (
                                    <li>
                                        <a href="#contact" className="industriesListcol">
                                            <div className="solutionListimg">
                                                <img src={ele.img} alt="" />
                                            </div>
                                            <div className="industriesListcoltext">
                                                <h4>{ele.title} </h4>
                                                <p>{ele.desc}</p>
                                                <div className="quicklinks casesquicklinks">
                                                    {" "}
                                                    View full case{" "}
                                                    <img src="images/right-arrow.png" alt="" />{" "}
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>
            </div> */}
        </>
    );
};

export default Artrabia;