import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const Privacy = () => {
    const [expanded, setExpanded] = React.useState("panel2");
    const navOption1 = React.useRef(null);
    const navOption2 = React.useRef(null);
    const navOption3 = React.useRef(null);
    const navOption4 = React.useRef(null);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    useEffect(() => {
        const title = document.getElementById("mainTitle");
        title.innerText = "Skylark Labs Privacy Policy"
        return () => {
            console.log('title changed');
        }
    }, []);
    return (
        <>
       <div className="privacybanner">
       <div className="container">
       <div className="dismanbanner">
        <div className="privacyimg">
        <img src="../images/privacy.png" alt=""/>
        </div>
              <h1>Skylark Labs Privacy Policy</h1>

              <ul>
                <li className="active"><a href="/privacy">Privacy</a> </li>
                <li><a href="/privacy-us">US Privacy</a> </li>
                <li><a href="/privacy-canada">Canada Privacy</a> </li>
                <li><a href="/privacy-australia">Australia Privacy</a> </li>
                <li><a href="/privacy-europe">Europe Privacy</a> </li>
               
                </ul>
              </div>
            </div>
        
       
        
      </div>

            <section className="termsprivacy">
                <div className="container">
                <div className="termsprivacycontent">
                <h2>AIM OF PRIVACY POLICY</h2>
                <p>We at Skylark Labs (hereafter referred to as "Skylark Labs," "the Company," "we," "us," and "our") understand the importance of our customer's personal information and take every precaution to keep it secure. Users, clients, and other visitors (collectively "users") to the Company's website (from now on "our website" or "this website") are essential to us, and we take their right to privacy very seriously. The purpose of this policy is to protect the personal information of anyone who visits our website. We are providing this notice to reiterate our dedication to collecting and using client data in an open and honest manner that does not compromise client privacy. Data submitted to any other website, which may have its own explicit privacy policies or data protection guidelines, is not covered by the privacy policy provided hereunder and the Company shall not be liable for safeguarding such data.</p>
                
                <h2>Data Collection</h2>

               

                <h3>
       DATA SOUGHT FROM THIS WEBSITE
       </h3>
       <p>Users' personal details (email addresses, names, etc.) may be collected via our website's registration/survey/other data collection forms.
</p>
<p>Information that will allow someone to get in touch with you, such as your name, address, phone number, and/or email address.
</p>
<p>Users' additional personal data (such as their birth dates or social security numbers) that they voluntarily disclose on the site.
</p>
<p>Information about the user's bank account and/or debit or credit card, which may be used for online payments.
</p>

<h3>
ENHANCED DATA COLLECTED BY THIS WEBSITE
       </h3>
       <p>The domain names and IP addresses of visitors to our websites are logged automatically by our servers, but this information is not linked to any personally identifiable information. In addition, the site may keep track of non-personally identifiable "traffic data" for the purposes of marketing and service improvement.
</p>
<p>Cookies are used on this site to collect non-personal information about the browsing habits of visitors. Information gathered by this site will be used to better administer and expand the usefulness of web services already available to users. To keep users' accessibility settings and text intact, cookies are used. Cookies allow us to monitor how users interact with our site. The data we collect from them allows us to examine the ways in which users navigate and search for content.
</p>
       <h2>DATA PROTECTION</h2>
       <h3>
         <span>I.</span>CONFIDENTIALITY DETAILS:
       </h3>
       <p>GDPR defines personal data as "any information relating to an identified or identifiable natural person ("data subject"); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identification number or one or more factors specific to his physical, physiological, mental, economic, cultural, or social identity." </p>
       <p>We will never disclose any of your private information to a third party without your permission unless we are required to do so by law. All submitted user information is used exclusively for the purpose of providing the services that were requested. To confirm a user's identity, check their credentials, or finalize a transaction started on the website, we may need to refer to and/or use certain personally identifiable information. Users' contact details may also be used to alert them to any changes to the site or to provide them with additional information about the Company or its services. </p>
       <h3>
         <span>II. </span>TRANSFORMING AND ERASING GATHERED INFORMATION:
       </h3>
       <p>There is currently no way for users to access, modify, or delete the information they have provided to us; however, they can contact us to have their information completely removed from our system. </p>
       <p> Users can opt-out of receiving updates, promotions, or any other types of communication from us at any time by clicking the "unsubscribe" link at the bottom of any email they receive from us. </p>
       <p>Users who have already submitted information but would like to make changes can do so by contacting us via the provided link. </p>
       <h3>
         <span>III. </span> SHARING OR DISCLOSURE OF PERSONAL DETAILS:
       </h3>
       <p>We have strict policies in place to prevent the selling or sharing of individual users' personal information for the purposes of advertising, promotion, or any other activity that would violate applicable identity theft or data breach laws. If the Company believes in good faith that it must disclose personal information to comply with all or any legal provisions governing the Company, to protect the rights or property of the Company or users of this website, or to ensure the safety of the public or users of this website, the Company will disclose such information whether or not such disclosure is required by law. </p>
       <h3>
         <span>IV. </span> ANONYMOUS INFORMATION:
       </h3>
       <p>We may use non-personally identifiable information collected on our site for statistical and analytical purposes, but we will never use this information to identify individual users. Furthermore, we may use IP addresses without revealing personal information to track down server issues and enhance the website's performance.</p>
       <h4>GENERAL DATA PROTECTION REGULATION PRIVACY POLICY </h4>
       <h3>PERSONAL INFORMATION GATHERED ON THIS WEBSITE</h3>
       <p>User information is gathered in a variety of ways. Users' payment information is collected when they make a purchase, but it is not stored by us once it has been sent to our payment card processor. Information such as a user's name, contact information, email address, username, and password is collected when a user registers for an account, makes a purchase, submits a request for more information, or uses the site in any other way. </p>
       <h3>USERS' DATA PRIVACY RIGHTS</h3>
       <p>Users have the legal right to request access to their personal information that has been processed by this website in accordance with this privacy policy, to obtain a copy of their personal information that has been processed by the site, to request the transfer of their personal data (the "right to data portability"), and to request that their personal information not be processed (the "right to be forgotten"). </p>
       <p>The user may also exercise their right to object to the processing of personal data where such processing is subject to the Company's overriding legal interests in accordance with (Article 6 (1) (f) of GDPR) by contacting us via the provided link. We will review your request to suspend the processing and comply with it unless we are able to demonstrate compelling legitimate grounds to continue processing, such as the need to use this information to prevent or defend against legal claims. </p>
       <h4>INFORMATION REGARDING THE COMPANY AND THE USERS </h4>
       <p>Any individual who can be recognized because of some combination of specific information (name, location, identification number, online identifier, etc.) and physical, physiological, mental, genetic, economic, cultural, or social characteristics is considered to be a natural person who is directly or indirectly identifiable. User rights under the EU General Data Protection Regulation (GDPR) are included. Data about a specific and identifiable individual is considered personal information. Note that if you are a user from a member state of the European Union, we will transfer all the information to the United States for processing and storage and that by providing your consent to this policy, you agree to any such processing, transfer, and storage. Because we consider the privacy of our users to be of the utmost importance, we have drafted this Privacy Policy to explain in detail how we collect, use, and safeguard any information provided by site visitors.</p>
       <h4> STORAGE AND USE</h4>
       <p>Unless required to do so by law or with the user's express consent, we will not disclose any information that personally identifies them to any third party. We use the information users provide about themselves on our site to fulfil their orders, keep them abreast of any changes made to the products they've already bought from us, and pitch them other goods and services we think they'll find valuable. Personal information provided by users may be used for purposes such as validating the user's identity or qualifications, or for following up on transactions initiated by the user. Additionally, we may use users' contact details to alert them to updates to the site or to send them additional information regarding the Company. </p>
       <p> When analysing the traffic to our Site, we may use aggregated, anonymous data from this source; however, we will not use this information to identify any individual users. Occasionally, we may use IP addresses that have been anonymized for the purpose of diagnosing server issues or making enhancements that will benefit all users. </p>
       <p> Cookies, which record data about your visit to our site and retrieve it later, may be used on this site. Except for what is contained in cookies, we do not store any information that could be used to identify you on our website. As a result of cookies, we are able to enhance the functionality of our website. To accept or decline cookies, the user can adjust browser preferences. </p>
       <p> Information about the user is collected only when it is necessary to fulfill the user's request for a product or service or when the user voluntarily submits such information in the form. </p>
       <h4>CHANGES TO THE PRIVACY STATEMENT</h4>
       <p> SKYLARK LABS may, at any time and without notice to users, add to, amend, update, or alter our privacy policy by posting about such additions, amendments, updates, or alterations. Any such updates, amendments, or modifications shall be effective immediately upon posting to the site. In the event that we make changes to our policy, those changes will be reflected in the company's privacy statement, so it is incumbent upon users to check for updates on a regular basis. We make sure every user understands what information we collect and why we collect it, as well as when and why we might use or share that information.</p>
       <h4>PLEASE GET IN TOUCH WITH US</h4>
       <p>Email us at [email protected]  if you have any questions about this Privacy Statement, the procedures we use to manage the information collected through the Website, or if you would like to modify or delete your information from our records.</p>
       <h4>Laws Regarding Face Recognition Technology (For US only)</h4>
       <h3>USERS' DATA PRIVACY RIGHTS</h3>
       <p> Users have the legal right to request access to their personal information that has been processed by this website in accordance with this privacy policy, to obtain a copy of their personal information that has been processed by the site, to request the transfer of their personal data (the "right to data portability"), and to request that their personal information not be processed (the "right to be forgotten"). </p>
       <p> The user may also exercise their right to object to the processing of personal data where such processing is subject to the Company's overriding legal interests in accordance with (Article 6 (1) (f) of GDPR) by contacting us via the provided link. We will review your request to suspend the processing and comply with it unless we are able to demonstrate compelling legitimate grounds to continue processing, such as the need to use this information to prevent or defend against legal claims. </p>
       <h3>INFORMATION REGARDING THE COMPANY AND THE USERS</h3>
       <p>Any individual who can be recognized because of some combination of specific information (name, location, identification number, online identifier, etc.) and physical, physiological, mental, genetic, economic, cultural, or social characteristics is considered to be a natural person who is directly or indirectly identifiable. User rights under the EU General Data Protection Regulation (GDPR) are included. Data about a specific and identifiable individual is considered personal information. Note that if you are a user from a member state of the European Union, we will transfer all the information to the United States for processing and storage and that by providing your consent to this policy, you agree to any such processing, transfer, and storage. Because we consider the privacy of our users to be of the utmost importance, we have drafted this Privacy Policy to explain in detail how we collect, use, and safeguard any information provided by site visitors.</p>
       <h3>STORAGE AND USE</h3>
       <p> Unless required to do so by law or with the user's express consent, we will not disclose any information that personally identifies them to any third party. We use the information users provide about themselves on our site to fulfil their orders, keep them abreast of any changes made to the products they've already bought from us, and pitch them other goods and services we think they'll find valuable. Personal information provided by users may be used for purposes such as validating the user's identity or qualifications, or for following up on transactions initiated by the user. Additionally, we may use users' contact details to alert them to updates to the site or to send them additional information regarding the Company. </p>
       <p> When analysing the traffic to our Site, we may use aggregated, anonymous data from this source; however, we will not use this information to identify any individual users. Occasionally, we may use IP addresses that have been anonymized for the purpose of diagnosing server issues or making enhancements that will benefit all users. </p>
       <p> Cookies, which record data about your visit to our site and retrieve it later, may be used on this site. Except for what is contained in cookies, we do not store any information that could be used to identify you on our website. As a result of cookies, we are able to enhance the functionality of our website. To accept or decline cookies, the user can adjust browser preferences. </p>
       <p> Information about the user is collected only when it is necessary to fulfill the user's request for a product or service or when the user voluntarily submits such information in the form. </p>
       <h3>CHANGES TO THE PRIVACY STATEMENT</h3>
       <p>SKYLARK LABS may, at any time and without notice to users, add to, amend, update, or alter our privacy policy by posting about such additions, amendments, updates, or alterations. Any such updates, amendments, or modifications shall be effective immediately upon posting to the site. In the event that we make changes to our policy, those changes will be reflected in the company's privacy statement, so it is incumbent upon users to check for updates on a regular basis. We make sure every user understands what information we collect and why we collect it, as well as when and why we might use or share that information. </p>
       <h4>4. Laws Regarding Face Recognition Technology (For US only)</h4>
       <h3>US: Laws regarding FRT</h3>
       <p>In the US, there is an ongoing trend of Biometric Laws that are sometimes in addition to the existing primary Privacy Legislations. There are certain federal laws, along with state laws, and city laws that regulate the aspect of privacy as well as Facial Recognition Technology (FRT). There are also Bills that are in motion to regulate the usage of FRT. Also, discussions and debates are going on regarding the impact of FRT technology on privacy, and the laws regarding FRT may see drastic changes in coming months and years. </p>
       <h5>US Federal</h5>
       <p>A legislative instrument has been introduced in the Senate that would ban federal spending on FRT. This is called Facial Recognition and Biometric Technology Moratorium Act of 2020.</p>
       <ul>
         <li> The Bill prohibits biometric surveillance by the Federal Government without explicit statutory authorization and to withhold certain Federal public safety grants from State and local governments that engage in biometric surveillance</li> It imposes limits on the use of biometric surveillance systems, such as facial recognition systems, by federal and state government entities <li>A federal agency or official may not in an official capacity acquire, possess, or use in the United States any such system or information obtained by such a system unless Congress passes an act that specifically authorises such a use. Such an act of Congress must contain certain provisions, such as provisions naming the specific authorised entity and auditing requirements relating to the system. </li>
         <li> Information obtained in violation of this bill shall not be admissible by the federal government in any proceeding or investigation, except in a proceeding alleging a violation of this bill </li>
         <li> An individual aggrieved by a violation of these restrictions shall have the right to sue. Any state officer authorised to sue on behalf of the state’s residents shall also have the right to sue on behalf of the state’s aggrieved residents. </li>
       </ul>
       <p>A state or local government unit shall not receive certain federal law enforcement grants unless the government unit complies with a law or policy that is substantially similar to this bill’s restrictions on acquiring and using biometric surveillance systems. No federal law banning private usage </p>
       <h5>States</h5>
       <p>State regulations in the US cannot regulate the federal usage, but can control the usage by entities under the state's government. </p>
       <h5>California</h5>
       <p>
         <strong>AB-1215 Law enforcement: facial recognition and other biometric surveillance.</strong>
         <br></br> Moratorium for use of FRT on Body Cams for a period of 3 years, that ends in 2023. Though the use in body cams by police officers has been prohibited temporarily, nothing has been said about the usage in stationary cameras.
       </p>
       <p> Private usage of FRT is allowed under the law, as the law is narrow to any law enforcement agency of California or California municipalities. </p>
       <p> No specific law on ban regarding private usage of FRT. </p>
       <p> AB 2261 AB 2261 would enable companies—and some government agencies—to use facial recognition technology to track people in the workplace without recourse, even if workers were notified (Bill Died) </p>
       <p> CCPA will govern the usage of FRT for businesses. Usage is allowed subject to compliance under the law. </p>

       <h3>Recommendation: No for govt. Use</h3>
       <h5>San Francisco</h5>
       <p>San Francisco has released an Ordinance stopping the use of FRT by City Departments. Under the SEC. 19B.2. (d)[3] of the shall  be unlawful for any Department to obtain, retain, access, or use:
</p>
        <ol>
            <li>any Face Recognition Technology on City-issued software or a City-issued product or device; </li>
            <li>any information obtained from Face Recognition Technology on City-issued software or a City-issued product or device. There are certain exceptions to this bar on use of FRT though namely.
        </li> 
        </ol>
        <ul>
            <li>
            The Department does not request or solicit its receipt, access to, or use of such information obtained from Face Recognition Technology; and

            </li>
            <li>
            The Department logs such receipt, access to, or use in its Annual Surveillance Report.
            </li>
        </ul>
        <p>Further, City Department can possess Face Recognition Technology on City-issued software or a City-issued product or device, provided that:
</p>
<ul>
    <li> The Face Recognition Technology is a stock, manufacturer-installed capability, bundled with software or stored on a product or device, and that the functions unrelated to Face Recognition Technology are necessary to perform essential City functions;
</li>
<li>  The software, product, or device was not acquired for the purpose of performing the Face Recognition Technology function.
</li>
<li> The Face Recognition Technology cannot be deleted from the software, product, or device; and
</li>
<li>The Department does not use the Face Recognition Technology.
</li>
</ul>
<p><strong>This, while essentially allowing FRT, does not allow the same for General Public Surveillance, and only allows it for limited purposes that does not involve actual use of FRT, but only if the same is an actual part of some other software or product.
</strong></p>
<p>No specific law on ban regarding private usage of FRT
</p>
<h5>Oakland</h5>
<p>Oakland has banned the use of FRT by city agencies, including the police department[6]. It defines "Biometric Surveillance Technology" as any computer software that uses face recognition technology or other remote biometric recognition in real time or on a recording or photograph and says , it shall be unlawful for the City or any City staff to obtain, retain, request, access, or use Biometric Surveillance Technology. The restriction does not apply to regular (Non Biometric) surveillance.
</p>
<p><strong>No specific law on ban regarding private usage of FRT</strong></p>
<h5>Santa Clara</h5>
<p>Santa Clara does not ban the use of FRT, but severely limits its use. The FRT can only be used in a passive manner and not an active manner and during a criminal investigation to assist in the identification of unknown persons associated with a specific criminal investigation. Only the personnel in the Sheriff's office are allowed to use it.
</p>
<p><strong>FRT cannot be used for personal or non-law enforcement purposes.</strong></p>
<h5>Berkley</h5>
<p>Permission needed by the city manager from the City Council for usage of Surveillance Technology or for Entering into an agreement with a non-City entity to acquire, share or otherwise use Surveillance Technology or the information it provides. [8] But the usage of FRT is banned for Government use.
</p>
<p><strong>No specific law on ban regarding private usage of FRT</strong></p>
<h5>New York</h5>
<p>Under the NY Biometric Identifier Information Law, there are certain curbs on the sale of biometric data but commercial establishments can use the Biometric Technologies (Including FRT), provided a clear and prominent notice is given that such technology is being used. It is unlawful to gain any profit from the data collected this way though.
</p>
<p><strong>Commercial Establishments include a place of entertainment, a retail store, or a food and drink establishment. (Includes Stadiums)
</strong></p>
<p>Can also be used in residential buildings under TDPA, subject to consent.
</p>
<p>The law though does not apply to the Government Agencies, employees or agents. 
Though in 2020, the NY City Council voted to enact the Public Oversight of Surveillance Technology (POST) Act which will mandate the police department to disclose their usage of surveillance technologies. 
</p>

<h5>Florida</h5>
<p>No laws governing usage of FRT.</p>
<h5>Miami</h5>
<p>Provides for rules regarding official use of FRT. The usage of FRT will not be proactive, and cannot be used for proactive monitoring of persons in real time even by private entities. 

</p>
<h5>Massachusetts</h5>
<p>Any law enforcement agency performing or requesting a facial recognition search using facial recognition technology shall only do so through a written request submitted to the registrar of motor vehicles, the department of state police or the Federal Bureau of Investigation. Law technically allows passive FRT. * Also a bill is in place that seeks to ban the usage of FRT in Massachusetts.

</p>
<p>Seven municipalities in Massachusetts have banned face surveillance in local government: Boston, Brookline, Cambridge, Easthampton, Northampton, Springfield, and Somerville.

</p>

<h5>Boston</h5>
<p>Use of FRT is banned. City officials are prohibited from obtaining and using it, or asking for its usage through any third party.

</p>
<p><strong>There are no explicit rules regarding ban on private use of FRT</strong></p>

<h5>Somerville</h5>
<p>The use of FRT is banned in Somerville till the time transparent regulations come up in this regard. This applies to every government entity in Somerville.

</p>

<p>There are no explicit rules regarding ban on private use of FRT
</p>
<h3>Oregon</h3>
<h5>Portland</h5>
<p>The city of Portland has banned the usage of Face Recognition Technologies by Private Entity in Places of Public Accommodation within the boundaries of the City of Portland. It has also banned the usage of FRT by the city bureau.
</p>
<p>A public accommodation is a private business or organisation that offers goods or services to the public
</p>
<h5>Illinois</h5>
<p>Under Illinois’ Biometric Information Privacy Act (“BIPA”) is considered the most stringent. Under BIPA, a private entity cannot collect or store facial template data without first providing notice, obtaining written consent, and making certain disclosures. If consent is obtained, FRT can be used in private property, company premises, stadiums and other venues where the consent for the same is obtained. Can be used in the Stadium
</p>
<h5>Texas</h5>
<p>Legal for commercial use after consent
 
 Usage of FRT for Airport, Stadiums etc depends on case to case basis. Most states have not banned the usage of FRT for private purpose.
 Technologies other than FRT
 </p>
 <p>
 <strong>Weapon Detection:</strong>  No explicit laws found ascertaining any ban on the usage of technology as there is no involvement of biometric data. Further confirmation needed for all US States.
 </p>
 <p>
 <strong>Violence Detection:</strong>  No explicit laws found ascertaining any ban on the usage of technology as there is no involvement of biometric data. Further confirmation needed for all US States. 

 </p>
 <p>Need to confirm
</p>
<p>
 <strong>Gait Detection-</strong> Not Covered </p>

 <p>NOT COVERED </p>
 <ul>
    <li>Rules regarding FRT for rest of the US States and Cities</li>
    <li>Laws regarding AI Automation, if any</li>
    <li>Case Laws, Legislative Debates</li>
 </ul>
 <h3>
 PLEASE GET IN TOUCH WITH US

       </h3>
       <p>Email us at<a href="mailto:info@skylarklabs.ai">info@skylarklabs.ai</a> if you have any questions about this Privacy Statement, the procedures we use to manage the information collected through the Website, or if you would like to modify or delete your information from our records.
</p>
     </div>

               
                </div>
            </section>
        </>
    );
};

export default Privacy;
