import React, { useEffect, useState, useRef } from "react"; 
import Grid from "@material-ui/core/Grid"; 
import { Link } from "react-router-dom"; 
import ReactPlayer from "react-player"; 
import Etios from "../../videofiles/etios-behaviour.mp4";
import Trafficmonitoring from "../../videofiles/Traffic_monitoring_new.mp4";

import CounterUAS from "../../videofiles/campus-weapon.mp4";
import Miras from "../../videofiles/people_tracking.mp4"; 
import Firesmokedetection from "../../videofiles/fire-smoke-detection.mp4"; 
import Publiccrowdcounting from "../../videofiles/public-crowd-counting.mp4";
import Publicsafetybanner from "../../videofiles/public-safety-banner.mp4";


import { Typography } from "@material-ui/core";
import PropTypes from 'prop-types'; 
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core"; 
const Publicsafety = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = ` Skylark Labs: Public Safety`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [showTrial, setShowTrial] = React.useState(false);
  const [expanded, setExpanded] = React.useState("panel2");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };
  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },
 
    {
      title: "D.S. Jeweller",
      desc: "The incidents happened in the locality where robbers used to come at night and loot the jewellery shops.",
      url: "ds-jewellers", 
      img: "images/cases-jewellers.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      // if (section4.current && offsetY >= section4.current.offsetTop)
      //   addActiveClass(navOption4);
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChangenew = (event, newValue) => {
    setValue(newValue);
  };
  
 

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>Public Safety</h1>
              <p>
              Skylark lab’s  advanced AI based solution provides city-wide surveillance by detecting various problems such as traffic problems, thefts, violence, riots, etc  to enhance public safety & security and prevent them by alerting the authorities to solve them retroactively.
   </p>
   <div className="subtages"><a href="#/">Cities  </a>  <a href="#/">Hotels </a> <a href="#/">Hospitals </a> <a href="#/">Stadiums </a></div>
           
            </div>
          </div>
        </div>
        <ReactPlayer
            style={{
              zIndex: "-1",
              pointerEvents: "none",
            }}
            controls={false}
            volume={0}
            playsinline
            width="100%"
            height="100%"
            loop={true}
            muted={true}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url={Publicsafetybanner}
          />
        
       
      </div>

      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>Public Safety</h2>
            <p>
            Skylark lab’s  advanced AI based solution provides city-wide surveillance by detecting various problems such as traffic problems, thefts, violence, riots, etc  to enhance public safety & security and prevent them by alerting the authorities to solve them retroactively.
 </p>
          </div>
        </div>
      </div>

      <section className="homeourproduct poductCatename productpag_section">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="videoleftblock fistblock bordernone">
                <div>
                 
                  <img src="images/public-safety.png" alt="" />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block fistblockight homeright-block-auto">
                <div>
                  <h4 className="mb-0">Enhance City Security</h4>
                  <p className="pt-0">
                  In public demonstrations and riots, there is always the possibility of an accident. A criminal or terrorist in a riot might pose a significant threat to demonstrations or protests. Our system assists law enforcement in identifying recorded criminals from a great distance. Works with standard CCTV cameras in a variety of lighting settings and verticals.

                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
        
      <section className="homeprodctlist blackrarkoutr lightgray">
<div className="container homeprodctlisttop whitetext">
<Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={7}>
             <h4> 
             Threats Detection From Land

             </h4>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
             <p className=" py-0 my-0">
             A complete public safety security system for land, from identifying future emerging and reactive threats to providing completely detailed information to first responders for appropriate action.

             </p>
          </Grid>
       </Grid>
       </div>
    <div className="container">
    <Box sx={{ width: '100%' }}>
     
      <TabPanel value={value} index={0}>
        <div className="productitembox ">
        <ReactPlayer
            style={{
              zIndex: "-1",
              pointerEvents: "none",
            }}
            controls={false}
            volume={0}
            playsinline
            width="100%"
            height="100%"
            loop={true}
            muted={true}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url={Etios}
          />

          <div className="productitemboxdis">
<h4>  Emerging Threats</h4>
<p>Detects repeated suspicious or risky activities that have the potential to escalate into significant threats.

</p> 
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <div className="productitembox ">
      <ReactPlayer
            style={{
              zIndex: "-1",
              pointerEvents: "none",
            }}
            controls={false}
            volume={0}
            playsinline
            width="100%"
            height="100%"
            loop={true}
            muted={true}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url={CounterUAS}
          />


          <div className="productitemboxdis">
<h4>Known Threats</h4>
<p>Detects repeated suspicious or risky activities that have the potential to escalate into significant threats.
</p> 
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <div className="productitembox ">
   <img src="images/silas.jpeg" alt="" />

          <div className="productitemboxdis">
    

<h4> Understanding Situation</h4>
<p> Detects the characteristics of incidents and provides a comprehensive analysis of the situation.</p> 
          </div>
        </div>
      </TabPanel> 
 
      <div className="poductlisttabs poductlisttabstop">
      <Box sx={{ borderColor: 'divider' }}>
     
        <Tabs value={value} onChange={handleChangenew} aria-label="basic tabs example"> 
          <Tab label=" ETOS - Emerging Threats" {...a11yProps(0)} />
          <Tab label=" ETOS - Known Threats" {...a11yProps(1)} /> 
          <Tab label="SILAS - Understanding Situation" {...a11yProps(2)} />
         
        </Tabs>
       
      </Box>
      </div>
    </Box> 
    </div>
    </section>

    
    <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section"
        id="surveillance"
      >
        <div className="container">
       
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
            <ReactPlayer
            style={{
              zIndex: "-1",
              pointerEvents: "none",
            }}
            controls={false}
            volume={0}
            playsinline
            width="100%"
            height="100%"
            loop={true}
            muted={true}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url={Firesmokedetection}
          />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block">
                <h4>
                 Forensic Analysis</h4>
                <p>
                Find hidden characteristics in pre-recorded incident videos and save analyses as reports for quick access to search results and alerts of interest by adding comments and annotations to the alarms inside the report.

</p>

                <ul className="mb-4">
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-people-fighting.svg"
                          alt=""
                        />
                      </span>
                      Video Investigate 
                      
                    </div>
                  </li>
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-person-with-weapon.svg"
                          alt=""
                        />
                      </span>
                      Forensic Reports 
                      
                    </div>
                  </li>
                </ul>

             
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <section className="homeprodctlist lightgray">
        <div className="container homeprodctlisttop">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={7}>
              <h4>Threats Detection with Drones</h4>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <p className=" py-0 my-0">
                Land Systems are a class of autonomous modules powered by Neural
                Core AI that use ground-based imaging sensors to provide
                tactical awareness for military missions.
              </p>
            </Grid>
          </Grid>
        </div>
        <div className="container">
          <Box sx={{ width: "100%" }}>
            <TabPanel value={value} index={0}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Trafficmonitoring}
                />

                <div className="productitemboxdis">
                  <h4> Traffic Management</h4>
                  <p>
                  Drones are used to observe vehicles and persons in motion for better traffic control and to assist first responders in taking appropriate steps in critical situations.

                  </p>
                  
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Publiccrowdcounting}
                />

                <div className="productitemboxdis">
                  <h4>Crowd Counting</h4>
                  <p>Counts the number of persons around the area with help of drones and helps in crowd management to take proper action in the occurring event



                   </p>
                
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Miras}
                  
                />

                <div className="productitemboxdis">
                  <h4>Tracking People</h4>
                  <p>
                    The system identifies and delivers alerts with information
                    in the event of any suspicious aerial birds that could be
                    injured by other military objects.
                  </p>
                 
                </div>
              </div>
            </TabPanel>
            <div className="poductlisttabs">
              <Box sx={{ borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChangenew}
                  aria-label="basic tabs example"
                >
                  <Tab label="Traffic Management" {...a11yProps(0)} />
                  <Tab label="Crowd Counting " {...a11yProps(1)} />
                  <Tab label="Tracking People" {...a11yProps(2)} />
                </Tabs>
              </Box>
            </div>
          </Box>
        </div>
      </section> 
     
      <section
        className="benefitmain industriesuses bggray latticeos_faq white-bg"
        id="admincontrol"
      >
        <div className="container">
          <div className="benefitssection  ">
            <h2 className="mb-0 text-center">Key Features</h2>
          </div>

          <div className="listofindust skylarksuite">
            <ul>
              <li className="text-center">
                <div className="featuresblock featuresblockbgthree">
                  <span>
                    <img src="images/object-detection.svg" alt="" />
                  </span>
                  <h5>Reactive Alerts</h5>
                  <p>
                  Automatically provide reactive alerts to the authorities in real-time once the threat is detected.


                  </p>
                </div>
              </li>

              <li className="text-center">
                <div className="featuresblock ">
                  <span>
                    <img src="images/identity-detection.svg" alt="" />
                  </span>
                  <h5>Multiple Behaviours</h5>
                  <p>
                  Detects multiple behaviour of a suspicious individual to identify various potential threats.


                  </p>
                </div>
              </li>
              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/icon-threat-detection.svg" alt="" />
                  </span>
                  <h5>On-Prem or On-Cloud Deployment</h5>
                  <p>
                  Deployed on secure on-cloud servers and also provide on-site deployment based on server size and number of cameras.


                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="caseList readmorecsases ">
        <div className="container">
          <div className="platformCamera_dis">
            <h2>Read More Cases</h2>
          </div>

          <div className="industriesListblock pt-5">
            <ul>
              {otherCases.map((ele, idx) => (
                <li>
                 <a href="#contact" className="industriesListcol">
                    <div className="solutionListimg">
                      <img src={ele.img} alt="" />
                    </div>
                    <div className="industriesListcoltext">
                      <h4>{ele.title} </h4>
                      <p>{ele.desc}</p>
                      <div className="quicklinks casesquicklinks">
                        {" "}
                        View full case{" "}
                        <img src="images/right-arrow.png" alt="" />{" "}
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Publicsafety;
