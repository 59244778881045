import React, { useState, useEffect } from "react";
import { Box, makeStyles, Button } from "@material-ui/core";
import LinearProgressWithLabel from "@material-ui/core/LinearProgress";
import API from "./useApi";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import ReactPlayer from "react-player";
import * as faceapi from "face-api.js";
import { PinDropSharp } from "@material-ui/icons";

const useStyle = makeStyles({
  uploadSection: {
    border: "2px solid #ffffff",
    height: "440px",
    width: "100%",
    margin: "40px 0px",
    borderRadius: "8px",
  },
  flexCol: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadedFileSection: {  
    minHeight: "440px",
    borderTop: "1px solid #ffffff",
    position: "relative",
  },
  uploadedFileBox: {
    // padding: "40px",
    display: "flex",
    justifyContent: "space-between",
    "& .fileName": {
      fontSize: "20px",
    },
  },
  dialog: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
    // position: "absolute",
    // right: "0px",
    // bottom: "0px",
  },
});

const UploadImageVideo2 = ({ model, uploadAgain, uploadFile }) => {
  const classes = useStyle();
  const [fileList, setFileList] = useState([]);
  const [progress, setProgress] = useState(10);
  const [processed, setProcessed] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const accept = "image/png, image/gif, image/jpeg";

  useEffect(() => {
    async function loadModels() {
      await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
      // handleNotifClose();
      console.log("models loaded");
      setLoading(false);
    }
    loadModels();
  });

  useEffect(() => {
    // setTimeout(() => setMessage(""), 2300)
  }, [message]);

  useEffect(() => {
    setFileList([]);
  }, [model]);

  useEffect(() => {
    if (loading) return;
    if (fileList.length === 0) {
      setProcessed(false);
      setMessage("");
      const inputElement = document.querySelector(".drop__input").files;
      if (inputElement && inputElement.files) {
        inputElement.value = null;
      }
    } else {
      onFileChangeHandler(fileList[0]);
      console.log(document.querySelector(".drop__input").files);
    }
  }, [fileList]);

  useEffect(() => {
    if (progress === 100) setMessage("Wait for reponse!");
    else setMessage("Uploading File!");
  }, [progress]);

  const dropZone = () => {
    const inputElement = document.querySelector(".drop__input");
    const dropZoneElement = inputElement.closest(".drop");

    dropZoneElement.addEventListener("click", (e) => {
      inputElement.click();
      if (inputElement.files.length !== 0) inputElement.value = null;
    });

    inputElement.addEventListener("change", (e) => {
      if (inputElement.files.length) {
        setFileList([inputElement.files[0]]);
        updateThumbnail(dropZoneElement, inputElement.files[0]);
      }
    });

    dropZoneElement.addEventListener("dragover", (e) => {
      e.preventDefault();
      dropZoneElement.classList.add("drop--over");
    });

    ["dragleave", "dragend"].forEach((type) => {
      dropZoneElement.addEventListener(type, (e) => {
        dropZoneElement.classList.remove("drop--over");
      });
    });

    dropZoneElement.addEventListener("drop", (e) => {
      e.preventDefault();

      if (e.dataTransfer.files.length) {
        setFileList([e.dataTransfer.files[0]]);
        updateThumbnail(dropZoneElement, e.dataTransfer.files[0]);
      }

      dropZoneElement.classList.remove("drop--over");
    });
    
    function updateThumbnail(dropZoneElement, file) {
      let thumbnailElement = dropZoneElement.querySelector(".drop__thumb");

      if (dropZoneElement.querySelector(".drop__prompt")) {
        dropZoneElement.querySelector(".drop__prompt").remove();
      }

      if (!thumbnailElement) {
        thumbnailElement = document.createElement("div");
        thumbnailElement.classList.add("drop__thumb");
        dropZoneElement.appendChild(thumbnailElement);
      }

      thumbnailElement.dataset.label = file.name;

      if (file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
          thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
        };
      } else {
        thumbnailElement.style.backgroundImage = null;
      }
    }
  };

  useEffect(() => {
    if (!loading) dropZone();
  }, [loading]);

  async function onFileChangeHandler(file) {
    const image = await faceapi.bufferToImage(file);
    var options = new faceapi.TinyFaceDetectorOptions({ scoreThreshold: 0.4 });
    let predictions = await faceapi.detectAllFaces(image, options);
    // alert(predictions.length);
    if(predictions.length !== 1) {
      uploadAgain("alert")
      alert("Reference Image should contain 1 face only!")
    } else {
      uploadFile(file)
    }
  }

  if (loading) return <>Loading....</>;
  return (
    <>
      <div className="drop">
        {fileList.length === 0 && (
          <AddToPhotosIcon style={{ fontSize: "120px", color: "#ffffff" }} />
        )}
        <span className="drop__prompt" style={{ color: "#ffffff" }}>
          <br />
          Drop a reference image here or click to upload
        </span>
        <input
          type="file"
          name="myFile"
          accept={accept}
          hidden
          className="drop__input"
        />
      </div>
      <Box className={classes.dialog}>
        <button
         
         className="btn btnsuploads uploadnewbtn"
          onClick={() => {
            return uploadAgain("Add an image for reference");
          }}
        >
          New Upload
        </button>
      </Box>
    </>
  );
};

export default UploadImageVideo2;
