import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import Testimonial from "home/sections/Testimonial";
const Platforms = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Platform The modules are deployed at various edge, cloud, and on-premise platforms`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [expanded, setExpanded] = React.useState("");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>Platforms</h1>
              <p>
                The modules are deployed at various edge, cloud, and on-premise
                platforms
              </p>
              <div className="subtages">
                <a href="#/">Single Camera </a> <a href="#/">Rack server</a> <a href="#/">Multiple Server</a> <a href="#/">Self Learning</a>
                <a href="#/">SCPD</a>
              </div>
            </div>
          </div>
        </div>

        <img
          src="../images/platforms-banner.png"
          alt=""
          className="imgdesktop"
        />
        <img
          src="../images/platforms-banner-mobile.png"
          alt=""
          className="imgmobile"
        />
        
      </div>

      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2> Platforms</h2>
            <p>
              The modules are deployed at various edge, cloud, and on-premise
              platforms
            </p>
          </div>
        </div>
      </div>

      <section
        className="homeourproduct poductCatename productpag_section"
        id="ssep"
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block homeright-blockleft">
                <div>
                  <h4>
                    <span>SMEP</span> Single Module Edge Platform

                  </h4>
                  <p>
                  In a single module edge platform, a single analytics solution is applied to a single video stream processed at the edge server and processed locally, displaying results on the dashboard and mobile app.
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="singaldeploymentbox">
                <img src="images/SSEP.png" alt="" />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section lightgray"
        id="mlp"
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="singaldeploymentbox">
                <img src="images/mlp.png" alt="" />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block">
                <h4>
                  <span>GMMP</span>Generic Multi-Module Platform

                </h4>
                <p>
                This module processes multiple streaming camera streams for single generalised modules made up of numerous solutions. These streams are processed on the rack server and the results are displayed on the dashboard.

                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section
        className="homeourproduct poductCatename productpag_section"
        id="industry"
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block homeright-blockleft">
                <div>
                  <h4>
                    <span>ILP</span>Industry-level Platform.

                  </h4>
                  <p>
                  This platform is designed for specific industries and allows the user to process a very large numerous of multiple streams together for various modules. These streams are processed on-premise on multiple rack servers. The results are shown on dashboard monitored by the security guards and weekly reports are sent to the management.


                  </p>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="singaldeploymentbox">
                <img src="images/ilp.png" alt="" />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section lightgray"
        id="mlp"
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="singaldeploymentbox">
                <img src="images/SCPD.png" alt="" />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block">
                <h4>
                  <span>SCPD</span>Self-adapting Client Prem Deployment
                </h4>
                <p>
                  New threats may appear which are not in the pre-defined
                  feature set. The user may not be able to provide us data for
                  it due to security reasons. Our self-adapting can be deployed
                  on client premises and can automatically train once data is
                  introduced to it, allowing the user to self-train and develop
                  new feature set without expert involvement.
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="benefitmain industriesuses white-bg latticeos_faq ">
        <div className="container">
          <div className="benefitssection  ">
            <h2 className="mb-0 text-center">Features</h2>
          </div>

          <div className="listofindust skylarksuite">
            <ul>
              <li className="text-center">
                <div className="featuresblock featuresblockbgthree">
                  <span>
                    <img src="images/object-detection.svg" alt="" />
                  </span>
                  <h5>Economical & Configurable</h5>
                  <p>
                    Our solution uses our software to make every camera
                    intelligent, rather than relying on expensive radars to
                    detect infiltration, frequently giving false alarms because
                    they only detect motion.
                  </p>
                </div>
              </li>

              <li className="text-center">
                <div className="featuresblock ">
                  <span>
                    <img src="images/identity-detection.svg" alt="" />
                  </span>
                  <h5>Multiple Detection Modules</h5>
                  <p>
                    Our platform can distinguish diverse threat signatures using
                    our ETOS, ARIES, MIRAS, and AROS systems. & Our systems
                    assist our customers in strengthening their land, sea, and
                    air defenses.
                  </p>
                </div>
              </li>
              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/icon-threat-detection.svg" alt="" />
                  </span>
                  <h5>Assisted Planning</h5>
                  <p>
                    Our Platform also highlights the areas where alerts are
                    being generated repeatedly. These insights can be used to
                    plan unit assignments or strengthen perimeter
                    infrastructure.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section> 
    </>
  );
};

export default Platforms;
