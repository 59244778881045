import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
 
import Typewriter from "typewriter-effect";
import Typical from 'react-typical'
import videobanner from "./skylark-homepage.mp4"; 

const Banner = () => {
  const [height, setHeight] = useState(window.innerHeight);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 767) {
      setHeight("auto");
    } else {
      setHeight(window.innerHeight);
    }
  }, [height]);

  const animate = (index, count) => {
    // try {
    const texts = [
      
      "",
       

      // " Behavioral Threats in the wild",
      // "  Using Human Motion Analysis",
    ];
    // var count = 0;
    // var index = 0;
    // var decrement = 0;
    // var currentText = "";
    // var letter = "";

    function sleep(delay) {
      return new Promise((resolve) => setTimeout(resolve, delay));
    }

    const typeWrite = async () => {
      if (count === texts.length) {
        count = 0;
      }
      let currentWord = texts[count];
      let currentLetter = currentWord.slice(0, ++index);
      // try {
      const typing = document.querySelector(".typing");
      const mobtyping = document.querySelector(".mobile-typing");
      if (typing) {
        try {
          // typing.forEach((ele) => (ele.textContent = currentLetter));
          typing.textContent = currentLetter
        } catch (e) {}
      } else return;
      if (mobtyping) {
        try {
          // typing.forEach((ele) => (ele.textContent = currentLetter));
          mobtyping.textContent = currentLetter
        } catch (e) {}
      } else return;
      // } catch (e) {}
      if (index === currentWord.length) {
        await sleep(1500);
        while (index > 0) {
          currentLetter = currentWord.slice(0, --index);
          const typing = 
          document
          .querySelector(".typing")
          const mobtyping = document.querySelector(".mobile-typing");

          if (typing) {
            try {
              // typing.forEach((ele) => (ele.textContent = currentLetter));
              typing.textContent = currentLetter
            } catch (e) {}
          } else return;

          if (mobtyping) {
            try {
              // typing.forEach((ele) => (ele.textContent = currentLetter));
              mobtyping.textContent = currentLetter
            } catch (e) {}
          } else return;
            // .forEach((ele) => (ele.textContent = currentLetter));
          await sleep(50);
        }
        count++;
        index = 0;
        await sleep(500);
      }
      setTimeout(typeWrite, Math.random() * 200 + 50);
    };
    typeWrite();
    // } catch (e) {}
  };
  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        animate(0, 0);
      }, 8000)
      setLoading(false);
    }
  }, [loading]);

  // if (loading) return null;
  return (
    <div>
      <section className="mainbanner banner_part bgnone">
        <div
          className="bannerimgblock"
          // style={{
          //   height: { height },
          // }}
        >
          <ReactPlayer
            style={{
              zIndex: "-1",
              pointerEvents: "none",
            }}
            controls={false}
            volume={0}
            width="100%"
            playsinline
            height="100%"
            loop={true}
            muted={true}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            
            
            // url="https://d1nbq27kwttg28.cloudfront.net/blog/New+Banner+(1).mp4"
            url="https://d1nbq27kwttg28.cloudfront.net/blog/homepagevideofull.mp4"
            />

          <div className="bannercontent homebanner">
            <div className="container">
              <div className="contentbox homepage">
                <h1 className="titlehome"> 
               <a href="self-learning-ai"> Self-Learning AI <br></br>To Foresee Emerging Threats</a>
              
                </h1>  
                <div class="intro">
                  <div class="typing"></div>
                
                
                </div> 
               
              </div>
            </div>
          </div>
        </div>

        
      </section>

      <div className="MobileBanner">
        <div className="container">
          <div className="contentbox homepagemobile">
            <h1>  <a href="self-learning-ai">Self-Learning AI <br></br>To Foresee Emerging Threats</a></h1>

            <div class="intro">
              <div class="mobile-typing"></div>
            </div>

          
 
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
