import React, { useEffect } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const Careers = () => {
  const [expanded, setExpanded] = React.useState("panel2"); 
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = "Skylark Labs: Careers";
    return () => {
      console.log("title changed");
    };
  }, []);
  return (
    <>
      <div className="platform_banner aboutouter">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner solutionbenner_dis aboutbanner">
              <h1>Careers</h1>
              <p>
                Contribute your talents to make the world a better and safer
                place.
              </p>
              {/* <a
                  href="#tryourdemo"
                  // href="#trial"
                  // onClick={() => showTrialHandler()}
                  className="btn btntrydemo"
                >
                  Try it now
                </a> */}
            </div>
          </div>
        </div>
        <svg
          className="bottomShape"
          width="100%"
          // height="167"
          viewBox="0 0 1920 167"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0.199997V166.9H1920V0.199997C1616 91.3 1293.7 140.3 960 140.3C626.3 140.3 304 91.3 0 0.199997Z"
            fill="#10101B"
          />
        </svg>
        <img src="../images/updated_banner_images/Career.webp" alt="" />
      </div>

      <section className="aboutpage aboutuone_glass">
        <div className="container">
          <div className="aboutpagetitle">
            <h2>Career</h2>
          </div>
          <div className="aboutdis">
            If you are passionate about innovating new technologies in the world
            of AI. Then come & join with us to learn, innovate & grow your
            career at the same time. We invite you to learn about the many
            exciting career opportunities awaiting you with us.
          </div>
        </div>
      </section>

     
      <section className="careers" id="openpositions">
        <div className="container">
          <div className="aboutpagetitle pagetitlespace">
            <h2>We're currently looking for...</h2>
          </div>
          <div className="accordion_collaps accordion_common careeraccordion">
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6bh-content"
                id="panel6bh-header"
              >
               
                  <h4 className="subtitle">Full-Stack Software Engineer </h4>
                
                
              </AccordionSummary>
              <AccordionDetails>
                <div className="career_dis">
                  <div className="career_sub_dis">
                  We are seeking a highly skilled full-stack software engineer with a strong background in working with video data  to join our team. The ideal candidate will have experience building web-based applications using Django and Flask for the backend and React for the frontend, and will be proficient in Core Python  ( OOPS, Multi-Threading) . The candidate will be able to work closely with cross-functional teams to develop and deploy software solutions that incorporate video data and utilize tools like FFmpeg.
                  </div>
                  <div className="aboutjob ">
                    <strong>
                      <span>Experience:</span> 3+ Years
                    </strong>
                    <strong>
                      <span>Job Type:</span> Full Time
                    </strong>
                    <strong>
                      <span>Location:</span> Pune 
                    </strong>
                  </div>
                  <a
                    href="https://www.linkedin.com/company/skylarklabs/jobs/"
                    target="blank"
                    className="btn btnapply mb-5 mt-5"
                  >
                    Apply now
                  </a>

                  <h4>Responsibilities:</h4>
                  <ul>
                    <li>
                    Design and build web-based applications using Django, Flask, and React that incorporate video data and utilize tools like FFmpeg
                    </li>
                    <li>
                    Write high-quality, maintainable code in Python, including experience with multi-threading
                    </li>
                    <li>
                    Collaborate with cross-functional teams to understand business needs and develop software solutions
                    </li>
                    <li>
                    Stay up to date with the latest developments in full-stack software development and video data integration
                    </li>
                    <li>Contribute to the development of software infrastructure and tools</li>
                  </ul>

                  <h4>Qualifications</h4>
                  <ul>
                    <li>
                    Bachelor's or Master's degree in Computer Science or a related field
                    </li>
                    <li>
                    3+ years experience as a backend web developer and as a frontend single page app web developer
                    </li>

                    <li>
                    Strong understanding of the 3 key areas of web application architecture: server backend, frontend presentation (HTML, CSS) and interactive web (Javascript)
                    </li>
                    <li>
                    Expert level knowledge of Python (OOPS, Multithreading), Django & Flask

                    </li>

                    <li>
                    Expert in Linux (preferred) & Windows environments

                    </li>

                    <li>
                    Expert level knowledge of frontend MVC framework (AngularJS or ReactJS - preferred)

                    </li>
                    <li>
                    Expert in HTTP protocol, REST APIs, JSON, HTML, CSS</li>
                    <li>
Expert in setting up web servers (nginx), app server (gunicorn or uwsgi), load balancing</li>
<li>
Expert in caching techniques for application data & static files</li>
<li>
Expert in database modelling with hands-on experience with Celery and Redis in a production environment</li>
<li>
Hands-on experience with Postgres, MySQL in a production environment</li>
<li>
Hands-on experience with websockets on both frontend and backend
</li>
<li>
Hands-on experience with Public Cloud services such as AWS (EC2, S3, RDS, ECS)</li>
<li>
Experience in DevOps software development/deployment practices (including CI and CD), source control and pipelines as code.
</li>
                  </ul>

                  <h4>Nice To Have
</h4>
<ul>
                    <li>
                    Knowledge of FFmpeg, or other image processing libraries

                    </li>
                    <li>
                    Knowledge of video standards H.264, MPEG2 and HEVC
                    </li>
                    <li>
                    Interest in Deep learning and AI Tech.
                    </li>
                    
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel7bh-content"
                id="panel7bh-header"
              >
                <h4 className="subtitle">Machine Learning Scientist (Various Sensor Data Modeling, Threat Prediction)</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="career_dis">
                  <div className="career_sub_dis">
                  We are seeking a highly skilled machine learning scientist with experience in various sensor data modeling, including video, radars, and satellite data, and a focus on threat prediction to join our team. The ideal candidate will have a strong background in machine learning and data science, and will be able to design and implement machine learning models and algorithms for analyzing sensor data and predicting potential threats.
                  </div>
                  <div className="aboutjob ">
                    <strong>
                      <span>Experience:</span> 5+ Years
                    </strong>
                    <strong>
                      <span>Job Type:</span> Full Time
                    </strong>
                    <strong>
                      <span>Location:</span> Pune 
                    </strong>
                  </div>
                  <a href="https://www.linkedin.com/company/skylarklabs/jobs/" target="blank" className="btn btnapply">
                    Apply now
                  </a>

                  


                  <h4>Responsibilities:</h4>

                  <ul>
                    <li>
                    Design and implement machine learning models and algorithms for analyzing various sensor data, including video, radar, and satellite data, and predicting potential threats
                    </li>
                    <li>
                    Collaborate with cross-functional teams to understand business needs and develop machine learning solutions
                    </li>
                    <li>
                    Analyze and interpret large and complex datasets, including sensor data
                    </li>
                    <li>
                    Stay up to date with the latest developments in machine learning, sensor data modeling, and threat prediction
                    </li>
                    <li>
                    Contribute to the development of machine learning infrastructure and tools
                    </li>
                  </ul>

                  <h4>Requirements</h4>
                  <ul>
                    <li>
                    Ph.D. in Computer Science, Machine Learning, Data Science, or a related field

                    </li>
                    <li>
                    5+ years of experience as a machine learning scientist, with a strong focus on various sensor data modeling and threat prediction

                    </li>
                    <li>
                    Strong coding skills, including proficiency in Python and at least one machine learning framework (e.g. TensorFlow, PyTorch)

                    </li>
                    <li>
                    Experience with data preprocessing, feature engineering, and model selection

                    </li>
                    <li>
                    Experience with analyzing and interpreting large and complex datasets, including sensor data


                    </li>
                    <li>
                    Ability to work independently and as part of a team



                    </li>
                  </ul>
                  <h4>Preferred Skills:</h4>

                  <ul>
                    <li>
                    Experience with video analysis and computer vision techniques


                    </li>
                    <li>
                    Experience with radar and satellite data modeling

                    </li>
                    <li>
                    Experience with deep learning models and techniques

                    </li>
                    <li>
                    Experience with cloud-based machine learning platforms (e.g. AWS, GCP)


                    </li>
                    <li>
                    Experience with agile development methodologies


                    </li>
                    <li>
                    Strong communication and collaboration skills





                    </li>
                  </ul>

                  <h4>About SkyLark Labs</h4>
                  <p>SkyLark Labs develops Artificial intelligence for real-time image and video analytics to assess, alert, and avert. We are solving the world's most crucial safety and security challenges ranging from identifying violence, detecting weapons, recognising offenders, etc. Skylark has over 50 full-time and part-time employees located in our three locations: Data Acquisition Lab in Amritsar, Data Modelling Lab in Hyderabad, India, and Production Lab based in Palo Alto, California, USA.</p>
                
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel8bh-content"
                id="panel8bh-header"
              >
                <h4 className="subtitle">Machine Learning Engineer (Video Analysis)</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="career_dis">
                  <div className="career_sub_dis">
                  We are seeking a highly skilled machine learning engineer with a strong background in video surveillance systems and a focus on threat prediction to join our team. The ideal candidate will have experience designing and implementing machine learning models and algorithms for analyzing video data and predicting potential threats, and will be able to work closely with cross-functional teams to develop and deploy machine learning solutions in the context of video surveillance systems.
                  </div>

                  <div className="aboutjob ">
                    <strong>
                      <span>Experience:</span> 2+ Years
                    </strong>
                    <strong>
                      <span>Job Type:</span> Full Time
                    </strong>
                    <strong>
                      <span>Location:</span>Pune
                    </strong>
                  </div>
                  <a href="https://www.linkedin.com/company/skylarklabs/jobs/" target="blank" className="btn btnapply">
                    Apply now
                  </a>
                  

                  <ul>
                    <li>
                    Design, implement, and maintain machine learning models and algorithms for analyzing video data and predicting potential threats in the context of video surveillance systems

                    </li>
                    <li>
                    Collaborate with cross-functional teams to understand business needs and develop machine learning solutions

                    </li>
                    <li>
                    Analyze and interpret large and complex datasets, including video data

                    </li>

                    <li>
                    Stay up to date with the latest developments in machine learning, video surveillance systems, and threat prediction


                    </li>
                    <li>
                    Contribute to the development of machine learning infrastructure and tools


                    </li>
                  </ul>

                  <h4>Requirements</h4>
                  <ul>
                    <li>
                    Bachelor's or Master's degree in Computer Science, Machine Learning, or a related field

                    </li>
                    <li>
                    2+ years of experience as a machine learning engineer, with a strong focus on video surveillance systems and threat prediction

                    </li>

                    <li>
                    Strong coding skills, including proficiency in Python and at least one machine learning framework (e.g. TensorFlow, PyTorch)

                    </li>

                    <li>
                    Experience with data preprocessing, feature engineering, and model selection

                    </li>

                    <li>
                    Experience with analyzing and interpreting large and complex datasets, including video data

                    </li>
                    <li>
                    Ability to work independently and as part of a team

                    </li>
                  </ul>
                  
                  <h4>Preferred Skills:</h4>

                  <ul>
                    <li>
                    Experience with video analysis and computer vision techniques

                    </li>
                    <li>
                    Experience with deep learning models and techniques

                    </li>

                    <li>
                    Experience with cloud-based machine learning platforms (e.g. AWS, GCP)

                    </li>

                    <li>
                    Experience with agile development methodologies

                    </li>

                    <li>
                    Strong communication and collaboration skills


                    </li>
                  
                  </ul>
                  <h4>About SkyLark Labs</h4>
                  <p>SkyLark Labs develops Artificial intelligence for real-time image and video analytics to assess, alert, and avert. We are solving the world's most crucial safety and security challenges ranging from identifying violence, detecting weapons, recognising offenders, etc. Skylark has over 50 full-time and part-time employees located in our three locations: Data Acquisition Lab in Amritsar, Data Modelling Lab in Hyderabad, India, and Production Lab based in Palo Alto, California, USA.</p>
                
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel9bh-content"
                id="panel9bh-header"
              >
                <h4 className="subtitle">Content Writer (National Security and Artificial Intelligence)</h4>
              </AccordionSummary>
              <AccordionDetails>
                <div className="career_dis">
                  <div className="career_sub_dis">
                  We are seeking a skilled content writer with a strong background in national security and artificial intelligence to join our team. The ideal candidate will have excellent writing skills and be able to produce high-quality content that is both informative and engaging.

                  </div>
                  <div className="aboutjob ">
                    <strong>
                      <span>Experience:</span> 3+ Years
                    </strong>
                    <strong>
                      <span>Job Type:</span> Full Time
                    </strong>
                    <strong>
                      <span>Location:</span>Pune
                    </strong>
                  </div>
                  <a href="https://www.linkedin.com/company/skylarklabs/jobs/" target="blank" className="btn btnapply">
                    Apply now
                  </a>
                  
                  <ul>
                    <li>
                    Write and edit content for a variety of mediums, including blog posts, articles, white papers, and web pages

                    </li>
                    <li>
                    Research and analyze current events, trends, and issues related to national security and artificial intelligence

                    </li>
                    <li>
                    Collaborate with other team members to develop content ideas and strategies

                    </li>
                    <li>Ensure that all content is accurate, well-written, and adheres to company standards</li>
                    <li>Stay up to date with the latest developments in the fields of national security and artificial intelligence</li>
                  </ul>

                  <h4>Requirements</h4>
                  <ul>
                    <li>
                    Bachelor's degree in a related field, such as journalism, political science, or computer science

                    </li>
                    <li>
                    3+ years of experience writing and editing content in the fields of national security and artificial intelligence
                    </li>
                    <li>
                    Strong research skills and the ability to analyze and synthesize complex information

                    </li>
                    <li>
                    Excellent writing and editing skills, with a focus on clarity and concision

                    </li>
                    <li>
                    Ability to work independently and meet deadlines

                    </li>
                    <li>Proficiency in Microsoft Office and Google Suite</li>
                  </ul>
                  <h4>Preferred Skills:
</h4>
<ul>
  <li>Experience writing for a range of audiences, including general readers, policymakers, and academics
</li>
<li>Familiarity with SEO principles and best practices

</li>
<li>Experience with content management systems (CMS)

</li>
<li>Ability to work as part of a team and collaborate with other writers, editors, and designers



</li>
</ul>
                  <h4>About SkyLark Labs</h4>
                  <p>SkyLark Labs develops Artificial intelligence for real-time image and video analytics to assess, alert, and avert. We are solving the world's most crucial safety and security challenges ranging from identifying violence, detecting weapons, recognising offenders, etc. Skylark has over 50 full-time and part-time employees located in our three locations: Data Acquisition Lab in Amritsar, Data Modelling Lab in Hyderabad, India, and Production Lab based in Palo Alto, California, USA.</p>
                
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </section>
    </>
  );
};

export default Careers;
