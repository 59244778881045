import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import MediaCoverage from "home/sections/MediaCoverage";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppBar from "@material-ui/core/AppBar";

const Data = () => {
  const [expanded, setExpanded] = React.useState("panel2");
  const navOption1 = React.useRef(null);
  const navOption2 = React.useRef(null);
  const navOption3 = React.useRef(null);
  const navOption4 = React.useRef(null);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = "Skylark Labs: Data Security"
    return () => {
      console.log('title changed');
    }
  }, []);
  return (
    <>


      <div className="platform_banner aboutouter">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner solutionbenner_dis aboutbanner">
              <h1>Data Security</h1>
              <p>
                AI technology that provides high tech interface which assures your data privacy & helps to keep your information safe & secure.
              </p>
              {/* <a
                  href="#tryourdemo"
                  // href="#trial"
                  // onClick={() => showTrialHandler()}
                  className="btn btntrydemo"
                >
                  Try it now
                </a> */}

            </div>
          </div>
        </div>
        <svg
          className="bottomShape"
          width="100%"
          // height="167"
          viewBox="0 0 1920 167"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0.199997V166.9H1920V0.199997C1616 91.3 1293.7 140.3 960 140.3C626.3 140.3 304 91.3 0 0.199997Z"
            fill="#10101B"
          />
        </svg>
        <img src="../images/banner/data_security.webp" alt="" />
      </div>


      <section className="aboutpage aboutuone_glass">
        <div className="container">
          <div className="aboutpagetitle">
            <h2>Security at Skylark Labs</h2>
          </div>
          <div className="aboutdis" style={{
            fontSize: '1.3rem'
          }}>
            Skylark Labs highly values our customer’s security. We take the
            security of our customer’s data seriously and believe in maintaining
            a trustworthy relationship with them. Our dedication and commitment
            towards providing grade-security to the enterprises is the reason
            that our solutions are used by the industry leaders. Our solutions
            are highly standardized, tested, audited by our external partners to
            provide high-level security to our customers and enterprises.
          </div>
        </div>
      </section>
      {/* <section
        className="aboutteam aboututow_glass detasecourity"
        id="overview"
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className="aboutpagetitle">
                <h3>
                  {" "}
                  Here are the reasons how skylark labs provide robust security
                  to our customers.
                </h3>
              </div>
              <ul>
                <li>
                  {" "}
                  <div className="textdeta">
                    Using advanced level security technology to protect
                    confidential data.
                  </div>
                </li>
                <li>
                  {" "}
                  <div className="textdeta">
                    Partnering with external security partners to get a detailed
                    analysis on the skylark’s security
                  </div>
                </li>
                <li>
                  {" "}
                  <div className="textdeta">
                    Meeting with the industry security standards and guidelines.
                  </div>
                </li>
              </ul>
            </Grid>

            <Grid item xs={12}>
              <div className="aboutpagetitle">
                <h3>Questions about security?</h3>
              </div>
              <p>
                If you are thinking to use our solutions but have any questions
                related to security, please try to reach us at{" "}
                <a href="/#"> abc@skylarklabs.com.</a> We’ll be happy to help
                you.
              </p>
            </Grid>
          </Grid>
        </div>
      </section> */}
    </>
  );
};

export default Data;
