import React, { useRef, useState, useEffect } from "react";
import { Box, makeStyles, Typography, Grid } from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UploadImageVideo from "./upload";
import useModels from "./models";
import RecogUploadImageVideo from "./recogUpload";
import Sample from "./Samples";

const useStyle = makeStyles({
  containerUpload: {
    // backgroundColor: "#121212",
    minHeight: "100vh",
    // paddingTop: '160px',
    // color: "#ffffff",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // padding: "40px",
  },
  uploadBox: {
    width: "100%",
  },
  uploadSec: {
    border: "4px dashed #ffffff",
  },
});

// const UploadUrl = ({ model }) => {};

export default function MainComponent(props) {
  const classes = useStyle();
  const models = useModels();
  const [click, setClick] = React.useState(null);

  const [load, setLoad] = useState(true);

  useEffect(() => {
    console.log(click);
  }, [click]);

  const loadHandler = () => {
    setLoad(false);
    setClick(null);
    const dropdown = document.querySelector(".dropdown-content");
    if (dropdown) dropdown.style.display = "none";
    setTimeout(() => {
      setLoad(true);
      // dropdown.style.display = 'block'
    }, 500);
  };

  const [model, setModel] = useState(models[props.models[0]]);
  useEffect(() => {
    setModel(models[props.models[0]]);
  }, [props]);

  return (
    <>
      <div class="platformCamera_dis">
        <h2>Try our demo</h2>
        {/* <p className="pt-0 mt-0 mb-3">
          Explore our demos by using our provided sample inputs or upload your
          own input to check the final results.
        </p> */}
      </div>
      <Box className={[classes.containerUpload, classes.flexCol, "trial"]}>
        {props.models.length > 1 && (
          <div
            className="dropdown"
            onMouseEnter={() =>
              (document.querySelector(".dropdown-content").style.display =
                "block")
            }
            onMouseLeave={() =>
              (document.querySelector(".dropdown-content").style.display =
                "none")
            }
          >
            <button className="dropbtn">
              {model.title}
              <ExpandMoreIcon />
            </button>
            <div className="dropdown-content">
              {props.models &&
                Object.entries(models)
                  .filter(([key, value]) => value.title !== model.title)
                  .filter(([key, val]) => props.models.includes(key))
                  .map(([key, value]) => (
                    <a
                      href="javascript:void(0)"
                      key={key}
                      onClick={() => {
                        setModel(value);
                        loadHandler();
                      }}
                    >
                      {value.title}
                    </a>
                  ))}
            </div>
          </div>
        )}
        {load ? (
          <Box pt={5} className={[classes.uploadBox, classes.flexCol]}>
            <Grid container spacing={3} justify="center" alignItems="center">
            <Grid item md={6}>
              <p className="m-0 pb-2">Process our Sample Image/Video</p>
                {/* {model.sample && model.sample.map((ele, idx) => ( */}
                {model.sample && (
                  <Sample
                    // idx={idx}
                    // src={ele}
                    setClick={setClick}
                    model={model}
                  />
                )}
                {/* ))} */}
              </Grid>
              <Grid item md={6}>
              <p className="m-0 pb-2">Upload Your Own</p>
                {model.recog ? (
                  <RecogUploadImageVideo
                    model={model}
                    uploadAgain={loadHandler}
                  />
                ) : (
                  <UploadImageVideo
                    setClick={setClick}
                    click={click}
                    model={model}
                    uploadAgain={loadHandler}
                  />
                )}
              </Grid>
      
            </Grid>
          </Box>
        ) : (
          "Loading..."
        )}
      </Box>
    </>
  );
}
