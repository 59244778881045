import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import videobanner from "../../videofiles/border-protection-banner.mp4";
import aros_intrusion from "../../videofiles/aros-intrusion.mp4";
import aros_lurking from "../../videofiles/aros-lurking.mp4";
import aros_crawling from "../../videofiles/crawling_detection.mp4";
import perimeter_monitoring from "../../videofiles/suspicious-vehicles.mp4";
import Camouflageddetection from "../../videofiles/camouflaged-detection.mp4";
import Dronedetection from "../../videofiles/home-conter_usa.mp4";
import Vesseldetection from "../../videofiles/miras-monitoring-water-borders.mp4";
import VehicletrackingBorder from "../../videofiles/vehicle_tracking.mp4";
import militaryobject from "../../videofiles/military-object.mp4";
import Drones_detection from "../../videofiles/drone-detection.mp4";
import fisherman from "../../videofiles/fisherman.mp4";

import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
const Borderprotection = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Border Protection`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [showTrial, setShowTrial] = React.useState(false);
  const [expanded, setExpanded] = React.useState("panel2");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };
  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/case-dronestream.png",
    },

    {
      title: "D.S. Jeweller",
      desc: "The incidents happened in the locality where robbers used to come at night and loot the jewellery shops.",
      url: "ds-jewellers",
      img: "images/cases-jewellers.png",
    },
    {
      title: "Dronestream",
      desc: "Unauthorized people tried to enter construction areas with weapons which can be dangerous.",
      url: "dronestream",
      img: "images/case-d1-security.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      // if (section4.current && offsetY >= section4.current.offsetTop)
      //   addActiveClass(navOption4);
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChangenew = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeone = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangethree = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>Border Protection</h1>
              <p>
                Advanced AI solutions that enhance national security, protects
                civilians from threats and other external dangers
              </p>
              <div className="subtages">
                <a href="#/">Mountains</a> <a href="#/">Coastline </a>{" "}
                <a href="#/">Fences </a>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="imgcenter bannerimgblock ">
          <ReactPlayer
            controls={true}
            playsinline
            volume={0}
            width="100%"
            height="100%"
            loop={true}
            muted={false}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url={videobanner}
          />
        </div>
      </div>

      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>Border Protection</h2>
            <p>
              Advanced AI solutions that enhance national security, protects
              civilians from threats and other external dangers
            </p>
          </div>
        </div>
      </div>

      <div className="plateformsub-page">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>
        <div className="container pt-5">
          <div className="plateformabout  mt-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className="aboutimgblock">
                  {" "}
                  <img src="images/border-protection.png" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 pb-0">
                      <span className="spanfirstword">Enhanced </span> Border
                      Security Technology
                    </h2>
                    <p className="pt-0 abouttext-pblock">
                      National risks are growing on a daily basis, and the
                      majority of illicit operations occur at all hours of the
                      day and in distant regions. We require a system that
                      detects suspicious activities and sends real-time alerts.
                      And, in order to address these issues, we propose our
                      solutions for detecting unauthorized people, cars,
                      violence, or weapons. They operate in an unconstrained
                      environment and integrate nicely with drones and CCTV
                      systems.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container">
            <div className="seactionblocktitles">
              <h2 className="seactiontitles">
                {" "}
                Land to Land Systems: Strengthen Border Lands
              </h2>
              <p>
                Land Systems are a class of autonomous modules powered by Neural
                Core AI that use ground-based imaging sensors to provide
                tactical awareness for military missions.
              </p>
            </div>
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Crawling </span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Crawling</span> Detection
                    </h4>
                    <p>
                      The system detects and Identifies a crawling persons to
                      predict emerging threats
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Loitering
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Lurking
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="videoblock-plateforms">
                  <ReactPlayer
                    controls={true}
                    playsinline
                    volume={0}
                    width="100%"
                    height="100%"
                    loop={true}
                    muted={false}
                    playing={true}
                    stopOnUnmount={true}
                    config={{
                      file: {
                        attributes: {
                          autoplay: true,
                          controls: false,
                          loop: true,
                          muted: true,
                        },
                      },
                    }}
                    url={aros_crawling}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="videoblock-plateforms">
                  <ReactPlayer
                    controls={true}
                    playsinline
                    volume={0}
                    width="100%"
                    height="100%"
                    loop={true}
                    muted={false}
                    playing={true}
                    stopOnUnmount={true}
                    config={{
                      file: {
                        attributes: {
                          autoplay: true,
                          controls: false,
                          loop: true,
                          muted: true,
                        },
                      },
                    }}
                    url={aros_lurking}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <div className="flatformtages">
                      <span>Fence </span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Fence</span> Jumping
                    </h4>
                    <p>
                      The system detects people jumping over the fence in a
                      restricted area in thermal and optical modularity to
                      predict emerging critical threats
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Abandoned car
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Abandoned Bags
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Crawling</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Intrusion</span> Detection
                    </h4>
                    <p>
                      The system detects suspicious persons/individuals moving
                      in a restricted area in thermal and optical modularity to
                      predict emerging critical threats
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Loitering
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Lurking
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="videoblock-plateforms">
                  <ReactPlayer
                    controls={true}
                    playsinline
                    volume={0}
                    width="100%"
                    height="100%"
                    loop={true}
                    muted={false}
                    playing={true}
                    stopOnUnmount={true}
                    config={{
                      file: {
                        attributes: {
                          autoplay: true,
                          controls: false,
                          loop: true,
                          muted: true,
                        },
                      },
                    }}
                    url={aros_intrusion}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container">
            <div className="seactionblocktitles">
              <h2 className="seactiontitles"> Loitering Detection</h2>
              <p>
                The system detects the suspicious person who stand or walk
                around somewhere for no obvious reason.
              </p>
            </div>
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/ezgif.com-gif-maker.gif" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <div className="flatformtages">
                      <span>Fence </span>
                    </div>
                    <h4 className="pb-4">
                      {" "}
                      <span className="spanfirstword">
                        Loitering
                      </span> Detection{" "}
                    </h4>
                    <p>
                      {" "}
                      The system detects the suspicious person who stand or walk
                      around somewhere for no obvious reason.{" "}
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Suspicious Person Detection
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Multiple Person Detection
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

      

          
        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container">
            <div className="seactionblocktitles">
              <h2 className="seactiontitles"> Land to Sea Systems: Secure Coastlines</h2>
              <p>
                The system detects the suspicious person who stand or walk
                around somewhere for no obvious reason.
              </p>
            </div>
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Crawling</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Fisherman</span>  Boat Detection
                    </h4>
                    <p>
                    The system detects and identifies the fishermn boats in
                        the sea.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Fisherman boat Detection
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Suspicious Fisherman Detection
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="videoblock-plateforms">
                  <ReactPlayer
                    controls={true}
                    playsinline
                    volume={0}
                    width="100%"
                    height="100%"
                    loop={true}
                    muted={false}
                    playing={true}
                    stopOnUnmount={true}
                    config={{
                      file: {
                        attributes: {
                          autoplay: true,
                          controls: false,
                          loop: true,
                          muted: true,
                        },
                      },
                    }}
                    url={fisherman}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="videoblock-plateforms">
                  <ReactPlayer
                    controls={true}
                    volume={0}
                    playsinline
                    width="100%"
                    height="100%"
                    loop={true}
                    muted={false}
                    playing={true}
                    stopOnUnmount={true}
                    config={{
                      file: {
                        attributes: {
                          autoplay: true,
                          controls: false,
                          loop: true,
                          muted: true,
                        },
                      },
                    }}
                    url={Vesseldetection}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <div className="flatformtages">
                      <span>Fence </span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Unknown</span>   Vessel Detection
                    </h4>
                    <p>
                    This system monitors and identifies unknown vessels and
                        their movements in optical and thermal modularity to
                        detect suspicious critical threats
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Unknown Vessel Detection 
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Unknow Person Detection
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

      </div>
 
      <section className="homeprodctlist latticeos_tabs whitebg-block">
        <div className="container homeprodctlisttop landsystemtitle">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={7}>
              <h4>Land to Air Systems: Protect Airspace</h4>
            </Grid>
          </Grid>
        </div>
        <div className="container">
          <Box sx={{ width: "100%" }}>
            <div className="poductlisttabs poductlisttabs-two  latticetabs">
              <Box sx={{ borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChangethree}
                  aria-label="basic tabs example"
                >
                  <Tab label="Drone Detection" {...a11yProps(0)} />
                  <Tab label="Military Air Objects" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </div>

            <TabPanel value={value} index={0}>
              <div className="homeourproduct productpag_section bg_transparent  pb-0">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="videoleftblock">
                      <ReactPlayer
                        controls={true}
                        volume={0}
                        playsinline
                        width="100%"
                        height="100%"
                        loop={true}
                        muted={false}
                        playing={true}
                        stopOnUnmount={true}
                        config={{
                          file: {
                            attributes: {
                              autoplay: true,
                              controls: false,
                              loop: true,
                              muted: true,
                            },
                          },
                        }}
                        url={Drones_detection}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="homeright-block">
                      <h4>Drone Detection</h4>
                      <p>
                        The system detects suspicious small and large drones
                        moving in a restricted area and send immediate alerts
                      </p>

                      <ul className="mb-4">
                        <li>
                          <div className="boxinner">
                            <span>
                              <img
                                src="images/nav/industries/icon-abandoned-car.svg"
                                alt=""
                              />
                            </span>
                            Suspicious <br></br>Drone Detection
                          </div>
                        </li>
                        <li>
                          <div className="boxinner">
                            <span>
                              <img
                                src="images/nav/industries/icon-abandoned-object.svg"
                                alt=""
                              />
                            </span>
                            Large <br></br>Drone Detection
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="homeourproduct productpag_section bg_transparent pb-0">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="homeright-block homeright-blockleft ">
                      <h4>Military Air Objects</h4>
                      <p>
                        The system detects Military objects such as drones,
                        helicopter, jets, etc and send immediate alerts to the
                        authorities to prevent the critical threats
                      </p>

                      <ul className="mb-4">
                        <li>
                          <div className="boxinner">
                            <span>
                              <img
                                src="images/nav/industries/icon-missing-security-guard.svg"
                                alt=""
                              />
                            </span>
                            Drone Detection
                          </div>
                        </li>
                        <li>
                          <div className="boxinner">
                            <span>
                              <img
                                src="images/nav/industries/icon-unknow-person-detection.svg"
                                alt=""
                              />
                            </span>
                            Helicopter Detection
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="videoleftblock">
                      <ReactPlayer
                        controls={true}
                        volume={0}
                        playsinline
                        width="100%"
                        height="100%"
                        loop={true}
                        muted={false}
                        playing={true}
                        stopOnUnmount={true}
                        config={{
                          file: {
                            attributes: {
                              autoplay: true,
                              controls: false,
                              loop: true,
                              muted: true,
                            },
                          },
                        }}
                        url={militaryobject}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
          </Box>
        </div>
      </section>

      

      <section className="homeprodctlist blackrarkoutr latticeos_tabs">
        <div className="container homeprodctlisttop landsystemtitle">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={7}>
              <h4>Aerial Systems</h4>
            </Grid>
          </Grid>
        </div>
        <div className="container">
          <Box sx={{ width: "100%" }}>
            <div className="poductlisttabs poductlisttabs-two  latticetabs">
              <Box sx={{ borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChangenew}
                  aria-label="basic tabs example"
                >
                  <Tab label="Vehicle Tracking" {...a11yProps(0)} />
                  <Tab label="Camouflaged Detection" {...a11yProps(1)} />
                </Tabs>
              </Box>
            </div>

            <TabPanel value={value} index={0}>
              <div className="homeourproduct productpag_section bg_transparent  pb-0">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="videoleftblock">
                      <ReactPlayer
                        controls={true}
                        volume={0}
                        playsinline
                        width="100%"
                        height="100%"
                        loop={true}
                        muted={false}
                        playing={true}
                        stopOnUnmount={true}
                        config={{
                          file: {
                            attributes: {
                              autoplay: true,
                              controls: false,
                              loop: true,
                              muted: true,
                            },
                          },
                        }}
                        url={VehicletrackingBorder}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="homeright-block">
                      <h4>Vehicle Tracking</h4>
                      <p>
                        The system detects the moving vehicles on the road and
                        tracks them.
                      </p>

                      <ul className="mb-4">
                        <li>
                          <div className="boxinner">
                            <span>
                              <img
                                src="images/nav/industries/icon-abandoned-car.svg"
                                alt=""
                              />
                            </span>
                            Vehicle<br></br> Detection Number
                          </div>
                        </li>
                        <li>
                          <div className="boxinner">
                            <span>
                              <img
                                src="images/nav/industries/icon-abandoned-object.svg"
                                alt=""
                              />
                            </span>
                            Plate Detection
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="homeourproduct productpag_section bg_transparent pb-0">
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="homeright-block homeright-blockleft ">
                      <h4>Camouflaged Detection</h4>
                      <p>Camouflaged Person Detection Camouflaged Vehicles</p>

                      <ul className="mb-4">
                        <li>
                          <div className="boxinner">
                            <span>
                              <img
                                src="images/nav/industries/icon-missing-security-guard.svg"
                                alt=""
                              />
                            </span>
                            Missing Security Guard
                          </div>
                        </li>
                        <li>
                          <div className="boxinner">
                            <span>
                              <img
                                src="images/nav/industries/icon-unknow-person-detection.svg"
                                alt=""
                              />
                            </span>
                            Unknow Person Detection
                          </div>
                        </li>
                      </ul>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <div className="videoleftblock">
                      <ReactPlayer
                        controls={true}
                        volume={0}
                        playsinline
                        width="100%"
                        height="100%"
                        loop={true}
                        muted={false}
                        playing={true}
                        stopOnUnmount={true}
                        config={{
                          file: {
                            attributes: {
                              autoplay: true,
                              controls: false,
                              loop: true,
                              muted: true,
                            },
                          },
                        }}
                        url={Camouflageddetection}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </TabPanel>
          </Box>
        </div>
      </section>

      <section
        className="benefitmain industriesuses bggray latticeos_faq white-bg"
        id="admincontrol"
      >
        <div className="container">
          <div className="benefitssection  ">
            <h2 className="mb-0 text-center">Value Created</h2>
          </div>

          <div className="listofindust skylarksuite">
            <ul>
              <li className="text-center">
                <div className="featuresblock featuresblockbgthree">
                  <span>
                    <img src="images/object-detection.svg" alt="" />
                  </span>
                  <h5>Economical & Configurable</h5>
                  <p>
                    Our solution uses our software to make every camera
                    intelligent rather than relying on expensive radars to
                    detect infiltration, which frequently gives false alarms
                    because they only detect motion.
                  </p>
                </div>
              </li>

              <li className="text-center">
                <div className="featuresblock ">
                  <span>
                    <img src="images/identity-detection.svg" alt="" />
                  </span>
                  <h5>Stress Free Environment</h5>
                  <p>
                    Getting only relevant alerts creates a stress-free
                    environment for security officers as they don’t have to
                    manually monitor the video wall regularly and can only turn
                    their attention to it when there is an audio-visual alert.
                  </p>
                </div>
              </li>
              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/icon-threat-detection.svg" alt="" />
                  </span>
                  <h5>Assisted Border Planning</h5>
                  <p>
                    Our Platform also highlights the areas where alerts are
                    being generated repeatedly. These insights can be used to
                    plan troop assignments or strengthen perimeter
                    infrastructure.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="caseList readmorecsases bggraytr">
        <div className="container">
          <div className="platformCamera_dis">
            <h2>Read More Cases</h2>
          </div>

          <div className="industriesListblock pt-5">
            <ul>
              {otherCases.map((ele, idx) => (
                <li>
                  <a href="#contact" className="industriesListcol">
                    <div className="solutionListimg">
                      <img src={ele.img} alt="" />
                    </div>
                    <div className="industriesListcoltext">
                      <h4>{ele.title} </h4>
                      <p>{ele.desc}</p>
                      <div className="quicklinks casesquicklinks">
                        {" "}
                        View full case{" "}
                        <img src="images/right-arrow.png" alt="" />{" "}
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Borderprotection;
