import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
const Footermenu = () => {
  return (
    <footer>
      {/* <section className="already_customer_section">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <div className="already_customer_dis">
                <h3>Already a customer?</h3>
                <p>
                  If you're encountering a technical or payment issue, the
                  customer support team will be happy to assist you.{" "}
                  <a href="#contact"> Contact support</a>
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </section> */}


      <div className="footerNav">

        <div className="container" >


          <Grid container spacing={3}>

            <Grid item xs={6} sm={4} md={2} lg={2} >
              <h6> <Link to="/self-learning-ai">Self-learning AI</Link></h6>
            </Grid>

            <Grid item xs={6} sm={4} md={2} lg={2} >

              <h6>Platforms</h6>
              <ul>
                <li>
                  <Link to="/kepler">Kepler</Link>

                </li>

                <li>
                  <Link to="/turing">Turing</Link>

                </li>


              </ul>
            </Grid>

            <Grid item xs={6} sm={4} md={2} lg={2} >
              <h6>Defense - <br></br>Government</h6>
              <ul>
                <li>
                  <Link to="/indian-navy">Indian Navy</Link>{" "}
                </li>
                <li>
                  <Link to="/indian-army">Indian Army</Link>
                </li>
                <li>
                  <Link to="/indian-airforce">Indian Airforce</Link>{" "}
                </li>
              </ul>
            </Grid>

            <Grid item xs={6} sm={4} md={2} lg={2} >
              <h6>Defense - <br></br>Industry </h6>
              <ul>
                <li>
                  <Link to="/ideaforge">Ideaforge</Link>
                </li>
                <li>
                  <Link to="/mantech">ManTech</Link>
                </li>
                <li>
                  <Link to="/collins-aerospace">Collins Aerospace</Link>
                </li>

              </ul>
            </Grid>

            {/* <Grid item xs={6} sm={4} md={2} lg={2}>
              <h6>COMMERCIAL</h6>
              <ul>
                <li>
                  <Link to="/mercedes">Mercedes </Link>{" "}
                </li>
                <li>
                  <Link to="/jones-lang-laSalle">JLL (Jones Lang LaSalle)</Link>{" "}
                </li>
                <li>
                  <Link to="/artrabia">Artrabia </Link>{" "}
                </li>

              </ul>
            </Grid> */}

            <Grid item xs={6} sm={4} md={2} lg={2}>
              <h6>Company</h6>
              <ul>

                <li>
                  <Link to="/about-us"> About Us</Link>{" "}
                </li>

                <li>
                  <Link to="/press">Press</Link>{" "}
                </li>
                <li>
                  <Link to="/careers">Careers</Link>{" "}
                </li>

                <li>
                  <Link to="/blogs">Blogs</Link>{" "}
                </li>



              </ul>
            </Grid>

            <Grid item xs={6} sm={4} md={2} lg={2}>
              <h6>Contact Us</h6>
              <ul>
                <li>
                  New York, USA
                </li>
                <li>
                  Sydney, Australia
                </li>
                <li>
                  Pune, India
                </li>

              </ul>
              <div className="contactusfoot pb-4 mb-5">
                {/* <a href="tel:+14156093633"> +1-415-6093633 </a> <br></br> */}
                <a href="mailto:info@skylarklabs.ai">info@skylarklabs.ai</a>

              </div>
            </Grid>

          </Grid>
        </div>
        {/* <section className="addressfooter" id="addressfooter">
          <div className="container pb-4" style={{
            marginBottom: "20px",
          }}>
            <h6>Contact Info </h6>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={3} md={3} lg={3}>
                <div className="contactusfoot">
                  <span>USA</span>
                  <p>2 Park Avenue, Park Avenue South, NYC, USA 10003</p>

                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3} lg={3}>
                <div className="contactusfoot">

                  <span>Australia</span>
                  <p>Level 14, 275 Alfred Street North, North Sydney NSW 2060</p>


                </div>
              </Grid>

              <Grid item xs={6} sm={3} md={3} lg={3}>
                <div className="contactusfoot">

                  <span>India</span>
                  <p>Gera’s Imperium, Hinjewadi,
                    Phase 2, Pune, Maharashtra 411057</p>

                </div>
              </Grid>

              <Grid item xs={6} sm={3} md={3} lg={3}>
                <div className="contactusfoot">

                  <span>Call Us</span>
                  <a href="tel:+14156093633"> +1-415-6093633 </a> <br></br>
                  <a href="mailto:info@skylarklabs.ai">info@skylarklabs.ai</a>

                </div>
              </Grid>
            </Grid>


          </div>

        </section> */}
      </div>
      <div className="copyright">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={6} sm={8} md={6}>
              <ul className="copyright_link">
                <li>© 2024 Skylarklabs, Inc.</li>
                <li><Link to="/privacy"> Privacy</Link></li>
                {/* <li><Link to="/terms">Terms of use</Link></li> */}
              </ul>
            </Grid>
            <Grid item xs={6} sm={4} md={6}>
              <ul className="footersocial_links">
                <li>
                  <a href="https://www.linkedin.com/company/skylarklabs/" target="blank">
                    <img src="images/linkedin.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UC0mf1_KkOBhRFpi5yEkxPeA" target="blank">
                    <img src="images/youtube.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.facebook.com/1SkyLarkLabs/" target="blank">
                    <img src="images/facebook.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/skylarklabs/" target="blank">
                    <img src="images/instagram.png" alt="" />
                  </a>
                </li>
                <li>© 2022 Skylarklabs, Inc.</li>
              </ul>
            </Grid>
          </Grid>
        </div>
      </div>
    </footer>
  );
};

export default Footermenu;
