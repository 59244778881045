import React from "react";
import Slider from "react-slick";

const settings = {
    autoplay: false,
    // autoplaySpeed: 3500,
    arrows: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const ImageSlider = ({ slideIndex = 0, images = [] }) => {
    const sliderRef = React.useRef();

    React.useEffect(() => {
        sliderRef.current.slickGoTo(slideIndex);
    }, [slideIndex]);

    return (
        <div>
            <div className="neuralslider">
                <Slider ref={sliderRef} {...settings} initialSlide={slideIndex}>
                    {
                        images.map((img, index) => {
                            return <div className="p-2 latestnewshome">
                                <div className="newsbox-home productslider">
                                    <a href="/" target="blank">
                                        <img src={img} alt={index} />
                                    </a>
                                </div>
                            </div>
                        })
                    }
                </Slider>
            </div>
        </div>
    );
};

export default ImageSlider;
