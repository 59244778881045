import getWebsocketToken from "./sections/WebsocketConnection";
import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import TopBar from "./sections/TopBar1";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "react-circular-progressbar/dist/styles.css";
import $ from "jquery";

import { Icon } from "@material-ui/core";

import FacesGrid from "./sections/facesGrid";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  fab: {
    width: "100%",
    background: "white",
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  section: { padding: "30px" },
  sectionHeader: {
    textAlign: "center",
    fontSize: "40px",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginBottom: "10px",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "normal !important",
  },
  crop_result: {
    height: "300px",
    marginBottom: "60px",
    marginLeft: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "60px",

    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "0px",
      width: "100%",
      height: "auto",
    },
  },
}));

const FaceMaskWebsocket = () => {
  const classes = useStyles();

  const size =
    $(window).width() <= 1300
      ? { width: $(window).width(), height: "600" }
      : { width: "900", height: "720" };
  const aspect = $(window).width() <= 1199 ? 0.8 : 1.78;

  const videoConstraints = {
    aspectRatio: aspect,
    facingMode: "user",
  };

  const [faceList, setFaceList] = useState([]);
  const [socketUrl, setSocketUrl] = useState(0);
  const [socket, setSocket] = useState(0);
  const [key, setKey] = useState(0);
  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);
  const [cameraHash, setCameraHash] = useState(1);

  const [startStream, setStartStream] = useState(false);
  const [compressionFactor, setCompressionFactor] = useState(0.5);

  const webcamRef = React.useRef(null);

  const useScript = (url) => {
    useEffect(() => {
      const script = document.createElement("script");
      script.src = url;
      script.async = true;

      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }, [url]);
  };
  useScript("https://cdn.jsdelivr.net/npm/@tensorflow/tfjs");

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );
  const changeCamera = () => {
    setCameraHash((cameraHash) => cameraHash + 1);
  };

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => {
    if (devices.length > 0) {
      console.log(cameraHash % devices.length);
      setDeviceId(devices[cameraHash % devices.length].deviceId);
    }
    // eslint-disable-next-line
  }, [cameraHash]);

  const handleStart = () => {
    setStartStream((prevItems) => !prevItems);
  };
  useEffect(() => {
    console.log("reference :");
    // console.log(referenceToUpload);
    if (!startStream && socket !== 0) {
      document.getElementById("btn").style.display = "";
      document.getElementById("can_res_1").style.display = "none";
      document.getElementById("webcam").style.visibility = "visible";
    }

    if (socket !== 0 && startStream) {
      document.getElementById("can_res_1").style.display = "block";
      document.getElementById("btn").style.display = "none";
      console.log("use websockets");
      document.getElementById("webcam").style.visibility = "hidden";

      let initParams = {
        action: "start_reference",
        fileSize: 140945,
      };
      socket.send(JSON.stringify(initParams));
    }
    //checked this!
  }, [socket, startStream]);

  useEffect(() => {
    setKey(getWebsocketToken());
    // eslint-disable-next-line
  }, [getWebsocketToken]);

  useEffect(() => {
    if (key !== 0) {
      console.log("got key" + key);
      setSocketUrl(
        "wss://api.skylarklabs.ai/ws/face-mask?authorization=" + key
      );
    }
  }, [key]);

  useEffect(() => {
    const initializeSocket = () => {
      if (socket !== 0) {
        socket.onopen = (e) => {
          // setBatchSize(2);
          console.log("opened" + e);
        };
        socket.onmessage = function (event) {
          console.log(`Message Received: ${event.data}`);

          if (startStream) {
            handleResponse(event.data);
          }
        };

        socket.onclose = function (event) {
          if (event.wasClean) {
            console.log(
              `Connection closed cleanly, code=${event.code} reason=${event.reason}`
            );
          } else {
            console.log("WebSocket Connection died");
          }
        };

        socket.onerror = function (error) {
          console.log(`Error: ${error.message}`);
        };
      }
    };
    initializeSocket();
    // eslint-disable-next-line
  }, [socket, startStream]);
  useEffect(() => {
    if (startStream) {
      socket.send(
        JSON.stringify({
          action: "set_batch_size",
          batchSize: 1,
        })
      );
    }
  }, [startStream, socket]);

  //   function sleep(ms) {
  //     return new Promise((resolve) => setTimeout(resolve, ms));
  //   }

  function drawBoxes(coordList, alertList) {
    // console.time("Execution Time");

    try {
      var img = document.getElementById("ss");
      var canvas = document.getElementById("can_res_1");
      canvas.width = img.width;
      canvas.height = img.height;
      var context = canvas.getContext("2d");

      context.drawImage(img, 0, 0);

      if (coordList !== null) {
        for (var i = 0; i < coordList.length; i++) {
          var coord = coordList[i];
          const start = [coord[0], coord[1]];
          const size = [coord[2] - coord[0], coord[3] - coord[1]];
          context.beginPath();
          context.rect(start[0], start[1], size[0], size[1]);
          context.lineWidth = 3;
          context.strokeStyle = "green";
          context.stroke();
        }
      }
      if (alertList !== null) {
        for (i = 0; i < alertList.length; i++) {
          coord = alertList[i];
          const start = [coord[0], coord[1]];
          const size = [coord[2] - coord[0], coord[3] - coord[1]];
          context.beginPath();
          context.rect(start[0], start[1], size[0], size[1]);
          context.lineWidth = 3;
          context.strokeStyle = "red";
          context.stroke();
        }
      }
    } catch (error) {
      console.log(error);
    }
    // console.timeEnd("Execution Time");
  }

  function getCroppedFaceFromQuery(coord, crops) {
    // console.time("Execution Time");

    try {
      const start = [coord[0], coord[1]];
      const size = [coord[2] - coord[0], coord[3] - coord[1]];
      var img1_src = document.getElementById("ss").src;
      var canvas = document.getElementById("can_res_2");
      var ctx = canvas.getContext("2d");
      //   console.log("pred");
      //   console.log(start[0], start[1]);
      //   console.log(size[0], size[1]);
      var img = new Image();

      img.onload = function () {
        canvas.width = size[0];
        canvas.height = size[1];

        ctx.drawImage(
          img,
          start[0],
          start[1],
          size[0],
          size[1],
          0,
          0,
          size[0],
          size[1]
        );
        // setFaceList((prevItems) => [
        //   ...prevItems,
        //   {
        //     id: prevItems.length,
        //     value: canvas.toDataURL(),
        //   },
        // ]);
        crops.push({
          id: crops.length,
          value: canvas.toDataURL(),
        });
      };
      img.src = img1_src;
    } catch (error) {
      console.log(error);
    }
    // console.timeEnd("Execution Time");
  }

  async function handleResponse(responseData) {
    if (responseData !== null && responseData !== 0) {
      var data = JSON.parse(responseData);
      //   setResponseState(data);

      if (data.hasOwnProperty("state") && data["state"] === "ready") {
        console.log("send now");
        socket.send(
          JSON.stringify({
            fileSize: 100,
            action: "start_frame",
          })
        );
      }
      if (data.hasOwnProperty("results")) {
        var results = data["results"];
        if (results !== null) {
          results = results[0];
          //   console.log(results);
          var listMask = results["has_mask"];
          var listNoMask = results["has_no_mask"];
          var crops = [];
          if (listNoMask !== null) {
            // clearList();
            for (var i = 0; i < listNoMask.length; i++) {
              getCroppedFaceFromQuery(listNoMask[i], crops);
            }
          }

          setTimeout(() => {
            setFaceList((faceList) => faceList.splice(0, 0).concat(crops));
          }, 100);

          drawBoxes(listMask, listNoMask);
        }
      }
      if (
        data.hasOwnProperty("state") &&
        data["state"] === "frame_progress" &&
        webcamRef.current
      ) {
        // console.log("sending");

        var image = webcamRef.current.getScreenshot();
        document.getElementById("ss").src = image;

        if (image !== null) {
          var base64result = image.split(",")[1];
          var raw = window.atob(base64result);
          var rawLength = raw.length;
          var array = new Uint8Array(new ArrayBuffer(rawLength));
          for (i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
          }
          var byteLen = array.byteLength;
          console.log(byteLen);
          if (30000 / byteLen < 1) {
            var val = 30000 / byteLen;
            if (val > 0) {
              console.log("compressino factor" + val.toFixed(1));
              setCompressionFactor(val.toFixed(1));
            }
          }
          socket.send(
            JSON.stringify({
              fileSize: array.byteLength,
              action: "start_frame",
            })
          );

          socket.send(array);
        } else {
          console.log("null");
        }
      }
    }
  }

  useEffect(() => {
    // const initializeSocket = () => {
    if (socketUrl !== 0) {
      setSocket(new WebSocket(socketUrl));
      console.log("socket set");
    }

    //   capture();
    // };
  }, [socketUrl]);

  return (
    <section className={classes.section}>
      <div>
        <h2 className={classes.sectionHeader}>Face Mask Detection</h2>
      </div>

      <canvas
        id="can_res_2"
        style={{
          background: "black",
          display: "none",
        }}
      />
      <TopBar />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Fab
          variant="extended"
          size="large"
          color="primary"
          onClick={handleStart}
          aria-label="Buy"
          className="px-20 py-10 text-24 m-2"
        >
          <Icon className="mr-4">laptop-code</Icon>
          {startStream ? "Stop" : "Start"}
        </Fab>
        <Fab
          variant="extended"
          size="large"
          color="primary"
          id="btn"
          onClick={changeCamera}
          aria-label="Buy"
          className="px-20 py-10 text-24 m-2"
        >
          <Icon className="mr-4">laptop-code</Icon>
          Change Camera
        </Fab>
      </div>
      <Grid container spacing={3} style={{ margin: "0px" }}>
        <img id="mtest" src="" style={{ display: "none" }} alt=""></img>

        <Grid
          item
          md={10}
          sm={12}
          xs={12}
          style={{ padding: "0px", position: "relative" }}
        >
          <img
            src=""
            alt="screenshot"
            id="ss"
            style={{ display: "none" }}
          ></img>
          {devices.length > 0 ? (
            <div>
              <Webcam
                id="webcam"
                height={size.height}
                width={size.width}
                style={{ position: "absolute" }}
                audio={false}
                screenshotQuality={compressionFactor}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ ...videoConstraints, deviceId: deviceId }}
              />

              <canvas
                id="can_res_1"
                style={{
                  position: "absolute",
                  background: "",
                  marginTop: "100px",
                  display: "none",
                }}
              />
            </div>
          ) : null}

          {/* <span>{resultText}</span> */}
        </Grid>

        <Grid item md={2} sm={12} xs={12}>
          <Card className="card relative h-full">
            <FacesGrid
              noFaceMessage="No Violators"
              hasFaceMessage="Violators"
              faces={faceList}
            />
          </Card>
        </Grid>
      </Grid>
    </section>
  );
};
export default FaceMaskWebsocket;
