import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player"; 
import ariesvideo from "../videofiles/aries-banner.mp4";
import mirasvideo from "../videofiles/miras-main-banner.mp4";

import satellitevideo from "../videofiles/dros-banner.mp4";
import PropTypes from "prop-types";
import { Box, Typography, Grid } from "@material-ui/core";
import Etostestimonial from "home/sections/Etos-testimonial";  
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";

const Etos = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `SkylarkLabs - Emerging Threat Observation and Detection System`;
  }, []);
  const [expanded, setExpanded] = React.useState("");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>
                <span className="mainsubtitle">ETOS</span>
                Emerging Threat Observation and Detection System
              </h1>
              <p>
                Guarding Nations Against Known and Unknown Emerging Threats, With Unwavering Vigilance and Cutting-Edge Technology.
              </p>
              <div className="subtages">
                <a>Loitering</a> <a>Stalking </a>{" "}
                <a>Violence Detection</a>{" "}
                <a>Weapons Detection</a> <a>Intrusion</a>
              </div>
            </div>
          </div>
        </div>

        <div className="imgcenter bannerimgblock ">
          <ReactPlayer
            controls={true}
            playsinline
            volume={0}
            width="100%"
            height="100%"
            loop={true}
            muted={false}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url="https://d1nbq27kwttg28.cloudfront.net/blog/etos-page-banner.3eb335ca.mp4"

          />
        </div>
      </div>

      {/* <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2> Border Reconnaissance & Observation System</h2>
            <p>
              Guarding Nations Against Known and Unknown Emerging Threats, With Unwavering Vigilance and Cutting-Edge Technology.
            </p>
          </div>
        </div>
      </div> */}

      <div className="plateformsub-page">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>

        <div className="container pt-5">
          <div className="plateformabout  mt-5">
            <Grid container spacing={3} className="bros-about-container">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="aboutimgblock">
                  {" "}
                  <img src="images/infographics-etos.png" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 pb-0">
                      <span className="spanfirstword">Border</span> Surviellance System 

                    </h2>
                    <p className="pt-0 abouttext-pblock">
                      ETOS swiftly detects both known and unknown emerging threats, ensuring enhanced safety for borders and critical areas. It promptly alerts the relevant authorities, providing a crucial layer of defense against potential threats. With advanced surveillance technology, thermal imaging, and motion sensors, BorderSentinel empowers nations to safeguard their borders and respond proactively to evolving security challenges.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="homeprodctlist">
       <div className="homeprodctlisttop">
          <Box style={{ width: '100%' }}>
           
          <div className="poductlisttabs  latticetabs aboutuspage-tabs upporboxtop">
          <div className="container pt-5">
              <Box style={{ borderColor: 'divider' }}>

              <Box sx={{ borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange1}
                  aria-label="basic tabs example"
                >
                  <Tab label="Outdoor Applications" {...a11yProps(0)} />
                  <Tab label="Indoor Applications" {...a11yProps(1)} />
                   
                </Tabs>
              </Box>

              </Box>
              </div>
            </div>
            <TabPanel value={value} index={0}>
            <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
         
          
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Shipping</span>{" "}
                       Yards and Ports
                    </h4>
                    <p>
                      Focused on securing national borders against various threats and unauthorized activities.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Loitering and Stalking Detection ("Dock Prowler Alert")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Weapons Detection ("Cargo Screening Sentinel")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          PPE Detection ("Longshoreman Safety Monitor")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Face Recognition ("Stevedore Identity Verification")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Vandalism Detection ("Port Property Protector")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Crowd Formation Detection ("Dockyard Gathering Analyzer")
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/shipping_yards_ports.png" />
                </div>
              </Grid>
            </Grid>
          </div>
            
         
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/etos_norder_monitoring.png" alt="" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Border</span>  Monitoring
                    </h4>
                    <p>
                      Designed to enhance security within military bases and other critical defense installations.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Loitering and Stalking Detection ("Border Trespasser Tracker")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Weapons Detection ("Frontier Arms Detector")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Face Recognition ("Border Watchlist Identifier")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Crowd Formation Detection ("Border Gathering Analyzer")
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Critical</span>{" "}
                       Infrastructure  
                    </h4>
                    <p>
                      Aimed at safeguarding critical defense infrastructure and assets, particularly in strategic locations.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Loitering and Stalking Detection
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Weapons Detection ("Infrastructure Threat Detector")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          PPE Detection ("Utility Worker Safety Compliance")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Face Recognition ("Secure Area Access Authenticator")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Vandalism Detection ("Critical Infrastructure Vandalism Monitor")
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/etos_critical_infrastructure.png" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
        </section> 
        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/etos_outdoor_event_venues.png" alt="" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Outdoor</span>   Event Venues
                    </h4>
                    <p>
                      Designed to enhance security within military bases and other critical defense installations.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Loitering and Stalking Detection ("Event Grounds Suspicious Activity Monitor")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Weapons Detection ("Spectator Screening Sentry")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Violence Detection ("Crowd Aggression Analyzer")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Face Recognition ("Banned Attendee Identifier")
                        </div>{" "}
                      </li>

                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Crowd Formation Detection ("Event Crowd Dynamics Monitor")
                        </div>{" "}
                      </li>


                      <li>
                        <div className="boxinner">
                          <span>
                            <div>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                            </div>
                          </span>
                          Vape/Smoking Detection ("Event Grounds Smoke Detector")
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
              </TabPanel>

              <TabPanel value={value} index={1}>
              <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
         
          
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Schools</span>{" "}
                       and Educational Institutions
                    </h4>
                    <p>
                      Focused on securing national borders against various threats and unauthorized activities.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Weapons Detection ("Campus Armament Alert")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Loitering and Stalking Detection ("School Grounds Trespasser Tracker")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Violence Detection ("Student Conflict Detector")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Face Recognition ("Scholastic Community Verifier")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Vape/Smoking Detection ("Campus Smoke Sentinel")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Vandalism Detection ("School Property Protector")
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/etos_indoor_applications.png" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
            
         
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/etos_industrial_parks_factories.png" alt="" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Industrial</span>  Parks and Factories
                    </h4>
                    <p>
                      Designed to enhance security within military bases and other critical defense installations.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          PPE Detection ("Factory Floor Safety Enforcer")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Loitering and Stalking Detection ("Restricted Area Intruder Alert")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Weapons Detection ("Workplace Threat Detector")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Face Recognition ("Employee Access Authenticator")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Vape/Smoking Detection ("Industrial Smoke Detector")
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Government</span>{" "}
                       Buildings and Courthouses  
                    </h4>
                    <p>
                      Aimed at safeguarding critical defense infrastructure and assets, particularly in strategic locations.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Weapons Detection ("Civic Center Security Screener")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Loitering and Stalking Detection ("Government Facility Suspicious Behavior Monitor"):
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Violence Detection ("Courthouse Aggression Detector")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Face Recognition ("Government Personnel Verifier")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Crowd Formation Detection ("Civic Center Crowd Analyzer")
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/etos_gov.png" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
        </section> 
        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/etos_hospitals_healthcare_facilities.png" alt="" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Hospitals</span>    and Healthcare Facilities
                    </h4>
                    <p>
                      Designed to enhance security within military bases and other critical defense installations.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          PPE Detection ("Medical Staff Safety Compliance Monitor")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Loitering and Stalking Detection ("Hospital Wing Intruder Alert")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Violence Detection ("Patient and Staff Protection System")
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Face Recognition ("Healthcare Facility Access Verifier")
                        </div>{" "}
                      </li>

                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Vape/Smoking Detection ("Medical Facility Smoke Detector")
                        </div>{" "}
                      </li>

 
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

</TabPanel>

            </Box>
            </div>
            </div>


        <section className="configurations">
          <div className="container">
            <div className="">
              <h3>ETOS: Proactive Threat Detection</h3>
              <p>
              ETOS swiftly identifies and responds to known and emerging threats, enhancing security at borders and critical areas. Equipped with advanced surveillance, thermal imaging, and motion sensors, ETOS ensures quick alerting and robust protection against potential security challenges.
              </p>
            </div>

            <div className="boxouterproduct">
              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>BVLOS Threat Detection</h4>
                <p>
                  Detect threats beyond visual line of sight (BVLOS) across all
                  domains, ensuring comprehensive security in any operational
                  context.
                </p>
              </div>
              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Real-time Processing</h4>
                <p>
                  Our solutions are built on top of computationally efficient
                  algorithms, allowing us to process Data from multiple sensors in
                  real time.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Hardware Agnostic</h4>
                <p>
                  Our solutions seamlessly integrate with a wide range of sensors,
                  without the need for specific hardware specifications.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Economical & Configurable</h4>
                <p>
                  Our solution adapts to existing hardware with low compute
                  demands and simple configuration.
                </p>
              </div>


              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Assisted Border Planning</h4>
                <p>
                  Our platform identifies areas with recurring alerts, aiding in
                  strategic troop deployment and fortification of perimeter
                  infrastructure.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Efficient On-Ground Communication</h4>
                <p>
                  Our system offers a comprehensive map view, enabling precise
                  threat tracking from outer to internal perimeters.
                </p>
              </div>
            </div>
          </div>
        </section>



        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Other</span> System
                    Configurations
                  </h2>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured">
                  <a href="aries">
                    <h5>
                      Aerial Reconnaissance and <br></br> Elimination System
                    </h5>

                    <div className="imgblockfeatured">
                      <ReactPlayer
                        controls={true}
                        playsinline
                        volume={0}
                        width="100%"
                        height="100%"
                        loop={true}
                        muted={false}
                        playing={true}
                        stopOnUnmount={true}
                        config={{
                          file: {
                            attributes: {
                              autoplay: true,
                              controls: false,
                              loop: true,
                              muted: true,
                            },
                          },
                        }}
                        url={ariesvideo}
                      />
                    </div>
                    <div className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}
                    </div>
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured">
                  <a href="miras">
                    <h5>
                      Marine Reconnaissance <br></br>System
                    </h5>
                    <div className="imgblockfeatured">
                      <ReactPlayer
                        controls={true}
                        playsinline
                        volume={0}
                        width="100%"
                        height="100%"
                        loop={true}
                        muted={false}
                        playing={true}
                        stopOnUnmount={true}
                        config={{
                          file: {
                            attributes: {
                              autoplay: true,
                              controls: false,
                              loop: true,
                              muted: true,
                            },
                          },
                        }}
                        url={mirasvideo}
                      />
                    </div>
                    <div className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}
                    </div>
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured">
                  <a href="dros">
                    <h5>
                      Drone Reconnaissance and <br></br> Observation System
                    </h5>

                    <div className="imgblockfeatured">
                      <ReactPlayer
                        controls={true}
                        playsinline
                        volume={0}
                        width="100%"
                        height="100%"
                        loop={true}
                        muted={false}
                        playing={true}
                        stopOnUnmount={true}
                        config={{
                          file: {
                            attributes: {
                              autoplay: true,
                              controls: false,
                              loop: true,
                              muted: true,
                            },
                          },
                        }}
                        url={satellitevideo}
                      />
                    </div>
                    <div className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}
                    </div>
                  </a>
                </div>
              </Grid>

            </Grid>
          </div>
        </section>



        {/* <div className="plateformaboutdd02aa">
        <section className="homeourproduct newblackbg darktheme-outerblock spanceseaction spanceseaction flatformblocdivother-featucher">
          <div className="imgplatformsbg imgplatformsbgleft">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container homeprodctlisttop whitetext pt-5 pb-0">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} className="text-center">
                <div className="flatformtages text-center">
                  <span>Product Capabilities</span>
                </div>
                <h4 className=" text-center">
                  <span className="spanfirstword">Other</span> System
                </h4>
              </Grid>
            </Grid>
          </div>
          <div className="container">
            <div className="homeourproductblockmain collum pt-4">
              <div className="homeourproductblock">
                <a href="#contact">
                  <div className="homeproductnew">
                    <img src="images/traffic-management-product.png" alt="" />
                    <div className="producttitle-bottom">
                      <h3>Traffic Management</h3>
                      <p>
                        {" "}
                        Our advanced AI monitors traffic congestion,
                        overspeeding drivers and other safety hazards to
                        safeguard the public
                      </p>

                      <div className="nextarrow-block">
                        <img src="images/arrow-white-big.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="homeourproductblock">
                <a href="#contact">
                  <div className="homeproductnew">
                    <img src="images/infrastructure-monitoring.png" alt="" />
                    <div className="producttitle-bottom">
                      <h3>Infrastructure Monitoring</h3>
                      <p>
                        Our advanced AI monitors critical infrastructure to find
                        damage to make sure the safety of the occupants and
                        workers
                      </p>

                      <div className="nextarrow-block">
                        <img src="images/arrow-white-big.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="homeourproductblock">
                <a href="#contact">
                  <div className="homeproductnew">
                    <img src="images/workplace-safety.png" alt="" />
                    <div className="producttitle-bottom">
                      <h3>Workplace Safety</h3>
                      <p>
                        Our advanced AI monitors workers at businesses to
                        validate their safety gear to safeguard them
                      </p>

                      <div className="nextarrow-block">
                        <img src="images/arrow-white-big.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        </div>
        <div className=" addspaceborderblock">
          <div className="linedividerblock"></div>
        </div> */}

      </div>

      <Etostestimonial />
    </>
  );
};

export default Etos;
