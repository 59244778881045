import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  intro: {
    padding: "180px 0 0 !important",
    overflow: "visible !important",

    [theme.breakpoints.down("sm")]: {
      padding: "100px 0 0 !important",
    },
  },
  product: {
    textAlign: "center",
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    margin: "auto",
    bottom: -80,
    maxWidth: 780,
    marginBottom: "5rem",
    "& img": {
      borderRadius: "0.5rem",
      boxShadow: "0 10px 16px rgba(0, 0, 0, 0.2)",
    },

    [theme.breakpoints.down("sm")]: {
      bottom: -63,
    },

    [theme.breakpoints.down("xs")]: {
      bottom: -39,
    },
  },
  title: {
    textShadow: "0 4px 4px rgba(0, 0, 0, 0.22)",
  },
}));

const Testimonial = () => {
  var settings = {
    draggable: true,
    autoplay: true,
    autoplaySpeed: 7000,
    arrows: false,
    dots: true,
    fade: true,
    speed: 500,
    infinite: true,
    cssEase: 'ease-in-out',
    touchThreshold: 100,
   
        
     
  };
  
 

  var rtl = {
    ...settings,
    slidesToScroll:1,
  }

  const classes = useStyles();

  return (
    <section
      className={clsx("client_testimonials")}


    >
    

      <div className="container">
        <div className="testimonials_outer">
        <Slider {...rtl}>
        <div>
          <p><img src="images/double-quotes.png" className="double-quotes" alt=""/> With the skylark labs solution, campus security professionals are able to quickly assess potential threats and take necessary action — without over-burdening their security team <img src="images/double-quotes.png" alt=""/></p>
       <span>Campus Security</span>
        </div>
      
     
        </Slider>
        
        </div>
      </div>

    </section>
  );
};

export default Testimonial;
