import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Etostestimonial from "home/sections/Etos-testimonial";

const Supplyintel = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Supply Chain Analysis`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);


  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>
                {/* <span className="mainsubtitle">SCA</span> */}
                SupplyIntel
              </h1>
              <p>
                Gain critical insights into your supply chain, ensuring
                resilience and success in an interconnected world of global
                commerce.
              </p>
              <div className="subtages">
                <a href="#/">Supply Network Navigator</a>{" "}
                <a href="#/">Data-Driven Logistics </a>{" "}
                <a href="#/">Engagement Analysis</a>{" "}
                <a href="#/">Predictive Analytics</a>
              </div>
            </div>
          </div>
        </div>

        <div className="imgcenter bannerimgblock ">
          <img src="../images/productSlider/supply-chain-analysis.png" alt="" />
        </div>
      </div>

      {/* <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2> Supply Chain Analysis</h2>
            <p>
              ETOS detects suspicious indicators using our proprietary
              technology to predict emerging threats.
            </p>
          </div>
        </div>
      </div> */}

      <div className="plateformsub-page">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>
        <div className="container pt-5">
          <div className="plateformabout  mt-5">
            <Grid container spacing={3} className="supplyintel-about-container">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div
                  className="aboutimgblock"
                  style={{
                    padding: "0",
                  }}
                >
                  {" "}
                  <img src="images/cropped-Supply Intel 1.png" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 pb-0">
                      <span className="spanfirstword">Supply </span> Intel
                    </h2>
                    <p className="pt-0 abouttext-pblock">
                      The globalized world relies heavily on intricate supply
                      chains. Turing's SupplyIntel module delves deep into
                      these networks, providing insights into links,
                      dependencies, and potential vulnerabilities, ensuring
                      businesses can navigate challenges and capitalize on
                      opportunities.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container">
            <div className="seactionblocktitles">
              <h2 className="seactiontitles"> Decode Your Supply Chain</h2>
              <p>
                Discover dependencies, enhance resilience, and ensure your path
                to success.
              </p>
            </div>
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Supply</span> Network Navigator
                    </h4>
                    <p>
                      Navigate complex supply networks with enhanced visibility and strategic insights.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Network Analysis
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Interdependency Mapping
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Vulnerability Identification
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Opportunity Exploration
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/Holistic Supply Chain Visualization.png" />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img
                      src="images/Vulnerability & Risk Assessment.png"
                      alt=""
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Engagement</span> Analysis
                    </h4>
                    <p>
                      Deepen supplier and customer engagement with targeted analysis and strategies.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Supplier Engagement Strategies
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Customer Demand Insights
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Market Response Analysis
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Stakeholder Communication Enhancement
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Data-Driven</span> Logistics
                    </h4>
                    <p>
                      Optimize your logistics with data-driven strategies for enhanced efficiency.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Transportation Analytics
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Inventory Management Insights
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Cost Efficiency Analysis
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Delivery Optimization
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/Predictive Supply Chain Analytics.png" />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img
                      src="images/Supplier & Vendor Engagement Metrics.png"
                      alt=""
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Predictive</span>{" "} Analytics
                    </h4>
                    <p>
                      Anticipate future challenges and opportunities with advanced predictive analytics.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Market Trend Forecasting
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Demand Prediction Models
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Risk Prediction and Mitigation
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Inventory Optimization
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Supply Chain</span> Intelligence System (SCIS)
                    </h4>
                    <p>
                      Empower your supply chain with intelligence for risk management and optimization.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Risk Assessment and Management
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Demand Forecasting
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Supplier Performance Monitoring
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Logistics Optimization
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Regulatory Compliance Tracking
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/Customizable News Dashboards.png" />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="configurations">
          <div className="container">
            <div className="">
              <h3>Turing: Next-gen Features for Modern Intelligence</h3>
            </div>

            <div className="boxouterproduct">
              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>
                <h4>Semantics Analysis</h4>
                <p>
                  Unlock the power of semantics with our intuitive platform.
                  Seamlessly analyze text using advanced semantic techniques,
                  providing you with valuable insights and a deeper
                  understanding of your data.
                </p>
              </div>
              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Data Filtering</h4>
                <p>
                  Efficiently sift through extensive datasets using advanced
                  customizable filtering techniques. Tailor your filters based
                  on criticality, hashtags, and more, giving you precise control
                  over the information you need.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Unknown Threats</h4>
                <p>
                  The Platform possesses the capability to analyze data sources
                  and accurately identify both known and previously unidentified
                  new threats. This ensures comprehensive threat detection and
                  protection.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Relationship Analysis</h4>
                <p>
                  This feature performs in-depth analysis to uncover various
                  connections and relationships associated with the identified
                  targets of interest. It provides valuable insights into
                  networks and associations within the monitored data.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Intelligent Threat Verification</h4>
                <p>
                  Leverage our platform's integration of physical sensors and
                  online data for swift and precise threat detection. Navigate
                  with ease using our user-friendly UI, ensuring top-tier
                  security.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Future Risk Anticipation</h4>
                <p>
                  Harness the power of our advanced UI's entity profiling to
                  anticipate potential threats. Through meticulous future
                  simulations, we elevate an entity's risk profile, equipping
                  you with insights into probable threats down the road. Stay a
                  step ahead and prepare effectively.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Other</span> System
                    Configurations
                  </h2>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured productfeatured_advis">
                  <a href="social-media-intel">
                    <h5>Social Media Intel</h5>
                    <div className="imgblockfeatured">
                      <img src="images/productSlider/01.png" alt="" />
                      <div className="readmore-btns">
                        <span>
                          Learn More{" "}
                          <img
                            src="/images/productSlider/nextarrow.svg"
                            alt=""
                          />
                        </span>{" "}
                      </div>
                    </div>
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured productfeatured_advis">
                  <a href="news-watch">
                    <h5>NewsWatch</h5>

                    <div className="imgblockfeatured">
                      <img src="images/productSlider/02.png" alt="" />
                    </div>
                    <div className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}
                    </div>
                  </a>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured productfeatured_advis">
                  <a href="financial-flux">
                    <h5>FinancialFlux</h5>
                    <div className="imgblockfeatured">
                      <img src="images/productSlider/04.png" alt="" />
                    </div>
                    <div className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}
                    </div>
                  </a>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
      </div>

      <Etostestimonial />
    </>
  );
};

export default Supplyintel;
