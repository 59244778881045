import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player"; 
import Satellitesystem from "../../videofiles/Satellite-system.mp4"; 

import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
const Indiancoastguard = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Airborne Systems`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [showTrial, setShowTrial] = React.useState(false);
  const [expanded, setExpanded] = React.useState("panel2");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };
  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "Guria NGO",
      desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
      url: "Guria-varanasi-human-trafficking",
      img: "images/cases-guria.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      // if (section4.current && offsetY >= section4.current.offsetTop)
      //   addActiveClass(navOption4);
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChangenew = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="plateformsub-page clientpage-outer">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>

        <div className="container">
          <div className="clientnavgations">
            <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
                <h5>Indian Coast Guard</h5>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={8}>
                <div className="overviewlinks">
                  <ul>
                    <li>
                      <a href="#/">Overview</a>{" "}
                    </li>
                    <li>
                      <a href="#/">Problem</a>{" "}
                    </li>
                    <li>
                      <a href="#/">Solution</a>{" "}
                    </li>
                    <li>
                      <a href="#/">Result</a>{" "}
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={3}>
          <Grid item xs={12} sm={3} md={3} lg={4}>
              <div className="text-left logoclient">
                <img
                  src="images/productSlider/Indian_Coast_Guard_Logo.png"
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <h1>
                  Cross-Border Challenges and Innovative Solutions at
                  Indo-Bhutan and Indo-Nepal Borders{" "}
                </h1>
                <p>
                  The friendly borders between India and its neighbors, Bhutan
                  and Nepal, are reinforced by Multiple Integrated Check Posts
                  (ICPs), established by the governments of these nations. These
                  ICPs are a testament to the strong ties of friendship and
                  cooperation, enabling residents to exchange goods and move
                  across borders with ease. This arrangement underscores mutual
                  trust and unity, allowing for a seamless interaction without
                  the common encumbrances of visas and passports typically
                  required for international travel.
                </p>
                <div className="clientblogimg">
                  <img src="images/industry.jpg" alt="" />{" "}
                </div>
                <p>
                  Open borders are a proof of our commitment to unity and mutual
                  respect, fostering cultural exchanges and economic growth.
                  Yet, this openness also brings challenges, such as the illicit
                  trade of goods and the complexity of monitoring cross-border
                  movement.
                </p>
                <p>
                  As we embrace the benefits of a vibrant tourism sector and the
                  free flow of people and commerce, we must also be vigilant
                  against the misuse of these open channels. Striking a balance
                  is crucial—we must safeguard the positive aspects of open
                  borders while preventing them from being exploited.
                </p>
                <p>
                  The key is to find a middle ground where security and freedom
                  coexist, ensuring that our open borders remain conduits for
                  prosperity and not for illicit activities.
                </p>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
          <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Overview</h3>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <h2>
                  Skylark Labs and Meity's Solution: A Pioneering Cross-Border
                  Initiative:{" "}
                </h2>
                <p>
                  The friendly borders between India and its neighbors, Bhutan
                  and Nepal, are reinforced by Multiple Integrated Check Posts
                  (ICPs), established by the governments of these nations. These
                  ICPs are a testament to the strong ties of friendship and
                  cooperation, enabling residents to exchange goods and move
                  across borders with ease. This arrangement underscores mutual
                  trust and unity, allowing for a seamless interaction without
                  the common encumbrances of visas and passports typically
                  required for international travel.
                </p>
                <div className="clientblogimg">
                  <img src="images/industry.jpg" alt="" />{" "}
                </div>
                <p>
                  Open borders are a proof of our commitment to unity and mutual
                  respect, fostering cultural exchanges and economic growth.
                  Yet, this openness also brings challenges, such as the illicit
                  trade of goods and the complexity of monitoring cross-border
                  movement.
                </p>
                <p>
                  As we embrace the benefits of a vibrant tourism sector and the
                  free flow of people and commerce, we must also be vigilant
                  against the misuse of these open channels. Striking a balance
                  is crucial—we must safeguard the positive aspects of open
                  borders while preventing them from being exploited.
                </p>
                <p>
                  The key is to find a middle ground where security and freedom
                  coexist, ensuring that our open borders remain conduits for
                  prosperity and not for illicit activities.
                </p>
                <div className="clientblogimg">
                  <img src="images/industry.jpg" alt="" />{" "}
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
          <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>The Problem</h3>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <h2>
                  Skylark Labs and Meity's Solution: A Pioneering Cross-Border
                  Initiative:{" "}
                </h2>
                <p>
                  The friendly borders between India and its neighbors, Bhutan
                  and Nepal, are reinforced by Multiple Integrated Check Posts
                  (ICPs), established by the governments of these nations. These
                  ICPs are a testament to the strong ties of friendship and
                  cooperation, enabling residents to exchange goods and move
                  across borders with ease. This arrangement underscores mutual
                  trust and unity, allowing for a seamless interaction without
                  the common encumbrances of visas and passports typically
                  required for international travel.
                </p>
                <div className="clientblogimg">
                  <img src="images/industry.jpg" alt="" />{" "}
                </div>
                <p>
                  Open borders are a proof of our commitment to unity and mutual
                  respect, fostering cultural exchanges and economic growth.
                  Yet, this openness also brings challenges, such as the illicit
                  trade of goods and the complexity of monitoring cross-border
                  movement.
                </p>
                <p>
                  As we embrace the benefits of a vibrant tourism sector and the
                  free flow of people and commerce, we must also be vigilant
                  against the misuse of these open channels. Striking a balance
                  is crucial—we must safeguard the positive aspects of open
                  borders while preventing them from being exploited.
                </p>
                <p>
                  The key is to find a middle ground where security and freedom
                  coexist, ensuring that our open borders remain conduits for
                  prosperity and not for illicit activities.
                </p>
                <div className="clientblogimg">
                  <img src="images/industry.jpg" alt="" />{" "}
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
          <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>The Solution</h3>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <h2>
                  Skylark Labs and Meity's Solution: A Pioneering Cross-Border
                  Initiative:{" "}
                </h2>
                <p>
                  The friendly borders between India and its neighbors, Bhutan
                  and Nepal, are reinforced by Multiple Integrated Check Posts
                  (ICPs), established by the governments of these nations. These
                  ICPs are a testament to the strong ties of friendship and
                  cooperation, enabling residents to exchange goods and move
                  across borders with ease. This arrangement underscores mutual
                  trust and unity, allowing for a seamless interaction without
                  the common encumbrances of visas and passports typically
                  required for international travel.
                </p>
                <div className="clientblogimg">
                  <img src="images/industry.jpg" alt="" />{" "}
                </div>
                <p>
                  Open borders are a proof of our commitment to unity and mutual
                  respect, fostering cultural exchanges and economic growth.
                  Yet, this openness also brings challenges, such as the illicit
                  trade of goods and the complexity of monitoring cross-border
                  movement.
                </p>
                <p>
                  As we embrace the benefits of a vibrant tourism sector and the
                  free flow of people and commerce, we must also be vigilant
                  against the misuse of these open channels. Striking a balance
                  is crucial—we must safeguard the positive aspects of open
                  borders while preventing them from being exploited.
                </p>
                <p>
                  The key is to find a middle ground where security and freedom
                  coexist, ensuring that our open borders remain conduits for
                  prosperity and not for illicit activities.
                </p>
                <div className="clientblogimg">
                  <img src="images/industry.jpg" alt="" />{" "}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="plateformsub-page">
        <div className="container pt-5">
          <div className="plateformabout  mt-5">
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="aboutimgblock">
                  {" "}
                  <img src="images/airborne.png" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 pb-0">
                      <span className="spanfirstword">Airborne </span> Airborne
                      Systems
                    </h2>
                    <p className="pt-0 abouttext-pblock">
                      National property destruction, whether intentional or
                      unintentional, is unavoidable, terrible, and has a
                      significant impact on a nation's dignity. It's far worse
                      when vandalism is involved. To help prevent these acts,
                      our systems deliver preemptive notifications and take
                      appropriate action depending on the insights gained from
                      the occurrence.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Satellite</span> Systems
                    </h4>
                    <p>
                      Skylark's Satellite Change Detection System tracks any
                      changes to the landscape, such as those caused by
                      construction or changes to troop movements in wartime
                      settings with the aid of satellites.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Infrastructure Monitoring
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Troop Movements
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <ReactPlayer
                    controls={true}
                    playsinline
                    volume={0}
                    width="100%"
                    height="100%"
                    loop={true}
                    muted={false}
                    playing={true}
                    stopOnUnmount={true}
                    config={{
                      file: {
                        attributes: {
                          autoplay: true,
                          controls: false,
                          loop: true,
                          muted: true,
                        },
                      },
                    }}
                    url={Satellitesystem}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Related</span> More Cases
                  </h2>
                </div>
              </Grid>
            </Grid>

            <div className="industriesListblock pt-5">
              <ul>
                {otherCases.map((ele, idx) => (
                  <li>
                    <a href="#contact" className="industriesListcol">
                      <div className="solutionListimg">
                        <img src={ele.img} alt="" />
                      </div>
                      <div className="industriesListcoltext">
                        <h4>{ele.title} </h4>
                        <p>{ele.desc}</p>
                        <div className="quicklinks casesquicklinks">
                          {" "}
                          View full case{" "}
                          <img src="images/right-arrow.png" alt="" />{" "}
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Indiancoastguard;
