import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';

const AllEvents = () => {
    const [allEvents, setAllEvents] = useState([]);

    useEffect(() => {
        document.getElementById("mainTitle").innerText = "Skylark Labs: Event's";

        fetch(`https://prodserver.skylarklabs.ai/skylarklabsblog/all-articles/?article_type=7`)
            .then((res) => res.json())
            .then((data) => {
                setAllEvents(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <>
            <div className="bloglanding">
                <div className="bloglanding-subblock">
                    <img src="../images/productSlider/gradient-circle.svg" alt="" className="uk-blur-large leftimg rightimgblur-img" />
                    <img src="../images/productSlider/gradient-circle.svg" alt="" className="uk-blur-large rightimg leftimgblur-img" />
                </div>
                <div className="bloglanding-subblock bloglanding-subblocktwo">
                    <div className="topgradint"></div>
                </div>

                <div className="container menuconteaner">
                <a href="/blogs" className="back-btn-blog"><span><img src="/images/productSlider/nextarrow.svg" alt="" /> Go back</span></a>
                    <div className="dismanbannertopblock">
                        <h1>Events</h1>

                        {/* <h2>AI that adapts with changing landscape to identify new unknown threats</h2> */}
                    </div>
                </div>

                <Grid container spacing={3} className="container" style={{
                    padding: "26px",
                    margin: "auto"
                }}>
                    {allEvents?.count > 0 ? (
                        allEvents?.results?.map((event, index) => {
                            if (index === 0) {
                                return (
                                  
                                        <Grid container className="pb-5" spacing={3}>
                                            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                <div className="item-box-shadow boxnow">
                                                    <div className="blog-item-img blog-item-mainimg">
                                                    <Link to={{
                                        pathname: `/event/${event.id}/${event.slug}`,
                                    }}>
                                                        <img src={event.image} alt="" />
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <div className="blog-title">
                                                <Link to={{
                                        pathname: `/event/${event.id}/${event.slug}`,
                                    }}>
                                                    <h2>{event.title}</h2>
                                                    </Link>
                                                </div>
                                                <div className="blog-short-dis big-card">
                                                    <div dangerouslySetInnerHTML={{ __html: event.content }} />
                                                </div>

                                                <div className="btnsmorenew">
                                                  <Link className="readmore-btns" to={{
                                        pathname: `/event/${event.id}/${event.slug}`,
                                    }}>
                                         <span>
                                                                    Learn More{" "}
                                                                    <img
                                                                        src="/images/productSlider/nextarrow.svg"
                                                                        alt=""
                                                                    />
                                                                </span>{" "}
                                        
                                         </Link>
                                         </div>
                                                
                                            </Grid>
                                        </Grid>
                                   
                                );
                            } else {
                                return (
                                    <Link to={{
                                        pathname: `/event/${event.id}/${event.slug}`,
                                    }}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="pl-0 pr-0" key={index}>
                                            <div className="item-box-shadow bloglisting-home">
                                                <div className="blog-item-img">
                                                    <img src={event.image} alt="" />
                                                </div>
                                                <div className="pl-4 bloglistingdis">
                                                    <div className="blog-title">
                                                        <h2>{event.title}</h2>
                                                    </div>
                                                    <div className="blog-short-dis">
                                                        <div dangerouslySetInnerHTML={{ __html: event.content }} />
                                                   
                                                    </div>
                                                    <div className="btnsmore">
                                                            <a className=" readmore-btns" style={{
                                                                color: "#fff",
                                                            }}>
                                                                <span>
                                                                    Learn More{" "}
                                                                    <img
                                                                        src="/images/productSlider/nextarrow.svg"
                                                                        alt=""
                                                                    />
                                                                </span>{" "}
                                                            </a>
                                                        </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Link>
                                );
                            }
                        })
                    ) : (
                        <Grid item xs={12}>
                            <Typography variant="h3">No Events Found</Typography>
                        </Grid>
                    )}
                </Grid>
            </div>
        </>
    );
};

export default AllEvents;
