import React, { useEffect, useRef, useState } from "react";
import { Box, makeStyles, Button } from "@material-ui/core";
// import axios from "axios";
import ReactPlayer from "react-player";
// import { image } from "face-api.js/node_modules/@tensorflow/tfjs-core";
// import { models } from "@tensorflow/tfjs-layers";
// import { ProgressBar } from "./Progress";
import ProgressBar from "@ramonak/react-progress-bar";

const useStyle = makeStyles({
  uploadSection: {
    border: "1px solid #ffffff",
    height: "440px",
    maxWidth: "780px",
    width: "100%",
    margin: "0px",
    borderRadius: "6px",
  },
  flexCol: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadedFileSection: {
    width: "100%",
    maxWidth: "780px",
    minHeight: "440px",
    margin: "0px",
    padding: "0px",
    borderRadius: "6px",
    border: "1px solid #ffffff",
    position: "relative",
  },
  uploadedFileBox: {
    // padding: "40px",
    display: "flex",
    justifyContent: "space-between",
    "& .fileName": {
      fontSize: "20px",
    },
  },
  dialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // position: "absolute",
    // right: "0px",
    // bottom: "0px",
  },
});

const Sample = ({ model, setClick }) => {
  const classes = useStyle();
  const [samples, setSamples] = useState(model.sample);
  const [slide, setSlide] = useState(model.sample[0]);
  const [idx, setIdx] = useState(0);
  const [progress, setProgress] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  // const [res, setRes] = useState(null);
  const [processed, setProcessed] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const [similarity, setsimilarity] = useState(0);
  var incProgress;

  useEffect(() => {
    setSamples(model.sample);
    setSlide(model.sample[0]);
    setProcessed(new Set());
  }, [model]);

  useEffect(() => {
    if (progress >= 100) setProcessed(processed.add(idx));
  }, [progress]);

  useEffect(() => {
    console.log(processed);
  }, [processed]);

  function myTimer() {
    if (progress >= 100) {
      console.log("hello");
      setProcessed(processed.add(idx));
      myStopFunction();
    } else setProgress((t) => t + 20);
  }

  function myStopFunction() {
    console.log(incProgress);
    clearInterval(incProgress);
  }

  function changeSlides(n) {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 240);
    setProgress(0);
    setShowProgress(false);
    const id = idx + n;
    // setIdx(idx => idx +n)
    if (id === -1) {
      // setSlide(model.sample[samples.length - 1]);
      setIdx(samples.length - 1);
    } else if (id === samples.length) {
      // setSlide(model.sample[0]);
      setIdx(0);
    } else {
      setIdx(id);
      // setSlide(samples[id]);
    }
  }

  const urlToObject = async (img) => {
    const response = await fetch(img);
    const blob = response.blob();
    const file = new File([blob], "image.jpg", { type: "image/jpeg" });
    console.log(file);
    setClick(file);
  };

  const processSample = (idx) => {
    setShowProgress(true);
    setProgress(0);
    var timeOut = setTimeout(() => {
      console.log("stopped");
      setProcessed(processed.add(idx));
      setShowProgress(false);
      myStopFunction();
    }, 4200);
    incProgress = setInterval(myTimer, 700);
  };

  const GetSampleRes = ({ idx }) => {
    // console.log(idx)
    const ext = samples[idx][1];
    if (ext === "image") {
      return <img src={`../sample/${model.res[idx][0]}`} />;
      // return (<div className="sample-img" style={{backgroundImage: `url(../sample/${model.res[idx][0]})`}}></div>)
    }
    return (
      <ReactPlayer
        controls={true}
        volume={0}
        width="100%"
        Playsinline
        height="100%"
        loop={false}
        muted={false}
        playing={true}
        stopOnUnmount={true}
        config={{
          file: {
            attributes: {
              autoplay: false,
              controls: true,
              loop: false,
              muted: true,
            },
          },
        }}
        url={model.res[idx][0]}
      />
    );
  };

  const SetSample = ({ idx }) => {
    const ext = samples[idx][1];
    if (ext === "image") {
      return <img src={`../sample/${samples[idx][0]}`} />;
      // return (<div className="sample-img" style={{backgroundImage: `url(../sample/${samples[idx][0]})`}}></div>)
    }
    return (
      <ReactPlayer
        controls={true}
        volume={0}
        width="100%"
        Playsinline
        height="100%"
        loop={false}
        muted={false}
        playing={false}
        stopOnUnmount={true}
        config={{
          file: {
            attributes: {
              autoplay: false,
              controls: true,
              loop: false,
              muted: true,
            },
          },
        }}
        url={samples[idx][0]}   
      />
    );
  };

  return (
    <>
      {!showProgress ? (
        <>
          {" "}
          <div className="zoomouter">
            <div className="slideshow-container">
              <div className="mySlides">
                {!loading && (
                  <>
                    {processed.has(idx) ? (
                      <>
                        <GetSampleRes idx={idx} />
                      </>
                    ) : (
                      <>
                        <SetSample idx={idx} />
                      </>
                    )}{" "}
                  </>
                )}
              </div>

              <button className="prev" onClick={() => changeSlides(-1)}>
                <img src="../images/sliderarrow-left.svg" alt="" />
              </button>
              <button className="next" onClick={() => changeSlides(1)}>
                <img src="../images/sliderarrow-right.svg" alt="" />
              </button>
            </div>
          </div>
          {!processed.has(idx) ? (
            <Box className={classes.dialog}>
              <Button
                variant="contained"
                className="btn btnsuploads processbtn"
                onClick={() => {
                  // setProgress(true)
                  processSample(idx);
                }}
              >
                Process
              </Button>
            </Box>
          ) : (
            <Box className={classes.dialog} >
              <Button
                variant="contained"
                className="btn btnsuploads processbtn"
                style={{backgroundColor: '#24AC48'}}
              >
                Result
              </Button>
            </Box>
          )}
        </>
      ) : (
        <>
          <div className="zoomouter">
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ProgressBar completed={progress} width="200px" />
            </div>
          </div>
          <Box className={classes.dialog}>
            <Button
              disabled
              variant="contained"
              className="btn btnsuploads processbtn"
              onClick={() => {
                // setProgress(true)
                processSample(idx);
              }}
            >
              Process
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default Sample;
