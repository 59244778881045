import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Customers = () => {

  const portfolioFilters = document.querySelectorAll('.customer-filter-main .filter-top li');
  const portfolioFiltersArray = Array.from(portfolioFilters);
  const portfolioItems = document.querySelectorAll('.filter-bottom .customer-item');
  const portfolioItemsArray = Array.from(portfolioItems);
  console.log(portfolioItemsArray);

  portfolioFiltersArray.forEach((filterItem) => {
    filterItem.addEventListener('click', () => {
      portfolioFiltersArray.forEach((filterItem) => {
        filterItem.classList.remove('active');
      });
      filterItem.classList.add('active');
      let filterValue = filterItem.getAttribute('data-filter');
      portfolioItemsArray.forEach((item) => {
        if (item.getAttribute('data-category') === filterValue || filterValue === 'all') {
          item.classList.add('show');
          item.classList.remove('hide');
        } else {
          item.classList.remove('show');
          item.classList.add('hide');
        }
      });
    });
  }
  );

  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = "Skylark Labs: Customers";
  }, []);

  return (
    <>
      <div className="customers-top-banner-wrap">
        <div className="container">
          <div className="customers-top-banner">
            <h1>Customers</h1>
            <p>
              Pioneering cutting-edge AI solutions, we stand as the trusted partner for industry leaders.
              Our unwavering commitment to excellence ensures the seamless delivery of high-quality services,
              precisely tailored to meet the discerning needs of our valued customers.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="customer-filter-main">
          <div className="filter-top">
            <ul>
              <li data-filter="all" className="active">
                <a>All</a>{" "}
              </li>{" "}
              <li data-filter="1">
                <a>Defense - Government</a>{" "}
              </li>{" "}
              <li data-filter="2">
                <a>Defense - Industry</a>{" "}
              </li>{" "}
              <li data-filter="3">
                <a>Commercial</a>{" "}
              </li>
            </ul>
          </div>
        </div>

        <div className="meet-ourcustomers customers-landing-collage container" style={{
          padding: "0 3rem",
        }}>
          <div className="container">
            <Grid container className="filter-bottom" spacing={3}>
              <Grid item className="customer-item" xs={12} sm={6} md={4} lg={4} data-category="1">
                <div className="collage-part">
                  <Link to="/indian-army">
                    <div className="collage parkersburg" style={{
                      backgroundImage: "url('images/Customers/Indian-Army/indian army overview.jpg')",

                    }}>
                      <div className="logo-container">
                        <img src="images/productSlider/indian-army.png" alt="" />
                      </div>
                      <p className="quote">
                      The Indian Army, an integral part of India's defense forces, is renowned for its versatility and adaptability across diverse operational scenarios.
                      </p>
                      <div className="button-wrapper">
                        <div className="button-circle  azure active"></div>
                      </div>
                    </div>
                  </Link>
                </div>
              </Grid>

              <Grid item className="customer-item" xs={12} sm={6} md={4} lg={4} data-category="1">
                <div className="collage-part">
                  <Link to="/sashastra-seema-bal">
                    <div className="collage parkersburg" style={{
                      backgroundImage: "url('images/Customers/Sashastra-Seema-Bal/-ssboverview.png')",

                    }}>
                      <div className="logo-container">
                        <img src="images/productSlider/ssb-addbg.png" alt="" />
                      </div>
                      <p className="quote">
                      The Sashastra Seema Bal (SSB) is a distinguished border guarding force of India, playing a crucial role in safeguarding the nation's frontiers.
                      </p>
                      <div className="button-wrapper">
                        <div className="button-circle  azure active"></div>
                      </div>
                    </div>
                  </Link>
                </div>
              </Grid>

              <Grid item className="customer-item" xs={12} sm={6} md={4} lg={4} data-category="1">
                <div className="collage-part">
                  <Link to="/indian-navy">
                    <div className="collage parkersburg" style={{
                      backgroundImage: "url('images/Customers/Indian-Navy/indian navy overview.png')",

                    }}>
                      <div className="logo-container">
                        <img src="images/productSlider/indian_navy.png" alt="" />
                      </div>
                      <p className="quote">
                      The Indian Navy, a pivotal force in India's defense, excels in maritime and aerial security. Commanding advanced naval and aerial assets, it ensures the nation's sovereignty over vast sea areas and airspace.
                      </p>
                      <div className="button-wrapper">
                        <div className="button-circle  azure active"></div>
                      </div>
                    </div>
                  </Link>
                </div>
              </Grid>

              <Grid item className="customer-item" xs={12} sm={6} md={4} lg={4} data-category="2">
                <div className="collage-part">
                  <Link to="/collins-aerospace">
                    <div className="collage parkersburg" style={{
                      backgroundImage: "url('images/industry.jpg')",

                    }}>
                      <div className="logo-container">
                        <img src="images/productSlider/collinsaerospace.png" alt="" />
                      </div>
                      <p className="quote">
                      Collins Aerospace stands at the forefront of innovation in the global aerospace and defense industry.
                      </p>
                      <div className="button-wrapper">
                        <div className="button-circle  azure active"></div>
                      </div>
                    </div>
                  </Link>
                </div>
              </Grid>

              <Grid item className="customer-item" xs={12} sm={6} md={4} lg={4} data-category="2">
                <div className="collage-part">
                  <Link to="/ideaforge">
                    <div className="collage parkersburg" style={{
                      backgroundImage: "url('images/Customers/Ideaforge/ideaforge overview.jpg')",

                    }}>
                      <div className="logo-container">
                        <img src="images/productSlider/Ideaforge.png" alt="" />
                      </div>
                      <p className="quote">
                      IdeaForge, a pioneer in the UAV (Unmanned Aerial Vehicle) industry, is making strides toward integrating Artificial Intelligence (AI) into its innovative drone solutions.
                      </p>
                      <div className="button-wrapper">
                        <div className="button-circle  azure active"></div>
                      </div>
                    </div>
                  </Link>
                </div>
              </Grid>

              <Grid item className="customer-item" xs={12} sm={6} md={4} lg={4} data-category="2">
                <div className="collage-part">
                  <Link to="/mantech">
                    <div className="collage parkersburg" style={{
                      backgroundImage: "url('images/Customers/ManTech/mantech overview.png')",

                    }}>
                      <div className="logo-container">
                        <img src="images/productSlider/mantech.png" alt="" />
                      </div>
                      <p className="quote">
                      ManTech, a distinguished provider of technological solutions for defense and security, confronts intricate challenges in maritime operations for the US Navy and Indian Navy.
                      </p>
                      <div className="button-wrapper">
                        <div className="button-circle  azure active"></div>
                      </div>
                    </div>
                  </Link>
                </div>
              </Grid>

              <Grid item className="customer-item" xs={12} sm={6} md={4} lg={4} data-category="3">
                <div className="collage-part">
                  <Link to="/mercedes">
                    <div className="collage parkersburg" style={{
                      backgroundImage: "url('images/Customers/Mercedes/mercedes_overview.png')",

                    }}>
                      <div className="logo-container">
                        <img src="images/productSlider/mercedesbenz.png" alt="" />
                      </div>
                      <p className="quote">
                       

Mercedes, a global leader in the automotive industry, is renowned for its commitment to excellence and innovation.
                      </p>
                      <div className="button-wrapper">
                        <div className="button-circle  azure active"></div>
                      </div>
                    </div>
                  </Link>
                </div>
              </Grid>

              <Grid item className="customer-item" xs={12} sm={6} md={4} lg={4} data-category="3">
                <div className="collage-part">
                  <Link to="/jones-lang-laSalle">
                    <div className="collage parkersburg" style={{
                      backgroundImage: "url('images/Customers/JLL/-JLL overview.jpg')",

                    }}>
                      <div className="logo-container">
                        <img src="images/productSlider/jll.png" alt="" />
                      </div>
                      <p className="quote">
                      JLL, a renowned leader in real estate services, is embracing the transformative power of Artificial Intelligence (AI) in collaboration with Skylark Labs.
                      </p>
                      <div className="button-wrapper">
                        <div className="button-circle  azure active"></div>
                      </div>
                    </div>
                  </Link>
                </div>
              </Grid>

              <Grid item className="customer-item" xs={12} sm={6} md={4} lg={4} data-category="3">
                <div className="collage-part">
                  <Link to="/artrabia">
                    <div className="collage parkersburg" style={{
                      backgroundImage: "url('images/Customers/Artrabia/artrabia-overview.jpeg')",

                    }}>
                      <div className="logo-container">
                        <img src="images/productSlider/ATRABIA_FULL_LOGO.png" alt="" />
                      </div>
                      <p className="quote">
                      Artrabia, a leader in the Drone industry, is embracing the cutting-edge technology of drones for infrastructure monitoring on construction sites.
                      </p>
                      <div className="button-wrapper">
                        <div className="button-circle  azure active"></div>
                      </div>
                    </div>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default Customers;
