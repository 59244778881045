import React from 'react';
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import { Rings } from 'react-loader-spinner';

const Spinner = () => {
    return (
        // <Rings color="#00BFFF" height={80} width={80} />
        <div className="custom-spinner">
            <div class="loader"></div>
        </div>
    )
}

export default Spinner;
