import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const Privacyus = () => {
    const [expanded, setExpanded] = React.useState("panel2");
    const navOption1 = React.useRef(null);
    const navOption2 = React.useRef(null);
    const navOption3 = React.useRef(null);
    const navOption4 = React.useRef(null);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    useEffect(() => {
        const title = document.getElementById("mainTitle");
        title.innerText = "Skylark Labs Privacy Policy For US"
        return () => {
            console.log('title changed');
        }
    }, []);
    return (
        <>
       <div className="privacybanner">
       <div className="container">
       <div className="dismanbanner">
        <div className="privacyimg">
        <img src="../images/privacy.png" alt=""/>
        </div>
              <h1>Skylark Labs Privacy Policy</h1>

              <ul>
                <li ><a href="/privacy">Privacy</a> </li>
                <li className="active"><a href="/privacy-us">US Privacy </a> </li>
                <li><a href="/privacy-canada">Canada Privacy</a> </li>
                <li><a href="/privacy-australia">Australia Privacy</a> </li>
                <li><a href="/privacy-europe">Europe Privacy</a> </li>
               
                </ul>
              </div>
            </div>
        
       
        
      </div>

            <section className="termsprivacy">
                <div className="container">
                <div className="termsprivacycontent">
       <h2>Laws Regarding Face Recognition Technology (US only)</h2>
       <h3>US: Laws regarding FRT</h3>
       <p>In the US, there is an ongoing trend of Biometric Laws that are sometimes in addition to the existing primary Privacy Legislations. There are certain federal laws, along with state laws, and city laws that regulate the aspect of privacy as well as Facial Recognition Technology (FRT). There are also Bills that are in motion to regulate the usage of FRT. Also, discussions and debates are going on regarding the impact of FRT technology on privacy, and the laws regarding FRT may see drastic changes in coming months and years. </p>
       <h5>US Federal</h5>
       <p>A legislative instrument has been introduced in the Senate that would ban federal spending on FRT. This is called Facial Recognition and Biometric Technology Moratorium Act of 2020.</p>
       <ul>
         <li> The Bill prohibits biometric surveillance by the Federal Government without explicit statutory authorization and to withhold certain Federal public safety grants from State and local governments that engage in biometric surveillance</li> It imposes limits on the use of biometric surveillance systems, such as facial recognition systems, by federal and state government entities <li>A federal agency or official may not in an official capacity acquire, possess, or use in the United States any such system or information obtained by such a system unless Congress passes an act that specifically authorises such a use. Such an act of Congress must contain certain provisions, such as provisions naming the specific authorised entity and auditing requirements relating to the system. </li>
         <li> Information obtained in violation of this bill shall not be admissible by the federal government in any proceeding or investigation, except in a proceeding alleging a violation of this bill </li>
         <li> An individual aggrieved by a violation of these restrictions shall have the right to sue. Any state officer authorised to sue on behalf of the state’s residents shall also have the right to sue on behalf of the state’s aggrieved residents. </li>
       </ul>
       <p>A state or local government unit shall not receive certain federal law enforcement grants unless the government unit complies with a law or policy that is substantially similar to this bill’s restrictions on acquiring and using biometric surveillance systems. No federal law banning private usage </p>
       <h5>States</h5>
       <p>State regulations in the US cannot regulate the federal usage, but can control the usage by entities under the state's government. </p>
       <h5>California</h5>
       <p>
         <strong>AB-1215 Law enforcement: facial recognition and other biometric surveillance.</strong>
         <br></br> Moratorium for use of FRT on Body Cams for a period of 3 years, that ends in 2023. Though the use in body cams by police officers has been prohibited temporarily, nothing has been said about the usage in stationary cameras.
       </p>
       <p> Private usage of FRT is allowed under the law, as the law is narrow to any law enforcement agency of California or California municipalities. </p>
       <p> No specific law on ban regarding private usage of FRT. </p>
       <p> AB 2261 AB 2261 would enable companies—and some government agencies—to use facial recognition technology to track people in the workplace without recourse, even if workers were notified (Bill Died) </p>
       <p> CCPA will govern the usage of FRT for businesses. Usage is allowed subject to compliance under the law. </p>

       <h3>Recommendation: No for govt. Use</h3>
       <h5>San Francisco</h5>
       <p>San Francisco has released an Ordinance stopping the use of FRT by City Departments. Under the SEC. 19B.2. (d)[3] of the shall  be unlawful for any Department to obtain, retain, access, or use:
</p>
        <ol>
            <li>any Face Recognition Technology on City-issued software or a City-issued product or device; </li>
            <li>any information obtained from Face Recognition Technology on City-issued software or a City-issued product or device. There are certain exceptions to this bar on use of FRT though namely.
        </li> 
        </ol>
        <ul>
            <li>
            The Department does not request or solicit its receipt, access to, or use of such information obtained from Face Recognition Technology; and

            </li>
            <li>
            The Department logs such receipt, access to, or use in its Annual Surveillance Report.
            </li>
        </ul>
        <p>Further, City Department can possess Face Recognition Technology on City-issued software or a City-issued product or device, provided that:
</p>
<ul>
    <li> The Face Recognition Technology is a stock, manufacturer-installed capability, bundled with software or stored on a product or device, and that the functions unrelated to Face Recognition Technology are necessary to perform essential City functions;
</li>
<li>  The software, product, or device was not acquired for the purpose of performing the Face Recognition Technology function.
</li>
<li> The Face Recognition Technology cannot be deleted from the software, product, or device; and
</li>
<li>The Department does not use the Face Recognition Technology.
</li>
</ul>
<p><strong>This, while essentially allowing FRT, does not allow the same for General Public Surveillance, and only allows it for limited purposes that does not involve actual use of FRT, but only if the same is an actual part of some other software or product.
</strong></p>
<p>No specific law on ban regarding private usage of FRT
</p>
<h5>Oakland</h5>
<p>Oakland has banned the use of FRT by city agencies, including the police department[6]. It defines "Biometric Surveillance Technology" as any computer software that uses face recognition technology or other remote biometric recognition in real time or on a recording or photograph and says , it shall be unlawful for the City or any City staff to obtain, retain, request, access, or use Biometric Surveillance Technology. The restriction does not apply to regular (Non Biometric) surveillance.
</p>
<p><strong>No specific law on ban regarding private usage of FRT</strong></p>
<h5>Santa Clara</h5>
<p>Santa Clara does not ban the use of FRT, but severely limits its use. The FRT can only be used in a passive manner and not an active manner and during a criminal investigation to assist in the identification of unknown persons associated with a specific criminal investigation. Only the personnel in the Sheriff's office are allowed to use it.
</p>
<p><strong>FRT cannot be used for personal or non-law enforcement purposes.</strong></p>
<h5>Berkley</h5>
<p>Permission needed by the city manager from the City Council for usage of Surveillance Technology or for Entering into an agreement with a non-City entity to acquire, share or otherwise use Surveillance Technology or the information it provides. [8] But the usage of FRT is banned for Government use.
</p>
<p><strong>No specific law on ban regarding private usage of FRT</strong></p>
<h5>New York</h5>
<p>Under the NY Biometric Identifier Information Law, there are certain curbs on the sale of biometric data but commercial establishments can use the Biometric Technologies (Including FRT), provided a clear and prominent notice is given that such technology is being used. It is unlawful to gain any profit from the data collected this way though.
</p>
<p><strong>Commercial Establishments include a place of entertainment, a retail store, or a food and drink establishment. (Includes Stadiums)
</strong></p>
<p>Can also be used in residential buildings under TDPA, subject to consent.
</p>
<p>The law though does not apply to the Government Agencies, employees or agents. 
Though in 2020, the NY City Council voted to enact the Public Oversight of Surveillance Technology (POST) Act which will mandate the police department to disclose their usage of surveillance technologies. 
</p>

<h5>Florida</h5>
<p>No laws governing usage of FRT.</p>
<h5>Miami</h5>
<p>Provides for rules regarding official use of FRT. The usage of FRT will not be proactive, and cannot be used for proactive monitoring of persons in real time even by private entities. 

</p>
<h5>Massachusetts</h5>
<p>Any law enforcement agency performing or requesting a facial recognition search using facial recognition technology shall only do so through a written request submitted to the registrar of motor vehicles, the department of state police or the Federal Bureau of Investigation. Law technically allows passive FRT. * Also a bill is in place that seeks to ban the usage of FRT in Massachusetts.

</p>
<p>Seven municipalities in Massachusetts have banned face surveillance in local government: Boston, Brookline, Cambridge, Easthampton, Northampton, Springfield, and Somerville.

</p>

<h5>Boston</h5>
<p>Use of FRT is banned. City officials are prohibited from obtaining and using it, or asking for its usage through any third party.

</p>
<p><strong>There are no explicit rules regarding ban on private use of FRT</strong></p>

<h5>Somerville</h5>
<p>The use of FRT is banned in Somerville till the time transparent regulations come up in this regard. This applies to every government entity in Somerville.

</p>

<p>There are no explicit rules regarding ban on private use of FRT
</p>
<h3>Oregon</h3>
<h5>Portland</h5>
<p>The city of Portland has banned the usage of Face Recognition Technologies by Private Entity in Places of Public Accommodation within the boundaries of the City of Portland. It has also banned the usage of FRT by the city bureau.
</p>
<p>A public accommodation is a private business or organisation that offers goods or services to the public
</p>
<h5>Illinois</h5>
<p>Under Illinois’ Biometric Information Privacy Act (“BIPA”) is considered the most stringent. Under BIPA, a private entity cannot collect or store facial template data without first providing notice, obtaining written consent, and making certain disclosures. If consent is obtained, FRT can be used in private property, company premises, stadiums and other venues where the consent for the same is obtained. Can be used in the Stadium
</p>
<h5>Texas</h5>
<p>Legal for commercial use after consent
 
 Usage of FRT for Airport, Stadiums etc depends on case to case basis. Most states have not banned the usage of FRT for private purpose.
 Technologies other than FRT
 </p>
 <p>
 <strong>Weapon Detection:</strong>  No explicit laws found ascertaining any ban on the usage of technology as there is no involvement of biometric data. Further confirmation needed for all US States.
 </p>
 <p>
 <strong>Violence Detection:</strong>  No explicit laws found ascertaining any ban on the usage of technology as there is no involvement of biometric data. Further confirmation needed for all US States. 

 </p>
 <p>Need to confirm
</p>
<p>
 <strong>Gait Detection-</strong> Not Covered </p>

 <p>NOT COVERED </p>
 <ul>
    <li>Rules regarding FRT for rest of the US States and Cities</li>
    <li>Laws regarding AI Automation, if any</li>
    <li>Case Laws, Legislative Debates</li>
 </ul>
 <h3>
 PLEASE GET IN TOUCH WITH US

       </h3>
       <p>Email us at<a href="mailto:info@skylarklabs.ai">info@skylarklabs.ai</a> if you have any questions about this Privacy Statement, the procedures we use to manage the information collected through the Website, or if you would like to modify or delete your information from our records.
</p>
     </div>

               
                </div>
            </section>
        </>
    );
};

export default Privacyus;
