import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AppBar from "@material-ui/core/AppBar";
import MediaCoverage from "home/sections/MediaCoverage";

const IntellectualProperty = () => {
  const [expanded, setExpanded] = React.useState("");
  const navOption1 = React.useRef(null);
  const navOption2 = React.useRef(null);
  const navOption3 = React.useRef(null);
  const navOption4 = React.useRef(null);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = "Skylark Labs: Intellectual Property"
    return () => {
      console.log('title changed');
    }
  }, []);
  return (
    <>
      <div className="platform_banner aboutouter">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner solutionbenner_dis aboutbanner">
              <h1>Intellectual Property</h1>
              <p>
                Skylark labs is committed to advancing ai research by publishing their work
              </p>
            </div>
          </div>
        </div>
        <svg
          className="bottomShape"
          width="100%"
          // height="167"
          viewBox="0 0 1920 167"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0.199997V166.9H1920V0.199997C1616 91.3 1293.7 140.3 960 140.3C626.3 140.3 304 91.3 0 0.199997Z"
            fill="#10101B"
          />
        </svg>
        <img src="../images/updated_banner_images/Intellectual_Property.webp" alt="" />
      </div>

      <section className="aboutpage aboutuone_glass">
        <div className="container">

          <div className="aboutpagetitle">
            <h2>Intellectual Property</h2>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="aboutdis" style={{
                fontSize: '1.5rem'
              }}>
                Intellectual Property: Our brain-inspired proprietary AI models are formed by combining unsupervised and supervised modules. This hybrid architecture results in memory-efficient models that can: (i) train with limited labeled datasets and recognize the threats in (ii) real-time with (iii) on-edge deployment. Continual Learning: AI systems are regularly exposed to news unknown events. Our solutions can continuously learn new events without forgetting learned knowledge.
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="officeimg">
                <img src="../images/sphere-art-modern-art.png" alt="" />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section
        className="intellectualproperty aboutcenter_glass"
        id="intellectualproperty"
      >
        <div className="container">
          <div className="aboutpagetitle">
            <h2>Patents</h2>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="patents">
                <div className="patentsimg">
                  <div className="patents_dis">
                    <p>
                      Continuously Evolving and Interactive Disguised Face
                      Identification (DFI) with Facial Key Points using Scatter
                      Net Hybrid Deep Learning (SHDL) Network
                    </p>
                    <span>App No. 62 / 898,528</span>
                  </div>
                  <div className="imgblock">
                    <img src="../images/patents_01.png" alt="" />
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="patents">
                <div className="patentsimg">
                  <div className="patents_dis">
                    <p>
                      Real-time Aerial Suspicious Analysis (ASANA) System and Method for Identification of Suspicious individuals in public areas
                    </p>
                    <span>App No. 62/861,326</span>
                  </div>
                  <div className="imgblock">
                    <img src="../images/patents_02.png" alt="" />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      {/* <section className="intellectualproperty">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="laboratory_img">
                <img src="../images/about-iaboratory.png" alt="" />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className="intellectual_dis">
                <div className="aboutpagetitle">
                  <h2>laboratory</h2>
                </div>
                <p>
                  Team brings together experts from academia, engineering, and
                  business to make AI practical for enterprises. Our team
                  includes computer science faculty at Stanford, Brown,
                  Washington, and Wisconsin and world-class alumni from Google,
                  Facebook, NVIDIA, Lyft, Twilio, Microsoft, and more.
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </section> */}
    </>
  );
};

export default IntellectualProperty;
