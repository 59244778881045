import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import videobanner from "../videofiles/strike.mp4";
import etosvideo from "../videofiles/etos-page-banner.mp4";
import ariesvideo from "../videofiles/aries-banner.mp4";
import mirasvideo from "../videofiles/miras-main-banner.mp4";
import satellitevideo from "../videofiles/dros-banner.mp4";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Etostestimonial from "home/sections/Etos-testimonial";
import Sensorfusion from "./productslider/Sensorfusion";
import Selflearn from "./productslider/Selflearn";
import Alertsproactive from "./productslider/Alertsproactive";
import Streamlined from "./productslider/Streamlined";
import Productlogo from "./productslider/Productlogo";
import RoundedTabSwitcher from "./components/RoundedTabSwitcher";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import ImageSlider from "./components/ImageSlider";
const Kepler = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Product Page:  Kepler is a seamless, plug-and-play platform designed`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [activeTab, setActiveTab] = React.useState(0);
  const [expanded, setExpanded] = React.useState("");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  let otherCases = [
    {
      title: "Use Cases",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/productSlider/usecases.png",
    },

    {
      title: "News",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/productSlider/skylarklabs-news.png",
    },

    {
      title: "Events",
      desc: "The incidents happened in the locality where robbers used to come at night and loot the jewellery shops.",
      url: "ds-jewellers",
      img: "images/productSlider/swavlamban-delhi.png",
    },

  ];

  return (
    <>
      <div className="platform_banner productbanner newproductbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>
                KEPLER   {" "}
              </h1>
              <p>  A plug-and-play platform designed to ingest sensor data, offering improved situational awareness for military operations.</p>
              <div className="subtages">
                <a href="bros">Border Reconnaissance</a>  <a href="aries">Aerial Reconnaissance </a>
                <a href="miras">Marine Reconnaissance </a>
                <a href="dros">Drone Reconnaissance </a>
                {" "}
              </div>
            </div>
          </div>
        </div>

        <div className="imgcenter bannerimgblock ">
          <ReactPlayer
            controls={true}
            playsinline
            volume={0}
            width="100%"
            height="100%"
            loop={true}
            muted={false}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url="https://d1nbq27kwttg28.cloudfront.net/blog/Newhomepage.mp4"
          />
          
        </div>
      </div>
      {/* 
      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>   KEPLER</h2>
            <p>
              A plug-and-play platform designed to ingest sensor data, offering improved situational awareness for military operations.
            </p>
          </div>
        </div>
      </div> */}

      <section className="productoverviewblock section-productoverviewblock pt-0 pb-3">
        <section className="productlogosouter ">
          <div className="container">
            <Productlogo />
          </div>
        </section>

        <section className="productoverviewblock section-productoverviewblock section-productoverviewblockfirstbox">
          <div className="container">
            <Grid container spacing={3} className="kepler-about-container">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoleftblock fistblock bordernone">
                  <div>
                    {" "}
                    <img
                      src="images/iris bg head.png"
                      alt=""
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 pb-0">
                      <span className="spanfirstword">About </span> Kepler
                    </h2>
                    <p className="pt-0">
                      Kepler is a seamless, plug-and-play platform designed to integrate data from multiple sensors, delivering actionable insights for emerging threats. It proactively generates alerts for both known and novel threats, empowering users to preemptively address potential dangers. Our platform is characterized by its continuous improvement and reliability, ensuring uninterrupted operation with no downtime. Kepler is specifically tailored for the tactical community, serving the Army, Navy, and Air Force by detecting threats across land, air, and sea.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
      </section>

      <section
        ref={section1}
        className="product-featured section-productoverviewblock Featuredstikeblock "
      >
        <div className="container pb-5 mb-5">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="">
                <h2>
                  <span className="spanfirstword">Featured</span> Kepler
                  Configurations
                </h2>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="productfeatured">
                <a href="/etos">
                  <h5>
                  Emerging Threat & Observation System

                  </h5>
                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      playsinline
                      volume={0}
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url="https://d1nbq27kwttg28.cloudfront.net/blog/strike.383b2ae4.mp4"

                    />
                    <div className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="productfeatured">
                <a href="aries">
                  <h5>
                    Aerial Reconnaissance and <br></br> Elimination System
                  </h5>

                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      playsinline
                      volume={0}
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url={ariesvideo}
                    />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="productfeatured">
                <a href="miras">
                  <h5>
                    Marine Reconnaissance <br></br>System
                  </h5>
                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      playsinline
                      volume={0}
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url={mirasvideo}
                    />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="productfeatured">
                <a href="dros">
                  <h5>
                    Drone Reconnaissance and <br></br> Observation System
                  </h5>

                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      playsinline
                      volume={0}
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url={satellitevideo}
                    />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="dashboardfeatures section-productoverviewblock">
        <div className="container plateformblock aboutbgblock">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="imgroundbox">
                <Sensorfusion />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="centerboxblock">
                <div className="dashboardfeatures-list pl-5">
                  <h4>
                    <span className="spanfirstword">Sensor Fusion</span>{" "}
                    Integrated Multisensory Solutions
                  </h4>
                  {/* <div className="plateformtabs">
               <a href="#/">Image</a>
               <a href="#/" className="active">Maping</a>
               <a href="#/">Text</a>
               <a href="#/">Audio</a>


               </div> */}
                  <p>
                    Our Sensor Fusion seamlessly integrates optical, thermal,
                    radar, and drone sensor data in real-time for precise object
                    detection, tracking, and classification. If one sensor
                    misses an event, others seamlessly pick it up.
                  </p>

                  <ul>
                    <li>
                      <div className="features-box">
                        <h6>Precision and Reliability</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box">
                        <h6>Instant Decision-Making</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box">
                        <h6>Unmatched Safety with Redundancy</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box">
                        <h6>Transparent Decision-Making</h6>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="imgplatformsmainbg">
            <img src="images/productSlider/blockbg-01.svg" alt="" />
          </div>
        </div>
      </section>

      <section className="dashboardfeatures dashboardfeaturesbg-two section-productoverviewblock">
        <div className="container plateformblock">
          <div className="imgplatformsbg imgplatformsbgleft">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="centerboxblock pr-0">
                <div className="dashboardfeatures-list  pl-5">
                  <h4>
                    <span className="spanfirstword">Proactive</span> & Reactive
                    Alerts{" "}
                  </h4>
                  <RoundedTabSwitcher
                    getActiveTab={(activeTab) => setActiveTab(activeTab)}
                    hideImage={true}
                    imagePosition="left"
                    tabs={[
                      {
                        id: 0,
                        text: "Image",
                        content: (
                          <>
                            <p>
                              Empower your classified missions with our
                              user-friendly platform, offering secure model adjustments for
                              seamless data search across Twitter, Facebook, and
                              YouTube.
                            </p>
                          </>
                        ),
                        image: "",
                      },
                      {
                        id: 1,
                        text: "Maping",
                        content: (
                          <>
                            <p>
                              Empower your classified missions with our
                              user-friendly platform, offering custom query
                              development and secure model adjustments for
                              seamless data search across Twitter, Facebook, and
                              YouTube.
                            </p>
                          </>
                        ),
                        image: "",
                      },


                    ]}
                  />

                  <ul>
                    <li>
                      <div className="features-box  ">
                        <h6>Early Warning with Precursor Insights</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box  ">
                        <h6>Enhanced Accuracy with Multi-Sensor Data</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box ">
                        <h6>Informed Responses with Real-Time Awareness</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box ">
                        <h6>Empowered Risk Mitigation</h6>
                        {/* <p>Enables proactive steps to minimize impacts.</p> */}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ImageSlider slideIndex={activeTab} images={[
                "images/productSlider/proactive-alert.png",
                "images/productSlider/reactive-alert.png"
              ]} />
            </Grid>
          </Grid>
          <div className="imgplatformsmainbg">
            <img src="images/productSlider/blockbg-01.svg" alt="" />
          </div>
        </div>
      </section>


      <section className="dashboardfeatures section-productoverviewblock">
        <div className="container plateformblock aboutbgblock">
          <div className="imgplatformsbg imgplatformsbgleft">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Selflearn />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="dashboardfeatures-list  pl-5">
                <h4>
                  <span className="spanfirstword">Self-Learn</span> Various New
                  Threats Automatically{" "}
                </h4>
                <p>
                  Our system autonomously analyzes threat escalation patterns
                  from multi-sensor data using advanced AI. It undergoes
                  self-learning based on occurrence patterns and distinctive
                  threat signatures, ensuring a dynamic response to emerging
                  threats.
                </p>

                <ul>
                  <li>
                    <div className="features-box  ">
                      <h6>Auto Training</h6>
                    </div>
                  </li>
                  <li>
                    <div className="features-box ">
                      <h6>Self-label</h6>
                    </div>
                  </li>
                  <li>
                    <div className="features-box ">
                      <h6>Signature Matching</h6>
                    </div>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
          <div className="imgplatformsmainbg">
            <img src="images/productSlider/blockbg-01.svg" alt="" />
          </div>
        </div>
      </section>

      <section className="configurations">
        <div className="container">
          <div className="">
            <h3>IRISINSIGHT: Pioneering Threat Awareness Features</h3>
            <p>
              IRISINSIGHT presents a suite of groundbreaking features that elevate
              situational awareness to new heights, ensuring you're consistently
              ahead in threat detection and management.
            </p>
          </div>

          <div className="boxouterproduct">
            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>BVLOS Threat Detection</h4>
              <p>
                Detect threats beyond visual line of sight (BVLOS) across all
                domains, ensuring comprehensive security in any operational
                context.
              </p>
            </div>
            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Real-time Processing</h4>
              <p>
                Our solutions are built on top of computationally efficient
                algorithms, allowing us to process Data from multiple sensors in
                real time.
              </p>
            </div>

            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Hardware Agnostic</h4>
              <p>
                Our solutions seamlessly integrate with a wide range of sensors,
                without the need for specific hardware specifications.
              </p>
            </div>

            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Economical & Configurable</h4>
              <p>
                Our solution adapts to existing hardware with low compute
                demands and simple configuration.
              </p>
            </div>

            {/* <div className="boxouterproductItem">
            <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt=""/> </div>

              <h4>Stress Free Environment</h4>
              <p>
              Receiving only pertinent alerts relieves soldiers from constant video wall monitoring, allowing them to focus when an audio-visual alert is triggered.
              </p>
            </div> */}

            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Assisted Border Planning</h4>
              <p>
                Our platform identifies areas with recurring alerts, aiding in
                strategic troop deployment and fortification of perimeter
                infrastructure.
              </p>
            </div>

            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Efficient On-Ground Communication</h4>
              <p>
                Our system offers a comprehensive map view, enabling precise
                threat tracking from outer to internal perimeters.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="caseList readmorecsases ">
        <div className="container">
          <div className="platformCamera_dis">
            <h2>Read More Cases</h2>
          </div>

          <div className="industriesListblock pt-5">
            <ul>
              {otherCases.map((ele, idx) => (
                <li>
                  <a href="#contact" className="industriesListcol">
                    <div className="solutionListimg">
                      <img src={ele.img} alt="" />
                    </div>
                    <div className="industriesListcoltext">
                      <h4>{ele.title} </h4>
                      <p>{ele.desc}</p>
                      <div className="quicklinks casesquicklinks">
                        {" "}
                        View Details{" "}
                        <img src="images/right-arrow.png" alt="" />{" "}
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      {/* <Etostestimonial /> */}
    </>
  );
};

export default Kepler;
