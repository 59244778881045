import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";
import { useParams } from 'react-router-dom';

const SpecificNews = (props) => {
    const params = useParams();
    const [newsData, setNewsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const options = { year: "numeric", month: "short", day: "numeric" };

    useEffect(() => {
        fetch(`https://prodserver.skylarklabs.ai/skylarklabsblog/article-detail/${params.newsId}/`)
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                setNewsData(data);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, [params.newsId]);

    useEffect(() => {
        if (newsData) {
            const title = document.getElementById("mainTitle");
            title.innerText = "Skylark - Blog - " + (newsData["title"] ? newsData["title"] : "No Blog Found");

            // if (newsData["title"]) {
            //     var h3Elements = document.getElementsByTagName('h3');

            //     for (var i = 0; i < h3Elements.length; i++) {
            //         if (h3Elements[i].innerText.trim() === 'Overview') {
            //             h3Elements[i].id = 'blog-overview';
            //         }
            //         else if (h3Elements[i].innerText.trim() === 'Problem') {
            //             h3Elements[i].id = 'blog-problem';
            //         }
            //         else if (h3Elements[i].innerText.trim() === 'Solution') {
            //             h3Elements[i].id = 'blog-solution';
            //         }
            //         else if (h3Elements[i].innerText.trim() === 'Result') {
            //             h3Elements[i].id = 'blog-result';
            //         }
            //     }

            //     var imgElements = document.getElementsByTagName('img');
            //     for (var i = 0; i < imgElements.length; i++) {
            //         var parentElement = imgElements[i].parentNode;
            //         if (parentElement.tagName.toLowerCase() === 'strong') {
            //             parentElement.classList.add('images-center');
            //         }
            //     }
            // }
        }
    }, [newsData]);

    const scrollToSection = (id) => {
        var element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
    };

    const rendernewsData = () => {
        return (
            <>
                <div className="bloglanding bloglistingnew">
                    <div className="bloglanding-subblock">
                        <img src="../images/productSlider/gradient-circle.svg" alt="" className="uk-blur-large leftimg rightimgblur-img" />
                        <img src="../images/productSlider/gradient-circle.svg" alt="" className="uk-blur-large rightimg leftimgblur-img" />
                    </div>
                    <div className="bloglanding-subblock bloglanding-subblocktwo">
                        <div className="topgradint"></div>
                    </div>
                    {/* <section className="section-hero hero-post detailspage-banner sfsfsd" style={{
                        backgroundImage: `url(${newsData["image"]})`,
                        padding: "0", backgroundRepeat: "no-repeat",
                        backgroundSize: "100% 100%"
                    }}>
                        <div className="container">
                            <div className="post-hero-block">
                                <div className="text-container no-margin">
                                    <h1 className="news-post-h">{newsData["title"]}</h1>
                                  
                                    <div className="clientnavgations" style={{
                                        padding: "0"
                                    }}>
                                        <div className="overviewlinks">
                                            <ul style={{
                                                flexWrap: "wrap"
                                            }}>
                                                <li>
                                                    <a onClick={() => scrollToSection("blog-overview")}>Overview</a>{" "}
                                                </li>
                                                <li>
                                                    <a onClick={() => scrollToSection("blog-problem")}>Problem</a>{" "}
                                                </li>
                                                <li>
                                                    <a onClick={() => scrollToSection("blog-solution")}>Solution</a>{" "}
                                                </li>
                                                <li>
                                                    <a onClick={() => scrollToSection("blog-result")}>Result</a>{" "}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}



                    <section className="section-hero-banner-top" style={{
                        paddingTop: "50px"
                    }}>
                        <div className="container">
                            <a href="/news" className="back-btn-blog"><span><img src="/images/productSlider/nextarrow.svg" alt="" /> Go back</span></a>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={5}>
                                    <div className="blogdetailspage-banner-img">
                                        <img src={newsData["image"]} alt="" />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={6} md={7}>
                                    <div className="blogdetailspage-banner-titles">

                                        <h1 className="news-post-h">{newsData["title"]}</h1>
                                        <div className="authore">
                                            <div className="authore">
                                                <ul style={{
                                                    flexWrap: "wrap"
                                                }}>
                                                    <li>
                                                        Author:<span>{newsData["author"]}</span>
                                                    </li>
                                                    <li>
                                                        Post :<span>{new Date(newsData["posted_on"]).toLocaleDateString("en-US", options)}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>


                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                    </section>

                    {/* <section className="main-top pt-0 bgnone">
                        <div className="container">
                            <div className="main-page-title">
                                <div class="top-breadcrumbs">
                                    <ul>
                                        <li>
                                            <a href="/blog">Home</a>{" "}
                                        </li>
                                        <li> {newsData["title"]} </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    <section class="main-top pt-0 bgnone">
                        <div class="container">
                            <Grid container spacing={3} className="bottom-pb-6">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="bottomspace-item">
                                    <div className="blog-detailpage">
                                        <div className="singleblogtext" dangerouslySetInnerHTML={{ __html: newsData["content"] }} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </section>
                </div>
            </>
        );
    };

    const LoadingPage = () => {
        return (
            <LoadingOverlay active={loading} spinner styles={{
                overlay: (base) => ({
                    ...base,
                    background: "#10101b",
                }),
            }}>
                <div style={{
                    height: "100vh", background: "#10101b", display: "flex",
                    alignContent: "center",
                    flexWrap: "wrap",
                }}>
                    <Grid container xs={12} style={{
                        display: "flex",
                        alignContent: "center",
                        flexWrap: "wrap",
                        flexDirection: "column",
                    }}>
                        <Typography variant="h3">No News Found</Typography>
                    </Grid>
                </div>
            </LoadingOverlay>
        );
    };

    return (
        <>
            <div className="blogouter">
                <section className="main-top pt-0">{newsData && newsData["title"] ? rendernewsData() : LoadingPage()}</section>
            </div>
        </>
    );
};

export default SpecificNews;