import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import * as faceapi from "face-api.js";
import $ from "jquery";

import Notification from "./sections/Notification";
import Slide from "@material-ui/core/Slide";

const { forwardRef, useImperativeHandle } = React;

const Scanner = forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false);

  const [device, setDevice] = useState(props.deviceId);
  useImperativeHandle(ref, () => ({
    handleToggle() {
      setVisible((prev) => !prev);
    },
    changeDeviceId(deviceId) {
      console.log("got device id : " + deviceId);
      setDevice(deviceId);
    },
  }));

  const refWebCam = React.useRef(null);

  // const [source, setSource] = useState(0);
  const [notif, setNotif] = useState({
    notifOpen: false,
    transition: Slide,
    message: "Starting Demo",
    typeLoader: true,
  });

  const handleNotifClose = () => {
    console.log("stopping");
    setNotif({
      notifOpen: false,
    });
  };
  const size =
    $(window).width() <= 1300
      ? { width: $(window).width(), height: "600" }
      : { width: $(window).width(), height: $(window).height() };

  const isLandscape = size.height <= size.width;
  const ratio = isLandscape
    ? size.width / size.height
    : size.height / size.width;

  const [videoConstraints, setVideoConstraints] = useState({
    aspectRatio: ratio,
    facingMode: "user",
    deviceId: device,
  });
  useEffect(() => {
    console.log(videoConstraints);
  }, [videoConstraints]);

  useEffect(() => {
    setTimeout(() => {
      handleNotifClose();
    }, 10000);
  }, []);
  useEffect(() => {
    setVideoConstraints({ ...videoConstraints, deviceId: device });
    // eslint-disable-next-line
  }, [device]);

  useEffect(() => {
    var img = document.getElementById("img_src");
    var canvas = document.getElementById("canvas_res");
    var options = new faceapi.TinyFaceDetectorOptions({ scoreThreshold: 0.6 });
    var speed = 0.05,
      movingDown = true,
      multiplier = 0;
    var pollVar;

    if (visible && pollVar === null) {
      pollVar = setInterval(() => {
        if (visible && refWebCam !== null && refWebCam.current !== null) {
          let imagesrc = refWebCam.current.getScreenshot();
          img.src = imagesrc;
          imagesrc = undefined;
        } else {
          clearInterval(pollVar);
        }

        async function detect() {
          const detections = await faceapi.detectAllFaces(img, options);

          // console.log(detections);
          drawBoxes(detections);

          function drawBoxes(coordList) {
            // console.time("Execution Time");
            //   console.log("drawing");

            try {
              canvas.width = img.width;
              canvas.height = img.height;
              var ctx = canvas.getContext("2d");
              ctx.clearRect(0, 0, canvas.width, canvas.height);

              ctx.drawImage(img, 0, 0);
              const borderWidth = 0.04 * canvas.width;
              ctx.fillStyle = "rgba(50, 50, 50, 0.7)";
              ctx.fillRect(0, 0, canvas.width, borderWidth);
              ctx.fillRect(
                canvas.width - borderWidth,
                borderWidth,
                borderWidth,
                canvas.height
              );
              ctx.fillRect(
                0,
                canvas.height - borderWidth,
                canvas.width - borderWidth,
                canvas.height
              );
              ctx.fillRect(
                0,
                borderWidth,
                borderWidth,
                canvas.height - 2 * borderWidth
              );
              // console.log(coordList);

              if (coordList !== null) {
                for (var i = 0; i < coordList.length; i++) {
                  const score = coordList[i].classScore;
                  const box = coordList[i].box;

                  const x = box.x + 0.1 * box.width, // should be box.x for models other than tinyfacedetector
                    y = box.y - 0.15 * box.height, // should be box.y for models other than tinyfacedetector
                    width = 0.8 * box.width, // should be box.width for models other than tinyfacedetector
                    height = box.height;

                  ctx.fillStyle = "rgba(50, 80, 150, 0.35)";
                  if (movingDown) {
                    //   console.log("moving down");
                    ctx.fillRect(x, y, width, height * speed * multiplier);
                  } else {
                    ctx.fillRect(
                      x,
                      y + height * speed * multiplier,
                      width,
                      height - height * speed * multiplier
                    );
                  }

                  ctx.lineWidth = 5;

                  ctx.font = "18px Helvetica";
                  ctx.fillStyle = "#fff";
                  ctx.fillText(
                    Math.round(score * 100) / 100,
                    x - ctx.lineWidth / 2,
                    y - 0.05 * height
                  );

                  ctx.shadowBlur = 10;
                  ctx.shadowColor = "#ababab";

                  if (movingDown) {
                    ctx.fillStyle = "#cccccc";
                    ctx.fillRect(
                      x,
                      y + height * speed * multiplier - 0.01 * height,
                      width,
                      height * 0.01
                    );
                    ctx.fillStyle = "#c4c4c4";
                    ctx.fillRect(
                      x,
                      y + height * speed * multiplier - 0.02 * height,
                      width,
                      height * 0.015
                    );
                    ctx.fillStyle = "#a3a3a3";
                    ctx.fillRect(
                      x,
                      y + height * speed * multiplier - 0.03 * height,
                      width,
                      height * 0.015
                    );
                    ctx.fillStyle = "#9c9c9c";
                    ctx.fillRect(
                      x,
                      y + height * speed * multiplier - 0.04 * height,
                      width,
                      height * 0.015
                    );
                    ctx.fillStyle = "#858585";
                    ctx.fillRect(
                      x,
                      y + height * speed * multiplier - 0.05 * height,
                      width,
                      height * 0.015
                    );
                  } else {
                    ctx.fillStyle = "#cccccc";
                    ctx.fillRect(
                      x,
                      y + height * speed * multiplier + 0.01 * height,
                      width,
                      height * 0.01
                    );
                    ctx.fillStyle = "#c4c4c4";
                    ctx.fillRect(
                      x,
                      y + height * speed * multiplier + 0.02 * height,
                      width,
                      height * 0.015
                    );
                    ctx.fillStyle = "#a3a3a3";
                    ctx.fillRect(
                      x,
                      y + height * speed * multiplier + 0.03 * height,
                      width,
                      height * 0.015
                    );
                    ctx.fillStyle = "#9c9c9c";
                    ctx.fillRect(
                      x,
                      y + height * speed * multiplier + 0.04 * height,
                      width,
                      height * 0.015
                    );
                    ctx.fillStyle = "#858585";
                    ctx.fillRect(
                      x,
                      y + height * speed * multiplier + 0.05 * height,
                      width,
                      height * 0.015
                    );
                  }

                  ctx.shadowBlur = null;
                  ctx.shadowColor = null;

                  ctx.strokeStyle = "#fff";
                  // ==================================
                  ctx.beginPath();
                  ctx.moveTo(x, y - ctx.lineWidth / 2);
                  ctx.lineTo(x, y + 0.2 * height);
                  ctx.stroke();

                  ctx.beginPath();
                  ctx.moveTo(x - ctx.lineWidth / 2, y);
                  ctx.lineTo(x + 0.2 * height, y);
                  ctx.stroke();
                  // ==================================

                  ctx.beginPath();
                  ctx.moveTo(x + width, y - ctx.lineWidth / 2);
                  ctx.lineTo(x + width, y + 0.2 * height);
                  ctx.stroke();

                  ctx.beginPath();
                  ctx.moveTo(x + width - ctx.lineWidth / 2, y);
                  ctx.lineTo(x + width - 0.2 * height, y);
                  ctx.stroke();
                  // ==================================

                  ctx.beginPath();
                  ctx.moveTo(x, y + height + ctx.lineWidth / 2);
                  ctx.lineTo(x, y + height - 0.2 * height);
                  ctx.stroke();

                  ctx.beginPath();
                  ctx.moveTo(x + ctx.lineWidth / 2, y + height);
                  ctx.lineTo(x + 0.2 * height, y + height);
                  ctx.stroke();
                  // ==================================

                  ctx.beginPath();
                  ctx.moveTo(x + width, y + height + ctx.lineWidth / 2);
                  ctx.lineTo(x + width, y + height - 0.2 * height);
                  ctx.stroke();

                  ctx.beginPath();
                  ctx.moveTo(x + width + ctx.lineWidth / 2, y + height);
                  ctx.lineTo(x + width - 0.2 * height, y + height);
                  ctx.stroke();
                  // ==================================

                  ctx.fillStyle = "#fff";
                  ctx.fillRect(x, y + height * speed * multiplier, width, 2);
                }

                if (movingDown) {
                  multiplier += 1;
                } else {
                  multiplier -= 1;
                }

                if (multiplier + 6 >= 1 / speed) {
                  movingDown = false;
                } else if (multiplier - 1 <= 1) {
                  movingDown = true;
                }
              }
            } catch (error) {
              console.log(error);
            }
            // console.timeEnd("Execution Time");
          }
        }
        if (visible) detect();
      }, 30);
    }
  }, [visible]);

  return (
    <>
      <div>
        <Notification notif={notif}></Notification>
      </div>
      {visible ? (
        <div id="container">
          <Webcam
            id="webcam"
            height={size.height}
            width={size.width}
            style={{ position: "absolute", visibility: "hidden" }}
            audio={false}
            ref={refWebCam}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
          <img
            id="img_src"
            alt=""
            style={{ display: "none", position: "absolute" }}
          ></img>
          <canvas id="canvas_res" style={{ position: "absolute" }}></canvas>
          {/* <video id="video" preload width="720" height="560" autoPlay></video> */}
        </div>
      ) : null}
    </>
  );
});

export default Scanner;
