import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import Slider from "react-slick";

const SashastraSeemaBal = () => {
    useEffect(() => {
        const title = document.getElementById("mainTitle");
        title.innerText = `Skylark Labs: Sashastra Seema Bal`;
    }, []);

    const navOption1 = useRef(null);
    const navOption2 = useRef(null);
    const navOption3 = useRef(null);
    const section1 = useRef(null);
    const section2 = useRef(null);
    const section3 = useRef(null);

    let otherCases = [
        {
            title: "D1 Security",
            desc: "Previously, everyone had to get their ID card checked before entering the park.",
            url: "d1-security",
            img: "images/cases-hotel.png",
        },

        {
            title: "AH1 Hotel",
            desc: "The basic CCTV cameras were unable to detect any violence incidents.",
            url: "ah1-hotel",
            img: "images/ah1-hotel.jpg",
        },

        {
            title: "Guria NGO",
            desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
            url: "Guria-varanasi-human-trafficking",
            img: "images/cases-guria.png",
        },
    ];
    const scrollCheck = (e) => {
        try {
            const addActiveClass = (option) => {
                if (option === undefined) return;
                [navOption1, navOption2, navOption3]
                    .filter((ele) => ele !== option)
                    .map((ele) => ele.current.classList.remove("active"));
                option.current.classList.add("active");
            };

            const offsetY = window.scrollY + 200;
            if (section3.current && offsetY >= section3.current.offsetTop)
                addActiveClass(navOption3);
            else if (section2.current && offsetY >= section2.current.offsetTop)
                addActiveClass(navOption2);
            else if (section1.current && offsetY >= section1.current.offsetTop) {
                addActiveClass(navOption1);
            }
        } catch (e) { }
    };

    useEffect(() => {
        // window.scrollTo({
        //   top: 0,
        // });
        window.addEventListener("scroll", scrollCheck);
    }, []);

    return (
        <>
            <div className="plateformsub-page clientpage-outer">
                <div className="noise-bg"></div>
                <div className="main-shape"></div>

                <div className="container">
                    <div className="clientnavgations">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4} md={4} lg={4}>
                                <h3>Sashastra Seema Bal</h3>
                                <div className="clientnavgationssubtitle"><span>Delhi,</span>  <span>India</span> </div>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={8}
                                md={8}
                                lg={8}
                                className="customer-page-nav-links-grid"
                            >
                                <div className="customer-page-nav-links-wrap">
                                    <ul>
                                        <li>
                                            <a href="#overview">Overview</a>{" "}
                                        </li>
                                        <li>
                                            <a href="#problem">Problem</a>{" "}
                                        </li>
                                        <li>
                                            <a href="#solution">Solution</a>{" "}
                                        </li>
                                        <li>
                                            <a href="#result">Result</a>{" "}
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                        </Grid>
                    </div>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={3} md={3} lg={4}>
                            <div className="text-left logoclient">
                                <img src="images/productSlider/BSF_Insignia.png" alt="" />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9} lg={8}>
                            <div className="client" id="overview">
                                <h3>Overview</h3>
                                <p>
                                    The Sashastra Seema Bal (SSB) is a distinguished border guarding force of India, playing a crucial role in safeguarding the nation's frontiers. Known for its commitment to service, integrity, and dedication, the SSB is adept in strategic intelligence and effective patrolling. Embracing modern technology, the force is incorporating advanced AI-based solutions to enhance border security, embodying India's dedication to maintaining territorial integrity and peace.
                                </p>
                                {/* <img src="images/Customers/Sashastra-Seema-Bal/-ssboverview.png" alt="" style={{
                                    borderRadius: "40px",
                                }} /> */}
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className="pt-5 mt-5" id="problem">
                        <Grid item xs={12} sm={3} md={3} lg={4}>
                            <h3>Problem</h3>
                        </Grid>

                        <Grid item xs={12} sm={9} md={9} lg={8}>
                            <div className="client">
                                <p>
                                    The Sashastra Seema Bal (SSB) faces complex challenges due to India's open borders with neighboring countries, symbols of mutual respect and unity. Key responsibilities for the SSB include monitoring cross-border movements and curbing illegal trade. Their essential role is to facilitate the smooth flow of legitimate trade and people, while simultaneously guarding against unauthorized activities and security threats. Balancing these duties is crucial for upholding the integrity of India's borders and maintaining amicable relations with its neighbors.
                                </p>
                                <img src="images/Customers/Sashastra-Seema-Bal/ssbproblem.jpg" alt="" style={{
                                    borderRadius: "40px",
                                }} />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className="pt-5 mt-5" id="solution">
                        <Grid item xs={12} sm={3} md={3} lg={4}>
                            <h3>Solutions</h3>
                        </Grid>

                        <Grid item xs={12} sm={9} md={9} lg={8}>
                            <div className="client">
                                <p>
                                    In response to border security challenges, Skylark Labs and the MeitY Startup Hub have developed a cutting-edge solution to bolster border safety. Their approach involves deploying Smart Individual and Vehicle Re-Identification systems at key Integrated Check Posts (ICPs). These systems greatly enhance the ability to monitor and identify suspicious entities. Additionally, the Long-Range Intrusion Detection system extends surveillance over wide border areas, significantly improving situational awareness. These integrated technologies ensure enhanced security while supporting legitimate cross-border movement, striking a balance between vigilance and openness.
                                </p>
                                <img src="images/Customers/Sashastra-Seema-Bal/ssbsolution.png" alt="" style={{
                                    borderRadius: "40px",
                                }} />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3} className="pt-5 mt-5" id="result">
                        <Grid item xs={12} sm={3} md={3} lg={4}>
                            <h3>Conclusion</h3>
                        </Grid>
                        <Grid item xs={12} sm={9} md={9} lg={8}>
                            <div className="client">
                                <p>
                                    The Sashastra Seema Bal's collaboration with Skylark Labs and the MeitY Startup Hub marks a significant advancement in border security. By integrating cutting-edge technologies like Smart Individual and Vehicle Re-Identification systems and Long-Range Intrusion Detection, the SSB enhances its capability to monitor and secure India's borders. These innovations balance effective security measures with the seamless flow of legitimate cross-border activities, reflecting the SSB's commitment to both safety and efficiency in border management.
                                </p>
                            </div>
                            <Grid container spacing={3} className="customer-page-key-features">
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <div className="key-features">
                                        <div className="iconsfeatucher">
                                            <img
                                                src="images/productSlider/Play.svg"
                                                alt=""
                                            /></div>
                                        <h3>
                                            75% Improved Suspicious Activity Monitoring
                                        </h3>
                                        <p>Enhanced ability to monitor and identify suspicious individuals and vehicles.</p>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6}>
                                    <div className="key-features">
                                        <div className="iconsfeatucher">
                                            <img
                                                src="images/productSlider/Analytics.svg"
                                                alt=""
                                            /></div>
                                        <h3>
                                            100% Increased Long-Range Intrusion Detection
                                        </h3>
                                        <p>Doubling surveillance range over vast border areas.</p>
                                    </div>
                                </Grid>

                            </Grid>
                        </Grid>
                    </Grid>

                </div>
            </div>
            <section
                className={clsx("client_testimonials")}


            >


                <div className="container">
                    <div className="testimonials_outer testimonials_outerhome">
                        <Slider>

                            <div>
                                <p><img src="images/double-quotes.png" className="double-quotes" alt="" />
                                    Cross-border integration is the only effective method to protect India from threats related to smuggling and other illegal trades. The solution developed by Skylark Labs will modernize the management of open borders.
                                    <img src="images/double-quotes.png" alt="" /></p>
                                <span> SSB, India</span>
                            </div>

                        </Slider>

                    </div>
                </div>

            </section>
            {/* 
            <div className="plateformsub-page">
                <section
                    ref={section1}
                    className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
                >
                    <div className="container pb-5 mb-5">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div className="">
                                    <h2>
                                        <span className="spanfirstword">Related</span> More Cases
                                    </h2>
                                </div>
                            </Grid>
                        </Grid>

                        <div className="industriesListblock pt-5">
                            <ul>
                                {otherCases.map((ele, idx) => (
                                    <li>
                                        <a href="#contact" className="industriesListcol">
                                            <div className="solutionListimg">
                                                <img src={ele.img} alt="" />
                                            </div>
                                            <div className="industriesListcoltext">
                                                <h4>{ele.title} </h4>
                                                <p>{ele.desc}</p>
                                                <div className="quicklinks casesquicklinks">
                                                    {" "}
                                                    View full case{" "}
                                                    <img src="images/right-arrow.png" alt="" />{" "}
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>
            </div> */}
        </>
    );
};

export default SashastraSeemaBal;