const useModels = () => {
  const models = {
    "weapon-detection": {
      title: "Weapon Detection",
      requires_reference: false,
      api_key: "abcd",
      sample: [
        ["weapon0.jpeg", "image"],
        ["weapon1.jpg", "image"],
      ],
      res: [
        ["weapon0-res.jpeg", "image"],
        ["weapon1-res.jpeg", "image"],
      ],
      apis: {
        image: {
          route: "weapon-detection/",
        },
        video: {
          route: "weapon-detection-video/",
        },
        url: {
          route: "weapon-detection-url/",
        },
        webcam: {
          button: "Webcam",
          icon: "videocam",
          create_connection_route: "web-socket-token",

          authorization: "token fac2f104431d289d6a3e3d6711f0d1535a6af3d9",
          websocket_url: "http://3.239.249.26:8000/weapon-detection",
        },
      },
    },
    "lurking-detection": {
      title: "Lurking Detection",
      requires_reference: false,
      api_key: "abcd",
      sample: [
        ["https://website-demo-media.s3.amazonaws.com/lurking1.webm", "video"],
        ["https://website-demo-media.s3.amazonaws.com/lurking2.webm", "video"],
      ],
      res: [
        [
          "https://website-demo-media.s3.amazonaws.com/lurking1_res_720p.webm",
          "video",
        ],
        [
          "https://website-demo-media.s3.amazonaws.com/lurking2_res_720p.webm",
          "video",
        ],
      ],
      apis: {
        image: {
          route: "lurking-detection/",
        },
        video: {
          route: "human-tracker-video/",
        },
        url: {
          route: "lurking-detection-url/",
        },
        webcam: {
          button: "Webcam",
          icon: "videocam",
          create_connection_route: "web-socket-token",

          authorization: "token fac2f104431d289d6a3e3d6711f0d1535a6af3d9",
          websocket_url: "http://3.239.249.26:8000/weapon-detection",
        },
      },
    },
    "cab-violence-detection": {
      title: "Cab Violence Detection",
      requires_reference: false,
      api_key: "abcd",
      apis: {
        image: {
          route: "violence-detection/",
        },
        video: {
          route: "violence-detection-video/",
        },
        url: {
          route: "violence-detection-url/",
        },
        webcam: {
          button: "Webcam",
          icon: "videocam",
          create_connection_route: "web-socket-token",

          authorization: "token fac2f104431d289d6a3e3d6711f0d1535a6af3d9",
          websocket_url: "http://3.239.249.26:8000/vehicle-recognition",
        },
      },
    },
    "face-detection": {
      title: "Face Detection",
      requires_reference: false,
      api_key: "abcd",
      sample: [
        ["weapon0.jpeg", "image"],
        ["weapon1.jpg", "image"],
      ],
      res: [
        ["weapon0-res.jpeg", "image"],
        ["weapon1-res.jpeg", "image"],
      ],
      apis: {
        image: {
          route: "face-detect/",
        },
        video: {
          route: "cab-violence/",
        },
        url: {
          route: "weapon-detection-url/",
        },
        webcam: {
          button: "Webcam",
          icon: "videocam",
          create_connection_route: "web-socket-token",

          authorization: "token fac2f104431d289d6a3e3d6711f0d1535a6af3d9",
          websocket_url: "http://3.239.249.26:8000/weapon-detection",
        },
      },
    },
    "face-recognition": {
      title: "Face Recognition",
      requires_reference: false,
      recog: true,
      api_key: "abcd",
      sample: [
        ["face-recog2.jpg", "image"],
        ["face-recog1.jpg", "image"],
      ],
      res: [
        ["face-recog2-res.jpg", "image"],
        ["face-recog1-res.jpg", "image"],
      ],
      apis: {
        image: {
          route: "face-recognition-image/",
        },
        video: {
          route: "face-recognition-video/",
        },
        url: {
          route: "face-recognition-url/",
        },
        webcam: {
          button: "Webcam",
          icon: "videocam",
          create_connection_route: "web-socket-token",

          authorization: "token fac2f104431d289d6a3e3d6711f0d1535a6af3d9",
          websocket_url: "http://3.239.249.26:8000/weapon-detection",
        },
      },
    },
    "person-detection": {
      title: "Person Detection",
      requires_reference: false,
      api_key: "abcd",
      sample: [
        "https://skylark-static.s3.us-west-2.amazonaws.com/1.png",
        "https://website-demo-media.s3.amazonaws.com/person2.jpg",
      ],
      apis: {
        image: {
          route: "person-detection/",
        },
        video: {
          route: "person-detection-video/",
        },
        url: {
          route: "person-detection-url/",
        },
        webcam: {
          button: "Webcam",
          icon: "videocam",
          create_connection_route: "web-socket-token",

          authorization: "token fac2f104431d289d6a3e3d6711f0d1535a6af3d9",
          websocket_url: "http://3.239.249.26:8000/person-detection",
        },
      },
    },
    "crowd-counting": {
      title: "Crowd Counting",
      requires_reference: false,
      api_key: "abcd",
      sample: [
        ["weapon0.jpeg", "image"],
        ["weapon1.jpg", "image"],
      ],
      res: [
        ["weapon0-res.jpeg", "image"],
        ["weapon1-res.jpeg", "image"],
      ],
      apis: {
        image: {
          route: "crowd-counting/",
        },
        video: {
          route: "crowd-counting-video/",
        },
        url: {
          route: "crowd-counting-url/",
        },
        webcam: {
          button: "Webcam",
          icon: "videocam",
          create_connection_route: "web-socket-token",

          authorization: "token fac2f104431d289d6a3e3d6711f0d1535a6af3d9",
          websocket_url: "http://3.239.249.26:8000/crowd-counting",
        },
      },
    },
    "dress-insights": {
      title: "Dress Insights",
      requires_reference: false,
      api_key: "abcd",
      apis: {
        image: {
          route: "dress-insights/",
        },
        video: {
          route: "dress-insights-video/",
        },
        url: {
          route: "dress-insights-url/",
        },
        webcam: {
          button: "Webcam",
          icon: "videocam",
          create_connection_route: "web-socket-token",

          authorization: "token fac2f104431d289d6a3e3d6711f0d1535a6af3d9",
          websocket_url: "http://3.239.249.26:8000/dress-insights",
        },
      },
    },
    "vehicle-recognition": {
      title: "Vehicle Recognition",
      requires_reference: false,
      api_key: "abcd",
      sample: [
        "https://website-demo-media.s3.amazonaws.com/vehicle-1.jpeg",
        "https://website-demo-media.s3.amazonaws.com/vehicle-2.png",
      ],
      apis: {
        image: {
          route: "vehicle-recognition/",
        },
        video: {
          route: "vehicle-recognition-video/",
        },
        url: {
          route: "vehicle-recognition-url/",
        },
        webcam: {
          button: "Webcam",
          icon: "videocam",
          create_connection_route: "web-socket-token",

          authorization: "token fac2f104431d289d6a3e3d6711f0d1535a6af3d9",
          websocket_url: "http://3.239.249.26:8000/vehicle-recognition",
        },
      },
    },
    "night-day": {
      title: "Night To Day",
      requires_reference: false,
      api_key: "abcd",
      sample: [
        ["weapon0.jpeg", "image"],
        ["weapon1.jpg", "image"],
      ],
      res: [
        ["weapon0-res.jpeg", "image"],
        ["weapon1-res.jpeg", "image"],
      ],
      apis: {
        image: {
          route: "night-day/",
        },
        video: {
          route: "night-day-video/",
        },
        url: {
          route: "night-day-url/",
        },
        webcam: {
          button: "Webcam",
          icon: "videocam",
          create_connection_route: "web-socket-token",

          authorization: "token fac2f104431d289d6a3e3d6711f0d1535a6af3d9",
          websocket_url: "http://3.239.249.26:8000/night-day",
        },
      },
    },
    "number-plate-detection": {
      title: "Number Plate Detection",
      requires_reference: false,
      api_key: "abcd",
      sample: [
        ["Number_plate_sample1.jpg", "image"],
        ["Number_plate_sample2.jpg", "image"],
      ],
      res: [
        ["Number_plate_result1.jpg", "image"],
        ["Number_plate_result2.jpg", "image"],
      ],
      apis: {
        image: {
          route: "number-plate-detection/",
        },
        video: {
          route: "number-plate-detection-video/",
        },
        url: {
          route: "vehicle-recognition-url/",
        },
        webcam: {
          button: "Webcam",
          icon: "videocam",
          create_connection_route: "web-socket-token",

          authorization: "token fac2f104431d289d6a3e3d6711f0d1535a6af3d9",
          websocket_url: "http://3.239.249.26:8000/vehicle-recognition",
        },
      },
    },
    "violence-detection": {
      title: "Violence Detection",
      requires_reference: false,
      api_key: "abcd",
      sample: [
        ["https://website-demo-media.s3.amazonaws.com/video1.webm", "video"],
        ["https://website-demo-media.s3.amazonaws.com/video2.webm", "video"],
      ],
      res: [
        ["https://website-demo-media.s3.amazonaws.com/output1.webm", "video"],
        ["https://website-demo-media.s3.amazonaws.com/output2.webm", "video"],
      ],

      apis: {
        image: {
          route: "violence-detection/",
        },
        video: {
          route: "violence-detection-video/",
        },
        url: {
          route: "violence-detection-url/",
        },
        webcam: {
          button: "Webcam",
          icon: "videocam",
          create_connection_route: "web-socket-token",

          authorization: "token fac2f104431d289d6a3e3d6711f0d1535a6af3d9",
          websocket_url: "http://3.239.249.26:8000/vehicle-recognition",
        },
      },
    },
  };

  return models;
};

export default useModels;
