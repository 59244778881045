import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef } from "react";

const Mantech = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Man Tech`;
  }, []);

  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);

  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "Guria NGO",
      desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
      url: "Guria-varanasi-human-trafficking",
      img: "images/cases-guria.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);

  return (
    <>
      <div className="plateformsub-page clientpage-outer">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>

        <div className="container">
          <div className="clientnavgations">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <h3>Man Tech</h3>
                <div className="clientnavgationssubtitle"><span>Indiana,</span>  <span>USA</span> </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                lg={8}
                className="customer-page-nav-links-grid"
              >
                <div className="customer-page-nav-links-wrap">
                  <ul>
                    <li>
                      <a href="#overview">Overview</a>{" "}
                    </li>
                    <li>
                      <a href="#problem">Problem</a>{" "}
                    </li>
                    <li>
                      <a href="#solution">Solution</a>{" "}
                    </li>
                    <li>
                      <a href="#result">Result</a>{" "}
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <div className="text-left logoclient">
                <img src="images/productSlider/product-logo/mantech.png" alt="" />
              </div>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9}>
              <div className="client" id="overview">
                <h3>Overview</h3>
                <p>
                  ManTech, a distinguished provider of technological solutions
                  for defense and security, confronts intricate challenges in
                  maritime operations for the US Navy and Indian Navy. Renowned
                  for its commitment to innovation and excellence in defense
                  technology, ManTech focuses on enhancing naval capabilities
                  through advanced solutions. Facing specific needs in corrosion
                  detection, Foreign Object Debris (FOD) detection, and maritime
                  target recognition, ManTech has partnered with Skylark Labs to
                  integrate state-of-the-art technology into these areas, aiming
                  to significantly elevate operational efficiency, safety, and
                  security within naval defense.
                </p>
                {/* <img src="images/Customers/ManTech/mantech overview.png" alt="" style={{
                  borderRadius: "40px",
                }} /> */}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="problem">
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <h3>Problem</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={9}>
              <div className="client">
                <p>
                  ManTech is addressing three major challenges to enhance naval
                  operations for the US and Indian Navies: detecting corrosion,
                  identifying Foreign Object Debris (FOD), and recognizing
                  maritime targets. These areas are crucial for maintaining
                  safety and efficiency in naval environments. Advanced
                  technology is key to tackling these complex issues
                  effectively.
                </p>

                <div className="customerstwocal" style={{ padding: "0" }}>
                  <h4 style={{ padding: "0" }}>Key areas of focus include:</h4>
                </div>
                <ul>
                  {/* <li>
                    <strong>
                      Corrosion Detection System for the US Navy and Mazadon
                      Docks in India:
                    </strong>{" "}
                    The objective here is to develop a cutting-edge system to
                    detect corrosion on submerged parts of naval ships. This is
                    essential for the upkeep and long-term functioning of these
                    vessels. The system must be highly advanced yet flexible
                    enough to work in various maritime conditions.
                  </li> */}
                  <li>
                    <strong>
                      Foreign Object Debris (FOD) Detection Using Drones for the
                      US Navy and Indian Navy:
                    </strong>{" "}
                    This involves creating a drone-based technology to detect
                    and analyze FOD, which is crucial for safe and efficient
                    naval operations. The technology would need sophisticated
                    sensors and imaging capabilities to accurately identify and
                    address potential hazards.
                  </li>
                  <li>
                    <strong>
                      Maritime Target Recognition for the US Navy and Indian
                      Navy:
                    </strong>{" "}
                    The goal is to develop a system that can accurately
                    recognize maritime targets. This requires creating advanced
                    algorithms and imaging techniques that can effectively
                    identify potential maritime threats from surveillance data,
                    thereby bolstering maritime security and readiness.
                  </li>
                </ul>
                <br />
                <img
                  src="images\Customers\ManTech\mantechproblem_1.png"
                  alt=""
                  style={{
                    borderRadius: "40px",
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="solution">
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <h3>Solutions</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={9}>
              <div className="client">
                <p>
                  To address these complex challenges, Skylark Labs has
                  developed innovative and tailored technological solutions.
                  These advancements, created in partnership with ManTech,
                  effectively tackle the identified issues, showcasing a blend
                  of Skylark Labs' cutting-edge technology and ManTech's
                  strategic vision to enhance maritime operational capabilities
                  for the US Navy and Indian Navy.
                </p>

                <div className="customerstwocal">
                 
                  {/* <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <img
                        src="images\Customers\ManTech\Mantech Corrosion problem_t.png"
                        alt=""
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                      }}
                    >
                      <h4
                        style={{
                          fontWeight: "500",
                          margin: "0",
                        }}
                      >
                        <span className="spanfirstword">Solution</span> for
                        Corrosion Detection System
                      </h4>
                      <p className="pt-0 abouttext-pblock">
                        Skylark Labs has integrated Kepler's MIRAS, featuring environmental monitoring, into its hyperspectral and visual data-based corrosion detection system for naval vessels. This advanced solution effectively identifies corrosion, crucial for maintenance in US and Indian maritime contexts, thus extending the lifespan of naval assets.
                      </p>
                    </Grid>
                  </Grid> */}
                </div>

                <div className="customerstwocal ">
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                      }}
                    >
                       <h4 className="pb-0 mb-0 pt-0">The solutions developed are:</h4>
                      <h4
                        style={{
                          fontWeight: "500",
                          margin: "0",
                        }}
                      >
                        <span className="spanfirstword">Solution</span> for FOD
                        Detection Using Drones
                      </h4>
                      <p className="pt-0 abouttext-pblock">
                        Skylark Labs has enhanced its drone-based FOD detection system with Kepler's BROS, featuring base monitoring. This upgrade, with advanced sensors and imaging, improves operational safety for the US and Indian Navies by effectively detecting hazardous debris in naval environments.
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <img
                        src="images\Customers\ManTech\mantech_fod_sol.png"
                        alt=""
                      />
                    </Grid>
                  </Grid>
                </div>

                <div className="customerstwocal pb-0 aboutbgblock">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <img
                        src="images\Customers\ManTech\-Maritime Target Recognition Solution(mantech).png"
                        alt=""
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flexWrap: "nowrap",
                        justifyContent: "center",
                      }}
                    >
                      <h4
                        style={{
                          fontWeight: "500",
                          margin: "0",
                        }}
                      >
                        <span className="spanfirstword">Maritime Target</span>{" "}
                        Recognition Solution
                      </h4>
                      <p className="pt-0 abouttext-pblock">
                        Skylark Labs has developed an advanced maritime target recognition system, now integrated with Kepler's MIRAS, which includes a border monitoring feature. This system utilizes sophisticated algorithms and imaging techniques to bolster the maritime security of the US and Indian Navies. It provides precise identification of potential threats and objects of interest, ensuring enhanced vigilance and security in maritime operations.
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="result">
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <h3>Conclusion</h3>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9}>
              <div className="client">
                <p>
                  ManTech's initiative, bolstered by Skylark Labs' technological
                  solutions, exemplifies a successful collaboration in tackling
                  complex naval challenges. These innovative solutions not only
                  address ManTech's operational needs but also mark significant
                  advancements in naval capabilities, reinforcing their
                  commitment to enhancing maritime safety and security through
                  technology.
                </p>
              </div>
              <Grid
                container
                spacing={3}
                className="customer-page-key-features"
              >
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img src="images/productSlider/Play.svg" alt="" />
                    </div>
                    <h3>70% Enhancement in Marine Operations</h3>
                    <p>
                      Significantly improving longevity and reducing breakdowns.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img src="images/productSlider/Analytics.svg" alt="" />
                    </div>
                    <h3>85% More Efficient FOD Detection</h3>
                    <p>
                      Drones offer greatly improved efficiency in detecting
                      hazardous debris in Marine environments.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img src="images/productSlider/Analytics.svg" alt="" />
                    </div>

                    <h3>95% Effective Threat Recognition</h3>
                    <p>
                      High accuracy in identifying maritime threats and objects
                      of interest.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* 
      <div className="plateformsub-page">
        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Related</span> More Cases
                  </h2>
                </div>
              </Grid>
            </Grid>

            <div className="industriesListblock pt-5">
              <ul>
                {otherCases.map((ele, idx) => (
                  <li>
                    <a href="#contact" className="industriesListcol">
                      <div className="solutionListimg">
                        <img src={ele.img} alt="" />
                      </div>
                      <div className="industriesListcoltext">
                        <h4>{ele.title} </h4>
                        <p>{ele.desc}</p>
                        <div className="quicklinks casesquicklinks">
                          {" "}
                          View full case{" "}
                          <img src="images/right-arrow.png" alt="" />{" "}
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div> */}
    </>
  );
};

export default Mantech;
