import React from "react";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { Box, Typography } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import ReactPlayer from "react-player";

import Etios from "../../videofiles/strike.mp4";
import CounterUAS from "../../videofiles/dummy.mp4";
import Industrialbanner from "../../videofiles/children-safety.mp4";
import censer from "../../videofiles/censer.mp4";

const Homeproductlist = () => {
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (

    <div>

      <section className="homeprodctlist">
        <div className="container homeprodctlisttop">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={7}>
              <h4>
                Platforms
              </h4>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <p className=" py-0 my-0">
                We provide two distinct platforms, each specifically designed for the tactical and intelligence communities


              </p>
            </Grid>
          </Grid>
        </div>
        <div className="container">
          <Box style={{ width: '100%' }}>
            <TabPanel value={value} index={0}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  width="100%"
                  playsinline
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url="https://d1nbq27kwttg28.cloudfront.net/blog/New+Banner+(1).mp4"
                />

                <div className="productitemboxdis">
                  <h4>KEPLER  </h4>
                  <p> Kepler is a seamless, plug-and-play tactical platform, integrating data from various sensors to proactively detect and alert against threats across land, air, and sea, tailored for the Army, Navy, and Air Force, ensuring continuous, reliable operation without downtime.
                  </p>
                  <a href="/kepler" className="aboutmore">More About Kepler <img src="images/linearrow-white.svg" alt="" /> </a>
                </div>
              </div>
            </TabPanel>


            <TabPanel value={value} index={1}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  width="100%"
                  height="100%"
                  playsinline
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={CounterUAS}
                />

                <div className="productitemboxdis">


                  <h4>TURING</h4>
                  <p> Turing offers the intelligence community a vital tool for preempting threats, uniquely capable of flagging known and unknown cyphers, while providing customized, secure analysis of emerging dangers.</p>
                  <a href="/turing" className="aboutmore">More About Turing<img src="images/linearrow-white.svg" alt="" /> </a>
                </div>
              </div>
            </TabPanel>
            <div className="poductlisttabs poductlisttabs-two">
              <Box style={{ borderColor: 'divider' }}>

                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Kepler" {...a11yProps(0)} />

                  <Tab label="Turing" {...a11yProps(1)} />


                </Tabs>

              </Box>
            </div>
          </Box>
        </div>
      </section>
      {/*     
<section className="dashboardfeatures section-productoverviewblock flatformblocdiv pt-0">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container">
            <div className="seactionblocktitles">
              <h2 className="seactiontitles"> Platforms</h2>
              <p>
              We provide two distinct products, each specifically designed for the tactical and intelligence communities

              </p>
              
            </div>
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Kepler</span>{" "}
                      
                    </h4>
                    <p>
                    Kepler is a seamless, plug-and-play intelligence platform, integrating data from various sensors to proactively detect and alert against threats across land, air, and sea, tailored for the Army, Navy, and Air Force, ensuring continuous, reliable operation without downtime.
                    </p>
               

              <div className="btnsmore">
              <a href="/kepler" className="readmore-btns">
              <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}

              </a>
              </div>
                  </div>
                </div>

              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="videoblock-plateforms">
                <ReactPlayer
            style={{
              zIndex: "-1",
              pointerEvents: "none",
            }}
            controls={false}
            volume={0}
            width="100%"
            height="100%"
            loop={true}
            muted={true}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url={Etios}
          />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="videoblock-plateforms">
                <ReactPlayer
            style={{
              zIndex: "-1",
              pointerEvents: "none",
            }}
            controls={false}
            volume={0}
            width="100%"
            height="100%"
            loop={true}
            muted={true}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url={CounterUAS}
          />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Turing</span>{" "}
                      
                    </h4>
                    <p>
                    Turing offers the intelligence community a vital tool for preempting threats, uniquely capable of flagging known and unknown cyphers, while providing customized, secure analysis of emerging dangers.
                    </p>
                    <div className="btnsmore">
              <a href="/turing" className="readmore-btns">
              <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}

              </a>
              </div>
                  </div>
                </div>
              </Grid>
            
            </Grid>
          </div>
        </section> */}

      {/* <section className="dashboardfeatures section-productoverviewblock flatformblocdiv pt-0">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container">
            <div className="seactionblocktitles">
              <h2 className="seactiontitles">  Non-Profit </h2>
              <p>
              We provide two distinct products, each specifically designed for the tactical and intelligence communities

              </p>
             
            </div>
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="videoblock-plateforms">
                <ReactPlayer
          style={{
            zIndex: "-1",
            pointerEvents: "none",
          }}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          loop={true}
          muted={true}
          playing={true}
          stopOnUnmount={true}
          config={{
            file: {
              attributes: {
                autoplay: true,
                controls: false,
                loop: true,
                muted: true,
              },
            },
          }}
          url={Industrialbanner}
        />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Children safety</span>{" "}
                      
                    </h4>
                    <p>
                    Skylark lab’s advanced AI solutions enhance the safety and security of Children by detecting potential threats such as kidnapping, child violence, child trafficking, intrusion, etc in real-time with instant reactive alerts.



                    </p>
                    <div className="btnsmore">
              <a href="children-safety" className="readmore-btns">
              <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}

              </a>
              </div>
                  </div>
                </div>
              </Grid>
            
            </Grid>
          </div>
         
      </section> */}

      {/* <section className="dashboardfeatures section-productoverviewblock flatformblocdiv pt-5">
        <div className="imgplatformsbg">
          <img src="images/productSlider/bg_plateform.png" alt="" />
        </div>

        <div className="container plateformblock">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <div className="centerboxblock platformscontent-block-center">
                <div className="dashboardfeatures-list pl-5  pt-0">
                  <div className="flatformtages">
                    <span>Product Capabilities</span>
                  </div>
                  <h4 className="pb-4">
                    <span className="spanfirstword">Censer</span>{" "}

                  </h4>
                  <p>

                    System uses Advanced AI and deep convolutional neural networks to learn high-level features to perform age-invariant classification for all three age, kin, and sketch cases.

                  </p>

                  <div className="btnsmore">
                    <a href="Censer" className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}

                    </a>
                  </div>
                </div>
              </div>

            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <div className="videoblock-plateforms">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={censer}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </section> */}
    </div>
  );

};

export default Homeproductlist;
