import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import videobanner from "../videofiles/dros-banner.mp4";

import aros_intrusion from "../videofiles/aros-intrusion.mp4";
import aros_violence from "../videofiles/aros-violence.mp4";
import Trafficmonitoring from "../videofiles/Traffic_monitoring_new.mp4";
import etosvideo from "../videofiles/etos-page-banner.mp4";
import ariesvideo from "../videofiles/aries-banner.mp4";
import mirasvideo from "../videofiles/miras-main-banner.mp4";
import Etosviolencedetection from "../videofiles/etos_violence_detection.mp4";
import Suspicioussituations from "../videofiles/etosnew-lurking.mp4";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Drostestimonial from "home/sections/Dros-testimonial";
const Dros = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs Aerial Systems:  Drone Systems -  Drone Reconnaissance and Observation System`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [expanded, setExpanded] = React.useState("");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>
                <span className="mainsubtitle">Dros</span>
                Drone Reconnaissance and Observation System 
              </h1>
              <p>
                Utilises drones to identify different concepts, such as people,
                vehicles, infrastructure etc., in optical and thermal modalities
                to provide actionable insights to law enforcement agencies.
              </p>
              <div className="subtages">
                <a>Intrusion</a> <a>Violence & Riot Detection</a>{" "}
                <a>Suspicious Vehicles Detection</a>
                <a>Traffic Monitoring</a>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="imgcenter bannerimgblock ">
          <ReactPlayer
            controls={true}
            playsinline
            volume={0}
            width="100%"
            height="100%"
            loop={true}
            muted={false}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url="https://d1nbq27kwttg28.cloudfront.net/blog/dros-banner.c09cd9d7.mp4"

          />
        </div>
      </div>

   

      <div className="topplatformsblock">
        <section className="productoverviewblock section-productoverviewblock section-productoverviewblockfirstbox spanceseaction">
          <div className="container">
            <Grid container spacing={3} className="dros-about-container">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoleftblock fistblock bordernone">
                  <div>
                    {" "}
                    <img src="images/infographics-aros.png" alt="" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 pb-0">
                      <span className="spanfirstword">Identify </span> objects
                      of interest from Drones
                    </h2>
                    <p className="pt-0">
                      DROS (Drone Reconnaissance and Observation System)
                      seamlessly integrates military-grade drones with optical
                      and thermal capabilities. It excels in detecting unknown
                      objects, individuals, and vehicles, significantly
                      enhancing situational awareness and operational
                      effectiveness. DROS is a cutting-edge solution that
                      identifies potential threats in real-time, ensuring timely
                      alerts to authorities.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container">
            <div className="seactionblocktitles">
              <h2 className="seactiontitles">Precision Drone Reconnaissance</h2>
              <p>
                Features advanced drone technology for enhanced surveillance.
              </p>
            </div>
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Urban</span> Surveillance
                      and Security Module
                    </h4>
                    <p>
                      Specialized in monitoring urban environments for security
                      and public safety.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Riot and Disturbance Monitoring
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Urban Traffic Analysis
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Public Event Surveillance
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Critical Infrastructure Watch
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/Platforms/iris-insight/dros/Remote Border Surveillance.png" />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/Platforms/iris-insight/dros/Advanced Threat Target Tracking.png" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Law Enforcement</span> and
                      Tactical Support Module
                    </h4>
                    <p>
                      Enhances law enforcement capabilities with advanced drone
                      reconnaissance.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Crime Scene Analysis
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Suspect Tracking and Identification
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Covert Surveillance Operations
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Emergency Response Coordination
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">
                        Strategic Intelligence
                      </span>{" "}
                      and Environmental Monitoring Module
                    </h4>
                    <p>
                      Provides strategic intelligence and environmental
                      oversight using drone technology.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Geographical and Environmental Surveillance
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Border Patrol and Security
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Natural Disaster Assessment and Response
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Wildlife and Environmental Conservation
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/Platforms/iris-insight/dros/Crowd Monitoring for Situational Awareness.png" />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        {/* <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/Platforms/iris-insight/dros/Situational Awareness Enhancement.png" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Situational</span> Awareness Enhancement
                    </h4>
                    <p>
                      Enhancing military operations, the system delivers comprehensive, real-time environmental data, supporting informed decision-making and effective command.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Comprehensive Environmental Data
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Real-time Information Feed
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Informed Decision-Making Support
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Effective Operational Command

                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section> */}
        <div className="imgplatformsbg  ">
          <img src="images/productSlider/bg_plateform.png" alt="" />
        </div>
      </div>

      <section className="configurations">
        <div className="container">
          <div className="">
            <h3>DROS: Enhanced Reconnaissance and Surveillance</h3>
            <p>
            DROS (Drone Reconnaissance and Observation System) combines military-grade drones with optical and thermal imaging to detect unknown objects, individuals, and vehicles. This system enhances situational awareness and boosts operational effectiveness by identifying potential threats in real-time and ensuring prompt alerts to authorities.
            </p>
          </div>

          <div className="boxouterproduct">
            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>BVLOS Threat Detection</h4>
              <p>
                Detect threats beyond visual line of sight (BVLOS) across all
                domains, ensuring comprehensive security in any operational
                context.
              </p>
            </div>
            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Real-time Processing</h4>
              <p>
                Our solutions are built on top of computationally efficient
                algorithms, allowing us to process Data from multiple sensors in
                real time.
              </p>
            </div>

            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Hardware Agnostic</h4>
              <p>
                Our solutions seamlessly integrate with a wide range of sensors,
                without the need for specific hardware specifications.
              </p>
            </div>

            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Economical & Configurable</h4>
              <p>
                Our solution adapts to existing hardware with low compute
                demands and simple configuration.
              </p>
            </div>

            {/* <div className="boxouterproductItem">
            <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt=""/> </div>

              <h4>Stress Free Environment</h4>
              <p>
              Receiving only pertinent alerts relieves soldiers from constant video wall monitoring, allowing them to focus when an audio-visual alert is triggered.
              </p>
            </div> */}

            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Assisted Border Planning</h4>
              <p>
                Our platform identifies areas with recurring alerts, aiding in
                strategic troop deployment and fortification of perimeter
                infrastructure.
              </p>
            </div>

            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Efficient On-Ground Communication</h4>
              <p>
                Our system offers a comprehensive map view, enabling precise
                threat tracking from outer to internal perimeters.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        ref={section1}
        className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
      >
        <div className="container pb-5 mb-5">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="">
                <h2>
                  <span className="spanfirstword">Other</span> System
                  Configurations
                </h2>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <div className="productfeatured">
                <a href="/bros">
                  <h5>
                    BorderSentinel
                    <br />
                    <br />
                  </h5>
                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      playsinline
                      volume={0}
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url={etosvideo}
                    />
                    <div className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <div className="productfeatured">
                <a href="aries">
                  <h5>
                    Aerial Reconnaissance and <br></br> Elimination System
                  </h5>

                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      playsinline
                      volume={0}
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url={ariesvideo}
                    />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <div className="productfeatured">
                <a href="miras">
                  <h5>
                    Marine Reconnaissance <br></br>System
                  </h5>
                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      playsinline
                      volume={0}
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url={mirasvideo}
                    />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <Drostestimonial />
    </>
  );
};

export default Dros;
