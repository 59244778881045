import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid"; 
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import videobanner from "../videofiles/satellite-banner.mp4"; 
import perimeter_monitoring from "../videofiles/infrastructure-changes.mp4"; 
import Troopmovements from "../videofiles/troop-movements.mp4";


import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import Testimonial from "home/sections/Testimonial";
import Scdstestimonial from "home/sections/Scds-testimonial";


const Scds = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs Aerial Systems:  Satellite Change Detection System`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [expanded, setExpanded] = React.useState("");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>
                <span className="mainsubtitle">SCDS</span>
                Satellite Change Detection System
              </h1>
              <p>
              Skylark's Satellite Change Detection System tracks historical changes on sites.
              </p>
              <div className="subtages"><a href="#/">Troop Movements</a>  <a href="#/">Infrastructure Changes</a></div>
             

            </div>
          </div>
        </div>

        <div className="imgcenter bannerimgblock ">
          <ReactPlayer
            controls={true}
            playsinline
            volume={0}
            width="100%"
            height="100%"
            loop={true}
            muted={false}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url={videobanner}
          />
        </div>
      </div>

      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>   Marine Reconnaissance System</h2>
            <p>
            Skylark's Satellite Change Detection System tracks historical changes on sites.
           
              </p>
          </div>
        </div>
      </div>

      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section"
        id="surveillance"
      >
        
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="videoleftblock fistblock bordernone">
                <div>
                 
                  <img src="images/infographics-miras.png" alt="" />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block fistblockight homeright-block-auto">
                <div>
                  <h4 className="mb-0">Historic Scene Changes in Sites
</h4>
                  <p className="pt-0">
                  Skylark's Satellite Change Detection System tracks any changes to the landscape, such as those caused by construction or changes to troop movements in wartime settings with the aid of satellites.

                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="homeprodctlist blackrarkoutr latticeos_tabs pb-0" id="troopmovements">
      <div className="container homeprodctlisttop landsystemtitle">
<Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={7}>
             <h4> 
             Scene Changes
             </h4>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
             <p className=" py-0 my-0">
             Detect scene changes of interest using our AI based scene change detection algorithms
           </p>
          </Grid>
       </Grid>
       </div>
        <div className="container">
          <div className="productitembox ">
             
            <ReactPlayer
                  controls={true}
                  playsinline
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Troopmovements}
                />
            <div className="productitemboxdis">
              <h4>Troop Movements
</h4>
              <p>
              The SCDS keeps track of changes in enemy military troop movements, analyses the situation for the command center, and properly provides guidance and support to our army.

              </p>
             
            </div>
          </div>
       
        </div>
      </section>

      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section lightgray darkborder"
        id="infrastructurechanges"
      >
        
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="videoleftblock">
                <ReactPlayer
                  controls={true}
                  playsinline
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={perimeter_monitoring}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block">
                <h4>
                  <span>Product Capabilities</span>Infrastructure Changes

                </h4>
                <p>
                SCDS observes the changes happening around the target area and provides analysis to the authorities about the changes happening around it.
                </p>
 
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
 

      <section
        ref={section6}
        className="benefitmain industriesuses bggray latticeos_faq white-bg"
        id="admincontrol"
      >
        <div className="container">
          <div className="benefitssection  ">
            <h2 className="mb-0 text-center">Features</h2>
          </div>

          <div className="listofindust skylarksuite">
            <ul>
              <li className="text-center">
                <div className="featuresblock featuresblockbgthree">
                  <span>
                    <img src="images/object-detection.svg" alt="" />
                  </span>
                  <h5>Long Range Processing</h5>
                  <p>
                  Our solutions are built to operate on both short as well as on long-range visuals for providing security solutions across all domains.
                  </p>
                </div>
              </li>

              <li className="text-center">
                <div className="featuresblock ">
                  <span>
                    <img src="images/identity-detection.svg" alt="" />
                  </span>
                  <h5>Multiple Location</h5>
                  <p>
                  Dashboard insights can be assessed in real-time using data sets collected from numerous locations.

                  </p>
                </div>
              </li>
              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/icon-threat-detection.svg" alt="" />
                  </span>
                  <h5>Multiple Sensors</h5>
                  <p>
                  Extract useful analytics from
different sensors such as Optical
and Thermal cameras, Radar, etc.
                  </p>
                </div>
              </li>
            
            </ul>
          </div>
        </div>
      </section>
      <Scdstestimonial />
    </>
  );
};

export default Scds;
