import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import HelpingForm from "HelpingForm";
import { Dialog, DialogActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";

const Header = () => {
  const [show, setShow] = useState(-1);
  const location = useLocation();
  const [scroll, setScroll] = useState(false);
  const [mobilemenu, setMobilemenu] = useState(true);
  const [headerClass, setHeaderClass] = useState("");
  const [showMenu, setShowMenu] = useState("");
  const [navclassName, setNavclassName] = useState("header_area dektopiew");

  const showMenuHandler = (panel) => {
    if (showMenu === panel) setShowMenu("");
    else setShowMenu(panel);
  };
  useEffect(() => {
    setMobilemenu(false);
    document.body.classList.remove("open-it");
  }, [location]);

  const scrollCheck = (e) => {
    if (window.scrollY > 30) {
      setHeaderClass("headertop-fixed");
    } else {
      setHeaderClass("");
    }
  };
  useEffect(() => {
    document.addEventListener(
      "scroll",
      () => {
        const scrollCheck = window.scrollY > 20;
        if (scrollCheck !== scroll) {
          setScroll(scrollCheck);
          scroll
            ? setNavclassName("header_area dektopiew")
            : setNavclassName("header_area dektopiew desktop_header_fix");
        }
      },
      true
    );

    window.addEventListener("scroll", scrollCheck);
    document.querySelector(".mobileopen").addEventListener("click", () => {
      setMobilemenu(false);
      document.body.classList.remove("open-it");
    });
  });

  useEffect(() => {
    var menuItems = [].slice.call(document.querySelectorAll(".menu__item")),
      menuSubs = [].slice.call(document.querySelectorAll(".dropdown-menu")),
      //holder = document.querySelector(".dropdown-holder"),
      selectedMenu = undefined,
      subBg = document.querySelector(".dropdown__bg"),
      subBgBtm = document.querySelector(".dropdown__bg-bottom"),
      subArr = document.querySelector(".dropdown__arrow"),
      subCnt = document.querySelector(".dropdown__wrap"),
      header = document.querySelector(".main-header"),
      closeDropdownTimeout,
      startCloseTimeout = function () {
        closeDropdownTimeout = setTimeout(() => closeDropdown(), 50);
      },
      stopCloseTimeout = function () {
        clearTimeout(closeDropdownTimeout);
      },
      openDropdown = function (el) {
        //- get menu ID
        // holder.style.display = "block";
        var menuId = el.getAttribute("data-sub");
        //- get related sub menu
        var menuSub = document.querySelector(
          '.dropdown-menu[data-sub="' + menuId + '"]'
        );
        // menuSub.style.display = 'block'

        //- get menu sub content
        var menuSubCnt = menuSub.querySelector(".dropdown-menu__content");
        //- get bottom section of current sub
        //  var menuSubBtm = menuSubCnt.querySelector('.bottom-section').getBoundingClientRect();
        //- get height of top section
        var menuSubTop = menuSubCnt
          .querySelector(".top-section")
          .getBoundingClientRect();
        //- get menu position
        var menuMeta = el.getBoundingClientRect();
        //- get sub menu position
        var subMeta = menuSubCnt.getBoundingClientRect();

        //- set selected menu
        selectedMenu = menuId;

        //- Remove active Menu 
        menuItems.forEach((el) => el.classList.remove("active"));
        //- Set current menu to active
        el.classList.add("active");

        //- Remove active sub menu
        menuSubs.forEach((el) => el.classList.remove("active"));
        //- Set current menu to active
        // menuSub.style.zIndex = '10';
        menuSub.classList.add("active");

        //- Set dropdown menu background style to match current submenu style
        subBg.style.opacity = 1;
        // subBg.style.display = 'block';
        subBg.style.left =
          menuMeta.left - (subMeta.width / 2 - menuMeta.width / 2) + "px";
        subBg.style.width = subMeta.width + "px";
        subBg.style.height = subMeta.height + "px";
        //- Set dropdown menu bottom section background position
        subBgBtm.style.top = menuSubTop.height + "px";
        //  console.log(menuSubBtm);

        // subCnt.style.display = 'block'

        //- Set Arrow position
        subArr.style.opacity = 1;
        // subArr.style.display = 'block';
        subArr.style.left = menuMeta.left + menuMeta.width / 2 - 10 + "px";

        //- Set sub menu style
        subCnt.style.opacity = 1;
        subCnt.style.left =
          menuMeta.left - (subMeta.width / 2 - menuMeta.width / 2) + "px";
        subCnt.style.width = subMeta.width + "px";
        subCnt.style.height = subMeta.height + "px";

        //- Set current sub menu style
        menuSub.style.opacity = 1;

        header.classList.add("dropdown-active");
      },
      closeDropdown = function (e) {
        //- Remove active class from all menu items
        // holder.style.display = "none";
        menuItems.forEach((el) => el.classList.remove("active"));
        //- Remove active class from all sub menus
        menuSubs.forEach((el) => {
          el.classList.remove("active");
          el.style.opacity = 0;
          //
          // el.style.display = 'none';
        });
        //- set sub menu background opacity
        // subBg.style.opacity = 0;
        // subBg.style.display = 'none';
        //- set arrow opacity
        // subArr.style.opacity = 0;
        // subArr.style.display = 'none';

        // subCnt.style.display = 'none'
        // unset selected menu
        selectedMenu = undefined;

        header.classList.remove("dropdown-active");
      };

    //- Binding mouse event to each menu items
    menuItems.forEach((el) => {
      //- mouse enter event
      el.addEventListener(
        "mouseenter",
        function () {
          stopCloseTimeout();
          openDropdown(this);
        },
        false
      );

      //- mouse leave event
      el.addEventListener("mouseleave", () => startCloseTimeout(), false);
    });

    //- Binding mouse event to each sub menus
    menuSubs.forEach((el) => {
      el.addEventListener("mouseenter", () => stopCloseTimeout(), false);
      el.addEventListener("mouseleave", () => startCloseTimeout(), false);
    });
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="modalpopup">
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <HelpingForm />
          <DialogActions>
            <Button onClick={handleClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 50 50"
                width="100%"
                height="100%"
              >
                <path
                  fill-rule="evenodd"
                  fill="currentcolor"
                  d="M25 0C11.071 0 0 11.071 0 25s11.071 25 25 25 25-11.071 25-25S38.929 0 25 0Zm0 46.429C13.214 46.429 3.571 36.786 3.571 25 3.571 13.214 13.214 3.571 25 3.571c11.786 0 21.429 9.643 21.429 21.429 0 11.786-9.643 21.429-21.429 21.429Zm9.643-8.929L25 27.857 15.357 37.5 12.5 34.643 22.143 25 12.5 15.357l2.857-2.857L25 22.143l9.643-9.643 2.857 2.857L27.857 25l9.643 9.643-2.857 2.857Z"
                ></path>
              </svg>
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <header className={`main-header ${headerClass}`}>
        {!window.location.pathname.startsWith(`/news`) && window.location.pathname != "/news" && window.location.pathname != "/use-cases" && (
          <div className="notification-header"><Link to={{
            pathname: "/news/100/skylark-labs-selected-under-the-sbir-phase-2-program-to-enhance-uas-detection-and-tracking-capabilities-for-us-airforce",
          }}>
            <h3>
            Skylark Labs Selected Under the SBIR Phase 2 Program to Enhance UAS Detection and Tracking Capabilities for U.S. Air Force
            </h3>
          </Link></div>
        )}
        <div className="header_container">

          <div className="top-nav-heder topmenubar">
            <nav role="navigation" className="top-main-header">
              <Link to="/" className="logotop"> <img
                src="images/White-Skylark-Labs-logo.png"
                alt=""
              /></Link>

              <ul className="links">

                <li>  <Link to="/self-learning-ai" className="top-level-link blogmenu">
                  Self-learning AI
                </Link>  </li>



                <li
                  selector="drop-down-menu-9"
                  className={
                    show !== 9 ? "top-level-list-item" : "top-level-list-item show"
                  }
                  onMouseOver={() => {
                    setShow(9);
                  }}
                  onMouseLeave={() => {
                    setShow(-1);
                  }}
                >
                  <Link to="#" className="top-level-link">

                    Platforms
                  </Link>
                </li>



                {/* <li
                  selector="drop-down-menu-4"
                  className={
                    show !== 7 ? "top-level-list-item" : "top-level-list-item show"
                  }
                  onMouseOver={() => {
                    setShow(7);
                  }}
                  onMouseLeave={() => {
                    setShow(-1);
                  }}
                >
                  <Link to="#" className="top-level-link">
                    Customers
                  </Link>
                </li> */}



                <li>  <Link to="/about-us" className="top-level-link blogmenu">
                  About Us
                </Link>  </li>
                <li
                  selector="drop-down-menu-4"
                  className={
                    show !== 11 ? "top-level-list-item" : "top-level-list-item show"
                  }
                  onMouseOver={() => {
                    setShow(11);
                  }}
                  onMouseLeave={() => {
                    setShow(-1);
                  }}
                >
                  <Link to="#" className="top-level-link">
                    Resources
                  </Link>
                </li>

                {/* <li>  <Link to="/press" className="top-level-link blogmenu">
                  Press
                </Link>  </li>

                <li>  <Link to="/careers" className="top-level-link blogmenu">
                  Career
                </Link>  </li>

                <li>  <Link to="/blogs" className="top-level-link blogmenu">
                  Blog
                </Link>  </li> */}
              </ul>
              <div className="right-bttion">

                <a onClick={() => {
                  handleClickOpen();
                }} className="btn requestdemo headertop-request">
                  Book a Demo
                  <span>  <img src="/images/productSlider/nextarrow.svg" alt="" /></span>
                </a>


              </div>
            </nav>



            <div
              id="drop-down-menu-1"
              className={show === 1 ? "dropdown-menu show" : "dropdown-menu hide"}
              onMouseOver={() => {
                setShow(1);
              }}
              onMouseLeave={() => {
                setShow(-1);
              }}
            >
              <div className="main-menu-container">

                <div className="col-nav col-nav-industriesright">
                  <p className="message-menu">
                    Detect threats with land-mounted cameras


                    <div className="subtages"><a href="/etos#loitering">Loitering </a> <a href="/etos#loitering">Violence Detection</a>

                      <a href="/etos#loitering">Weapon Detection</a>   <a href="/etos#suspicious">Suspicious Persons</a>
                      <a href="/etos#aries">Rogue Drones	</a>
                      <a href="/etos#vessels">Unknown Vessels</a>
                    </div>
                  </p>
                </div>
              </div>
            </div>


            <div
              id="drop-down-menu-9"
              className={show === 9 ? "dropdown-menu show" : "dropdown-menu hide"}
              onMouseOver={() => {
                setShow(9);
              }}
              onMouseLeave={() => {
                setShow(-1);
              }}
            >
              <div className="main-menu-container">
                <div className="col-nav col-nav-industries">
                  <p className="mt-0">

                  </p>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6}>
                      <ul className="menu-colblock">


                        <li>
                          <a href="/kepler">

                            <div className="subnav-icon"><img src="images/industry-level.svg" alt="" /> </div>
                            Kepler
                            <span> Plug and Play Platform for Enhanced Situational Awareness</span>
                            <p className="configurationsmenu"><a href="etos"> ETOS</a>  <a href="aries"> Counter Aerial</a>  <a href="miras">Marine</a>  <a href="dros">Airborne</a></p>

                          </a>
                        </li>



                      </ul>
                    </Grid>


                    <Grid item xs={12} sm={6} md={6}>
                      <ul className="menu-colblock">
                        <li>
                          <a href="/turing">
                            <div className="subnav-icon"><img src="images/single-module.svg" alt="" /> </div>
                            Turing

                            <span>Low-code platform for Intelligence Gathering</span>
                            <p className="configurationsmenu"><a href="social-media-intel"> Social Media</a>  <a href="news-watch"> News</a>  <a href="supply-intel">Supply Chain</a>  <a href="financial-flux">Financial</a></p>
                          </a>


                        </li>


                      </ul>

                    </Grid>
                  </Grid>


                </div>
                {/* <div className="col-nav col-nav-industriesright">
            <p className="message-menu">
            Edge, Cloud, and On-premise platforms
            <div className="subtages"><a href="/platforms#ssep">Edge Server </a>  <a href="/platforms#industry">Rack server</a> <a href="/platforms#mlp">Multi-rack Server</a>  <a href="/platforms#mlp">Self Learning</a></div>
            
         </p>
            </div> */}
              </div>
            </div>

            <div
              id="drop-down-menu-11"
              className={show === 11 ? "dropdown-menu show" : "dropdown-menu hide"}
              onMouseOver={() => {
                setShow(11);
              }}
              onMouseLeave={() => {
                setShow(-1);
              }}
            >
              <div className="main-menu-container">
                <div className="col-nav col-nav-industries widthfull">
                  <p className="mt-0">

                  </p>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4} md={4}>
                      <ul className="menu-colblock">
                        <li>
                          <a href="/press">
                            <div className="subnav-icon"><img src="images/single-module.svg" alt="" /> </div>
                            <strong className="menusubtitle">  Press</strong>


                          </a>

                        </li>


                      </ul>
                    </Grid>





                    <Grid item xs={12} sm={4} md={4}>
                      <ul className="menu-colblock">


                        <li>
                          <a href="/careers">

                            <div className="subnav-icon"><img src="images/industry-level.svg" alt="" /> </div>
                            <strong className="menusubtitle">  Careers</strong>


                          </a>
                        </li>

                      </ul>

                    </Grid>


                    <Grid item xs={12} sm={4} md={4}>
                      <ul className="menu-colblock">


                        <li>
                          <a href="/blogs">

                            <div className="subnav-icon"><img src="images/industry-level.svg" alt="" /> </div>
                            <strong className="menusubtitle"> Blogs</strong>

                          </a>
                        </li>

                      </ul>

                    </Grid>
                  </Grid>


                </div>
                {/* <div className="col-nav col-nav-industriesright">
            <p className="message-menu">
            Edge, Cloud, and On-premise platforms
            <div className="subtages"><a href="/platforms#ssep">Edge Server </a>  <a href="/platforms#industry">Rack server</a> <a href="/platforms#mlp">Multi-rack Server</a>  <a href="/platforms#mlp">Self Learning</a></div>
            
         </p>
            </div> */}
              </div>
            </div>




            <div
              id="drop-down-menu-7"
              className={show === 7 ? "dropdown-menu show" : "dropdown-menu hide"}
              onMouseOver={() => {
                setShow(7);
              }}
              onMouseLeave={() => {
                setShow(-1);
              }}
            >
              <div className="main-menu-container">
                <div className="col-nav col-nav-industries widthfull">

                  <Grid container>
                    <Grid item xs={4} sm={4} md={4}>
                      <ul className="menu-colblock industries-nav top-industries-nav">
                        <li className="titlenav">
                          Defense - Government
                        </li>
                        <li>
                          <Link to="/indian-navy">
                            <div className="subnav-icon"><img src="images/drone-systems.svg" alt="" /> </div>
                            Indian Navy <span>FOD | Maritime Target Recognition</span>

                          </Link>
                        </li>
                        <li>
                          <Link to="/indian-army">
                            <div className="subnav-icon"><img src="images/border-protection.svg" alt="" /> </div>
                            Indian Army  <span>Social Media Monitoring</span></Link>
                        </li>
                        <li>
                          <Link to="/sashastra-seema-bal">
                            <div className="subnav-icon"><img src="images/public-safety.svg" alt="" /> </div>
                            Sashastra Seema Bal  <span>Intrusion Detection | Reidentification System </span>
                          </Link>
                        </li>
                        {/* <li>
                          <Link to="/border-security-force">
                            <div className="subnav-icon"><img src="images/public-safety.svg" alt="" /> </div>
                            Border Security Force  <span>Cities | Hotels | Hospitals | stadiums </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/indian-coast-guard">
                            <div className="subnav-icon"><img src="images/public-safety.svg" alt="" /> </div>
                            Indian Coast Guard  <span>Cities | Hotels | Hospitals | stadiums </span>
                          </Link>
                        </li> */}





                      </ul>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                      <ul className="menu-colblock industries-nav top-industries-nav pb-4">
                        <li className="titlenav">
                          Defense - Industry
                        </li>


                        <li>
                          <Link to="/ideaforge">
                            <div className="subnav-icon"><img src="images/box-counting.svg" alt="" /> </div>
                            Ideaforge <span> Drone Analytics</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/mantech">
                            <div className="subnav-icon"><img src="images/transportation.svg" alt="" /> </div>
                            ManTech <span>Corrosion | FOD | Maritime Target Recognition</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/collins-aerospace">
                            <div className="subnav-icon"><img src="images/campus.svg" alt="" /> </div>
                            Collins Aerospace  <span>AI Algorithms</span>
                          </Link>
                        </li>
                      </ul>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4}>
                      <ul className="menu-colblock industries-nav top-industries-nav pb-4">
                        <li className="titlenav">
                          Commercial
                        </li>


                        <li>
                          <Link to="/mercedes">
                            <div className="subnav-icon"><img src="images/box-counting.svg" alt="" /> </div>
                            Mercedes <span> Accident Hotspot Detection   |  Xentry  </span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/jones-lang-laSalle">
                            <div className="subnav-icon"><img src="images/transportation.svg" alt="" /> </div>
                            JLL (Jones Lang LaSalle) <span> Security Infrastructure  | Security Optimization  </span>
                          </Link>
                        </li>
                        <li>
                          <Link to="/artrabia">
                            <div className="subnav-icon"><img src="images/transportation.svg" alt="" /> </div>
                            Artrabia <span> Crowd Counting System </span>
                          </Link>
                        </li>

                      </ul>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <div className="all-cus-btn-wrap">
                        <a href="/customers">
                          <div className="all-cus-btn"><span>See all customers  <img src="/images/productSlider/nextarrow.svg" alt="" /></span>   </div>
                        </a>
                      </div>
                    </Grid>

                  </Grid>

                </div>

              </div>
            </div>
          </div>


          <div className="mobiletoggle">
            <Link to="/" className="header-mobilelogo"> <img
              src="https://skylarklabs.ai/images/skylarklabs-logo.svg"
              alt=""
            /></Link>
            <div className="mobile-call">
              {/* <a href="careers" className="btn requestdemo">
            We're Hiring
            </a> */}
              <div
                id="nav-icon3"
                className={mobilemenu ? "open" : ""}
                style={{ zIndex: "100" }}
                onClick={() => {
                  console.log(mobilemenu);
                  if (mobilemenu) {
                    document.body.classList.remove("open-it");
                    setMobilemenu(false);
                  } else {
                    document.body.classList.add("open-it");
                    setMobilemenu(true);
                  }
                }}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>



      </header>

      <div className={["menuMobile" + " " + (mobilemenu && "mobilemenu-open")]}>
        <div className="mobilemenuOuter">
          <div className="mobilesolution">
            <h4 className="MobileNavMain latnav">
              <Link to="/">Home</Link>
              <div
                id="nav-icon3"
                className={mobilemenu ? "open" : ""}
                style={{ zIndex: "100" }}
                onClick={() => {
                  console.log(mobilemenu);
                  if (mobilemenu) {
                    document.body.classList.remove("open-it");
                    setMobilemenu(false);
                  } else {
                    document.body.classList.add("open-it");
                    setMobilemenu(true);
                  }
                }}
              >
                <div className="closeicons">
                  <img src="images/close_menu.svg" alt="" />
                </div>
              </div>
            </h4>

            <h4 className="MobileNavMain">
              <Link to="/self-learning-ai">Self-learning AI</Link>

            </h4>

            <h4 className="MobileNavMain productcate" onClick={() => showMenuHandler("panel6")}>
              Platforms
              <span >
                {showMenu === "panel6" ? (
                  <ExpandLessIcon style={{ fontSize: "32px" }} />
                ) : (
                  <ExpandMoreIcon style={{ fontSize: "32px" }} />
                )}
              </span>
            </h4>
            {showMenu === "panel6" && (
              <div className="mobilemenuCat">
                <ul className="mobileCustomers">
                  <li>
                    <Link to="/kepler">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav_d1.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>Kepler</span>
                        <p>  Plug and Play Platform for Enhanced Situational Awareness</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/turing">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav/aegis.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>Turing</span>
                        <p>Low-code platform for Intelligence Gathering</p>
                      </div>
                    </Link>
                  </li>

                </ul>
              </div>
            )}



            {/* 

        <h4 className="MobileNavMain productcate"  onClick={() => showMenuHandler("panel9")}>
            Aerial Systems
              <span >
                {showMenu === "panel9" ? (
                  <ExpandLessIcon style={{ fontSize: "32px" }} />
                ) : (
                  <ExpandMoreIcon style={{ fontSize: "32px" }} />
                )}
              </span>
            </h4>
            {showMenu === "panel9" && (
              <div className="mobilemenuCat">
                <ul className="mobileCustomers">
                <li>
                    <Link to="/mrts">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav_d1.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>MRTS</span>
                        <p>Missile Reconnaissance and Tracking System   </p>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dros">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav_d1.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>DROS</span>
                        <p>Drone Reconnaissance and Observation System </p>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/dros">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav_d1.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>SCDS</span>
                        <p>Satellite Change Detection System </p>
                      </div>
                    </Link>
                  </li>
                 
                
                </ul> 
              </div>



            )}


<h4 className="MobileNavMain productcate"  onClick={() => showMenuHandler("panel10")}>
Audit Systems
              <span >
                {showMenu === "panel10" ? (
                  <ExpandLessIcon style={{ fontSize: "32px" }} />
                ) : (
                  <ExpandMoreIcon style={{ fontSize: "32px" }} />
                )}
              </span>
            </h4>
            {showMenu === "panel10" && (
              <div className="mobilemenuCat">
                <ul className="mobileCustomers">
                <li>
                    <Link to="/silas">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav_d1.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>SILAS</span>
                        <p>Situational Awareness System </p>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/rdrm">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav_d1.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>RDRMS</span>
                        <p>Resource Dispatch and Report Management</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/forensics">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav_d1.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>Forensics</span>
                        <p>Investigating Video Analysis Reports </p>
                      </div>
                    </Link>
                  </li>
                 
                
                </ul> 
              </div>
            )}



<h4 className="MobileNavMain productcate"  onClick={() => showMenuHandler("panel11")}>
 Platforms
              <span >
                {showMenu === "panel11" ? (
                  <ExpandLessIcon style={{ fontSize: "32px" }} />
                ) : (
                  <ExpandMoreIcon style={{ fontSize: "32px" }} />
                )}
              </span>
            </h4>
            {showMenu === "panel11" && (
              <div className="mobilemenuCat">
                <ul className="mobileCustomers">
                <li>
                <a href="platforms#ssep"> 
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav_d1.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>SMEP</span>
                        <p> Single Module Edge Platform </p>
                      </div>
                    </a>
                  </li>

                  <li>
                  
                  <a href="platforms#mlp"> 
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav_d1.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>GMMP</span>
                        <p> Generic Multi-Module Platform </p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="platforms#industry"> 
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav_d1.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>ILP</span>
                        <p>Industry-Level Platform</p>
                      </div> 
                    </a>
                  </li>

                  <li>
                    <a href="platforms#industry"> 
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav_d1.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>SCPD</span>
                        <p>Self-adapting Client Prem Deployment </p>
                      </div> 
                    </a>
                  </li>
                 
                
                </ul> 
              </div>
            )}

  */}

            {/* <h4 className="MobileNavMain productcate" onClick={() => showMenuHandler("panel2")}>
              Customers

              <span>
                {showMenu === "panel2" ? (
                  <ExpandLessIcon style={{ fontSize: "32px" }} />
                ) : (
                  <ExpandMoreIcon style={{ fontSize: "32px" }} />
                )}
              </span>
            </h4> */}
            {showMenu === "panel2" && (
              <div className="mobilemenuCat mobileindustries">
                <div className="blockA">
                  <h5 className="mobileNav_cat">Defense - Government </h5>
                  <ul>
                    <li>
                      <Link to="/indian-navy">
                        <div className="mobmenuicons">
                          <img
                            src="../images/nav/national_security.svg"
                            alt=""
                            className="navicons"
                          />
                        </div>
                        <div>
                          <span>Indian Navy  </span>
                          <p>FOD | Maritime Target Recognition</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/indian-army">
                        <div className="mobmenuicons">
                          <img
                            src="../images/nav/Cities.svg"
                            alt=""
                            className="navicons"
                          />
                        </div>
                        <div>
                          <span>Indian Army     </span>
                          <p>Social Media Monitoring</p>
                        </div>

                      </Link>
                    </li>

                    <li>
                      <Link to="/sashastra-seema-bal">
                        <div className="mobmenuicons">
                          <img
                            src="../images/nav/Cities.svg"
                            alt=""
                            className="navicons"
                          />
                        </div>
                        <div>
                          <span>Sashastra Seema Bal  </span>
                          <p>Intrusion Detection | Reidentification System </p>
                        </div>

                      </Link>
                    </li>

                    {/* <li>
                      <Link to="/border-security-force">
                        <div className="mobmenuicons">
                          <img
                            src="../images/nav/Cities.svg"
                            alt=""
                            className="navicons"
                          />
                        </div>
                        <div>
                          <span>Border Security Force    </span>
                          <p>City Monitroring </p>
                        </div>

                      </Link>
                    </li>

                    <li>
                      <Link to="/indian-coast-guard">
                        <div className="mobmenuicons">
                          <img
                            src="../images/nav/Cities.svg"
                            alt=""
                            className="navicons"
                          />
                        </div>
                        <div>
                          <span>Indian Coast Guard  </span>
                          <p>City Monitroring </p>
                        </div>

                      </Link>
                    </li> */}

                  </ul>
                  <h5 className="mobileNav_cat"> Defense - Industry </h5>
                  <ul>
                    <li>
                      <Link to="/ideaforge">
                        <div className="mobmenuicons">
                          <img
                            src="../images/nav/national_security.svg"
                            alt=""
                            className="navicons"
                          />
                        </div>
                        <div>
                          <span>Ideaforge </span>
                          <p> Drone Analytics </p>
                        </div>
                      </Link>
                    </li>

                    <li>
                      <Link to="/mantech">
                        <div className="mobmenuicons">
                          <img
                            src="../images/nav/national_security.svg"
                            alt=""
                            className="navicons"
                          />
                        </div>
                        <div>
                          <span>ManTech </span>
                          <p>Corrosion | FOD | Maritime Target Recognition</p>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/collins-aerospace">
                        <div className="mobmenuicons">
                          <img
                            src="../images/nav/national_security.svg"
                            alt=""
                            className="navicons"
                          />
                        </div>
                        <div>
                          <span>Collins Aerospace    </span>
                          <p>School  | Universities  | Offices  </p>
                        </div>
                      </Link>
                    </li>


                  </ul>


                  <h5 className="mobileNav_cat"> Commercial </h5>
                  <ul>
                    <li>
                      <Link to="/mercedes">
                        <div className="mobmenuicons">
                          <img
                            src="../images/nav/national_security.svg"
                            alt=""
                            className="navicons"
                          />
                        </div>
                        <div>
                          <span>Mercedes  </span>
                          <p> Accident Hotspot Detection   |  Xentry  </p>
                        </div>
                      </Link>
                    </li>

                    <li>
                      <Link to="/jones-lang-laSalle">
                        <div className="mobmenuicons">
                          <img
                            src="../images/nav/national_security.svg"
                            alt=""
                            className="navicons"
                          />
                        </div>
                        <div>
                          <span>JLL (Jones Lang LaSalle)  </span>
                          <p> Security Infrastructure  | Security Optimization  </p>
                        </div>
                      </Link>
                    </li>

                    <li>
                      <Link to="/artrabia">
                        <div className="mobmenuicons">
                          <img
                            src="../images/nav/national_security.svg"
                            alt=""
                            className="navicons"
                          />
                        </div>
                        <div>
                          <span>Artrabia </span>
                          <p>Face Recognition System </p>
                        </div>
                      </Link>
                    </li>




                  </ul>
                </div>
              </div>
            )}


            <h4 className="MobileNavMain">
              <Link to="/about-us">About us </Link>
            </h4>

            {/* <h4 className="MobileNavMain">
              <Link to="/blogs">Blog</Link>
            </h4>

           

            <h4 className="MobileNavMain">
              <Link to="/press">Press </Link>
            </h4>

            <h4 className="MobileNavMain">
              <Link to="/careers">Careers </Link>
            </h4> */}

            <h4 className="MobileNavMain productcate" onClick={() => showMenuHandler("panel22")}>
              Resources

              <span>
                {showMenu === "panel22" ? (
                  <ExpandLessIcon style={{ fontSize: "32px" }} />
                ) : (
                  <ExpandMoreIcon style={{ fontSize: "32px" }} />
                )}
              </span>
            </h4>

            {showMenu === "panel22" && (
              <div className="mobilemenuCat">
                <ul className="mobileCustomers">
                  <li>
                    <Link to="/blogs">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav_d1.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>Blogs</span>
                        <p>  Plug and Play Platform for Enhanced Situational Awareness</p>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to="/press">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav/aegis.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>Press</span>
                        <p>Low-code platform for Intelligence Gathering</p>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link to="/careers">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav/aegis.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>Careers</span>
                        <p>Low-code platform for Intelligence Gathering</p>
                      </div>
                    </Link>
                  </li>

                </ul>
              </div>
            )}

            {/* <h4 className="MobileNavMain">
              <Link to="/about">About Us</Link>
              <span onClick={() => showMenuHandler("panel4")}>
                {showMenu === "panel4" ? (
                  <ExpandLessIcon style={{ fontSize: "32px" }} />
                ) : (
                  <ExpandMoreIcon style={{ fontSize: "32px" }} />
                )}
              </span>
            </h4> */}
            {showMenu === "panel4" && (
              <div className="mobilemenuCat">
                <ul className="mobileCustomers">
                  <li>
                    <Link to="/press">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav/Press.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>Press</span>
                        <p> </p>
                      </div>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/intellectual-property">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav/intellectual_property.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span> Intellectual Property</span>
                        <p> </p>
                      </div>
                    </Link>
                  </li> */}
                  <li>
                    <a href="#contact">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav/data_security.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span> Data</span>
                        <p> </p>
                      </div>
                    </a>
                  </li>

                  <li>
                    <a href="/partners">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav/intellectual_property.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span> Partners</span>

                      </div>
                    </a>
                  </li>


                  <li>
                    <a href="/about-us">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav/intellectual_property.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span> About Us</span>

                      </div>
                    </a>
                  </li>



                  <li>
                    <Link to="/careers">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav/Career.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>Career</span>
                        <p> </p>
                      </div>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/customers">
                      <div className="mobmenuicons">
                        <img
                          src="../images/nav/Career.svg"
                          alt=""
                          className="navicons"
                        />
                      </div>
                      <div>
                        <span>Customers</span>
                        <p> </p>
                      </div>
                    </Link> 
                  </li> */}
                </ul>
              </div>
            )}

            {/* <h4 className="MobileNavMain latnav">
              <Link to="/pricing">Pricing</Link>
            </h4> */}

            <div className=""></div>
          </div>
        </div>
      </div>

      <div className="graybg mobileopen"></div>
    </>
  );
};

export default Header;
