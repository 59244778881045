import React from "react";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import LinearWithValueLabel from "components/LinearProgress";
// import CrossfadeImage from "react-crossfade-image";
const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: "wrap",
    margin: "0px",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {},
}));
const ResultGrid = (props) => {
  const facesList = props.faces;
  const otherImage = props.otherImage;
  const referenceImage = props.referenceImage;

  const hasFaceMessage = props.hasFaceMessage;

  // const colCount = facesList ? facesList.length : 0;

  // console.log("in component:" + colCount);

  const classes = useStyles();
  return (
    // <div classname="row feature_inner justify-content-center mt-10">{cols}</div>

    <div
      className={classes.root}
      style={{
        paddingTop: "10px",
        justifyContent: "center",
        maxWidth: "300px",
      }}
    >
      <div style={{ width: "100%" }}>
        <Alert
          variant="filled"
          severity="success"
          style={{
            fontSize: "12px",
            padding: "2px",
            textAlign: "center",
            marginLeft: "5px",
            marginRight: "5px",
          }}
        >
          {hasFaceMessage}
        </Alert>
      </div>
      <GridList
        className={classes.gridList}
        cols={1}
        style={{
          cellHeight: "150px",
          display: "block",
          // , maxHeight: "450px"
        }}
      >
        {facesList.length > 0 ? (
          facesList.map((tile) => (
            <GridListTile
              key={tile.id}
              cols={tile.cols || 1}
              style={{
                width: "max-content",
                height: "max-content",
                justifyContent: "center",
                padding: "10px",
                margin: "10px",
              }}
            >
              <div style={{ padding: "30px", justifyContent: "center" }}>
                <img
                  alt="result"
                  id="match"
                  style={{
                    maxWidth: "130px",
                    objectFit: "contain",
                    width: "130px",
                    justifyContent: "center",
                  }}
                  src={tile.id === 0 ? referenceImage : otherImage}
                />
                <LinearWithValueLabel progress={tile.value} />
              </div>
            </GridListTile>
          ))
        ) : (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <h1>Matches will appear here</h1>
          </div>
        )}
      </GridList>
    </div>
  );
};

export default ResultGrid;
