import React, { useEffect, useRef, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import LoadingOverlay from "react-loading-overlay";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/core";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { Link, useLocation } from "react-router-dom";


function EmailValidation(enteredEmail) {
  var mail_format =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (enteredEmail.match(mail_format)) return true;
  return false;
}
function PhoneValidation(enteredPhone) {
  var phone_format =
    /^\+{0,2}([\-\. ])?(\(?\d{0,3}\))?([\-\. ])?\(?\d{0,3}\)?([\-\. ])?\d{3}([\-\. ])?\d{4}/;
  if (enteredPhone.match(phone_format)) return true;
  return false;
}

const useStyles = makeStyles((theme) => ({
  cssLabel: {
    color: "rgba(255, 255, 255, 0.7)",
    "&.Mui-focused": {
      color: "rgba(255, 255, 255, 0.7)",
    },
  },

  textfieldinput: {
    color: "white",
  },

  textfieldinnerinput: {},

  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "rgba(255, 255, 255, 0.7) !important",
    },
  },

  cssFocused: {
    borderColor: "rgba(255, 255, 255, 0.7) !important",
  },

  notchedOutline: {
    borderWidth: "1px",
    borderColor: "rgba(255, 255, 255, 0.7) !important",
  },
  grid: {
    padding: "0px 10px !important",
  },
}));

const CustomInputField = ({ ref, value, setValue, label }) => {
  const classes = useStyles();
  return (
    <TextField
      ref={ref}
      onChange={(e) => {
        e.preventDefault();
        console.log(e.target.value)
        setValue(e.target.value);
      }}
      value={value}
      id="outlined-basic5"
      label={label}
      variant="outlined"
      margin="dense"
      style={{ height: 40, paddingBottom: 0 }}
      required
      fullWidth
      InputLabelProps={{ className: classes.cssLabel }}
      InputProps={{
        classes: {
          root: classes.cssOutlinedInput,
          focused: classes.cssFocused,
          notchedOutline: classes.notchedOutline,
          input: classes.textfieldinnerinput,
        },
        className: classes.textfieldinput,
      }}
    />
  );
};

const HelpingForm = React.forwardRef((props, ref) => {
  // const location = useLocation();
  const classes = useStyles();
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");
  const [lastname, setLastname] = useState("");
  const [contact, setContact] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [cntryName, setCntryName] = useState("");
  const [businessChallenge, setBusinessChallenge] = useState("");
  const [loading, setLoading] = useState(false);
  const fnameInput = useRef(null);
  const lnameInput = useRef(null);
  const emailInput = useRef(null);
  const contactInput = useRef(null);
  const companyNameInput = useRef(null);
  const cntryNameInput = useRef(null);
  const [error, setError] = useState(null);
  const [checkbox, setCheckBox] = useState(0);
  const companyTypes = {
    0: "LAW_ENFORCEMENT",
    1: "FACTORIE",
    2: "DEFENSE",
    3: "APARTMENT_COMPEXES",
    4: "INSURANCE",
    5: "BANKS",
    6: "HOME",
    8: "OTHER",
    7: "HOTELS",
  };
  useEffect(() => {
    console.log(fnameInput.current);
    console.log(lnameInput.current);
    console.log(emailInput.current);
    console.log(contactInput.current);
    console.log(companyNameInput.current);
    console.log(cntryNameInput.current);
  }, []);
  const toggle = (index) => {
    setCheckBox(index);
  };

  // useEffect(() => {
  //   if (window.location.href.indexOf("/#contact") != -1) {
  //     try {
  //       props.scrollToView() 
  //     } catch (err) {}
  //   } else {}
  //   // ActiveMenuScroll.destroy()
  // }, [location]);


  const clearForm = () => {
    setFirstname("");
    setEmail("");
    setLastname("");
    setContact("");
    setCompanyName("");
    setCntryName("");
    setBusinessChallenge("");
    setCheckBox(0);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    setError(null);
    if (EmailValidation(email)) {
      if (PhoneValidation(contact)) {
        const data = {
          email: email,
          business_phone: contact,
          phone: contact,
          first_name: firstname,
          last_name: lastname,
          company_name: CompanyName,
          country_name: cntryName,
          company_type: companyTypes[checkbox],
          message: businessChallenge,
        };
        console.log(data);
        setLoading(true);
        fetch("https://prodserver.skylarklabs.ai/skylarklabsblog/contact/", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => {
            // console.log(res);
            clearForm();
            setLoading(false);
            toast.success("Request sent.");
          })
          .catch((err) => {
            setError("Something went wrong. Try again...");
            console.log(err.response);
            setLoading(false);
          });
      } else {
        toast.error("Invalid Phone Number.");
      }
    } else {
      toast.error("Invalid Email.");
    }
  };

  return (
    <section ref={ref} id="contact" className="helpingwith_section modalbox-helpingwith">
      <div className="container">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <div className="helpingwith_dis">
              <h2>Helping with security in your industry</h2>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <LoadingOverlay active={loading} spinner text="Sending Request...">
              <div className="helpingform">
                <form autoComplete="off" onSubmit={handleOnSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} className={classes.grid}>
                      <CustomInputField
                        ref={emailInput}
                        value={email}
                        setValue={setEmail}
                        label="Email"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className={classes.grid}>
                      <CustomInputField
                        ref={contactInput}
                        value={contact}
                        setValue={setContact}
                        label="Enter your phone"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className={classes.grid}>
                      <CustomInputField
                        ref={fnameInput}
                        value={firstname}
                        setValue={setFirstname}
                        label="First Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className={classes.grid}>
                      <CustomInputField
                        ref={lnameInput}
                        value={lastname}
                        setValue={setLastname}
                        label="Last Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={classes.grid}>
                      <CustomInputField
                        ref={companyNameInput}
                        value={CompanyName}
                        setValue={setCompanyName}
                        label="Company Name"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} className={classes.grid}>
                      {/* international num input field */}
                      {/* <IntlTelInput */}
                      {/* preferredCountries={["tw"]} */}
                      {/* onPhoneNumberChange={onChange()} */}
                      {/* // onPhoneNumberBlur={onBlur()} */}
                      {/* />   */}
                      <CustomInputField
                        ref={cntryNameInput}
                        value={cntryName}
                        setValue={setCntryName}
                        label="Country Name"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <div className="interested">
                        Please tell us more about what you are interested in?
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <div className="custom_checkBox">
                        <input
                          checked={checkbox == 0}
                          onChange={(e) => {
                            // e.preventDefault();
                            toggle(0);
                          }}
                          type="checkbox"
                          id="Border"
                        />
                        <label htmlFor="Border">Border</label>

                         
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className="custom_checkBox">
                        <input
                          checked={checkbox == 1}
                          onChange={(e) => {
                            // e.preventDefault();
                            toggle(1);
                          }}
                          type="checkbox"
                          id="socialmedia"
                        />
                        <label htmlFor="socialmedia">Social Media</label>
                         
                      </div>
                    </Grid>
                     
                    <Grid item xs={12} sm={6} md={6}>
                      <div className="custom_checkBox">
                        <input
                          checked={checkbox == 2}
                          onChange={(e) => {
                            // e.preventDefault();
                            toggle(2);
                          }}
                          type="checkbox"
                          id="counteraerial"
                        />
                        <label htmlFor="counteraerial">Counter Aerial</label>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className="custom_checkBox">
                        <input
                          checked={checkbox == 3}
                          onChange={(e) => {
                            // e.preventDefault();
                            toggle(3);
                          }}
                          type="checkbox"
                          id="news"
                        />
                        <label htmlFor="news">News</label>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className="custom_checkBox">
                        <input
                          checked={checkbox == 4}
                          onChange={(e) => {
                            // e.preventDefault();
                            toggle(4);
                          }}
                          type="checkbox"
                          id="marine"
                        />
                        <label htmlFor="marine">Marine</label>
                         
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className="custom_checkBox">
                        <input
                          checked={checkbox == 5}
                          onChange={(e) => {
                            // e.preventDefault();
                            toggle(5);
                          }}
                          type="checkbox"
                          id="supplychain"
                        />
                        <label htmlFor="supplychain">Supply Chain</label>
                         
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className="custom_checkBox">
                        <input
                          checked={checkbox == 6}
                          onChange={(e) => {
                            // e.preventDefault();
                            toggle(6);
                          }}
                          type="checkbox"
                          id="airborne"
                        />
                        <label htmlFor="airborne">Airborne</label>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <div className="custom_checkBox">
                        <input
                          checked={checkbox == 7}
                          onChange={(e) => {
                            // e.preventDefault();
                            toggle(7);
                          }}
                          type="checkbox"
                          id="financial"
                        />
                        <label htmlFor="financial">Financial</label>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                      <div className="custom_checkBox">
                        <input
                          checked={checkbox == 8}
                          onChangeCapture={(e) => {
                            // e.preventDefault();
                            toggle(8);
                          }}
                          type="checkbox"
                          id="other"
                        />
                        <label htmlFor="other">Other</label>
                      </div>
                    </Grid>

                    <Grid item xs={12}>
                      <div className="textarea_box">
                        <label>
                          Tell us more about your business challenge
                        </label>
                        <TextField
                          onChange={(e) => {
                            e.preventDefault();
                            // console.log(e.target.value);
                            setBusinessChallenge(e.target.value);
                          }}
                          variant="outlined"
                          id="standard-multiline-static"
                          label="Your Message"
                          multiline
                          rows={4}
                          value={businessChallenge}
                          required
                          fullWidth
                          InputLabelProps={{ className: classes.cssLabel }}
                          InputProps={{
                            classes: {
                              root: classes.cssOutlinedInput,
                              focused: classes.cssFocused,
                              notchedOutline: classes.notchedOutline,
                              input: classes.textfieldinnerinput,
                            },
                            className: classes.textfieldinput,
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <p
                        style={{
                          color: "red",
                          fontSize: "14px",
                          margin: "0px",
                        }}
                      >
                        {error}
                      </p>
                    </Grid>
                    <Grid item xs={12} >
                      <button type="submit" className="btn">
                        Contact Us
                      </button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </LoadingOverlay>
          </Grid>
        </Grid>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
        />
      </div>
    </section>
  );
});

export default HelpingForm;
