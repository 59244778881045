import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Link } from 'react-router-dom';


const BlogMain = () => {
    const [allBlogs, setAllBlogs] = useState([]);
    const [allEvents, setAllEvents] = useState([]);
    const [allNews, setAllNews] = useState([]);

    useEffect(() => {
        const title = document.getElementById("mainTitle");
        title.innerText = "Skylark Labs: Blogs";

        fetch(`https://prodserver.skylarklabs.ai/skylarklabsblog/all-articles/?limit=3&article_type=5`)
            .then((res) => res.json())
            .then((data) => {
                setAllBlogs(data);
            })
            .catch((err) => {
                console.log(err);
            });

        fetch(`https://prodserver.skylarklabs.ai/skylarklabsblog/all-articles/?limit=2&article_type=7`)
            .then((res) => res.json())
            .then((data) => {
                setAllEvents(data);
            })
            .catch((err) => {
                console.log(err);
            });

        fetch(`https://prodserver.skylarklabs.ai/skylarklabsblog/all-articles/?limit=2&article_type=6`)
            .then((res) => res.json())
            .then((data) => {
                setAllNews(data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    return (
        <>
            <div className="bloglanding">
                <div className="bloglanding-subblock">
                    <img src="../images/productSlider/gradient-circle.svg" alt="" className="uk-blur-large leftimg rightimgblur-img" />
                    <img src="../images/productSlider/gradient-circle.svg" alt="" className="uk-blur-large rightimg leftimgblur-img" />
                </div>
                <div className="bloglanding-subblock bloglanding-subblocktwo">
                    <div className="topgradint"></div>
                </div>

                <div className="container menuconteaner">
                    <div className="dismanbannertopblock">
                        <h1>BLogs</h1>



                        {/* <h2>AI that adapts with changing landscape to identify new unknown threats</h2> */}
                    </div>
                    <div className="blogfiltermain">
                        <div className="filterleft-top">
                            <ul>
                                {/* <li className="active">
                                    <a href="#/">Kepler</a>{" "}
                                </li>{" "}
                                <li>
                                    <a href="#/">Turing</a>{" "}
                                </li>{" "} */}
                                <li>
                                    <a href="#/">Tactical Applications  </a>{" "}
                                </li>{" "}
                                <li>
                                    <a href="#/">Intelligence Applications</a>{" "}
                                </li>{" "}
                                <li>
                                    <a href="#/">Geography</a>{" "}
                                </li>{" "}

                            </ul>
                        </div>
                        <div className="filterright-top">
                            <label class="filter-dropdown">
                                <div class="dd-button">
                                    <img src="../images/productSlider/filter.svg" alt="" />
                                    <span> Filter</span>
                                </div>

                                <input type="checkbox" class="dd-input" id="test" />

                                <ul class="dd-menu">
                                    <li>Recent Post</li>
                                    <li>Popular</li>
                                </ul>
                            </label>
                        </div>
                    </div>

                    <div className="Subscribe_form">
                        <input type="email" placeholder="search" required="" />
                        <button>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="black">
                                <path d="M16.837 5.755l.123.092 6.063 5.055.038.036-.1-.092.071.063.099.103.074.092.067.099.08.146.038.086c.071.174.11.365.11.565s-.039.39-.11.564l-.092.188-.06.095-.086.113-.079.088-.087.082-6.026 5.022a1.5 1.5 0 0 1-2.033-2.201l.113-.104 2.815-2.348H10a1.5 1.5 0 0 1-.144-2.993L10 10.5h7.857L15.04 8.152a1.5 1.5 0 0 1 1.669-2.475l.129.078zM4 10.5a1.5 1.5 0 0 1 .144 2.993L4 13.5H2a1.5 1.5 0 0 1-.144-2.993L2 10.5h2z"></path>
                            </svg>
                        </button>
                    </div>
                </div>

                <section className="container all-sections-seq">
                    <div className="rsrc-sec-line"></div>
                    <div className="rsrc-sec-head">
                        <div className="div-in-rsrc-sec-head">
                            <div className="rsrc-sec-icon-block">
                                <div className="rsrc-sec-icon w-embed">
                                    <svg width="100%" height="100%" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <path d="M16,1.15470054 L28.8564065,8.57735027 L28.8564065,23.4226497 L16,30.8452995 L3.14359354,23.4226497 L3.14359354,8.57735027 L16,1.15470054 Z" stroke="currentcolor" stroke-width="2" fill="#bcbcd1"></path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <h1 className="rsrc-sec-h">Use Cases</h1>
                        </div>
                        <div className="blogfiltermain" style={{
                            padding: 0
                        }}>
                            <div className="filterleft-top">
                                <ul>
                                    <li className="active">
                                        <a href="/use-cases">Read More...</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Grid className="grid-each-section" container spacing={3}>
                        {allBlogs?.count > 0 ? (
                            allBlogs?.results?.map((blog, index) => {
                                return (<Grid item xs={12} sm={6} md={4} lg={4} className="blogs-grid">
                                    <div className="eventand-news eventand-newstwo bloglistmain bloglistallpoint">
                                        <div className="eventboxleft blogitemslist">
                                            <div>
                                                <img src={blog.image} alt="" />
                                                {/* <h3 className="pt-3">BLOG</h3> */}
                                                <p>{blog.title}</p>
                                            </div>
                                            <Link to={{
                                                pathname: `/blog/${blog.id}/${blog.slug}`,
                                            }}>
                                                <span>
                                                    Learn More <img src="/images/productSlider/nextarrow.svg" alt="" />
                                                </span>
                                            </Link>
                                        </div>
                                    </div>
                                </Grid>)
                            })) : (
                            <Grid item xs={12}>
                                <Typography variant="h3">No Blogs Found</Typography>
                            </Grid>
                        )}

                    </Grid>
                    <br />
                    <br />
                    <div className="rsrc-sec-head">
                        <div className="div-in-rsrc-sec-head">
                            <div className="rsrc-sec-icon-block">
                                <div className="rsrc-sec-icon w-embed">
                                    <svg width="100%" height="100%" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <path d="M16,1.15470054 L28.8564065,8.57735027 L28.8564065,23.4226497 L16,30.8452995 L3.14359354,23.4226497 L3.14359354,8.57735027 L16,1.15470054 Z" stroke="currentcolor" stroke-width="2" fill="#bcbcd1"></path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <h1 className="rsrc-sec-h">Events</h1>
                        </div>
                        <div className="blogfiltermain" style={{
                            padding: 0
                        }}>
                            <div className="filterleft-top">
                                <ul>
                                    <li className="active" >
                                        <a href="/events">Read More...</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Grid className="grid-each-section" container spacing={3}>
                        {allEvents?.count > 0 ? (
                            allEvents?.results?.map((event, index) => {
                                return (<Grid item xs={12} sm={6} md={6} lg={6}>
                                    <div className="eventand-news bloglistmain">
                                        <Grid container spacing={3} style={{
                                            padding: "26px"
                                        }}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <div className="eventboxleft">
                                                    <img src={event.image} alt="" />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <div className="eventboxleft">
                                                    <p style={{
                                                        margin: 0,
                                                    }}>
                                                        {event.title}
                                                    </p>

                                                    <Link to={{
                                                        pathname: `/event/${event.id}/${event.slug}`,
                                                    }}>
                                                        <span>
                                                            Learn More <img src="/images/productSlider/nextarrow.svg" alt="" />
                                                        </span>{" "}
                                                    </Link>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>)
                            })) : (
                            <Grid item xs={12}>
                                <Typography variant="h3">No Events Found</Typography>
                            </Grid>
                        )}
                    </Grid>
                    <br />
                    <br />
                    <div className="rsrc-sec-head">
                        <div className="div-in-rsrc-sec-head">
                            <div className="rsrc-sec-icon-block">
                                <div className="rsrc-sec-icon w-embed">
                                    <svg width="100%" height="100%" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                            <path d="M16,1.15470054 L28.8564065,8.57735027 L28.8564065,23.4226497 L16,30.8452995 L3.14359354,23.4226497 L3.14359354,8.57735027 L16,1.15470054 Z" stroke="currentcolor" stroke-width="2" fill="#bcbcd1"></path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <h1 className="rsrc-sec-h">News</h1>
                        </div>
                        <div className="blogfiltermain" style={{
                            padding: 0
                        }}>
                            <div className="filterleft-top">
                                <ul>
                                    <li className="active">
                                        <a href="/news" >Read More...</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Grid className="grid-each-section" container spacing={3}>
                        {allNews?.count > 0 ? (
                            allNews?.results?.map((news, index) => {
                                return (<Grid item xs={12} sm={6} md={6} lg={6}>
                                    <div className="eventand-news bloglistmain">
                                        <Grid container spacing={3} style={{
                                            padding: "26px"
                                        }}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <div className="eventboxleft">
                                                    <img src={news.image} alt="" />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <div className="eventboxleft">
                                                    <p style={{
                                                        margin: 0,
                                                    }}>
                                                        {news.title}
                                                    </p>
                                                    {/* <div className="newdetailslinks" dangerouslySetInnerHTML={{ __html: news.content }} /> */}
                                                    <Link to={{
                                                        pathname: `/news/${news.id}/${news.slug}`,
                                                    }}>
                                                        <span>
                                                            Learn More <img src="/images/productSlider/nextarrow.svg" alt="" />
                                                        </span>{" "}
                                                    </Link>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>)
                            })) : (
                            <Grid item xs={12}>
                                <Typography variant="h3">No News Found</Typography>
                            </Grid>
                        )}
                    </Grid>
                </section>
            </div>
        </>
    );
};

export default BlogMain;