import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const Terms = () => {
  const [expanded, setExpanded] = React.useState("panel2");
  const navOption1 = React.useRef(null);
  const navOption2 = React.useRef(null);
  const navOption3 = React.useRef(null);
  const navOption4 = React.useRef(null);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = "Terms of Use - Skylark Labs";
    return () => {
      console.log("title changed");
    };
  }, []);
  return (
    <>
      <section className="aboutpage termsprivacy">
        <div className="container">
          <h1>Terms and Conditions</h1>

          <ul>
            <li>
              <p>
                DEFINITIONS: “Buyer” means Skylark Labs, Inc., a Delaware
                corporation. “Supplier” means any vendor or contractor supplying
                goods under the purchase order (“purchase order”) referenced
                hereinabove (the purchase order, along with the Terms and
                Conditions hereof, collectively, the “Purchase Order”).
              </p>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Terms;
