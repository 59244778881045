export const data = {
  "/jewellery": {
    title: "Jewellery",
    bannermobile: '../images/banner/Jewelry.jpg',
    bannerpc: '../images/banner/Jewelry.jpg',
    bannertab: '../images/banner/Jewelry.jpg',
    desc1:
      "Advanced Security Solution that protects Jewelry Stores from robberies, lurking. Decreases expenditure of manual security.",
    cases: ["ritani"],
    otherCases: [
      {
        title: "Tanishq Jewellery",
        desc: "Tanishq has emerged as India fastest growing jewellery brand and is a name which signifies superior craftsmanship, exclusive designs and superlative product quality.",
        url: "Tanishq",
      },
      {
        title: "Jamesallen",
        url: "jamesallen",
        desc: "Founded in 2006, James Allen has pioneered the way consumers shop for fine jewelry online.",
      },
    ],
    models1: ["weapon-detection"],
    models2: ["weapon-detection"],
  },
  "/bank": {
    bannermobile: '../images/banner/Bank.jpg',
    bannerpc: '../images/banner/Bank.jpg',
    bannertab: '../images/banner/Bank.jpg',
    title: "Bank",
    desc1:
      "AI solutions provide banks a new experience of security having advanced features such as weapon & violence detection, criminal detection in real time & instant alerts to security personnel.",
    cases: ["yes-bank-limited"],
    otherCases: [
      {
        title: "Citigroup Inc.",
        desc: "Citigroup Inc. or Citi (stylized as citi) is an American multinational investment bank and financial services",
        url: "citigroup-inc",
      },
    ],
    models1: ["weapon-detection", "face-detection"],
    models2: ["weapon-detection"],
    models3: ["weapon-detection"],
    models4: ["weapon-detection"],
  },
  "/education": {
    bannermobile: '../images/banner/School.jpg',
    bannerpc: '../images/banner/School.jpg',
    bannertab: '../images/banner/School.jpg',
    title: "Schools",
    desc1:
      "Provide a safe environment in schools by detecting threats such as terrorist attacks, violence incidents among students & vandalism of infrastructure as well.",
    cases: ["School"],
    otherCases: [
      {
        title: "School - XYZ",
        desc: "The PQR Public School Society is a large chain of schools. ",
        url: "University-1",
      },
    ],
    models1: ["weapon-detection", "violence-recognition"],
    models2: ["weapon-detection"],
    models3: ["face-recognition"],
  },
  "/airport": {
    bannermobile: '../images/banner/Airport.jpg',
    bannerpc: '../images/banner/Airport.jpg',
    bannertab: '../images/banner/Airport.jpg',
    title: "Airport",
    desc1:
      "Advanced AI solutions manage airport security, reduce risk, provide high tech security with smart features.",
    cases: ["Airport-1"],
    otherCases: [
      {
        title: "Sheikh Ul-Alam International Airport",
        desc: "Sheikh ul-Alam International Airport also known as Srinagar Airport or Budgam Airbase",
        url: "Airport-2",
      },
    ],
    models1: ["weapon-detection", "face-recognition"],
    models2: ["weapon-detection"],
    models3: ["face-recognition"],
  },
  "/hospital": {
    bannermobile: '../images/banner/Hospital.jpg',
    bannerpc: '../images/banner/Hospital.jpg',
    bannertab: '../images/banner/Hospital.jpg',
    title: "Hospital",
    desc1:
      "Sky larks’ Labs AI powered solutions provide high-tech security and automatic attendance management ",
    cases: ["Hospital"],
    otherCases: [
      {
        title: "ABC Hospitals",
        desc: "ABC Hospitals Enterprise Limited is an Indian multinational hospital chain headquartered in Chennai, Tamil Nadu, India.",
        url: "ABC-hospitals",
      },
    ],
    models1: ["weapon-detection", "face-recognition"],
    models2: ["weapon-detection"],
    models3: ["face-recognition"],
  },
  "/hotels": {
    bannermobile: '../images/banner/Hotel.jpg',
    bannerpc: '../images/banner/Hotel.jpg',
    bannertab: '../images/banner/Hotel.jpg',
    title: "Hotels",
    desc1:
      "AI Security Solutions for Hotel Industry to detect the threats of weapons & violence at early stages to prevent the incidents.",
    cases: ["Hotel"],
    otherCases: [
      {
        title: "Fairmont",
        desc: "Fairmont is truly a royal destination.",
        url: "fairmont",
      },
      {
        title: "Hotel Star",
        desc: "The Hotel Star, one of the first 5-star luxury hotels in XYX city",
        url: "hotel-star",
      },
    ],
    models1: ["weapon-detection", "violence-recognition", "face-recognition"],
    models2: ["weapon-detection"],
    models3: ["face-recognition"],
  },
  "/companies": {
    
    title: "Big Companies",
    desc1:
      "Reliance Digital is an Indian consumer electronics retailer. It is a subsidiary of Reliance Retail, a wholly owned subsidiary of Reliance Industries. ",
    cases: ["reliancestore"],
    otherCases: [
      {
        title: "D-Mart",
        desc: "DMart is a one-stop supermarket chain that aims to offer customers a wide range of basic home and personal products under one roof.",
        url: "d-mart",
      },
    ],
    models1: ["weapon-detection", "face-recognition"],
    models2: ["weapon-detection"],
    models3: ["face-recognition"],
  },
};
