import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef } from "react";
import Slider from "react-slick";
import clsx from "clsx";

const Indiannavy = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Indian Navy`;
  }, []);

  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);

  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "Guria NGO",
      desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
      url: "Guria-varanasi-human-trafficking",
      img: "images/cases-guria.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);

  return (
    <>
      <div className="plateformsub-page clientpage-outer">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>

        <div className="container">
          <div className="clientnavgations">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <h3>Indian Navy</h3>
                <div className="clientnavgationssubtitle"><span>Delhi,</span>  <span>India</span> </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                lg={8}
                className="customer-page-nav-links-grid"
              >
                <div className="customer-page-nav-links-wrap">
                  <ul>
                    <li>
                      <a href="#overview">Overview</a>{" "}
                    </li>
                    <li>
                      <a href="#problem">Problem</a>{" "}
                    </li>
                    <li>
                      <a href="#solution">Solution</a>{" "}
                    </li>
                    <li>
                      <a href="#result">Result</a>{" "}
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <div className="text-left logoclient">
                <img src="/images/productSlider/indian_navy.png" alt="" />
              </div>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9}>
              <div className="client" id="overview">
                <h3>Overview</h3>
                <p>
                  The Indian Navy, a pivotal force in India's defense, excels in
                  maritime and aerial security. Commanding advanced naval and
                  aerial assets, it ensures the nation's sovereignty over vast
                  sea areas and airspace. Embracing AI and cutting-edge
                  technology, the Navy is enhancing its capabilities for more
                  robust and intelligent security, reflecting its commitment to
                  modernizing and strengthening India's maritime and aerial
                  defense infrastructure.
                </p>
                {/* <img src="images\Customers\Indian-Navy\indian navy overview.png" alt="" style={{
                  borderRadius: "40px",
                }} /> */}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="problem">
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <h3>Problem</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={9}>
              <div className="client">
                <p>
                  In the fast-paced and intricate world of naval operations, the Indian Navy confronts a range of sophisticated challenges. These issues are crucial for maintaining naval security and improving operational efficiency, and they require cutting-edge solutions to ensure both safety and performance are optimized.
                </p>



                <div className="customerstwocal" style={{ padding: "0" }}>
                  <h4 style={{ padding: "0" }}>Key areas of focus include:</h4>
                </div>
                <ul>
                  <li><strong>Foreign Object Debris (FOD) Detection:</strong> This is a critical area, as managing FOD involves dealing with small, seemingly innocuous objects like screws and fragments that pose significant risks to aircraft safety and integrity. The presence of FOD on airstrips can lead to substantial damage to aircraft, jeopardizing safety and causing operational setbacks. Efficient detection methods are crucial to mitigate these risks, ensuring aircraft safety and maintaining operational readiness, thereby preventing delays and disruptions in mission-critical activities.</li>
                  <li><strong>Maritime Target Recognition:</strong> Upgrading the surveillance capabilities of Dornier aircraft is vital for the accurate identification and classification of marine entities. This enhancement is crucial for maritime security and operational efficiency.</li>
                  <li><strong>Shipyard Management:</strong> The focus here is on maintaining high levels of safety and security within shipyards. This involves robust surveillance to prevent unauthorized access and innovative methods for early detection of corrosion, both of which are key to preserving the integrity of naval assets.</li>
                </ul>
                <br />
                <img src="images\Customers\Indian-Navy\indian-prob.png" alt="" style={{
                  borderRadius: "40px",
                }} />
              </div>
            </Grid>
          </Grid>



          <Grid container spacing={3} className="pt-5 mt-5" id="solution">
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <h3>Solutions</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={9}>
              <div className="client">
                <p>
                  Skylark Labs has partnered with the Indian Navy to introduce innovative AI-based technologies aimed at enhancing naval operations and security. This collaboration brings advanced technology to the forefront of naval efficiency.
                </p>


                <div className="customerstwocal">
                  <h4>The key solutions developed are:</h4>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <img src="images\Customers\Indian-Navy\fod solution.png" alt="" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <h4 style={{
                        fontWeight: "500",
                        margin: "0",
                      }}><span className="spanfirstword">AI-Enabled</span> Mobile FOD Detection System (DebriSense)</h4>
                      <p className="pt-0 abouttext-pblock">
                        Skylark Labs has crafted an innovative vehicle-mounted system for detecting Foreign Object Debris (FOD) on runways, utilizing Kepler's BROS with base monitoring features. Equipped with high-resolution cameras and self-learning algorithms, this system specializes in identifying small, potentially hazardous debris, a vital safety concern in aviation. The integration of the BROS enhances its detection capabilities, ensuring precise identification of runway hazards. This advanced technology plays a crucial role in reducing operational disruptions and bolstering runway safety.
                      </p>
                    </Grid>
                  </Grid>
                </div>



                <div className="customerstwocal">

                  <Grid container spacing={3}>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <h4 style={{
                        fontWeight: "500",
                        margin: "0",
                      }}><span className="spanfirstword">Maritime</span> Target Recognition System</h4>
                      <p className="pt-0 abouttext-pblock">
                        Integrating MIRAS from the Kepler platform into Dornier planes elevates the Navy's maritime surveillance. This AI module enhances detection and response capabilities, ensuring swift, accurate maritime operations. Our innovation signifies a leap in maritime monitoring efficiency and precision.
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <img src="images\Customers\Indian-Navy\marine solution.png" alt="" />
                    </Grid>
                  </Grid>
                </div>



                <div className="customerstwocal pb-0 aboutbgblock">

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <img src="images\Customers\Indian-Navy\shipyard solution.png" alt="" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <h4 style={{
                        fontWeight: "500",
                        margin: "0",
                      }}><span className="spanfirstword">Advanced</span> Shipyard Management System</h4>
                      <p className="pt-0 abouttext-pblock">
                        The integration of Kepler's MIRAS enhances our system with superior surveillance and AI-driven corrosion detection, elevating safety and efficiency in shipyard operations. This innovation represents a significant leap in maritime infrastructure management.
                      </p>
                    </Grid>
                  </Grid>
                  <p>
                    Overall, these solutions from Skylark Labs mark a significant advancement in modernizing the Indian Navy's operational capabilities in both maritime and aerial domains.
                  </p>
                </div>




              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5 customerslast-col" id="result">
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <h3>Conclusion</h3>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9}>
              <div className="client">
                <p>
                  The partnership between Skylark Labs and the Indian Navy
                  represents a leap forward in utilizing AI for naval
                  operations. By integrating the AI-Based Mobile FOD Detection
                  System, the Navy elevates its safety protocols and operational
                  efficiency on runways. The Maritime Target Recognition System,
                  deployed on surveillance aircraft, significantly boosts the
                  Navy's capability in monitoring vast maritime areas.
                  Additionally, the Advanced Shipyard Management System enhances
                  the security and maintenance of naval facilities. These
                  state-of-the-art solutions place the Indian Navy at the
                  vanguard of technological innovation in maritime and aerial
                  defense.
                </p>
              </div>
              <Grid container spacing={3} className="customer-page-key-features pt-5 pb-5 mt-5 mb-5">
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img
                        src="images/productSlider/Play.svg"
                        alt=""
                      /></div>
                    <h3>
                      80% Operational Disruption Reduction
                    </h3>
                    <p>Achieved through our FOD system that drastically decreases runway operational disruptions.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img
                        src="images/productSlider/Analytics.svg"
                        alt=""
                      /></div>
                    <h3>
                      60% Surveillance Range Enhancement
                    </h3>
                    <p>Surpassing standard systems in increasing surveillance range capabilities.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img
                        src="images/productSlider/Slideshow.svg"
                        alt=""
                      /></div>

                    <h3>
                      40% Safety Incident Reduction
                    </h3>
                    <p>Enhanced surveillance and AI-driven tools significantly lowering safety incidents.</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </div>
      </div>
      <section
        className={clsx("client_testimonials")}


      >


        <div className="container">
          <div className="testimonials_outer testimonials_outerhome">
            <Slider>
              <div>
                <p><img src="images/double-quotes.png" className="double-quotes" alt="" />
                  FOD is a recognized issue, and Skylark Labs' FOD Detection System will significantly aid in mitigating the FOD situation we are currently facing at air stations.
                  <img src="images/double-quotes.png" alt="" /></p>
                <span> Chief of Naval Staff, India</span>
              </div>

            </Slider>

          </div>
        </div>

      </section>
      {/* 
      <div className="plateformsub-page">
        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Related</span> More Cases
                  </h2>
                </div>
              </Grid>
            </Grid>

            <div className="industriesListblock pt-5">
              <ul>
                {otherCases.map((ele, idx) => (
                  <li>
                    <a href="#contact" className="industriesListcol">
                      <div className="solutionListimg">
                        <img src={ele.img} alt="" />
                      </div>
                      <div className="industriesListcoltext">
                        <h4>{ele.title} </h4>
                        <p>{ele.desc}</p>
                        <div className="quicklinks casesquicklinks">
                          {" "}
                          View full case{" "}
                          <img src="images/right-arrow.png" alt="" />{" "}
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div> */}
    </>
  );
};

export default Indiannavy;
