import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";

const Privacyeurope = () => {
    const [expanded, setExpanded] = React.useState("panel2");
    const navOption1 = React.useRef(null);
    const navOption2 = React.useRef(null);
    const navOption3 = React.useRef(null);
    const navOption4 = React.useRef(null);
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    useEffect(() => {
        const title = document.getElementById("mainTitle");
        title.innerText = "Skylark Labs Privacy Policy"
        return () => {
            console.log('title changed');
        }
    }, []);
    return (
        <>
       <div className="privacybanner">
       <div className="container">
       <div className="dismanbanner">
        <div className="privacyimg">
        <img src="../images/privacy.png" alt=""/>
        </div>
              <h1>Skylark Labs Privacy Policy</h1>

              <ul>
                <li ><a href="/privacy">Privacy</a> </li>
                <li><a href="/privacy-us">US Privacy</a> </li>
                <li><a href="/privacy-canada">Canada Privacy</a> </li>
                <li><a href="/privacy-australia">Australia Privacy</a> </li>
                <li  className="active"><a href="/privacy-europe">Europe Privacy</a> </li>
               
                </ul>
              </div>
            </div>
        
       
        
      </div>

            <section className="termsprivacy">
                <div className="container">
                <div className="termsprivacycontent">
       <h2>Laws Regarding Face Recognition Technology (For Europe only)
</h2>
       <h3>
       United Kingdom

       </h3>
       <p>The use of FRT is not banned in UK.
</p>
<h4>Usage for FRT by Authority
</h4>
     <p>The usage of live automated facial recognition technology by police was been held unconstitutional by the court in Wales in the case of R(Bridges) v The Chief Constable of South Wales Police.  But the same as not banned, as the court thought the benefits would outweigh any concerns. The police were told to stop the usage, and follow appropriate privacy principles as per the Data Protection Act 2018. The Metropolitan Police in London also uses the Live FRT, and claims that it is in line with the present law. The public is also told about each use of facial recognition technology in advance of, during and afterwards.[2]
The Scottish government has released a code whose primary purpose Code is to guide the use of biometric data for justice and community safety purposes in Scotland and which will apply to Police Scotland and the Scottish Police Authority (SPA). The code is in the consultation phase. It does not ban the usage of FRT, rather it directs following of Data Privacy Principles when handling personal data.

</p>
<p>Following the case, the Surveillance Camera Commissioner released guidance for the usage of Live FRT. These are though just guidance and not law. The contents of these guidelines are applicable to Chief Officers and Police and Crime Commissioners of police forces in England and Wales. They also mention principals developed by the Biometrics and Forensics Ethics Group for consideration of ethical issues to be addressed when using FRT. The SCC has developed a ‘passport to compliance’ document which sets out a staged approach to consider when planning, implementing and operating a surveillance camera system so as to ensure it complies with the SC Code. The government is currently under consultation stage, and will make revisions after the consultations.
</p>
<p>Surveillance Camera Commissioner has also released Draft updated surveillance camera code of practice, which is applicable in relevant authorities as defined by section 33(5) of the 2012 Act in England and Wales, which also regulates the usage of FRT. This code identifies clear standards and good practice without being prescriptive about the detail of how the guiding principles must be followed, or about any specific operational, technical or competency measures which a system operator should follow. The code says the following regarding FRT
</p>
<ul>
  <li> Any use of facial recognition or other biometric characteristic recognition systems needs to be clearly justified and proportionate in meeting the stated purpose, and be suitably validated. It should always involve human intervention before decisions are taken that affect an individual adversely.</li>
<li>Any use of technologies such as ANPR or facial recognition systems which may rely on the accuracy of information generated elsewhere such as databases provided by others should not be introduced without regular assessment to ensure the underlying data is fit for purpose.
</li>
<li> When using a surveillance camera system for live facial recognition (LFR) purposes to find people on a watchlist, chief police officers should:
</li>
<li>set out and publish (a) the categories of people to be included on a watchlist and (b) the criteria that will be used in determining when and where to deploy LFR, having regard to the need only to do so for a lawful policing purpose;
</li>
<li>ensure that any biometric data that does not produce an alert against someone on the watchlist by the LFR system is deleted instantaneously or near-instantaneously;
</li>
<li>have regard to the Public Sector Equality Duty, in particular taking account of any potential adverse impact that the LFR algorithm may have on members of protected groups;
</li>
<li>establish an authorisation process for LFR deployments and identify the criteria by which officers are empowered to issue LFR deployment authorisations.
</li>
</ul>
<h3>Private use of FRT in United Kingdom
</h3>
<p>There are instances of supermarkets using the live FRT in England.[9] They are using Legitimate Interests clause under GDPR for using the FRT. The public is also warned with a signage indicating the usage of FRT. The ICO is investigating where live facial recognition is being used in the private sector and expects to report its findings early next year. As of now, the usage of FRT in private is allowed.

</p>
<h3>Germany
</h3>
<p>In 2020 German interior minister Horst Seehofer announced plans to use automatic facial recognition in numerous train stations and airports. The same was met with opposition, and hence. Further, the Federal Data Protection Commissioner Ulrich Kelber had said there was no legal basis for allowing automated facial recognition in public spaces.
In the coalition agreement between the German social democrats, the greens, and the liberal FDP, the three parties committed to banning facial recognition technologies in public spaces and restricting the use of mass surveillance tools to a minimum.
</p>
<h3>France
</h3>
<p>The usage of FRT in France is not banned. Every instance of usage has to follow the principles of GDPR regarding necessity, proportionality, consent etc. It can be inferred from the opinion of the CNIL, i.e., the data protection authority in response to the questions regarding usage of FRT in Airports. So, in civilian use, travellers have the option of using a facial recognition system called Parafe at passport checks in airports and train stations, and real-time facial recognition was used during an experiment at a Carnival event in the city of Nice in February 2019.
</p>
<p>In another instance, the French regulator raised concerns about a facial recognition trial commissioned by the Provence-Alpes-Côte d’Azur Regional Council, and which took place in two schools to control access by pupils and visitors. The regulator’s concerns were subsequently supported by a regional court in 2020. It concluded that free and informed consent of students had not been obtained and the controller had failed to demonstrate that its objectives could not have been achieved by other, less intrusive means.
</p>
<h3>Council of Europe guideline applicable to member states
</h3>
<p>Council of Europe released a guideline in January 2021 that addressed the use of facial recognition technology. The guideline does not ban the usage of FRT but only regulates it use.
</p>
<p>These guidelines provide a set of reference measures that governments, facial recognition developers, manufacturers, service providers and entities using facial recognition technologies should follow and apply. The guidelines have a general scope and cover uses of facial recognition technologies in the private and public sectors.
</p>
<p>In the press release accompanying the publication of these guidelines, the Council of Europe argued “strict rules” were necessary to ensure that facial recognition technology was used in a way that avoided unnecessary risks to privacy and ensured that individuals’ data was protected. The guideline says the Consent should not, as a rule, be the legal ground used for facial recognition performed by public authorities considering the imbalance of powers between data subjects and public authorities. For the same reason, as a rule, it should not be the legal ground used for facial recognition performed by private entities authorised to carry out similar tasks as public authorities. Legislators and decision-makers have to lay down specific rules for biometric processing by facial recognition technologies for law enforcement purposes. Laws can provide different necessity and proportionality tests depending on whether the purpose is verification or identification, considering the potential risks to fundamental rights and as long as individuals' images are lawfully collected.
Regarding usage by Private Sector that does not perform tasks similar to a public authority
The guideline says explicit, specific, free and informed consent of data subjects whose biometric data is processed is required. The consent will be according to the Article 5 of the Convention 108+. Thus, the usage should only be in the controller environment. Private entities shall not deploy facial recognition technologies in uncontrolled environments such as shopping malls, especially to identify persons of interest, for marketing purposes or for private security purposes.
</p>
<h3>European Commission</h3>
<p>A non-binding resolution passed by the EC notes that the deployment of facial recognition systems by law enforcement should be limited to clearly warranted purposes in full respect of the principles of proportionality and necessity and the applicable law; reaffirms that as a minimum, the use of facial recognition technology must comply with the requirements of data minimisation, data accuracy, storage limitation, data security and accountability, as well as being lawful, fair and transparent, and following a specific, explicit and legitimate purpose that is clearly defined in Member State or Union law; is of the opinion verification and authentication systems can only continue to be deployed and used successfully if their adverse effects can be mitigated and the above criteria fulfilled. It also calls for the permanent prohibition of the use of automated analysis and/or recognition in publicly accessible spaces of other human features, such as gait, fingerprints, DNA, voice, and other biometric and behavioural signals. In essence, The European Commission’s proposal of the bill restricts the use of remote biometric identification — including facial recognition technology — in public places unless it is to fight “serious” crime, such as kidnappings and terrorism. The FRT used would still have to follow the principles of GDPR though.</p>
<h3>
 PLEASE GET IN TOUCH WITH US

       </h3>
       <p>Email us at<a href="mailto:info@skylarklabs.ai">info@skylarklabs.ai</a>  if you have any questions about this Privacy Statement, the procedures we use to manage the information collected through the Website, or if you would like to modify or delete your information from our records.
</p>
     
     </div>

               
                </div>
            </section>
        </>
    );
};

export default Privacyeurope;
