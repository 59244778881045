import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid"; 
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";  
import Dispatch_management from "../videofiles/dispatch-management.mp4";
import Resource_management from "../videofiles/resource-management.mp4";
import Rdrms from "../videofiles/rdrm-banner.mp4";

import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import Testimonial from "home/sections/Testimonial";

const Rdrm = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs Audit Systems:  Resource Dispatch and Report Management`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [expanded, setExpanded] = React.useState("");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>
                <span className="mainsubtitle">RDRMS</span>
                Resource Dispatch and Report Management
              </h1>
              <p>
              After analyzing the scenario, RDRMS uses the information from the event and transforms it into insights to dispatch resources, and a report is then prepared for further use.

              </p>

              <div className="subtages"> 
                <a href="#/"> Resource Management </a> <a href="#/">Dispatch Management</a>  <a href="#/">Report Management</a>
          

              </div>
             
            </div>
          </div>
        </div>

        <ReactPlayer
                  controls={true}
                  playsinline
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Rdrms}
                />
      </div>

      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>   Resource Dispatch and Report Management</h2>
            <p>
            After analyzing the scenario, RDRMS uses the information from the event and transforms it into insights to dispatch resources, and a report is then prepared for further use.

            </p>
          </div>
        </div>
      </div>

      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section darkborder"
        id="resourcemanagement"
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="fistblock bordernone">
                <div className="singaldeploymentbox"> 
              
                <ReactPlayer
                  controls={true}
                  playsinline
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Resource_management}
                />
        
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="homeright-block fistblockight homeright-block-auto">
                <div>
                  <h4 className="mb-0">Resource Management</h4>
                  <p className="pt-0">
                  To keep track of critical information, the system saves video analysis and scene statistics as reports
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section lightgray"
      >
        <div className="container">
          <Grid container spacing={3} className="boxmobileone">
            <Grid item xs={12} sm={12} md={6}>
              <div className="homeright-block homeright-blockleft">
                <h4>
                  <span>Product Capabilities</span>Dispatch Management
                </h4>
                <p>
                The nearest first responder team is sent to the scene of the incident after RDRMS analyses the situation and determines the resources needed.

                </p>

               
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
            <div className="singaldeploymentbox">
            <ReactPlayer
                  controls={true}
                  playsinline
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Dispatch_management}
                />

              
              </div>
            </Grid>
          </Grid>
        </div>
      </section> 

      
     

 
      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section white-bg"
        id="surveillance"
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}  lg={6}>
              <div className="videoleftblock">
              <img src="images/report.png" alt=""/>

              </div>
            </Grid>
            <Grid item  xs={12} sm={12} md={6}  lg={6}>
              <div className="homeright-block">
              <div>
                  <h4>
                    <span>Product Capabilities</span>Report Management
                  </h4>
                  <p className="pt-0 mt-0">
                  To keep track of critical information, the system saves video analysis as reports for fast access to search results and alerts of interest by adding comments and annotations to the alarms inside the report.

                  </p>
                </div>
               

               
              </div>
            </Grid>
          </Grid>
        </div>
      </section> 

    
{/* 
      <section className="homeourproduct lightgray">
        <div className="container">
          <div className="homeourproductblockmain">
            <div className="homeourproductblock">
              <div className="homeproductnew">
                <img src="images/banner/hawkeye02.jpg" alt="" />
                <div className="homeproductnew-dis">
                  <div className="titledis">Search Resemblance</div>
                  <p>
                    Our AI analytics algorithms will evaluate the uploaded image
                    and provide all alarms matching that appearance from all
                    cameras on the site.
                  </p>
                 
                </div>
              </div>
            </div>
            <div className="homeourproductblock">
              <div className="homeproductnew">
                <img src="images/banner/Border-Security_Header-1.jpg" alt="" />
                <div className="homeproductnew-dis">
                  <div className="titledis">Specific video search</div>
                  <p>
                    Our system will do site-wide searches for certain video
                    analytics events, filtering by loitering, crowd size, and
                    other factors, and will promptly show relevant results for
                    forensic video analysis and investigation.
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
       */}

      <section
        ref={section6}
        className="benefitmain industriesuses bggray latticeos_faq lightgray"
        id="admincontrol"
      >
        <div className="container">
          <div className="benefitssection  ">
            <h2 className="mb-0 text-center">Features</h2>
          </div>

          <div className="listofindust skylarksuite">
            <ul>
              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/object-detection.svg" alt="" />
                  </span>
                  <h5>Small Memory Footprint</h5>
                  <p>
                    Product has been optimised to use a minimal amount of memory
                    for running.
                  </p>
                </div>
              </li>

              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/icon-real-time-alert.svg" alt="" />
                  </span>
                  <h5>Search by Date/time</h5>
                  <p>
                    Easy to search particular events by date, time and save
                    search time.
                  </p>
                </div>
              </li>

              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/icon-face-matches.svg" alt="" />
                  </span>
                  <h5>Face Matches</h5>
                  <p>
                    Matches the face of the person involved in crime with the
                    existing database.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Testimonial />
    </>
  );
};

export default Rdrm;
