import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";

const Singleblogpage = (props) => {
  const [blogData, setBlogData] = useState(null);
  var options = { year: "numeric", month: "short", day: "numeric" };

  useEffect(() => {
    handleLoadFetchBlog()
  }, []);

  const handleLoadFetchBlog = () => {
    const query = new URLSearchParams(props.location.search);
    const id = query.get("id");
    if (!id) {
      window.location.pathname = "/blog";
      return;
    } else {
      fetchBlog(id);
    }
  }

  const fetchBlog = (blog_id) => {
    fetch(`http://paasd-Publi-XTGXRE5GD531-1419742008.us-east-1.elb.amazonaws.com/skylarkblog/getblog/${blog_id}/`)
      .then((res) => res.json())
      .then((data) => {
        setBlogData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = "Skylark Labs: Blog";
    return () => {
      console.log("title changed");
    };
  }, []);

  return (
    <>
      {
        blogData &&
        <div className="blogouter">
          <section className="main-top">
            <section className="section-hero hero-post">
              <div className="container">
                <div className="post-hero-block">
                  <div className="text-container no-margin">
                    <h1 className="news-post-h">{blogData["title"]}</h1>
                    <div className="authore">
                      <ul>
                        <li>
                          Author:<span>{blogData["author"]}</span>
                        </li>
                        <li>
                          Post :<span>{new Date(blogData["timeStamp"]).toLocaleDateString("en-US", options)}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="main-top">
              <div className="container">
                <div className="main-page-title">
                  <div class="top-breadcrumbs">
                    <ul>
                      <li>
                        <a href="/blog">Home</a>{" "}
                      </li>
                      <li> {blogData["title"]} </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            <section class="main-top  detailpage mt-0 pt-0">
              <div class="container">
                <Grid container spacing={3} className="bottom-pb-6">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="bottomspace-item">
                    <div className="blog-detailpage">
                      <div className="singleblogtext" dangerouslySetInnerHTML={{ __html: blogData["content"] }} />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </section>
          </section>
        </div>
      }
    </>
  );
};

export default Singleblogpage;