import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef } from "react";

const Ideaforgetech = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Idea Forge Tech`;
  }, []);

  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);

  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "Guria NGO",
      desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
      url: "Guria-varanasi-human-trafficking",
      img: "images/cases-guria.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);

  return (
    <>
      <div className="plateformsub-page clientpage-outer">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>

        <div className="container">
          <div className="clientnavgations">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <h3>Idea Forge Tech</h3>
                <div className="clientnavgationssubtitle"><span>Mumbai,</span>  <span>India</span> </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                lg={8}
                className="customer-page-nav-links-grid"
              >
                <div className="customer-page-nav-links-wrap">
                  <ul>
                    <li>
                      <a href="#overview">Overview</a>{" "}
                    </li>
                    <li>
                      <a href="#problem">Problem</a>{" "}
                    </li>
                    <li>
                      <a href="#solution">Solution</a>{" "}
                    </li>
                    <li>
                      <a href="#result">Result</a>{" "}
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <div className="text-left logoclient">
                <img src="images/productSlider/Ideaforge.png" alt="" />
              </div>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client" id="overview">
                <h3>Overview</h3>
                <p>
                  IdeaForge, a pioneer in the UAV (Unmanned Aerial Vehicle) industry, is making strides toward integrating Artificial Intelligence (AI) into its innovative drone solutions. Renowned for designing versatile drones that excel in surveillance, mapping, and inspection, ideaForge's products stand out for their high endurance, robust performance, and adaptability in diverse operational environments. By embracing AI, ideaForge aims to elevate these drones beyond traditional capabilities, transforming them into advanced, intelligent systems for enhanced operational efficiency, real-time data processing, and autonomous decision-making, thereby setting new benchmarks in drone technology across various fields.
                </p>
                {/* <img src="images\Customers\Ideaforge\ideaforge overview.jpg" alt="" style={{
                  borderRadius: "40px",
                }} /> */}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="problem">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Problem</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  IdeaForge was challenged to improve its drone technology for sophisticated security tasks like managing vehicles, controlling perimeters, and monitoring infrastructure. The main hurdle was processing and analyzing the large amounts of video data collected by the drones in real-time. This required a system capable of efficiently managing heavy data streams, delivering precise analytics, and functioning well in different environmental conditions. Additionally, the system needed to be optimized for on-edge deployment to ensure minimal delay and maximize operational efficiency.
                </p>
                <img src="images\Customers\Ideaforge\idea forge problem.png" alt="" style={{
                  borderRadius: "40px",
                }} />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="solution">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Solutions</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  Skylark Labs has enhanced IdeaForge's AI-driven drone technology by integrating the DROS from Kepler, focusing on boosting situational awareness in real-time video analytics. These advanced drones, equipped with the DROS, excel in identifying and tracking objects, analyzing patterns, and making autonomous decisions based on live data. This integration significantly heightens situational awareness, aiding in quick, informed decision-making during security operations. The DROS's real-time data processing capability is key to agile responses in rapidly changing situations. Designed for durability and reliability, these drones consistently perform in various environments. The system's user-friendly interface also ensures that this sophisticated surveillance technology remains easily operable for everyday use.
                </p>
                <img src="images\Customers\Ideaforge\ideaforgesolution.png" alt="" style={{
                  borderRadius: "40px",
                }} />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="result">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Conclusion</h3>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  The deployment of drone analytics with IdeaForge's drones marks a significant advancement in drone-based surveillance technology. This collaboration showcases how AI-enhanced analytics can revolutionize UAV capabilities, offering efficient, real-time insights for complex security operations and positioning IdeaForge at the forefront of innovation in the UAV sector.
                </p>
              </div>
              <Grid container spacing={3} className="customer-page-key-features">
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img
                        src="images/productSlider/Play.svg"
                        alt=""
                      /></div>
                    <h3>
                      90% Enhanced Situational Awareness
                    </h3>
                    <p>AI analytics on drones boost situational awareness, crucial for rapid and effective decision-making in security scenarios.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img
                        src="images/productSlider/Analytics.svg"
                        alt=""
                      /></div>
                    <h3>
                      70% Increased Efficiency in Real-Time Responses
                    </h3>
                    <p>The system's real-time data processing enhances response efficiency, ensuring swift action in dynamic security environments.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img
                        src="images/productSlider/Analytics.svg"
                        alt=""
                      /></div>
                    <h3>
                      60% Enhanced Reliability in Varied Environments
                    </h3>
                    <p>The drones' robust build and adaptable AI improve performance consistency across diverse conditions.</p>
                  </div>
                </Grid>

              </Grid>
            </Grid>

          </Grid>

        </div>
      </div>
      {/* 
      <div className="plateformsub-page">
        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Related</span> More Cases
                  </h2>
                </div>
              </Grid>
            </Grid>

            <div className="industriesListblock pt-5">
              <ul>
                {otherCases.map((ele, idx) => (
                  <li>
                    <a href="#contact" className="industriesListcol">
                      <div className="solutionListimg">
                        <img src={ele.img} alt="" />
                      </div>
                      <div className="industriesListcoltext">
                        <h4>{ele.title} </h4>
                        <p>{ele.desc}</p>
                        <div className="quicklinks casesquicklinks">
                          {" "}
                          View full case{" "}
                          <img src="images/right-arrow.png" alt="" />{" "}
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div> */}
    </>
  );
};

export default Ideaforgetech;