import React from 'react'

/*

tabs prop should be like this 

tabs = [
    {
        id: 1,
        text: 'First Item',
        content: <>Content</>,
        image: '',
    },
    {
        id: 2,
        text: 'First Item',
        content: <>Content</>,
        image: '',
    }
    ...
]

*/

export default function RoundedTabSwitcher({ tabs = [], imagePosition = "right", getActiveTab, hideImage = true }) {
    const [activeTab, setActiveTab] = React.useState(tabs[0]);

    React.useEffect(() => {
        getActiveTab(activeTab.id)
    }, [activeTab])

    const handleChangeActiveTab = (tabId) => {
        setActiveTab(tabs.find((tab) => tab.id === tabId) || tabs[0]);
    };

    const renderTabContent = (tabId) => {
        return tabs.find((tab) => tab.id === tabId)?.content
    }

    const renderImage = (imageSrc, imagePos) => {
        switch (imagePos) {
            case 'left':
                return <div className="p-2 latestnewshome">
                    <div className="newsbox-home productslider">
                        <a href="/" target="blank">
                            <img src={imageSrc} alt="" />
                        </a>
                    </div>
                </div>
            case 'right':
                return <div className="p-2 latestnewshome">
                    <div className="newsbox-home productslider">
                        <a href="/" target="blank">
                            <img src={imageSrc} alt="" />
                        </a>
                    </div>
                </div>
            default:
                break;
        }
    }

    return (
        <>
            <div className="plateformtabs">
                {
                    tabs.map((t) => {
                        return <a
                            className={t.id === activeTab.id ? "active" : ""}
                            href="##"
                            onClick={() => {
                                handleChangeActiveTab(t.id);
                            }}
                        >{t.text}</a>
                    })
                }
            </div>
            {renderTabContent(activeTab.id)}
            {!hideImage && renderImage(activeTab.image, imagePosition)}
        </>
    )
}
