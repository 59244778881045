import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid"; 
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import videobanner from "../videofiles/occulus.mp4"; 
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import Testimonial from "home/sections/Testimonial";
import Silastestimonial from "home/sections/Silas-testimonial";

const Silas = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs Audit Systems: Situational Awareness System`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [expanded, setExpanded] = React.useState("");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>
                <span className="mainsubtitle">Silas</span> 

                Situational Awareness System


              </h1>
              <p>
              Silas understands the characteristics of entities involved
                in an incident and provides a detailed analysis to the concerned
                authorities through mobile and web channels.
              </p>
<div class="subtages">
<a href="#/">Vehicle Characteristics</a> <a href="#/"> Person Characteristics</a>  <a href="#/"> Number of People Count</a>  <a href="#/"> Scene Statistics </a>

</div>
              
              
            </div>
          </div>
        </div>

        <div className="imgcenter bannerimgblock ">
          <ReactPlayer
            controls={true}
            playsinline
            volume={0}
            width="100%"
            height="100%"
            loop={true}
            muted={false}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url={videobanner}
          />
        </div>
      </div>

      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>
            Detailed Analysis for First Responders


            </h2>
            <p>
            Silas understands the characteristics of entities involved
                in an incident and provides a detailed analysis to the concerned
                authorities through mobile and web channels.
            </p>
          </div>
        </div>
      </div>

      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section darkborder"
        
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <div className="videoleftblock fistblock bordernone">
                <div>
                 
                  <img src="images/Hawkeye-banner.png" alt="" />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="homeright-block fistblockight homeright-block-auto">
                <div>
                  <h4 className="mb-0">
                  Optimal Analysis for First Responders


                  </h4>
                  <p className="pt-0">
                  Silas uses state-of-the-art computer vision and deep
                    learning algorithms to provide an overview of the threats to
                    the first responders that helps them to provide appropriate
                    responses to the critical events. It provides detailed and
                    actionable threat insights to safeguard various
                    infrastructures.
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section lightgray" id="personcharacteristics"
       
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}>
              <div className="videoleftblock">
              <img src="images/cognizance-scene-statistics.png" alt=""/>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="homeright-block">
                <h4>
                  <span>Product Capabilities</span>Person Characteristics
                </h4>
                <p className="pt-0 mt-0">
                  {" "}
                  Provides insights about the scene of the event like location
                  of the incident.{" "}
                </p>

                <ul className="mb-4">
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-vehicle-characteristics.svg"
                          alt=""
                        />
                      </span>{" "}
                      Vehicle<br></br> Characteristics
                    </div>{" "}
                  </li>
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-number-people-count.svg"
                          alt=""
                        />
                      </span>
                      Number of <br></br>People Count
                    </div>
                  </li>
                </ul>
                <div class="bottmaligh">
                  <Link to="/number-plate-recognition" className="btn">
                    Schedule a Demo
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="homeourproduct poductCatename productpag_section ">
        <div className="container">
          <Grid container spacing={3} className="boxmobileone">
            <Grid item xs={12} sm={12} md={6}>
              <div className="homeright-block homeright-blockleft">
                <div>
                  <h4>
                    <span>Product Capabilities</span>Scene Statistics 
                  </h4>
                  <p className="pt-0 mt-0">
                    {" "}
                    Provides the characteristics of the suspect such as facial
                    description, gender, disguises used, attire description etc.{" "}
                  </p>
                </div>

                <div class="bottmaligh">
                  <Link to="/face-recognition" className="btn">
                    Try Our Demo
                  </Link>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="videoleftblock">
              <img src="images/cognizance-person-statistics.png" alt=""/>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

     
      {/* 
      <section className="homeourproduct">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <h4>
                <span>Product</span>
                The Full Spectrum Of Aviation
              </h4>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <p className=" pt-5 mt-4 mb-0">
                We’re building Hivemind, our AI Pilot, to enable swarms of
                drones and aircraft to operate autonomously without GPS,
                communications.
              </p>
            </Grid>
          </Grid>
          <div className="homeourproductblockmain collum">
            <div className="homeourproductblock">
              <Link to="/">
                <div className="homeproductnew">
                  <img src="images/banner/hawkeye02.jpg" alt="" />
                  <div className="producttitle-bottom">
                    <h3>Product Name</h3>
                    <div className="nextarrow-block">
                      <img src="images/arrow-white-big.svg" alt="" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="homeourproductblock">
              <Link to="/">
                <div className="homeproductnew">
                  <img src="images/banner/hawkeye02.jpg" alt="" />
                  <div className="producttitle-bottom">
                    <h3>Product Name</h3>
                    <div className="nextarrow-block">
                      <img src="images/arrow-white-big.svg" alt="" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="homeourproductblock">
              <Link to="/">
                <div className="homeproductnew">
                  <img src="images/banner/hawkeye02.jpg" alt="" />
                  <div className="producttitle-bottom">
                    <h3>Product Name</h3>
                    <div className="nextarrow-block">
                      <img src="images/arrow-white-big.svg" alt="" />
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section> */}

      <section
        ref={section6}
        className="benefitmain industriesuses bggray latticeos_faq lightgray "
      
      >
        <div className="container">
          <div className="benefitssection  ">
            <h2 className="mb-0 text-center">Features</h2>
          </div>

          <div className="listofindust skylarksuite">
            <ul>
              <li className="text-center">
                <div className="featuresblock featuresblockbgthree">
                  <span>
                    <img src="images/object-detection.svg" alt="" />
                  </span>
                  <h5>Multiple Behaviours</h5>
                  <p>
                    Recognizes multiple behaviours like violence, intrusion,
                    lurking, aggression.
                  </p>
                </div>
              </li>

              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/object-detection.svg" alt="" />
                  </span>
                  <h5>Economical</h5>
                  <p>
                    Product has been optimised to use a minimal amount of memory
                    for running.
                  </p>
                </div>
              </li>

              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/icon-real-time-alert.svg" alt="" />
                  </span>
                  <h5>Computationally Efficient</h5>
                  <p>
                    Less resource consumption, requires less amount of time and
                    space.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Silastestimonial />
    </>
  );
};

export default Silas;
