import React, { useEffect, useState, useRef } from "react"; 
import Grid from "@material-ui/core/Grid"; 
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";   
import videobanner from "../../videofiles/airborne-banner.mp4";
import Satellite from "../../videofiles/airborne-missile-systems.mp4";
import Satellitesystem from "../../videofiles/Satellite-system.mp4"; 
import Aerialsystemshome from "../../videofiles/aerial-systems-home.mp4"; 


import { Typography } from "@material-ui/core";
import PropTypes from 'prop-types'; 
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core"; 
const Airbornesystems = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Airborne Systems`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [showTrial, setShowTrial] = React.useState(false);
  const [expanded, setExpanded] = React.useState("panel2");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };
  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },
 
    {
      title: "Guria NGO",
      desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
      url: "Guria-varanasi-human-trafficking",
      img: "images/cases-guria.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      // if (section4.current && offsetY >= section4.current.offsetTop)
      //   addActiveClass(navOption4);
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChangenew = (event, newValue) => {
    setValue(newValue);
  };
  
 

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>Airborne Systems</h1>
              <p>
              Skylark labs' advanced AI Airborne Systems are used to detect threats from the air which are beyond the line of sight and threatening the nation's security.

              </p>
              <div className="subtages"><a href="#/">Drone </a>  <a href="#/">Satellite </a> <a href="#/">Brahmos</a> </div>
           
            </div>
          </div>
        </div>
       
        <div className="imgcenter bannerimgblock ">
          <ReactPlayer
            controls={true}
            playsinline
            volume={0}
            width="100%"
            height="100%"
            loop={true}
            muted={false}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url={videobanner}
          />
        </div>
       
      </div>

      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>Airborne Systems</h2>
            <p>
            Skylark labs' advanced AI Airborne Systems are used to detect threats from the air which are beyond the line of sight and threatening the nation's security.

            </p>
            
          </div>
        </div>
      </div>


      <div className="plateformsub-page">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>
        <div className="container pt-5">
          <div className="plateformabout  mt-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className="aboutimgblock">
                  {" "}
                  <img src="images/airborne.png" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 pb-0">
                      <span className="spanfirstword">Airborne </span> Airborne Systems
                    </h2>
                    <p className="pt-0 abouttext-pblock">
                    National property destruction, whether intentional or unintentional, is unavoidable, terrible, and has a significant impact on a nation's dignity. It's far worse when vandalism is involved. To help prevent these acts, our systems deliver preemptive notifications and take appropriate action depending on the insights gained from the occurrence.

                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <div className="container pt-5">
          <div className="seactionblocktitles ">
            <h2 className="seactiontitles"> Emerging Threats</h2>
            <p>
              ETOS detects suspicious indicators using our proprietary
              technology to predict emerging threats.
            </p>
          </div>
        </div>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div> 
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Missile</span>{" "}
                       Systems

                    </h4>
                    <p>
                    Skylark’s Missile Reconnaissance and Tracking System detects threats and targets from the air with help of missiles using radar and lasers which are beyond the line of sight


                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Underwater vessels
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Target Infrastructure of Interest
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="videoblock-plateforms">
                  <ReactPlayer
                    controls={true}
                    playsinline
                    volume={0}
                    width="100%"
                    height="100%"
                    loop={true}
                    muted={false}
                    playing={true}
                    stopOnUnmount={true}
                    config={{
                      file: {
                        attributes: {
                          autoplay: true,
                          controls: false,
                          loop: true,
                          muted: true,
                        },
                      },
                    }}
                    url={Satellite}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>


        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="videoblock-plateforms"> 
                  <div className="videoblock-plateforms">
                  <ReactPlayer
                    controls={true}
                    playsinline
                    volume={0}
                    width="100%"
                    height="100%"
                    loop={true}
                    muted={false}
                    playing={true}
                    stopOnUnmount={true}
                    config={{
                      file: {
                        attributes: {
                          autoplay: true,
                          controls: false,
                          loop: true,
                          muted: true,
                        },
                      },
                    }}
                    url={Aerialsystemshome}
                  />
                </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Drone</span>  Systems

                    </h4>
                    <p>
                    With the use of drones, Skylark's Drone Reconnaissance and Observation System can identify threats from the air, such as camouflaged vehicles, suspicious individuals, and objects, as well as risks that are invisible to the human eye.


                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Intrusion Detection
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Traffic Management
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>


        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Satellite</span>  Systems
                    </h4>
                    <p>
                    Skylark's Satellite Change Detection System tracks any changes to the landscape, such as those caused by construction or changes to troop movements in wartime settings with the aid of satellites.

                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Infrastructure Monitoring
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Troop Movements
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={6}>
                <div className="videoblock-plateforms">
                  <ReactPlayer
                    controls={true}
                    playsinline
                    volume={0}
                    width="100%"
                    height="100%"
                    loop={true}
                    muted={false}
                    playing={true}
                    stopOnUnmount={true}
                    config={{
                      file: {
                        attributes: {
                          autoplay: true,
                          controls: false,
                          loop: true,
                          muted: true,
                        },
                      },
                    }}
                    url={Satellitesystem}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        
<section
        ref={section1}
        className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
      >
        <div className="container pb-5 mb-5">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="">
                <h2>
                  <span className="spanfirstword">Read</span> More Cases

                </h2>
              </div>
            </Grid>
          </Grid>
         
          <div className="industriesListblock pt-5">
            <ul>
              {otherCases.map((ele, idx) => (
                
         <li>
          <a href="#contact" className="industriesListcol">
         
           <div className="solutionListimg">
             <img src={ele.img} alt="" />
           </div>
           <div className="industriesListcoltext">
             <h4>{ele.title} </h4>
             <p>{ele.desc}</p>
             <div className="quicklinks casesquicklinks">
               {" "}
               View full case{" "}
               <img src="images/right-arrow.png" alt="" />{" "}
             </div>
           </div>
           </a>
       </li>

              ))}
            </ul>
          </div>
        </div>
      </section>



</div> 
 

    </>
  );
};

export default Airbornesystems;
