import React from "react";
import { exampleData } from "usecases/exampleData";
import { Link } from "react-router-dom";

const Cases = ({ caseList }) => {
  const CaseButton = (props) => {
    const { url } = props;

    return (
      <Link to={url} className="btn" style={{ color: "#000000" }}>
        Read full case
      </Link>
    );
  };

  return (
    <>
      {exampleData &&
        caseList &&
        Object.entries(exampleData)
          .filter(([key, item]) => caseList.includes(key))
          .map(([key, item]) => (
            <div key={item.title} className="usecasesProduct usecase_space">
              <div className="usecases_dis">
                <div className="solution_usescases_list">
                  <h3>{item.title}</h3>
                  <p className="usecases_info">{item.desc1}</p>
                </div>
                <div className="usecaseCompany_logo">
                  <img src={item.logo} alt="" />

                </div>
              </div>
              <div className="usecases_Item solution_usescases">
                <ul>
                  <li>
                    <h4>Problem</h4>
                    <p>{item.descProblem}</p>
                  </li>
                  <li>
                    <h4>Solution</h4>
                    <p>{item.descSolution}</p>
                  </li>
                  <li>
                    <h4>Conclusion</h4>
                    <p>{item.descResult}</p>
                  </li>
                </ul>
              </div>
              {/* ***** Please create new class ***** */}
              <div className="usecases_count exampleredults">
                <ul>
                  {item.after.map((i) => {
                    return (
                      <>
                        <li>
                          <div className="countBOx ">
                            <h4>{i.cnt}</h4>
                            <p>{i.des}</p>
                          </div>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </div>
              <div className="fullcases bottmaligh">
                <CaseButton url={`/case${key}`} />
              </div>
            </div>
          ))}
    </>
  );
};

export default Cases;
