import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import useApi from "../api";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Grid from "@material-ui/core/Grid";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Blogdetail from "blog/BlogDetailTemplate";

export default function TextEditor({ setLock, page, setAction, data }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [html, setHtml] = useState(null);
  const [title, setTitle] = useState(data.title);
  const [tag, setTag] = useState(data.tag);
  const [id, setId] = useState(data.id);
  const [slug, setSlug] = useState(data.slug);
  const [excerpt, setExcerpt] = useState(data.excerpt);
  const [banner, setBanner] = useState(data.banner);
  const [readTime, setreadTime] = useState(0);
  const [views, setViews] = useState(0);
  const [date, setDate] = useState(data.date);
  const [category, setCategory] = useState(data.category);
  const [categoryPost, setCategoryPost] = useState("");
  const [categories, setCategories] = useState([]);
  // const [tag, setTag] = useState(data.tag);
  const [tagPost, setTagPost] = useState("");
  const [tags, setTags] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [author, setAuthor] = useState("");
  const api = useApi();
  const postData = Object.freeze({
    title: "",
    tag: "",
    excerpt: "",
    banner: "",
    content: "",
    category: "",
    read_time: "",
    views: "",
    slug: "",
    writer: ""
  });
  const [posts, setPosts] = useState(postData);

  const onEditorStateChange = (edits) => {
    setEditorState(edits);
    setHtml(draftToHtml(convertToRaw(edits.getCurrentContent())));
  };

  const getCategories = () => {
    api
      .get("api/category/")
      .then((res) => {
        console.log(res.data);
        setCategories(res.data);
        setCategory(document.getElementById("select-category").value);
      })
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    console.log(categories);
    if (!categories.length) getTags();
    for (let item of categories) {
      console.log(category);
      if (item.id.toString() === category.toString()) {
        console.log(item.tags);
        setTags(item.tags);
        setTag(document.getElementById("select-tag").value);
      }
    }
  }, [category, categories]);

  const getTags = () => {
    api
      .get(`api/tag/?category=${category}`)
      .then((res) => {
        setTags(res.data);
        setTag(document.getElementById("select-tag").value);
      })
      .catch((err) => console.log(err.message));
  };

  // useEffect(() => {
  // getCategories();
  // setLock(true);
  // }, []);

  useEffect(() => {
    setTitle(data.title);
    // setTag(data.tag);
    // document.getElementById("select-tag").value = data.tag
    setBanner(data.banner);
    setExcerpt(data.excerpt);
    setId(data.id);
    setSlug(data.slug);
    // setCategory(data.category);
    // document.getElementById("select-category").value = data.category
    setreadTime(data.read_time ?? 0);
    setViews(data.views ?? 0);
    setDate(data.published);
    setAuthor(data?.writer);
    getCategories();
  }, [data]);

  useEffect(() => {
    setLock(true);
    setPosts({
      title: title.trim(),
      tag: tag,
      banner: banner.trim(),
      excerpt: excerpt.trim(),
      content: html,
      id: id,
      slug: slug,
      category: category,
      read_time: readTime,
      views: views,
      published: date,
      writer: author,
    });
    console.log(posts);
  }, [title, tag, banner, excerpt, html, category, readTime, views, date, author]);

  const htmlToEditor = (markup) => {
    setHtml(markup);
    const blocksFromHTML = convertFromHTML(markup);
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    setEditorState(EditorState.createWithContent(state));
  };

  const deletePost = (slug) => {
    var result = window.confirm(`Want to delete ${slug}?`);
    if (!result) {
      console.log(result);
      return;
    }
    api
      .delete(`api/blog/${slug}`)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => console.log(err.message));
  };

  useEffect(() => {
    if (data) {
      htmlToEditor(data.content);
    }
  }, [data]);

  const postCategoryHandler = () => {
    const data = {
      name: categoryPost.trim(),
    };
    api
      .post(`api/category/`, data)
      .then((res) => {
        getCategories();
        console.log(res.data);
      })
      .catch((err) => console.log(err.message));
  };

  const postTagHandler = () => {
    const data = {
      name: tagPost.trim(),
      category: category,
    };
    api
      .post(`api/tag/`, data)
      .then((res) => {
        // getTags();
        // setTags([tags, ])
        getCategories();
        console.log(res.data);
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <>
      {page === "View" && (
        <div
          className="container review-blog"
          style={{ backgroundColor: "#10101B", color: "#ffffff" }}
        >
          <Blogdetail
            data={{
              ...posts,
              category_name:
                document.getElementById("select-category").options[
                  document.getElementById("select-category").selectedIndex
                ]?.text,
              tag_name:
                document.getElementById("select-tag").options[
                  document.getElementById("select-tag").selectedIndex
                ]?.text,
            }}
          />
        </div>
      )}
      {page === "Editor" && (
        <>
          <div className="container">
            <div class="row">
              <div class="col-md-6">
                <label for="fname">Category</label>
                <select
                required
                  class="form-select"
                  aria-label="Default select example"
                  id="select-category"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {categories.map((item, idx) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div class="col-md-6">
                <label for="fname">Post Category</label>
                <div className="d-flex">
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    value={categoryPost}
                    className="form-control"
                    onChange={(e) => setCategoryPost(e.target.value)}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => postCategoryHandler()}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
            <hr />

            <div class="row">
              <div class="col-md-6">
                <label for="fname">Tag</label>
                <select
                required
                  class="form-select"
                  aria-label="Default select example"
                  id="select-tag"
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                >
                  {tags &&
                    tags.map((item, idx) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                </select>
              </div>
              <div class="col-md-6">
                <label for="fname">Post Tag</label>
                <div className="d-flex">
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    value={tagPost}
                    className="form-control"
                    onChange={(e) => setTagPost(e.target.value)}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => postTagHandler()}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <br />
          <Accordion style={{ backgroundColor: "#F5F9FC", boxShadow: "none" }}>
            <AccordionSummary
              style={{
                backgroundColor: "#ffffff",
                boxShadow: "none",
                border: "1px solid #E7EAF0",
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              // expanded={expanded} onChange={setExpanded(!expanded)}
            >
              <span style={{ color: "#000000" }}>More Details</span>
            </AccordionSummary>
            <AccordionDetails>
              <div class="container">
                <form>
                  <hr />
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="fname">Title</label>
                        <input
                        required
                          type="text"
                          id="fname"
                          name="fname"
                          value={title}
                          className="form-control"
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="img">Banner Image Url</label>
                        <input
                        required
                          type="text"
                          id="img"
                          name="img"
                          value={banner}
                          className="form-control"
                          onChange={(e) => setBanner(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="lname">Excerpt</label>
                        <textarea
                        required
                          type="text"
                          id="Excerpt"
                          name="lname1"
                          value={excerpt}
                          style={{ height: "160px" }}
                          className="form-control"
                          onChange={(e) => setExcerpt(e.target.value)}
                        />
                      </div>
                    </div>
                    {data.id && (

                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="tag">Status</label>
                        <input
                          disabled
                          type="text"
                          name="lname2"
                          value={data.status}
                          className="form-control"
                        />
                      </div>
                      <div class="form-group">
                        <label for="tag">Category</label>
                        <input
                          disabled
                          type="text"
                          name="lname2"
                          value={data.category_name}
                          className="form-control"
                        />
                      </div>
                      <div class="form-group">
                        <label for="tag">Tag</label>
                        <input
                          disabled
                          type="text"
                          name="lname2"
                          value={data.tag_name}
                          className="form-control"
                        />
                      </div>
                    </div>
                    )}
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="lname">Reading time in minutes</label>
                        <input
                        required
                          type="number"
                          name="lname1"
                          value={readTime}
                          className="form-control"
                          row="5"
                          onChange={(e) => setreadTime(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="tag">Views</label>
                        <input
                        required
                          type="number"
                          name="lname2"
                          value={views}
                          className="form-control"
                          onChange={(e) => setViews(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="lname">Edit Published Date/Time</label>
                        <input
                        required
                          type="datetime-local"
                          name="lname1"
                          value={date}
                          className="form-control"
                          onChange={(e) => setDate(e.target.value)}
                        />
                      </div>
                    </div>
                    {data.id && (
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="lname">Published On</label>
                          <input
                            type="text"
                            disabled
                            name="lname1"
                            value={new Date(data.published).toLocaleString()}
                            className="form-control"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <hr />

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="lname">Author</label>
                        <input
                        required
                          type="text"
                          // name="lname1"
                          value={author}
                          className="form-control"
                          row="5"
                          onChange={(e) => setAuthor(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="lname">Slug</label>
                        <input
                        required
                          type="text"
                          // name="lname1"
                          value={slug}
                          className="form-control"
                          row="5"
                          onChange={(e) => setSlug(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                </form>
              </div>
            </AccordionDetails>
          </Accordion>
          <br />
          <div className="container">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={7}>
                <div className="editor">
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                  />
                </div>
                <br />
                <br />
                <ButtonGroup
                  variant="text"
                  color="primary"
                  aria-label="text primary button group"
                >
                  <Button
                    onClick={() =>
                      setAction(
                        {
                          ...posts,
                          tag: document.getElementById("select-tag").value,
                        },
                        "action-draft"
                      )
                    }
                  >
                    Save as draft
                  </Button>
                  <Button
                    onClick={() =>
                      setAction(
                        {
                          ...posts,
                          tag: document.getElementById("select-tag").value,
                        },
                        "action-post"
                      )
                    }
                  >
                    Post
                  </Button>
                  {data.id && (
                    <Button onClick={() => deletePost(data.slug)}>
                      Delete
                    </Button>
                  )}
                </ButtonGroup>
              </Grid>
              <Grid item xs={12} sm={12} md={5}>
                <div class="form-group">
                  <textarea
                    id="descript"
                    rows="8"
                    cols="16"
                    className="form-control"
                    value={html}
                    onChange={(e) => htmlToEditor(e.target.value)}
                  ></textarea>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </>
  );
}
