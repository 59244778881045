import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef } from "react";

const Collinsaerospace = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Collins Aerospace`;
  }, []);

  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);

  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "Guria NGO",
      desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
      url: "Guria-varanasi-human-trafficking",
      img: "images/cases-guria.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3].filter((ele) => ele !== option).map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      if (section3.current && offsetY >= section3.current.offsetTop) addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop) addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);

  return (
    <>
      <div className="plateformsub-page clientpage-outer">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>

        <div className="container">
          <div className="clientnavgations">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <h3>Collins Aerospace</h3>
                <div className="clientnavgationssubtitle"><span>Connecticut, </span>  <span>USA</span> </div>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={8} className="customer-page-nav-links-grid">
                <div className="customer-page-nav-links-wrap">
                  <ul>
                    <li>
                      <a href="#overview">Overview</a>{" "}
                    </li>
                    <li>
                      <a href="#problem">Problem</a>{" "}
                    </li>
                    <li>
                      <a href="#solution">Solution</a>{" "}
                    </li>
                    <li>
                      <a href="#result">Result</a>{" "}
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <div className="text-left logoclient">
                <img src="images/productSlider/collinsaerospace.png" alt="" />
              </div>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client" id="overview">
                <h3>Overview</h3>
                <p>
                  Collins Aerospace stands at the forefront of innovation in the global aerospace and defense industry. As the sector advances, the
                  integration of Artificial Intelligence (AI) into an array of physical systems has become a central focus. These systems, poised to
                  execute observable actions autonomously, are the future of marine and aerial defense. But with this evolution comes the challenge:
                  ensuring these AI-driven systems are robust against sophisticated forms of prediction, deception, and disruption aimed at their
                  underlying algorithms.
                </p>
                {/* <img src="images\Customers\Ideaforge\ideaforge overview.jpg" alt="" style={{
                  borderRadius: "40px",
                }} /> */}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="problem">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Problem</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  The diverse landscape of AI systems that Collins Aerospace must navigate includes everything from highly sophisticated to
                  rudimentary "garage-built" systems, and even repurposed civilian technologies. The complexity of AI algorithms embedded within these
                  systems presents a unique vulnerability—susceptibility to adversarial exploitation. A critical issue is the lack of full
                  observability, which hinders the ability to fully understand and counteract potential threats, making it imperative to develop
                  strategies that address these blind spots effectively.
                </p>
                <img
                  src="images/Customers/collins/problem2.jpg"
                  alt=""
                  style={{
                    borderRadius: "40px",
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="solution">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Solutions</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  Skylark Labs steps in with a solution tailored to the intricacies of AI within defense systems. By pioneering lightweight, easily
                  configurable research testbeds, Skylark Labs equips Collins Aerospace with the means to simulate and study AI components under a
                  variety of conditions. These testbeds, coupled with a solid theoretical framework, allow for the creation and exploitation of AI
                  models or partial models, even in closed-loop systems with limited observability. This approach empowers Collins Aerospace to not
                  only predict and understand adversarial strategies but also to disrupt them proactively.
                </p>
                {/* <img
                  src="images/Customers/collins/solution.png"
                  alt=""
                  style={{
                    borderRadius: "40px",
                  }}
                /> */}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="result">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Conclusion</h3>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  The collaboration between Collins Aerospace and Skylark Labs marks a significant milestone in defense technology. By addressing the
                  complexities of AI within physical systems, this partnership solidifies Collins Aerospace's position as an industry leader, capable
                  of navigating the unpredictable terrain of AI with increased confidence and strategic foresight. The success of this venture not
                  only overcomes the current challenges but also lays a robust groundwork for future advancements in AI resilience and security in the
                  defense sector.
                </p>
              </div>
              <Grid container spacing={3} className="customer-page-key-features">
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img src="images/productSlider/Play.svg" alt="" />
                    </div>
                    <h3>20% Increase in Predictive Accuracy</h3>
                    <p>Predict adversarial moves with heightened precision and reliability.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img src="images/productSlider/Analytics.svg" alt="" />
                    </div>
                    <h3>30% Reduction in Response Time</h3>
                    <p>Swiftly counteract threats with agile, responsive AI systems.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img src="images/productSlider/Analytics.svg" alt="" />
                    </div>
                    <h3>65% Cost Savings on Monitoring and Analysis</h3>
                    <p>Reduce expenses with efficient, AI-enhanced surveillance techniques.</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      {/* 
      <div className="plateformsub-page">
        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Related</span> More Cases
                  </h2>
                </div>
              </Grid>
            </Grid>

            <div className="industriesListblock pt-5">
              <ul>
                {otherCases.map((ele, idx) => (
                  <li>
                    <a href="#contact" className="industriesListcol">
                      <div className="solutionListimg">
                        <img src={ele.img} alt="" />
                      </div>
                      <div className="industriesListcoltext">
                        <h4>{ele.title} </h4>
                        <p>{ele.desc}</p>
                        <div className="quicklinks casesquicklinks">
                          {" "}
                          View full case{" "}
                          <img src="images/right-arrow.png" alt="" />{" "}
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div> */}
    </>
  );
};

export default Collinsaerospace;
