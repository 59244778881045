import $ from "jquery";
var pollCount = 0;

const callService = (formData, url, mode) => {
  var result = null;
  $.ajax({
    url: url,
    type: "post",
    cors: true,
    async: false,
    data: formData,
    contentType: false,
    processData: false,
    // headers: {
    //     'Authorization': "token 17f2b2b5ca72f9b128d5709ce2129c93fe8c1e98",
    // },
    success: function (response) {
      console.log("polling");
      //   poll(this.url, response.id);
      result = response.id;
    },
  });
  return result;
};

const poll = (url, id, callback, errorCallback) => {
  console.log("get request");
  pollCount = 0;
  console.log("Got url" + url);
  var new_url = url.toString() + id + "/";
  console.log(new_url);
  var pollVar = setInterval(function () {
    pollCount++;
    if (pollCount >= 5) {
      clearInterval(pollVar);
    } else {
      console.log("polling" + pollCount);
      $.ajax({
        url: new_url,
        type: "get",
        cors: true,
        async: false,
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        data: {
          key: "c3d0c6336b2318391a8f9c1e8b72f083586559dff2f0c1a75195056d871c25bf",
        },
        // headers: {
        //     'Authorization': "token 17f2b2b5ca72f9b128d5709ce2129c93fe8c1e98",
        // },
        success: function (response) {
          if (response.status === "success") {
            console.log("clearing interval");
            callback(response);
            clearInterval(pollVar);
            return;
          }
        },
        fail: function (xhr, textStatus, errorThrown) {
          console.log("falied response");
          errorCallback();
          clearInterval(pollVar);
        },
      });
    }
  }, 1000);
};
function getLocation(saveLocation) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(saveLocation);
  } else {
    console.log("Geolocation is not supported by this browser.");
  }
}

export { callService, poll, getLocation };
