import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Satellitesystem from "../../videofiles/Satellite-system.mp4";
import etosvideo from "../../videofiles/etos-page-banner.mp4";
import ariesvideo from "../../videofiles/aries-banner.mp4";
import mirasvideo from "../../videofiles/miras-main-banner.mp4";
import satellitevideo from "../../videofiles/dros-banner.mp4";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
const Clientlisting = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Airborne Systems`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [showTrial, setShowTrial] = React.useState(false);
  const [expanded, setExpanded] = React.useState("panel2");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };
  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "Guria NGO",
      desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
      url: "Guria-varanasi-human-trafficking",
      img: "images/cases-guria.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      // if (section4.current && offsetY >= section4.current.offsetTop)
      //   addActiveClass(navOption4);
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChangenew = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="plateformsub-page clientpage-outer">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>


        <div className="container pb-5 mb-5">

          <div className="container">
            <div className="seactionblocktitles selflearingPage">
              <h2 className="seactiontitles"> Customers</h2>
              <p>
                ETOS detects suspicious indicators using our proprietary
                technology to predict emerging threats.
              </p>
            </div>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>

            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <div className="productfeatured">
                <a href="/bros">
                  <h5>
                    BorderSentinel<br /><br />
                  </h5>
                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      volume={0}
                      playsinline
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url={etosvideo}
                    />
                    <div className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <div className="productfeatured">
                <a href="aries">
                  <h5>
                    Aerial Reconnaissance and <br></br> Elimination System
                  </h5>

                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      volume={0}
                      playsinline
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url={ariesvideo}
                    />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <div className="productfeatured">
                <a href="miras">
                  <h5>
                    Marine Reconnaissance <br></br>System
                  </h5>
                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      volume={0}
                      playsinline
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url={mirasvideo}
                    />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <div className="productfeatured">
                <a href="dros">
                  <h5>
                    Drone Reconnaissance and <br></br> Observation System
                  </h5>

                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      volume={0}
                      playsinline
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url={satellitevideo}
                    />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <div className="productfeatured">
                <a href="dros">
                  <h5>
                    Drone Reconnaissance and <br></br> Observation System
                  </h5>

                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      volume={0}
                      playsinline
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url={satellitevideo}
                    />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <div className="productfeatured">
                <a href="dros">
                  <h5>
                    Drone Reconnaissance and <br></br> Observation System
                  </h5>

                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      volume={0}
                      playsinline
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url={satellitevideo}
                    />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <div className="productfeatured">
                <a href="dros">
                  <h5>
                    Drone Reconnaissance and <br></br> Observation System
                  </h5>

                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      volume={0}
                      playsinline
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url={satellitevideo}
                    />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <div className="productfeatured">
                <a href="dros">
                  <h5>
                    Drone Reconnaissance and <br></br> Observation System
                  </h5>

                  <div className="imgblockfeatured">
                    <ReactPlayer
                      controls={true}
                      volume={0}
                      playsinline
                      width="100%"
                      height="100%"
                      loop={true}
                      muted={false}
                      playing={true}
                      stopOnUnmount={true}
                      config={{
                        file: {
                          attributes: {
                            autoplay: true,
                            controls: false,
                            loop: true,
                            muted: true,
                          },
                        },
                      }}
                      url={satellitevideo}
                    />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
          </Grid>
        </div>



      </div>


    </>
  );
};

export default Clientlisting;
