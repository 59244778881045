import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import TwentyTwenty from "react-twentytwenty";
const Partners = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Partners`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [showTrial, setShowTrial] = React.useState(false);
  const [expanded, setExpanded] = React.useState("panel2");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      // if (section4.current && offsetY >= section4.current.offsetTop)
      //   addActiveClass(navOption4);
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };

  const handleChange1 = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : true);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop partnersdesktop">
          <div className="container">
            <div className="partnerscontent">
            <div className="dismanbanner">
              <h1>We Built Advance AI solutions together for future</h1>
              <p>
                Skylark labs is a global security software company providing
                video analytics solutions designed to automatically identify
                potential threats in real-time for large scale surveillance. We
                build partnerships with camera systems, video management
                systems, Drones monitoring and video analytics companies.
              </p>
            </div>
            <div className="partnersmobile"><img src="../images/distributor.svg" alt=""/> </div>
            </div>
          </div>
          <div className="mobileshaps">
        <img src="../images/shaps.svg" alt=""/>

          </div>
        </div>
        
        <img
          src="../images/banner/partners.jpg"
          alt=""
          className="imgdesktop"
        />
        
      </div>

    

      <section className="platformproactive partnersblock partnerlsection" id="features">
    

        <div className="container">
        <div className="partnersouter">
      <h3>Helping Partners Fly With Skylark Labs</h3>
      <div className="partnerlogolist">
      <div className="partnerslogo">
        <img src="../images/partners_001.png" alt=""/>
        </div>
        <div className="partnerslogo">
        <img src="../images/partners_002.png" alt=""/>
        </div>
        <div className="partnerslogo">
        <img src="../images/partners_003.png" alt=""/>
        </div>
        <div className="partnerslogo">
        <img src="../images/partners_004.png" alt=""/>
        </div>
        <div className="partnerslogo">
        <img src="../images/partners_005.png" alt=""/>
        </div>
       
      </div> 
      </div>
      </div>
     
      <section className="section-partners">
      <div className="partnersouter titlespace">
      <h3>Who We Work With</h3>
      </div>
      <div className="container">
        <div className="timeline-line">
        <div className="timeline-circle timeline-circle-starting"></div>
        <div className="timeline-circle timeline-circle-ending"></div>
        </div>
        <div className="rowblock">
    <div className="colbox-lg-6">
      <div className="imgblock">
        <img src="../images/partners-01.svg" alt="" />
      </div>
    </div>
    <div className="colbox-lg-6">
      <div className="section-block section-title-timeline-right">
        <h2>Partners <div className="timeline-circle timeline-circletitle"></div>
          <div className="timeline-connect"></div>
        </h2>
        <p className="pt-0 mt-0 pb-5 mb-5"> Become SkyLark Labs service partner.Global System Integrators (GSI) and regional System Integrators (SI) focused towards IT infrastructure, surveillance system, security,safety,advanced defense system implementations. </p>
      </div>
    </div>
  </div>

  <div className="rowblock boxrevars">
  <div className="mobileshaps">
        <img src="../images/shaps.svg" alt=""/>

          </div>
    <div className="colbox-lg-6">
      <div className="section-block section-title-timeline-left">
        <h2>Distributor 
          <div className="timeline-circle timeline-circletitle"></div>
          <div className="timeline-connect"></div>
        </h2>
        <p className="pt-0 mt-0 pb-5 mb-5">System Integrators (SI), Facility Management, Consulting organisations who can recommend and streamline blanket deployments via channel or network.</p>
      </div>
    </div>
    <div className="colbox-lg-6">
      <div className="imgblock">
        <img src="../images/distributor.svg" alt="" />
      </div>
    </div>
  </div>
       
  <div className="rowblock">
    <div className="colbox-lg-6">
      <div className="imgblock">
        <img src="../images/partners-icon.svg" alt="" />
      </div>
    </div>
    <div className="colbox-lg-6">
      <div className="section-block section-title-timeline-right">
        <h2>Reseller <div className="timeline-circle timeline-circletitle"></div>
          <div className="timeline-connect"></div>
        </h2>
        <p className="pt-0 mt-0 pb-5 mb-5"> Anyone can apply to become a reseller. Ideal for businesses below 1.5 cr. turnover. Preferrence given to businesses who deals in surveillance, networking,IT infrastructure & CCTV cameras. </p>
      </div>
    </div>
  </div>

      </div>
      </section>
      
      </section>

     


       
      <section  className="platformproactive partnersblock">
      <div className="container">
        <div className="block-boxed"> 
        <div className="text-center">

        <h5 className="partnershiptitle">Partnership Application</h5>
         <p>To apply to join our partner program please use the link below. You will be redirected to our offsite Portal form to submit the application.  </p>
         </div>
          <div>
       
          <a href="https://partner.skylarklabs.ai/" target="blank" className="block-boxed">
              <div className="partnership">
                <div>
                <h5>Skylark Labs Partnership</h5>
                <p>You'll have the opportunity to host all your clients under one account, we provide you with a profit-rich discount taking your margins to the next level.</p>
                </div>
                <div className="applicationicons">  <img src="../images/partnership-application.svg" alt=""/></div> 
                
              </div>
              <span>Apply for a Skylark Labs Partnership</span>
            </a>
            </div>
         
        </div>
      </div>
      <div className="container">
        <p>
      *SkyLark Labs is expanding to new verticals and geographies.
If you are a OEM/Software proprietor/IOT platform ,please reach us for any special alliance or joint venture propositions.
</p>
</div>
</section>
      
    </>
  );
};

export default Partners;
