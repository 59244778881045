import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid"; 
import ReactPlayer from "react-player";
import { Link } from "react-router-dom"; 
import Forensics_suspiciousbehavious from "../videofiles/Forensics_suspiciousbehavious.mp4";
import Forensics_suspicious_objects from "../videofiles/Forensics_suspicious_objects.mp4";
import Forensics_suspicious_situation from "../videofiles/Forensics_suspicious_situation.mp4"; 
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core"; 
import Testimonial from "home/sections/Testimonial";
import Forensicstestimonial from "home/sections/Forensics-testimonial";

const Forensics = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs Audit Systems: Search features for incidents that happened in the past`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [expanded, setExpanded] = React.useState("");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>
                <span className="mainsubtitle">Forensics</span>
                Search features for incidents that happened in the past.
              </h1>
              <p>
                Forensics is used to find the evidence in recorded videos of the
                incidents that happened in the past.
              </p>
              <div className="subtages"> 
                <a href="#/"> Investigate Video Analysis</a> <a href="#/">Objects of Interest</a> <a href="#/">Behaviour of Interest</a><a href="#/">Situation of Interest</a>
          

              </div>
            </div>
          </div>
        </div>

        <img
          src="../images/forensic-3d-banner.png"
          alt=""
          className="imgdesktop"
        />
      </div>

      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>   Search features for incidents that happened in the past.</h2>
            <p>
            Forensics is used to find the evidence in recorded videos of the
                incidents that happened in the past.
            </p>
          </div>
        </div>
      </div>

      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section"
        id="forensicsvideo"
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="fistblock bordernone">
                <div className="singaldeploymentbox">
                  {" "}
                  <img src="images/forensic-query.jpg" alt="" />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className="homeright-block fistblockight homeright-block-auto">
                <div>
                  <h4 className="mb-0">Investigate Video Analysis </h4>
                  <p className="pt-0">
                    Forensics uses state-of-art path computer vision and search
                    algorithms to find the certain evidence from recorded videos
                    of the incidents that happened in the past. The product
                    helps the law enforcement agencies in the investigation of
                    certain crimes, and provides a safer environment.
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section lightgray"
      >
        <div className="container">
          <Grid container spacing={3} className="boxmobileone">
            <Grid item xs={12} sm={12} md={6}>
              <div className="homeright-block homeright-blockleft">
                <h4>
                  <span>Product Capabilities</span>Behaviour of Interest

                </h4>
                <p>
                A collection of pre recorded incident videos provide investigators a faster access to any needed video clip based on the suspicious behaviour of a person

                </p>

              
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="videoleftblock">
              <ReactPlayer
                  controls={true}
                  playsinline
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={ Forensics_suspiciousbehavious }
                  
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </section> 

      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section white-bg"
        id="surveillance"
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}  lg={6}>
              <div className="videoleftblock">
                <ReactPlayer
                  controls={true}
                  playsinline
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Forensics_suspicious_objects}
                />
              </div>
            </Grid>
            <Grid item  xs={12} sm={12} md={6}  lg={6}>
              <div className="homeright-block">
                <h4>
                  <span>Product Capabilities</span>Objects of Interest

                </h4>
                <p className="pt-0 mt-0">
                  {" "}
                  Provide Advanced AI based search functionality to search  the past incident and find the evidence on the basis of objects of interest such as weapons, hazardous devices, packages, etc.


                </p>

               
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section lightgray"
      >
        <div className="container">
          <Grid container spacing={3} className="boxmobileone">
            <Grid item xs={12} sm={12} md={6}>
              <div className="homeright-block homeright-blockleft">
                <h4>
                  <span>Product Capabilities</span>Situation of Interest

                </h4>
                <p>
                Helps the investigators to gather the crime-related physical evidence by analysing suspicious situations in order to come to a conclusion about a suspect

                </p>

                
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <div className="videoleftblock">
              <ReactPlayer
                  controls={true}
                  playsinline
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={ Forensics_suspicious_situation }
                  
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </section> 

{/* 
      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section white-bg"
        id="surveillance"
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={6}  lg={6}>
              <div className="videoleftblock">
                <ReactPlayer
                  controls={true}
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={perimeter_monitoring}
                />
              </div>
            </Grid>
            <Grid item  xs={12} sm={12} md={6}  lg={6}>
              <div className="homeright-block">
                <h4>
                  <span>Product Capabilities</span>Retro Search
                </h4>
                <p className="pt-0 mt-0">
                  {" "}
                  Provide Advanced AI based search functionality to search the
                  pre-recorded incident videos using various search filters.
                </p>

                <div class="bottmaligh">
                  <Link to="/number-plate-recognition" className="btn">
                    Try it now
                  </Link>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section> */}

      <section className="homeourproduct poductCatename productpag_section white-bg">
        <div className="container">
          <Grid container spacing={3} className="column-reverseblock">
            <Grid item  xs={12} sm={12} md={6}  lg={6}>
              <div className="homeright-block homeright-blockleft">
                <div>
                  <h4>
                    <span>Product Capabilities</span>Forensic Report
                  </h4>
                  <p className="pt-0 mt-0">
                    To keep track of critical information, the system saves
                    video analysis as reports for fast access to search results
                    and alerts of interest by adding comments and annotations to
                    the alarms inside the report.
                  </p>
                </div>

                 
              </div>
            </Grid>
            <Grid item  xs={12} sm={12} md={6}  lg={6}>
             
               <div className="singaldeploymentbox"><img src="images/forensic-report.jpg" alt=""/></div> 
              
            </Grid>
          </Grid>
        </div>
      </section>
{/* 
      <section className="homeourproduct lightgray">
        <div className="container">
          <div className="homeourproductblockmain">
            <div className="homeourproductblock">
              <div className="homeproductnew">
                <img src="images/banner/hawkeye02.jpg" alt="" />
                <div className="homeproductnew-dis">
                  <div className="titledis">Search Resemblance</div>
                  <p>
                    Our AI analytics algorithms will evaluate the uploaded image
                    and provide all alarms matching that appearance from all
                    cameras on the site.
                  </p>
                 
                </div>
              </div>
            </div>
            <div className="homeourproductblock">
              <div className="homeproductnew">
                <img src="images/banner/Border-Security_Header-1.jpg" alt="" />
                <div className="homeproductnew-dis">
                  <div className="titledis">Specific video search</div>
                  <p>
                    Our system will do site-wide searches for certain video
                    analytics events, filtering by loitering, crowd size, and
                    other factors, and will promptly show relevant results for
                    forensic video analysis and investigation.
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
       */}

      <section
        ref={section6}
        className="benefitmain industriesuses bggray latticeos_faq lightgray"
        id="admincontrol"
      >
        <div className="container">
          <div className="benefitssection  ">
            <h2 className="mb-0 text-center">Features</h2>
          </div>

          <div className="listofindust skylarksuite">
            <ul>
              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/object-detection.svg" alt="" />
                  </span>
                  <h5>Small Memory Footprint</h5>
                  <p>
                    Product has been optimised to use a minimal amount of memory
                    for running.
                  </p>
                </div>
              </li>

              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/icon-real-time-alert.svg" alt="" />
                  </span>
                  <h5>Search by Date/time</h5>
                  <p>
                    Easy to search particular events by date, time and save
                    search time.
                  </p>
                </div>
              </li>

              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/icon-face-matches.svg" alt="" />
                  </span>
                  <h5>Face Matches</h5>
                  <p>
                    Matches the face of the person involved in crime with the
                    existing database.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Forensicstestimonial />
    </>
  );
};

export default Forensics;
