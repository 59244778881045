import React, { useEffect, useState } from "react";
import Cases from "usecases/cases";
import Grid from "@material-ui/core/Grid";
import { data as Data } from "./data";
import { Link } from "react-router-dom";
// import UsecasesItem from "./components/usecaseItem";
import MainComponent from "demo/mainComponent";
const TemplateUseCases = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: ${data["title"]}`;
    return () => {
      console.log('title changed');
    }
  }, []);
  const [showTrial, setShowTrial] = useState("");
  const showTrialHandler = (ele) => {
    if (showTrial === ele) setShowTrial("");
    else setShowTrial(ele);
    // if (showTrial !== ele) document.getElementById(ele).scrollIntoView();
  };
  const [isLoading, setisLoading] = useState(true);
  const [data, setData] = useState({});
  useEffect(() => {
    setisLoading(false);
    // window.scrollTo({
    //   top: 0,
    // });
    setData(Data[window.location.pathname]);
    console.log(window.location.pathname);
  }, []);

  const TrialBtn = (props) => {
    const n = props.n;
    // const sec = `trial${n}`;
    const sec = "try";
    return (
      <a
        href={"#" + sec}
        class="btn btn_border"
      // onClick={() => showTrialHandler(sec)}
      >
        Try it now{" "}
      </a>
    );
  };

  const TrialSection = (props) => {
    const n = props.n;
    return null;
    return (
      <div id={`trial${n}`}>
        {showTrial === `trial${n}` && (
          <MainComponent models={data[`models${n}`]} />
        )}
      </div>
    );
  };

  if (isLoading) {
    return <>Loading</>;
  }
  return (
    <>
      <div className="platform_banner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>{data["title"]}</h1>
              <p>{data["desc1"]}</p>
            </div>
          </div>
        </div>
        <svg
          className="bottomShape"
          width="100%"
          // height="167"
          viewBox="0 0 1920 167"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0.199997V166.9H1920V0.199997C1616 91.3 1293.7 140.3 960 140.3C626.3 140.3 304 91.3 0 0.199997Z"
            fill="#10101B"
          />
        </svg>
        <div className="bannerblock bannerblock_one">
          <img src="../images/white_union.svg" className="icontopleft" alt="" />
          <img
            src="../images/roundpoint.svg"
            className="iconbottmright"
            alt=""
          />
        </div>
        <div className="bannerblock bannerblock_two">
          <img src="../images/white_union.svg" className="icontopleft" alt="" />
          <img
            src="../images/roundpoint.svg"
            className="iconbottmright"
            alt=""
          />
        </div>
        <div className="bannerblock bannerblock_three">
          <img src="../images/white_union.svg" className="icontopleft" alt="" />
          <img
            src="../images/roundpoint.svg"
            className="iconbottmright"
            alt=""
          />
        </div>
        <div className="bannerblock bannerblock_four">
          <img src="../images/white_union.svg" className="icontopleft" alt="" />
          <img
            src="../images/roundpoint.svg"
            className="iconbottmright"
            alt=""
          />
        </div>
        <div className="bannerblock bannerblock_five">
          <img src="../images/white_union.svg" className="icontopleft" alt="" />
          <img
            src="../images/roundpoint.svg"
            className="iconbottmright"
            alt=""
          />
        </div>
        <div className="imgcenter">
          {/* {data.bannermobile && 
            <img className='imgmobile' src={data.bannermobile} alt="" />
          } */}
          {data.bannerpc && <img className='' src={data.bannerpc} alt="" />}
          {/* {data.bannertab && <img className='imgtab' src={data.bannertab} alt="" />} */}
        </div>
        {/* <img src="../images/banner/about.jpg" alt="" /> */}
      </div>

      <div className="platform_banner_dis banner_dis_mob">
        <div className="container">
          <div className="dismanbanner">
            <h1>{data["title"]}</h1>
            <p>{data["desc1"]}</p>
          </div>
        </div>
      </div>
      {window.location.pathname === "/jewellery" && (
        <div class="container">
          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Lurking Detection</h3>
                  <p>
                    Automatically detects suspicious individuals or activity
                    around the property and provides real-time alerts to the
                    security personnel. Detects the suspicious individuals from
                    a long range in any environment or lighting conditions
                    regardless of any disguise.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={1} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-right">
                  <img src="../images/homePage/Lurking.webp" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>

          {/* {data["models1"] && ( */}
          <div id={`trial1`}>
            {showTrial === `trial1` && (
              <MainComponent models={data["models1"]} />
            )}
          </div>
          {/* )} */}

          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-left">
                  <img src="../images/homePage/Weapon.webp" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Weapon Detection</h3>
                  <p>
                    Automatically detects individuals entering the store with
                    weapons, except security guards, and alerts authorities in
                    real time. Detects over 200 types of weapons from long-range
                    using conventional CCTV cameras in different lighting
                    conditions and verticals, angles.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={1} />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          {/* {data["models"] && ( */}
          <div id={`trial2`}>
            {showTrial === `trial2` && (
              <MainComponent models={data["models2"]} />
            )}
          </div>
          {/* )} */}
        </div>
      )}
      {window.location.pathname === "/airport" && (
        <div class="container">
          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Weapon Detection</h3>
                  <p>
                    Automatically detects individuals entering the airport with
                    weapons, except airport security, and alerts authorities in
                    real-time. Detects over 200 types of weapons from long-range
                    using conventional CCTV cameras in different lighting
                    conditions and verticals, angles.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={1} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-right">
                  <img src="../images/homePage/Weapon.webp" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
          <div id={`trial1`}>
            {showTrial === `trial1` && (
              <MainComponent models={data["models1"]} />
            )}
          </div>

          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-left">
                  <img src="../images/homePage/abandoned_bag.webp" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Abandoned Bags</h3>
                  <p>
                    The solution detects suspicious/unattended baggage or
                    luggage lying inside the airport and provides real-time
                    alerts to authorities. Integrates with existing CCTV cameras
                    and detects luggage from a long distance in any
                    environmental, lightning conditions.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={2} />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div id={`trial2`}>
            {showTrial === `trial2` && (
              <MainComponent models={data["models2"]} />
            )}
          </div>

          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Face recognition</h3>
                  <p>
                    Detects banned employees or documented criminals who attempt
                    to enter premises and alerts authorities along with photos.
                    Recognize individuals in the presence of various disguises
                    such as glasses, masks, etc.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={3} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-right">
                  <img src="../images/homePage/Face_Recognition.webp" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
          <div id={`trial3`}>
            {showTrial === `trial3` && (
              <MainComponent models={data["models3"]} />
            )}
          </div>
        </div>
      )}

      {window.location.pathname === "/bank" && (
        <div class="container">
          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Weapon Detection</h3>
                  <p>
                    Automatically detects individuals entering the store with
                    weapons, except security guards, and alerts authorities in
                    real time. Detects over 200 types of weapons from long-range
                    using conventional CCTV cameras in different lighting
                    conditions and verticals, angles.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={1} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-right">
                  <img src="../images/homePage/Weapon.webp" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
          <div id={`trial1`}>
            {showTrial === `trial1` && (
              <MainComponent models={data["models1"]} />
            )}
          </div>

          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-left">
                  <img src="../images/homePage/Lurking.webp" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Lurking Detection</h3>
                  <p>
                    Automatically detects suspicious individuals or activity
                    around the property and provides real-time alerts to the
                    security personnel. Detects the suspicious individuals from
                    a long range in any environment or lighting conditions
                    regardless of any disguise.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={2} />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div id={`trial2`}>
            {showTrial === `trial2` && (
              <MainComponent models={data["models2"]} />
            )}
          </div>

          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Face Detection</h3>
                  <p>
                    Detects banned employees or documented criminals who attempt
                    to enter premises and alerts authorities along with photos.
                    Recognize individuals in the presence of various disguises
                    such as glasses, masks, etc.
                  </p>
                  <div className="casesbtns">
                    <a
                      href="#trial3"
                      class="btn btn_border"
                      onClick={() => showTrialHandler(`trial3`)}
                    >
                      Try it now{" "}
                    </a>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-right">
                  <img src="../images/homePage/Face_Recognition.webp" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>

          <div id={`trial3`}>
            {showTrial === `trial3` && (
              <MainComponent models={data["models3"]} />
            )}
          </div>

          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-left">
                  <img src="../images/homePage/attendance-management.webp" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Attendance Management</h3>
                  <p>
                    Automatically marks attendance of staff when they pass
                    through CCTV cameras. Detects employees' faces from various
                    angles and distances using your existing CCTV cameras in
                    real-time for daily attendance. Provide weekly/monthly
                    reports to management.
                  </p>
                  <div className="casesbtns">
                    <a
                      href="#trial4"
                      class="btn btn_border"
                      onClick={() => showTrialHandler(`trial4`)}
                    >
                      Try it now{" "}
                    </a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <div id={`trial4`}>
            {showTrial === `trial4` && (
              <MainComponent models={data["models4"]} />
            )}
          </div>
        </div>
      )}

      {window.location.pathname === "/hospital" && (
        <div class="container">
          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Attendance Management</h3>
                  <p>
                    Automatically marks attendance of staff when they pass
                    through CCTV cameras. Detects employees' faces from various
                    angles and distances using your existing CCTV cameras in
                    real-time for daily attendance. Provide weekly/monthly
                    reports to management.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={1} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-right">
                  <img src="../images/homePage/attendance-management.webp" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
          <TrialSection n={1} />

          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-left">
                  <img src="../images/homePage/Face_Recognition.webp" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Face Recognition</h3>
                  <p>
                    Detects banned employees or documented criminals who attempt
                    to enter premises illegally and alerts authorities along
                    with photos. Recognize individuals in the presence of
                    various disguises such as glasses, masks, etc.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={2} />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <TrialSection n={2} />
          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Weapon detection</h3>
                  <p>
                    Detects individuals with weapons from long-range using
                    conventional CCTV cameras. Solution detects 200 types of
                    guns and sharp objects in different lighting conditions and
                    verticals.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={3} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-right">
                  <img src="../images/homePage/Weapon.webp" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
          <TrialSection n={3} />
        </div>
      )}

      {window.location.pathname === "/hotels" && (
        <div class="container">
          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Weapon Detection</h3>
                  <p>
                    AI Based Weapon detection solution quickly detects
                    individuals with weapons in real time, Easy to implement and
                    manage with your existing surveillance and detects 200 types
                    of guns.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={1} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-right">
                  <img src="../images/homePage/Weapon.webp" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
          <TrialSection n={1} />

          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-left">
                  <img src="../images/homePage/Violence.webp" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Violence Detection</h3>
                  <p>
                    Solution detects violence activity such as person fighting,
                    hitting etc, in real time and gives alert. Solution
                    integrates with standard CCTV cameras and works in various
                    conditions, angles. Indicates the location of violence using
                    heat maps in groups of peoples.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={2} />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <TrialSection n={2} />

          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Face recognition</h3>
                  <p>
                    Face Recognition solution detects individuals in real-time
                    without requiring personnel to monitor. Easy to implement
                    and manage with your existing surveillance.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={3} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-right">
                  <img src="../images/homePage/Face_Recognition.webp" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
          <TrialSection n={3} />
        </div>
      )}

      {window.location.pathname === "/education" && (
        <div class="container">
          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Weapon Detection</h3>
                  <p>
                    Automatically detects individuals entering the store with
                    weapons, except security guards, and alerts authorities in
                    real time. Detects over 200 types of weapons from long-range
                    using conventional CCTV cameras in different lighting
                    conditions and verticals, angles.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={1} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-right">
                  <img src="../images/homePage/Weapon.webp" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
          <TrialSection n={1} />

          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-left">
                  <img src="../images/homePage/Violence.webp" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Violence Detection</h3>
                  <p>
                    Our solution detects violence activity such as person
                    fighting, hitting, pushing, etc, in real time and gives
                    alert to security. Solution integrates with standard CCTV
                    cameras and works in various conditions, angles. Indicates
                    the location of violence using heat maps in groups of
                    peoples.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={2} />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <TrialSection n={2} />

          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Attendance management</h3>
                  <p>
                    Automatically marks attendance of staff and students when
                    they pass through CCTV cameras. Detects faces from various
                    angles and distances using your existing CCTV cameras in
                    real-time for daily attendance. Provide weekly/monthly
                    reports to management.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={3} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-right">
                  <img src="../images/homePage/attendance-management.webp" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
          <TrialSection n={3} />
        </div>
      )}

      {window.location.pathname === "/companies" && (
        <div class="container">
          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Attendance management</h3>
                  <p>
                    Automatically marks attendance of staff and students when
                    they pass through CCTV cameras. Detects faces from various
                    angles and distances using your existing CCTV cameras in
                    real-time for daily attendance. Provide weekly/monthly
                    reports to management.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={1} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-right">
                  <img src="../images/homePage/attendance-management.webp" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
          <TrialSection n={1} />

          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-left">
                  <img src="../images/homePage/Violence.webp" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Attendance Management</h3>
                  <p>
                    Solution automatically note the entry and exit time of
                    employees and record daily attendance, maintain weekly
                    reports. Recognize employees from their face in real time
                    using CCTV cameras.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={2} />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
          <TrialSection n={2} />

          <div class="usecasespage">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="solution_aboutdis">
                  <h3>Face Recognition</h3>
                  <p>
                    Detects documented criminals from existing databases, works
                    with standard surveillance systems from a large distance.
                    Any individual enters in the store whose data is already
                    stored in an unsafe person’s database, the system detects
                    and gives an alert to security with their picture in real
                    time.
                  </p>
                  <div className="casesbtns">
                    <TrialBtn n={3} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <div className="aboutsolution_img imgblock-right">
                  <img src="../images/homePage/Face_Recognition.webp" alt="" />
                </div>
              </Grid>
            </Grid>
          </div>
          <TrialSection n={3} />
        </div>
      )}
      <div className="container">
        <br />
        <br />

        <div className="casesTItle" style={{ marginBottom: "0px" }}>
          <h2>Cases: {data["cases"].length}</h2>
          <div className="sort">
            <div className="Countries">
              {" "}
              Countries:<a href="#/">14</a>
            </div>
            <div className="sortby">
              Sort by:<a href="#/"> Alphabetical A-Z</a>
            </div>
          </div>
        </div>
        <Cases caseList={data["cases"]} />
      </div>

      <section className="caseList readmorecsases">
        <div className="container">
          <div className="platformCamera_dis">
            <h2>Read More Cases</h2>
          </div>
          <ul className="acquisitionLab readsases">
            {data["otherCases"].map((ele, idx) => (
              <li>
                <Link to={`/case${ele.url}`} className="similarcases">
                  <h6>
                    {ele.title}
                    <span>{ele.desc}</span>
                  </h6>
                  <div className="arrowLine">
                    <img src="../images/Right_long.svg" alt="" />{" "}
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </section>

      {data["models1"] && (
        <div className="container p" id="try">
          <div className="bottmspace">
            {showTrial !== "" ? (
              <MainComponent models={data["models1"]} />
            ) : (
              <Grid container spacing={3} justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={6}>
                  {/* <div className="container" id="trial"> */}

                  <div className="demoimgide">
                    <img src="../images/trydemo_img02.jpg" alt="" />
                    <a href="#/" className="videoplay">
                      <img src="../images/video.svg" alt="" />{" "}
                    </a>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <div className="trydemo_dis p_outer">
                    <h3>Try our Demo</h3>
                    <p>
                      Our solutions detect suspicious individuals around the
                      properties such as jewellery shops, ATMs, societies, etc.
                    </p>
                    <div className="bottmaligh">
                      {" "}
                      <a
                        href="javascript:void(0)"
                        // href="#trial"
                        onClick={() => showTrialHandler(`trial`)}
                        className="btn"
                      >
                        Try it now
                      </a>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default TemplateUseCases;
