import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Etios from "../../videofiles/etios-behaviour.mp4";
import enviornmentmonitoring from "../../videofiles/etios-behaviour.mp4";
import Lurking from "../../videofiles/etosnew-lurking.mp4";
import Loitering from "../../videofiles/loitering-etosnew.mp4";
import Weapondetection from "../../videofiles/campus-weapon.mp4";
import Theftdetectioninsideoutside from "../../videofiles/theft-detection-inside-outside.mp4";
import Tailgating from "../../videofiles/tailgating.mp4";
import Casing from "../../videofiles/athena.mp4";
import videobanner from "../../videofiles/campus-banner.mp4";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
const Campus = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Campus`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [showTrial, setShowTrial] = React.useState(false);
  const [expanded, setExpanded] = React.useState("panel2");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };
  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "Guria NGO",
      desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
      url: "Guria-varanasi-human-trafficking",
      img: "images/cases-guria.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      // if (section4.current && offsetY >= section4.current.offsetTop)
      //   addActiveClass(navOption4);
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChangenew = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>Campus</h1>
              <p>
                Advanced AI security solutions for providing a safe & secure
                environment for campuses such as Universities, Colleges, Schools
                and hospitals by managing the attendances and detecting various
                threats.
              </p>
              <div className="subtages">
                <a href="#/">School</a>
                <a href="#/">Universities </a>
                <a href="#/">Offices</a>
              
              </div>
            </div>
          </div>
        </div>

        <div className="imgcenter bannerimgblock ">
          <ReactPlayer
            controls={true}
            volume={0}
            playsinline
            width="100%"
            height="100%"
            loop={true}
            muted={false}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url={videobanner}
          />
        </div>
      </div>

      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>Campus</h2>
            <p>
              Advanced AI security solutions for providing a safe & secure
              environment for campuses such as Universities, Colleges, Schools
              and hospitals by managing the attendances and detecting various
              threats.
            </p>
          </div>
        </div>
      </div>

      <section className="homeourproduct poductCatename productpag_section">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="videoleftblock fistblock bordernone">
                <div>
                  <img src="images/schools-colleges-campuses.png" alt="" />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block fistblockight homeright-block-auto">
                <div>
                  <h4 className="mb-0">
                  Enhance Campus Security 
                  </h4>
                  <p className="pt-0">
                    Violent incidents are frequently provoked by non-university
                    students who are not authorized to participate in annual
                    cultural events. Our system recognizes and follows persons
                    who are engaging in violent behavior. It alerts security
                    professionals in real-time, as well as the location of the
                    suspicious object. It also automatically records students'
                    and teachers' in and out time on campus, saving time and
                    resources.  
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="homeprodctlist blackrarkoutr lightgray">
        
        <div className="container">
          <Box sx={{ width: "100%" }}>
            <TabPanel value={value} index={0}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  playsinline
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Loitering}
                />

                <div className="productitemboxdis">
                  <h4> Loitering Detection</h4>
                  <p>
                    Detecting suspicious individuals staying around longer than
                    usual outside the premises
                  </p>
                 
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Lurking}
                />

                <div className="productitemboxdis">
                  <h4>Lurking Detection</h4>
                  <p>
                    Detecting suspicious individuals lurking at campus entrance
                    for potential security breach
                  </p>
                  
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Casing}
                />

                <div className="productitemboxdis">
                  <h4>Casing Detection</h4>
                  <p>
                    {" "}
                    Detects a person's behavior who observing a location in
                    order to become acquainted with it for some criminal action.
                  </p>
                 
                </div>
              </div>
            </TabPanel>

            <div className="poductlisttabs">
              <Box sx={{ borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChangenew}
                  aria-label="basic tabs example"
                >
                  <Tab label="Loitering" {...a11yProps(0)} />
                  <Tab label="Lurking" {...a11yProps(1)} />
                  <Tab label="Casing" {...a11yProps(2)} />
                </Tabs>
              </Box>
            </div>
          </Box>
        </div>
      </section>

      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section"
        id="surveillance"
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="videoleftblock">
                <ReactPlayer
                  controls={true}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Weapondetection}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block">
                <h4>
                  <span>Product Capabilities</span>Weapon Detection{" "}
                </h4>
                <p>
                  Detects pedestrians carrying suspicious objects such as
                  weapons/rods etc to stop a critical event proactively
                </p>

                <ul className="mb-4">
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-people-fighting.svg"
                          alt=""
                        />
                      </span>
                      Violence Detection <br></br>
                      Classification
                    </div>
                  </li>
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-person-with-weapon.svg"
                          alt=""
                        />
                      </span>
                      Weapons Detection<br></br>
                      Classification
                    </div>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="homeourproduct poductCatename productpag_section lightgray">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block homeright-blockleft">
                <div>
                  <h4>
                    <span>Product Capabilities</span>Theft Detection (Inside,
                    Outside)
                  </h4>
                  <p className="pt-0 mt-0">
                    Detect suspicious individuals stealing valuable resources
                    from the site
                  </p>
                </div>
                <ul className="mb-4">
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-facial-description.svg"
                          alt=""
                        />
                      </span>{" "}
                      Intrusion<br></br>
                      Detection
                    </div>{" "}
                  </li>
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-attire-description.svg"
                          alt=""
                        />
                      </span>
                      Fence
                      <br></br>Jumping
                    </div>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="videoleftblock">
                <ReactPlayer
                  controls={true}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Theftdetectioninsideoutside}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section"
        id="surveillance"
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="videoleftblock">
                <ReactPlayer
                  controls={true}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Tailgating}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block">
                <h4>
                  <span>Product Capabilities</span>Tailgating Detection
                </h4>
                <p>
                  Detects individuals entering the premises locations by
                  tailgating and bypassing the authentication
                </p>

                <ul className="mb-4">
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-people-fighting.svg"
                          alt=""
                        />
                      </span>
                      Violence Detection <br></br>
                      Classification
                    </div>
                  </li>
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-person-with-weapon.svg"
                          alt=""
                        />
                      </span>
                      Weapons Detection<br></br>
                      Classification
                    </div>
                  </li>
                </ul>

               
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
     

      <section className="benefitmain industriesuses bggray latticeos_faq white-bg">
        <div className="container">
          <div className="benefitssection  ">
            <h2 className="mb-0 text-center">Key Features</h2>
          </div>

          <div className="listofindust skylarksuite">
            <ul>
              <li className="text-center">
                <div className="featuresblock featuresblockbgthree">
                  <span>
                    <img src="images/object-detection.svg" alt="" />
                  </span>
                  <h5>Reactive Alerts</h5>
                  <p>
                    Automatically provide reactive alerts to the authorities in
                    real-time once the threat is detected.
                  </p>
                </div>
              </li>

              <li className="text-center">
                <div className="featuresblock ">
                  <span>
                    <img src="images/identity-detection.svg" alt="" />
                  </span>
                  <h5>Multiple Behaviours</h5>
                  <p>
                    Recognizes multiple behaviours like violence, intrusion,
                    lurking, aggression.
                  </p>
                </div>
              </li>
              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/icon-threat-detection.svg" alt="" />
                  </span>
                  <h5>Actionable insights</h5>
                  <p>
                    Sends real-time actionable alerts to the concerned
                    authorities through mobile and web. These alerts help them
                    to respond quickly to the incident and prevent them.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="caseList readmorecsases">
        <div className="container">
          <div className="platformCamera_dis">
            <h2>Read More Cases</h2>
          </div>

          <div className="industriesListblock pt-5">
            <ul>
              {otherCases.map((ele, idx) => (
                <li>
                  <a href="#contact" className="industriesListcol">
                    <div className="solutionListimg">
                      <img src={ele.img} alt="" />
                    </div>
                    <div className="industriesListcoltext">
                      <h4>{ele.title} </h4>
                      <p>{ele.desc}</p>
                      <div className="quicklinks casesquicklinks">
                        {" "}
                        View full case{" "}
                        <img src="images/right-arrow.png" alt="" />{" "}
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Campus;
