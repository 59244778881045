import React, { useState, useEffect } from "react";
import Forensicstestimonial from "home/sections/Forensics-testimonial";
import { Link } from "react-router-dom";

const Homemedia = () => {
  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {

    fetch(`https://prodserver.skylarklabs.ai/skylarklabsblog/all-articles/?limit=1&article_type=5`)
      .then((res) => res.json())
      .then((data) => {
        if (data) setBlogs(data.results[0]);
      })
      .catch((err) => {
        console.log(err);
      });

    fetch(`https://prodserver.skylarklabs.ai/skylarklabsblog/all-articles/?limit=1&article_type=7`)
      .then((res) => res.json())
      .then((data) => {
        if (data) setEvents(data.results[0]);

      })
      .catch((err) => {
        console.log(err);
      });

    fetch(`https://prodserver.skylarklabs.ai/skylarklabsblog/all-articles/?limit=1&article_type=6`)
      .then((res) => res.json())
      .then((data) => {
        if (data) setNews(data.results[0]);

      })
      .catch((err) => {
        console.log(err);
      });

  }, []);

  return (


    <div>

      <section className="caseList readmorecsases pt-0">
        <div className="container">
          <div className="platformCamera_dis">
            <h2>Recent Activities</h2>
          </div>

          <div className="industriesListblock pt-5 homenewsblock">
            <ul>

              {blogs && (
                <li>
                  <Link to={{
                    pathname: `/blog/${blogs.id}/${blogs.slug}`,
                  }} className="industriesListcol">
                    <div className="solutionListimg">
                      <img src={blogs.image} alt="" />
                    </div>
                    <div className="industriesListcoltext">
                      <h4>{blogs.title} </h4>
                      {/* <p>{ele.desc}</p> */}
                      <div className="quicklinks casesquicklinks">
                        {" "}
                        View Details{" "}
                        <img src="images/right-arrow.png" alt="" />{" "}
                      </div>
                    </div>
                  </Link>
                </li>
              )}
              {news && (
                <li>
                  <Link to={{
                    pathname: `/news/${news.id}/${news.slug}`,
                  }} className="industriesListcol">
                    <div className="solutionListimg">
                      <img src={news.image} alt="" />
                    </div>
                    <div className="industriesListcoltext">
                      <h4>{news.title} </h4>
                      {/* <p>{ele.desc}</p> */}
                      <div className="quicklinks casesquicklinks">
                        {" "}
                        View Details{" "}
                        <img src="images/right-arrow.png" alt="" />{" "}
                      </div>
                    </div>
                  </Link>
                </li>
              )}
              {events && (
                <li>
                  <Link to={{
                    pathname: `/event/${events.id}/${events.slug}`,
                  }} className="industriesListcol">
                    <div className="solutionListimg">
                      <img src={events.image} alt="" />
                    </div>
                    <div className="industriesListcoltext">
                      <h4>{events.title} </h4>
                      {/* <p>{ele.desc}</p> */}
                      <div className="quicklinks casesquicklinks">
                        {" "}
                        View Details{" "}
                        <img src="images/right-arrow.png" alt="" />{" "}
                      </div>
                    </div>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </section>

      <Forensicstestimonial />
    </div>
    // <section className="homepage_media" >

    //   <div className="container">
    //     <div className="innerblocknews">
    //       <div className="titlenewblocks">
    //         <h4>Latest News</h4>

    //         <a href="/press">View All</a>

    //       </div>


    //       <div className="mi-success-stories-partation">
    //         <div className="mi-success-stories-horizontal">
    //           <a href="#/" className="success-stories-main">
    //             <div className="success-stories-img-vide">
    //               <img src="images/new-home-02.png" alt="" />

    //             </div>
    //             <div className="success-stories-content">

    //               <div className="small-heading-h4-text">  Drone Violent Individuals Identification</div>
    //               <p>Discovery Channel's Seeker covered our  paper titled 'Eye in the Sky: Real-time Drone Surveillance System (DSS) for Violent Individuals Identification using ScatterNet Hybrid Deep Learning Network'. </p>

    //             </div>

    //           </a>

    //           <a href="#/" className="success-stories-main">
    //             <div className="success-stories-img-vide">
    //               <img src="images/new-home-03.png" alt="" />

    //             </div>
    //             <div className="success-stories-content">

    //               <div className="small-heading-h4-text">   Real-time Drone Surveillance System</div>
    //               <p>This is the video demonstration of the paper titled 'Eye in the Sky: Real-time Drone Surveillance System (DSS) for Violent Individuals Identification using ScatterNet Hybrid Deep Learning Network'. </p>

    //             </div>

    //           </a>
    //         </div>
    //         <div className="mi-success-stories-vertical">
    //           <div className="success-stories-main-vertical">
    //             <a href="#/" className="success-stories-main">
    //               <div className="success-stories-img-vide">
    //                 <img src="images/videthumb_home.png" alt="" />

    //               </div>
    //               <div className="success-stories-content">

    //                 <div className="small-heading-h4-text">   BBC World News Interview of Dr. Amarjot Singh about the Drone Surveillance System</div>
    //                 <p>The interview of Dr. Amarjot Singh with the BBC World News about the Drone Surveillance System titled 'Eye in the Sky...'!  </p>

    //               </div>

    //             </a>


    //           </div>



    //         </div>

    //       </div>

    //     </div>

    //   </div>


    // </section>
  );
};

export default Homemedia;