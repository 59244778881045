import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Boxcounting from "../../videofiles/counting_boxes.mp4";
import Rollcounting from "../../videofiles/counting_rolls.mp4";
import Ppeviolation from "../../videofiles/ppe-violation.mp4";
import enviornmentmonitoring from "../../videofiles/entry-exit-monitoring.mp4";
import Safetygeardetection from "../../videofiles/safety-gear-detection.mp4";
import Industrialbanner from "../../videofiles/industrial-park-banner.mp4"; 

import Trafficmonitoring from "../../videofiles/infrastructure_monitoring.mp4";
import Enviornmentmonitoring from "../../videofiles/enviornment_monitoring.mp4";
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
const Industrialparks = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Industrial Parks`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [showTrial, setShowTrial] = React.useState(false);
  const [expanded, setExpanded] = React.useState("panel2");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };
  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "D.S. Jeweller",
      desc: "The incidents happened in the locality where robbers used to come at night and loot the jewellery shops.",
      url: "ds-jewellers",
      img: "images/cases-jewellers.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      // if (section4.current && offsetY >= section4.current.offsetTop)
      //   addActiveClass(navOption4);
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChangenew = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>Industrial Parks</h1>
              <p>
                Skylark Lab’s advanced AI solutions enhance the safety and
                security of business parks by detecting potential threats such
                as thefts, vandalism, violence, weapon, intrusion, etc in real
                time with instant reactive alerts.
              </p>
              <div className="subtages"><a href="#/">Box Counting </a>  <a href="#/">Roll Counting </a> <a href="#/">Bag Counting
 </a> <a href="#/"> Entry and Exit Monitoring</a>
</div>
     
            </div>
          </div>
        </div>

        <ReactPlayer
          style={{
            zIndex: "-1",
            pointerEvents: "none",
          }}
          controls={false}
          playsinline
          volume={0}
          width="100%"
          height="100%"
          loop={true}
          muted={true}
          playing={true}
          stopOnUnmount={true}
          config={{
            file: {
              attributes: {
                autoplay: true,
                controls: false,
                loop: true,
                muted: true,
              },
            },
          }}
          url={Industrialbanner}
        />
      </div>

      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>Industrial Parks</h2>
            <p>
            Skylark Lab’s advanced AI solutions enhance the safety and
                security of business parks by detecting potential threats such
                as thefts, vandalism, violence, weapon, intrusion, etc in real
                time with instant reactive alerts.
            </p>
          </div>
        </div>
      </div>

      <section className="homeourproduct poductCatename productpag_section">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="videoleftblock fistblock bordernone">
                <div>
                  {" "}
                  <img src="images/industrial-Parks.png" alt="" />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block fistblockight homeright-block-auto">
                <div>
                  <h4 className="mb-0">A New Experience in Security</h4>
                  <p className="pt-0">
                    It is impossible to manage every scenario, especially the
                    human dynamic if dangerous behaviors like violence or weapon
                    use occur. To put a halt to these operations, appropriate
                    action must be taken on time. And to accomplish this, our AI
                    solutions provide business parks/factories a new experience
                    of security having advanced features such as face & violence
                    detection, weapon detection in real-time & instant alerts to
                    security personnel.
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="homeprodctlist blackrarkoutr lightgray"> 
        <div className="container">
          <Box sx={{ width: "100%" }}>
            <TabPanel value={value} index={0}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Boxcounting}
                />

                <div className="productitemboxdis">
                  <h4> Box Counting</h4>
                  <p>
                    The system counts the number of boxes in the assigned areas.
                  </p>
                
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  playsinline
                  controls={false}
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Rollcounting}
                />

                <div className="productitemboxdis">
                  <h4>Roll Counting</h4>
                  <p>
                    The system observes and detects the number of rolls loaded
                    in the vehicles for transportation in different warehouses
                    or industry areas.
                  </p>
                  
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  playsinline
                  controls={false}
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Ppeviolation}
                />

                <div className="productitemboxdis">
                  <h4>Bag Counting</h4>
                  <p>
                    {" "}
                    Counts the number of bags held at a warehouse or factory, as
                    well as the number of bags carried out at the time of
                    withdrawal from the factory or warehouse.
                  </p>
                 
                </div>
              </div>
            </TabPanel>

            <div className="poductlisttabs">
              <Box sx={{ borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChangenew}
                  aria-label="basic tabs example"
                >
                  <Tab label="Box Counting" {...a11yProps(0)} />
                  <Tab label="Roll Counting" {...a11yProps(1)} />
                  <Tab label="Bag Counting" {...a11yProps(2)} />
                </Tabs>
              </Box>
            </div>
          </Box>
        </div>
      </section>

      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section"
        id="surveillance"
      >
        <div className="container homeprodctlisttop whitetext pb-4 mb-5">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={7}>
              <h4>Industrial Security</h4>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <p className=" py-0 my-0">
              Skylark's systems not only track the entrance and exit of cars or individuals but also count the number of boxes or bags and provide alerts for security equipment.


              </p>
            </Grid>
          </Grid>
        </div>
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <ReactPlayer
                style={{
                  zIndex: "-1",
                  pointerEvents: "none",
                }}
                playsinline
                controls={false}
                volume={0}
                width="100%"
                height="100%"
                loop={true}
                muted={true}
                playing={true}
                stopOnUnmount={true}
                config={{
                  file: {
                    attributes: {
                      autoplay: true,
                      controls: false,
                      loop: true,
                      muted: false,
                    },
                  },
                }}
                url={Safetygeardetection}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block">
                <h4>
                  <span>Product Capabilities</span>Safety Gear Detection
                </h4>
                <p>
                  Trigger real-time alerts for the absence of essential safety
                  gears like high visibility jackets, helmets, and harnesses
                  while working at heights.
                </p>

                <ul className="mb-4">
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-people-fighting.svg"
                          alt=""
                        />
                      </span>
                      Violence Detection <br></br>
                      Classification
                    </div>
                  </li>
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-person-with-weapon.svg"
                          alt=""
                        />
                      </span>
                      Weapons Detection<br></br>
                      Classification
                    </div>
                  </li>
                </ul>

               
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="homeourproduct poductCatename productpag_section lightgray">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block homeright-blockleft">
                <div>
                  <h4>
                    <span>Product Capabilities</span>Entry and Exit Monitoring
                  </h4>
                  <p className="pt-0 mt-0">
                    Monitors the vehicles and people entering and exiting the
                    area and marks the time of entry and exiting the industrial
                    area.
                  </p>
                </div>
                <ul className="mb-4">
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-facial-description.svg"
                          alt=""
                        />
                      </span>{" "}
                      Vehicle<br></br>
                      Detection
                    </div>{" "}
                  </li>
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-attire-description.svg"
                          alt=""
                        />
                      </span>
                      Person
                      <br></br>Detection
                    </div>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="videoleftblock">
                <ReactPlayer
                  controls={true}
                  playsinline
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={enviornmentmonitoring}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
 
      <section className="homeourproduct">
        <div className="container">
          
          <div className="homeourproductblockmain">
            <div className="homeourproductblock">
              <div className="homeproductnew">
             
              <ReactPlayer
                  controls={true}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={ Trafficmonitoring }
                />
                <div className="homeproductnew-dis">
                  <div className="titledis">Infrastructure Monitoring</div>
                  <p>
                  The system keeps tabs on the area's property and alerts the authorities if anything suspicious happens, such as theft or vandalism.

                  </p>
                </div>
              </div>
            </div>
            <div className="homeourproductblock">
              <div className="homeproductnew">
              
              <ReactPlayer
                  controls={true}
                  volume={0}
                  width="100%"
                  playsinline
                  height="100%"
                  loop={true}
                  muted={false}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={ Enviornmentmonitoring }
                />
                  <div className="homeproductnew-dis">
                  <div className="titledis">Enviornment Monitoring</div>
                  <p>
                  The system detects and identifies potentially hazardous events and alerts the authorities before escalating further.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="benefitmain industriesuses bggray latticeos_faq white-bg"
        id="admincontrol"
      >
        <div className="container">
          <div className="benefitssection  ">
            <h2 className="mb-0 text-center">Key Features</h2>
          </div>

          <div className="listofindust skylarksuite">
            <ul>
              <li className="text-center">
                <div className="featuresblock featuresblockbgthree">
                  <span>
                    <img src="images/object-detection.svg" alt="" />
                  </span>
                  <h5>Actionable insights</h5>
                  <p>
                    Sends real-time actionable alerts to the concerned
                    authorities through mobile and web. These alerts help them
                    to respond quickly to the incident and prevent them.
                  </p>
                </div>
              </li>

              <li className="text-center">
                <div className="featuresblock ">
                  <span>
                    <img src="images/identity-detection.svg" alt="" />
                  </span>
                  <h5>Computationally Efficient</h5>
                  <p>
                    Less resource consumption, requires less amount of time and
                    space.
                  </p>
                </div>
              </li>
              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/icon-threat-detection.svg" alt="" />
                  </span>
                  <h5>Stress Free Environment</h5>
                  <p>
                    Getting only relevant alerts creates a stress-free
                    environment for security officers as they don’t have to
                    manually monitor the video wall regularly and can only turn
                    their attention to it when there is an audio-visual alert.
                    (edited)
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="caseList readmorecsases ">
        <div className="container">
          <div className="platformCamera_dis">
            <h2>Read More Cases</h2>
          </div>

          <div className="industriesListblock pt-5">
            <ul>
              {otherCases.map((ele, idx) => (
                <li>
                  <a href="#contact" className="industriesListcol">
                    <div className="solutionListimg">
                      <img src={ele.img} alt="" />
                    </div>
                    <div className="industriesListcoltext">
                      <h4>{ele.title} </h4>
                      <p>{ele.desc}</p>
                      <div className="quicklinks casesquicklinks">
                        {" "}
                        View full case{" "}
                        <img src="images/right-arrow.png" alt="" />{" "}
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Industrialparks;
