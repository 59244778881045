import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import videobanner from "../videofiles/dummy.mp4";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
import Etostestimonial from "home/sections/Etos-testimonial";
import Suspicioussituations from "../videofiles/etosnew-lurking.mp4";
import Lurkingdetection from "../videofiles/lurking_detection.mp4";
import RoundedTabSwitcher from "./components/RoundedTabSwitcher";
import ImageSlider from "./components/ImageSlider";
const Selflearingainewtwo = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Product Page:  Self Learing AI -   Turing is a cutting-edge AI suite tailored for Intelligence Organizations`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [expanded, setExpanded] = React.useState("");
  const [activeTabCustomPipeline, setActiveTabCustomPipeline] =
    React.useState(0);
  const [activeTabIntelligenceGathering, setActiveTabIntelligenceGathering] =
    React.useState(0);
  const [activeTabCustomAI, setActiveTabCustomAI] = React.useState(0);
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  let otherCases = [
    {
      title: "Use Cases",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/productSlider/usecases.png",
    },

    {
      title: "News",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/productSlider/skylarklabs-news.png",
    },

    {
      title: "Events",
      desc: "The incidents happened in the locality where robbers used to come at night and loot the jewellery shops.",
      url: "ds-jewellers",
      img: "images/productSlider/swavlamban-delhi.png",
    },
  ];
  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner newproductbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>What Is Self-learning AI ?</h1>
            </div>
          </div>
        </div>

        <div className="imgcenter bannerimgblock ">
          <ReactPlayer
            controls={true}
            playsinline
            volume={0}
            width="100%"
            height="100%"
            loop={true}
            muted={false}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url={videobanner}
          />
        </div>
      </div>

      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>
              Self learning AI: Turing is a cutting-edge AI suite tailored
              for Intelligence Organizations{" "}
            </h2>
            <p>
              It efficiently searches data from various online platforms,
              offering proactive alerts based on comprehensive risk assessments
            </p>
          </div>
        </div>
      </div>

      <div className="topplatformsblock pbottom firstbloctop">
        <section className="productoverviewblock section-productoverviewblock section-productoverviewblockfirstbox">
          <div className="container">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoleftblock bordernone">
                  <div>
                    {" "}
                    <img
                      src="images/productSlider/predict-emerging-threats.png"
                      alt=""
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 mb-0 pb-0">
                      <span className="spanfirstword">Self</span> Learing AI
                    </h2>
                    <p className="pt-0">
                      {" "}
                      Turing is a cutting-edge AI suite tailored for
                      Intelligence Organizations. It efficiently searches data
                      from various online platforms, offering proactive alerts
                      based on comprehensive risk assessments. The system
                      integrates data from physical sensors and online sources
                      for rapid threat verification. It also boasts advanced
                      entity profiling, potential threat scenario simulations,
                      and allows customization using your datasets for precise
                      threat prediction.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
        <div className="container">
          <h2 className="maintitle-seleflearning">
            <span className="spanfirstword">History</span> of AI Enabled
            Software Self learning AI
          </h2>
        </div>
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={1} lg={1}>
              <div className="circle mx-auto"></div>
              <div className="campaignline mx-auto"></div>
            </Grid>
            <Grid item xs={12} sm={12} md={11} lg={11}>
              <div className="rightblock-learning">
                <a href="#/" className="eyebrow-banner">
                  <div className="eyebrow-banner-sub">
                    <div className="titleselflearning-link">
                      <h4>GitHub Universe: AI. Security. DevEx</h4>
                      <p>Register for free and join us on Nov 8-9</p>
                    </div>
                    <span>
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>
                  </div>
                </a>
                <div className="pagetitle-self mb-5">
                  <div className="learningcontnetboloc01">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className=" ">
                          <h3>
                            Grace Hopper, born in December 1906 was an American
                            computer scientist and Navy rear admiral.
                          </h3>
                          <p className="laxtbloc-bottomspace">
                            It efficiently searches data from various online
                            platforms,<br></br>
                            offering proactive alerts based on comprehensive
                            risk assessments
                          </p>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6}>
                        <div className=" ">
                          <div className="learningtwo">
                            <img
                              src="images/productSlider/Grace-Hopper-UNIVAC.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <p className="pb-5 logo-selflearnings-title">
                    Trusted by the world’s leading organizations
                  </p>
                  <div className="logo-selflearnings laxtbloc-bottomspace pt-5">
                    <img src="images/productSlider/001.svg" alt="" />
                    <img src="images/productSlider/002.svg" alt="" />
                    <img src="images/productSlider/003.svg" alt="" />
                    <img src="images/productSlider/004.svg" alt="" />
                    <img src="images/productSlider/005.svg" alt="" />

                    <img src="images/productSlider/006.svg" alt="" />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={1} lg={1}>
              <div className="mx-auto">
                <div className="codeicons">
                  <span>
                    <svg
                      aria-hidden="true"
                      height="24"
                      viewBox="0 0 24 24"
                      version="1.1"
                      width="24"
                      data-view-component="true"
                      class="octicon octicon-code"
                    >
                      <path d="M15.22 4.97a.75.75 0 0 1 1.06 0l6.5 6.5a.75.75 0 0 1 0 1.06l-6.5 6.5a.749.749 0 0 1-1.275-.326.749.749 0 0 1 .215-.734L21.19 12l-5.97-5.97a.75.75 0 0 1 0-1.06Zm-6.44 0a.75.75 0 0 1 0 1.06L2.81 12l5.97 5.97a.749.749 0 0 1-.326 1.275.749.749 0 0 1-.734-.215l-6.5-6.5a.75.75 0 0 1 0-1.06l6.5-6.5a.75.75 0 0 1 1.06 0Z"></path>
                    </svg>
                  </span>
                </div>
              </div>
              <div className="campaignline linetwo mx-auto hline-94"></div>
            </Grid>
            <Grid item xs={12} sm={12} md={11} lg={11}>
              <div className="rightblock-learning">
                <div className="pagetitle-self mb-5">
                  <h2>Product Capabilities</h2>

                  <h3 className="colortwo">Suspicious Objects</h3>
                  <p className="laxtbloc-bottomspace-40">
                    Monitors and detects suspicious moving objects and alerts
                    the authorities in real-time.
                  </p>
                </div>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <div className="learningcontnetboloc01 towcallsblock">
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div className="learningtwo imgblowtwocol">
                            <img
                              src="images/productSlider/self-learning.png"
                              alt=""
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <div className=" ">
                            <h3>
                              Grace Hopper, born in December 1906 was an
                              American computer scientist and Navy rear admiral.
                            </h3>
                            <p className="laxtbloc-bottomspace pb-4">
                              It efficiently searches data from various online
                              platforms, offering proactive alerts based on
                              comprehensive risk assessments
                            </p>
                          </div>
                        </Grid>

                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div className="dashboar-learning mt-5">
                            <img src="images/productSlider/self-learning.png" alt="" />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div className="dashboar-learning mt-5">
                            <img src="images/productSlider/self-learning.png" alt="" />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div className="dashboar-learning mt-5">
                            <img src="images/productSlider/self-learning.png" alt="" />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <div className="dashboar-learning mt-5">
                            <img src="images/productSlider/self-learning.png" alt="" />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={1} lg={1}>
              <div className="campaignline linetwo mx-auto"></div>

              <div className="roundshaps">
                <img
                  src="images/productSlider/git-branch-security-2.svg"
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={11} lg={11}>
              <div className="rightblock-learning">
                <div className="pagetitle-self mb-5 outsideshare-title">
                  <h2>Product Capabilities</h2>

                  <h3 className="colorthree">Suspicious Behavious</h3>
                  <p className="laxtbloc-bottomspace-40">
                    Identifies suspicious behavior and actions that have the
                    potential to evolve into major threats.
                  </p>
                </div>
                <div className="dashboar-learning">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className="learningcontnetboloc01 towcallsblock">
                        <div className=" ">
                          <h3>
                            Grace Hopper, born in December 1906 was an American
                            computer scientist and Navy rear admiral.
                          </h3>
                          <p className="laxtbloc-bottomspace pb-4">
                            It efficiently searches data from various online
                            platforms, offering proactive alerts based on
                            comprehensive risk assessments
                          </p>
                        </div>

                        <div className="learningtwo imgblowtwocol">
                          <img
                            src="images/productSlider/placeholder.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className="learningcontnetboloc01 towcallsblock">
                        <div className=" ">
                          <h3>
                            Grace Hopper, born in December 1906 was an American
                            computer scientist and Navy rear admiral.
                          </h3>
                          <p className="laxtbloc-bottomspace pb-4">
                            It efficiently searches data from various online
                            platforms, offering proactive alerts based on
                            comprehensive risk assessments
                          </p>
                        </div>
                        <div className="learningtwo">
                          <img
                            src="images/productSlider/placeholder.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={1} lg={1}>
              <div className="campaignline linetwo mx-auto hline-94"></div>

              <div className="roundshaps">
                <img src="images/productSlider/productivity.svg" alt="" />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={11} lg={11}>
              <div className="rightblock-learning">
                <div className="pagetitle-self mb-5 outsideshare-title">
                  <h2>Product Capabilities</h2>

                  <h3 className="colorthree">Suspicious Behavious</h3>
                  <p className="laxtbloc-bottomspace-40">
                    Identifies suspicious behavior and actions that have the
                    potential to evolve into major threats.
                  </p>
                </div>
                <div className="dashboar-learning">
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className="learningcontnetboloc01 towcallsblock">
                        <div className=" ">
                          <h3>
                            Grace Hopper, born in December 1906 was an American
                            computer scientist and Navy rear admiral.
                          </h3>
                          <p className="laxtbloc-bottomspace pb-4">
                            It efficiently searches data from various online
                            platforms, offering proactive alerts based on
                            comprehensive risk assessments
                          </p>
                        </div>

                        <div className="learningtwo imgblowtwocol">
                          <img
                            src="images/productSlider/placeholder.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <div className="learningcontnetboloc01 towcallsblock">
                        <div className=" ">
                          <h3>
                            Grace Hopper, born in December 1906 was an American
                            computer scientist and Navy rear admiral.
                          </h3>
                          <p className="laxtbloc-bottomspace pb-4">
                            It efficiently searches data from various online
                            platforms, offering proactive alerts based on
                            comprehensive risk assessments
                          </p>
                        </div>
                        <div className="learningtwo">
                          <img
                            src="images/productSlider/placeholder.jpg"
                            alt=""
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="imgplatformsbg  ">
          <img src="images/productSlider/bg_plateform.png" alt="" />
        </div>
      </div>
    </>
  );
};

export default Selflearingainewtwo;
