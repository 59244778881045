import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef } from "react";

const JLL = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: JLL (Jones Lang LaSalle)`;
  }, []);

  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);

  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "Guria NGO",
      desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
      url: "Guria-varanasi-human-trafficking",
      img: "images/cases-guria.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);

  return (
    <>
      <div className="plateformsub-page clientpage-outer">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>

        <div className="container">
          <div className="clientnavgations">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <h3>JLL (Jones Lang LaSalle)</h3>
                <div className="clientnavgationssubtitle"><span>Bengaluru,</span>  <span>India</span> </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                lg={8}
                className="customer-page-nav-links-grid"
              >
                <div className="customer-page-nav-links-wrap">
                  <ul>
                    <li>
                      <a href="#overview">Overview</a>{" "}
                    </li>
                    <li>
                      <a href="#problem">Problem</a>{" "}
                    </li>
                    <li>
                      <a href="#solution">Solution</a>{" "}
                    </li>
                    <li>
                      <a href="#result">Result</a>{" "}
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <div className="text-left logoclient">
                <img src="images/productSlider/jll.png" alt="" />
              </div>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client" id="overview">
                <h3>Overview</h3>
                <p>
                  JLL, a renowned leader in real estate services, is embracing the transformative power of Artificial Intelligence (AI) in collaboration with Skylark Labs. This strategic partnership aims to address complex challenges in property management, enhancing safety, efficiency, and operational transparency. JLL's commitment to innovation, coupled with Skylark Labs' technological expertise, is paving the way for advanced AI-driven solutions in the real estate sector, reflecting a shared vision of pioneering future-ready property management practices.
                </p>
                {/* <img src="images/Customers/JLL/-JLL overview.jpg" alt="" style={{
                  borderRadius: "40px",
                }} /> */}
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="problem">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Problem</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  JLL is addressing key challenges in property management, with a focus on improving security infrastructure and optimizing security at IT Parks. Their goal is to maintain high safety and operational efficiency standards across their extensive real estate assets.
                </p>
                <div className="customerstwocal" style={{ padding: "0" }}>
                  <h4 style={{ padding: "0" }}>Key areas of focus include:</h4>
                </div>
                <ul>
                  <li><strong>Security Infrastructure for Interconnecting Facilities:</strong> JLL aims to upgrade security across its Asian facilities by implementing an AI-based integrated surveillance system. This system needs to be sophisticated enough for real-time monitoring and threat detection across various locations. The solution must be technologically advanced, scalable, and adaptable to the evolving needs of property management.</li>
                  <li><strong>Security Optimization for IT Parks:</strong> JLL seeks to enhance the efficiency of security management in IT Parks. This involves integrating an AI-based surveillance system to enable faster, more proactive threat response with fewer guards on site, all while integrating smoothly with existing security structures.</li>
                </ul>
                <br />
                <img src="images\Customers\JLL\securityjllproblem.png" alt="" style={{
                  borderRadius: "40px",
                }} />
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="solution">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Solutions</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  In collaboration with Skylark Labs, JLL has implemented an enhanced AI-powered surveillance system in its facilities, integrating the BROS from Kepler, with specializing in infrastructure security features. This advanced solution provides not only real-time threat detection but also centralized control, significantly boosting safety and operational efficiency. The incorporation of the BROS elevates surveillance capabilities and optimizes facility management processes, setting a new standard in infrastructure security and efficiency.
                </p>
                <div className="customerstwocal">
                  <h4>The solutions developed are:</h4>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <img src="images\Customers\JLL\_jllsol1_.png" alt="" />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                    }}>
                      <h4 style={{
                        fontWeight: "500",
                        margin: "0",
                      }}><span className="spanfirstword">Solution</span> for Security Infrastructure</h4>
                      <p className="pt-0 abouttext-pblock">In collaboration with Skylark Labs, JLL implemented an AI-powered surveillance system for its facilities. This advanced solution offered real-time threat detection and centralized control, significantly enhancing safety and operational efficiency. The AI integration modernized the surveillance process, establishing a new standard in facility management and security.
                      </p>
                    </Grid>
                  </Grid>
                </div>
                <div className="customerstwocal">

                  <Grid container spacing={3}>

                    <Grid item xs={12} sm={12} md={6} lg={6} style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "nowrap",
                      justifyContent: "center",
                    }}>
                      <h4 style={{
                        fontWeight: "500",
                        margin: "0",
                      }}><span className="spanfirstword">Solution</span> for Security Optimization in IT Parks</h4>
                      <p className="pt-0 abouttext-pblock">
                        For IT Park security, JLL, in partnership with Skylark Labs, has upgraded its AI-based surveillance module by incorporating Kepler's BROS, equipped with advanced surveillance features. This enhancement optimizes guard deployment and elevates their ability to respond swiftly to threats. The integration of the BROS not only improves surveillance efficiency but also ensures high safety standards. It streamlines resource allocation and operational costs, thus elevating the overall standards of optimization and operations in IT Park security.
                      </p>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <img src="images\Customers\JLL\jllsol2.png" alt="" />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="result">
            <Grid item xs={12} sm={3} md={3} lg={4}>
              <h3>Conclusion</h3>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={8}>
              <div className="client">
                <p>
                  The partnership between JLL and Skylark Labs in integrating AI into real estate services has set a benchmark in property management innovation. Their collaborative approach in solving complex challenges has not only enhanced safety and efficiency but also underscored their commitment to leading the digital transformation in the real estate industry.
                </p>
              </div>
              <Grid container spacing={3} className="customer-page-key-features customerlast">
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img
                        src="images/productSlider/Play.svg"
                        alt=""
                      /></div>
                    <h3>
                      85% Enhanced Centralized Control Efficacy
                    </h3>
                    <p>Centralized system significantly boosting operational efficiency.</p>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6}>
                  <div className="key-features">
                    <div className="iconsfeatucher">
                      <img
                        src="images/productSlider/Analytics.svg"
                        alt=""
                      /></div>
                    <h3>
                      60% Operational Cost Optimization
                    </h3>
                    <p>Streamlines operational costs by optimizing manpower while maintaining security effectiveness.</p>
                  </div>
                </Grid>

              </Grid>
            </Grid>
          </Grid>

        </div>
      </div>
      {/* 
      <div className="plateformsub-page">
        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Related</span> More Cases
                  </h2>
                </div>
              </Grid>
            </Grid>

            <div className="industriesListblock pt-5">
              <ul>
                {otherCases.map((ele, idx) => (
                  <li>
                    <a href="#contact" className="industriesListcol">
                      <div className="solutionListimg">
                        <img src={ele.img} alt="" />
                      </div>
                      <div className="industriesListcoltext">
                        <h4>{ele.title} </h4>
                        <p>{ele.desc}</p>
                        <div className="quicklinks casesquicklinks">
                          {" "}
                          View full case{" "}
                          <img src="images/right-arrow.png" alt="" />{" "}
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div> */}
    </>
  );
};

export default JLL;