import About from "about/about";
import Verifyuser from "about/inner/verify_user";
import RequestDemo from "demo/requestDemo";
import FaceMaskWebsocket from "home/FaceMaskWebsocket";
import Homepage from "homepage";
import Test from "home/Test";
import UnconstrainedDemo from "home/UnconstrainedDemo";
import Landing1 from "home/Webcam";
import WebSocketComponent from "home/WebSocketComponent";
// import Blogs from "blog/Blogs";
import Platformdetail from "blog/BlogDetailTemplate";
import Solutions from "solutions/solutions";
import Webcam from "./home/Webcam";
import ExampleCase from "usecases/examplecase";
import TemplateUseCases from "usecases/usecasesInner/TemplateUseCases";
import Press from "about/inner/press";
import Team from "about/inner/offices";
import IntellectualProperty from "about/inner/intellectualproperty";
import Data from "about/inner/data";
import Careers from "about/inner/careers";
import { exampleData } from "usecases/exampleData";
import MainComponent from "demo/mainComponent";
import Customers from "customers/customers";
import Borderprotection from "solutions/byindustry/Border_protection";
import Campus from "solutions/byindustry/Campus";
import Airbornesystems from "solutions/byindustry/Airborne_systems";
import Investors from "about/inner/investors";
import TextEditor from "blog/admin/TextEditor";
import AdminPanel from "blog/admin/AdminPanel";
import Transport from "solutions/byindustry/transport";
// import PostDetails from "blog/BlogDetail";
import Industrialparks from "solutions/byindustry/Industrial_parks";
import Publicsafety from "solutions/byindustry/Public-safety";
import Childrensafety from "solutions/byindustry/Childrensafety";
import Censer from "solutions/byindustry/Censer";
import Mrts from "productssuite/Mrts";
import Partners from "about/inner/partners";
import Platforms from "productssuite/Platforms";
import Silas from "productssuite/Silas";
import Etos from "productssuite/Etos";
import Forensics from "productssuite/Forensics";
import Aries from "productssuite/Aries";
import Miras from "productssuite/Miras";
import Scds from "productssuite/Scds";
import Dros from "productssuite/Dros";
import Privacy from "about/inner/privacy";
import Privacycanada from "about/inner/privacy-canada";
import Privacyaustralia from "about/inner/privacy-australia";
import Privacyeurope from "about/inner/privacy-europe";
import Privacyus from "about/inner/privacy-us";
import Terms from "about/inner/terms";
import Rdrm from "productssuite/Rdrm";
import Turing from "productssuite/Turing";
import Kepler from "productssuite/Kepler";
import Selflearingai from "productssuite/Selflearingai";
// Blog Routes//
// import Bloghome from "blogs/Bloghome";
import Blogcategory from "blogs/Blogcategory";
import Blogsubcategory from "blogs/Blogsubcategory";
import Singleblogpage from "blogs/Singleblogpage";
import Bloghomenew from "blogs/Bloghomenew";
import Supplyintel from "productssuite/Supplyintel";
import Socialmediaintel from "productssuite/Socialmediaintel";
import Newswatch from "productssuite/Newswatch";
import Financialflux from "productssuite/Financialflux";
import Bloglanding from "blogs/Bloglanding";
import Clientpage from "solutions/byindustry/Clientpage";
import Clientlisting from "solutions/byindustry/Clientlisting";
import Selflearingainew from "productssuite/Selflearingai";
import Selflearingainewtwo from "productssuite/Selflearingainewtwo";
import BlogMain from "blogs/BlogMain";
import AllBlogs from "blogs/AllBlogs";
import AllNews from "blogs/AllNews";
import AllEvents from "blogs/AllEvents";
import Indiannavy from "solutions/byindustry/Indiannavy";
import Indianarmy from "solutions/byindustry/Indianarmy";
import Indianairforce from "solutions/byindustry/Indianairforce ";
import Bordersecurityforce from "solutions/byindustry/Bordersecurityforce";
import Indiancoastguard from "solutions/byindustry/Indiancoastguard";
import Ideaforgetech from "solutions/byindustry/Ideaforgetech";
import Mantech from "solutions/byindustry/Mantech";
import Collinsaerospace from "solutions/byindustry/Collinsaerospace";
import Mercedes from "solutions/byindustry/Mercedes";
import Jll from "solutions/byindustry/Jll";
import Ashokapurestudy from "solutions/byindustry/Ashokapurestudy";
import SashastraSeemaBal from "solutions/byindustry/SashastraSeemaBal";
import Artrabia from "solutions/byindustry/Artrabia";
import SpecificBlog from "blogs/SpecificBlog";
import SpecificEvent from "blogs/SpecificEvent";
import SpecificNews from "blogs/SpecificNews";

// import FacesGrid from "home/sections/facesGrid";
let urlsExampleData = []

for (let i in exampleData) {
  urlsExampleData = [
    { path: "/case" + i, component: ExampleCase },
    ...urlsExampleData,
  ];
}

export const routes = [
  {
    path: "/",
    component: Homepage,
  },
  {
    path: "/webcam",
    component: Webcam,
  },
  {
    path: "/test",
    component: Test,
  },
  {
    path: "/constrained_face_recog",
    component: Landing1,
  },
  {
    path: "/customers",
    component: Customers,
  },
  {
    path: "/websocket",
    component: WebSocketComponent,
  },
  {
    path: "/face_mask",
    component: FaceMaskWebsocket,
  },
  {
    path: "/unconstrained",
    component: UnconstrainedDemo,
  },
  {
    path: "/platform-detail",
    component: Platformdetail,
  },
  {
    path: "/about-us",
    component: About,
  },
  {
    path: "/demo",
    component: RequestDemo,
  },
  {
    path: "/case",
    component: ExampleCase,
  },
  {
    path: "/solutions",
    component: Solutions,
  },
  {
    path: "/transport",
    component: Transport,
  },
  {
    path: "/border-protection",
    component: Borderprotection,
  },
  {
    path: "/airborne-systems",
    component: Airbornesystems,
  },
  {
    path: "/industrial-parks",
    component: Industrialparks,
  },
  {
    path: "/children-safety",
    component: Childrensafety,
  },
  {
    path: "/censer",
    component: Censer,
  },
  {
    path: "/campus",
    component: Campus,
  },
  {
    path: "/jewellery",
    component: TemplateUseCases,
  },
  {
    path: "/bank",
    component: TemplateUseCases,
  },
  {
    path: "/airport",
    component: TemplateUseCases,
  },
  {
    path: "/hospital",
    component: TemplateUseCases,
  },
  {
    path: "/hotels",
    component: TemplateUseCases,
  },
  {
    path: "/companies",
    component: TemplateUseCases,
  },
  {
    path: "/press",
    component: Press,
  },
  // {
  //   path: "/offices",
  //   component: Team,
  // },
  {
    path: "/intellectual-property",
    component: IntellectualProperty,
  },
  {
    path: "/data-security",
    component: Data,
  },
  {
    path: "/careers",
    component: Careers,
  },
  // {
  //   path: "/investors",
  //   component: Investors,
  // },
  {
    path: "/trial",
    component: MainComponent,
  },
  {
    path: "/public-safety",
    component: Publicsafety,
  },
  {
    path: "/mrts",
    component: Mrts,
  },
  {
    path: "/platforms",
    component: Platforms,
  },
  {
    path: "/silas",
    component: Silas,
  },
  {
    path: "/rdrm",
    component: Rdrm,
  },
  {
    path: "/etos",
    component: Etos,
  },
  {
    path: "/forensics",
    component: Forensics,
  },
  {
    path: "/aries",
    component: Aries,
  },
  {
    path: "/miras",
    component: Miras,
  },
  {
    path: "/dros",
    component: Dros,
  },
  {
    path: "/scds",
    component: Scds,
  },
  {
    path: "/partners",
    component: Partners,
  },
  {
    path: "/privacy",
    component: Privacy,
  },
  {
    path: "/privacy-canada",
    component: Privacycanada,
  },
  {
    path: "/privacy-australia",
    component: Privacyaustralia,
  },
  {
    path: "/privacy-europe",
    component: Privacyeurope,
  },
  {
    path: "/privacy-us",
    component: Privacyus,
  },
  {
    path: "/terms",
    component: Terms,
  },
  {
    path: "/blog-category",
    component: Blogcategory,
  },
  {
    path: "/blog-sub-category",
    component: Blogsubcategory,
  },

  {
    path: "/turing",
    component: Turing,
  },

  {
    path: "/kepler",
    component: Kepler,
  },

  {
    path: "/self-learning-ai",
    component: Selflearingai,
  },
  {
    path: "/blog/:blogId/:slug",
    component: SpecificBlog,
  },
  {
    path: "/event/:eventId/:slug",
    component: SpecificEvent,
  },
  {
    path: "/news/:newsId/:slug",
    component: SpecificNews,
  },
  {
    path: "/blog/detail",
    component: Singleblogpage,
  },
  {
    path: "/supply-intel",
    component: Supplyintel,
  },
  {
    path: "/social-media-intel",
    component: Socialmediaintel,
  },
  {
    path: "/news-watch",
    component: Newswatch,
  },
  {
    path: "/blog-landing",
    component: Bloglanding,
  },

  {
    path: "/financial-flux",
    component: Financialflux,
  },
  {
    path: "/clientpage",
    component: Clientpage,
  },
  {
    path: "/client-listing",
    component: Clientlisting,
  },
  {
    path: "/self-learingai-new",
    component: Selflearingainew,
  },

  {
    path: "/self-learingai-new-two",
    component: Selflearingainewtwo,
  },

  {
    path: "/blogs",
    component: BlogMain,
  },

  {
    path: "/use-cases",
    component: AllBlogs,
  },

  {
    path: "/news",
    component: AllNews,
  },

  {
    path: "/events",
    component: AllEvents,
  },
  {
    path: "/indian-navy",
    component: Indiannavy,
  },
  {
    path: "/indian-army",
    component: Indianarmy,
  },
  {
    path: "/indian-airforce",
    component: Indianairforce,
  },
  {
    path: "/border-security-force",
    component: Bordersecurityforce,
  },
  {
    path: "/indian-coast-guard",
    component: Indiancoastguard,
  },

  {
    path: "/ideaforge",
    component: Ideaforgetech,
  },
  {
    path: "/mantech",
    component: Mantech,
  },
  {
    path: "/artrabia",
    component: Artrabia,
  },
  {
    path: "/collins-aerospace",
    component: Collinsaerospace,
  },

  {
    path: "/mercedes",
    component: Mercedes,
  },

  {
    path: "/jones-lang-laSalle",
    component: Jll,
  },

  {
    path: "/ashoka-purestudy",
    component: Ashokapurestudy,
  },

  {
    path: "/customers",
    component: Customers,
  },

  {
    path: "/sashastra-seema-bal",
    component: SashastraSeemaBal,
  },


  ...urlsExampleData,
];
