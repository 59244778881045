import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import React, { useState } from "react";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
}));

function Test() {
  const classes = useStyles();
  const submit = async (e) => {
    await e.preventDefault();

    const res = await fetch("./api/sendMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ to: number, body: body }),
    });

    const data = await res.json();

    if (data.success) {
      await setNumber("");
      await setBody("");
    } else {
      await setNumber("An Error has occurred.");
      await setBody("An Error has occurred.");
    }

    console.log("submitting");
  };

  const [number, setNumber] = useState("");
  const [body, setBody] = useState("");

  return (
    <div className="App">
      <Grid>
        <h2>Send SMS</h2>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            id="number"
            label="Standard secondary"
            color="secondary"
          />
          <TextField
            as="textarea"
            rows="3"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            id="body"
            label="Filled secondary"
            variant="filled"
            color="secondary"
          />
        </form>
        {console.log(`Number is ${number} and the Message is ${body}`)}
        <Button onClick={submit}>Submit</Button>
      </Grid>
    </div>
  );
}

export default Test;
