import React, { useState, useEffect } from "react";
import { debounce, classList } from "utils";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

const TopBar1 = () => {
  const [isClosed, setIsClosed] = useState(true);

  let scrollableElement = document.querySelector(".scrollable-content");
  if (!scrollableElement) scrollableElement = window;

  let handleScrollRef = null;
  let toggleIcon = isClosed ? "menu" : "close";
  const handleScroll = () => {
    return debounce(({ target: { scrollTop } }) => {
      // let isCurrentTop = scrollTop < 100 || scrollableElement.scrollY < 100;
      // setIsTop(isCurrentTop);
    }, 20);
  };

  handleScrollRef = handleScroll();

  useEffect(() => {
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScrollRef);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScrollRef);
      }
    };
  }, [scrollableElement, handleScrollRef]);

  return (
    <section
      style={{ backgroundColor: "rgba(0, 0, 0, 0.0)" }}
      className={classList({
        header: true,

        closed: isClosed,
      })}
    >
      <div className="container header-container" style={{ maxWidth: "100%" }}>
        <div className="brand">
          <img
            src="./assets/images/small_logo.png"
            alt=""
            style={{ marginLeft: "10%", maxHeight: "45px" }}
          />
        </div>
        {/* <ul className="navigation">
          <li>
            <NavLink to="/demos">Demos</NavLink>
          </li>
          <li>
            <ScrollTo to="intro1" onScroll={close}>
              Home
            </ScrollTo>
          </li>
          <li>
            <ScrollTo to="portfolio1" onScroll={close}>
              Works
            </ScrollTo>
          </li>
          <li>
            <ScrollTo to="service1" onScroll={close}>
              Service
            </ScrollTo>
          </li>

          <li>
            <ScrollTo to="pricing1" onScroll={close}>
              Pricing
            </ScrollTo>
          </li>
          <li>
            <ScrollTo to="contact1" onScroll={close}>
              Contact
            </ScrollTo>
          </li>
        </ul> */}
        <div className="m-auto" />
        {/* <ul className="navigation">
          <li>
            <Link to="/dashboard">
              <Icon className="mr-4">person</Icon> My Account
            </Link>
          </li>
        </ul> */}
        <IconButton
          className="header__toggle"
          onClick={() => {
            setIsClosed(!isClosed);
          }}
        >
          <Icon>{toggleIcon}</Icon>
        </IconButton>
      </div>
    </section>
  );
};

export default TopBar1;
