import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingOverlay from "react-loading-overlay";

const Verifyuser = () => {
  const search = useLocation().search;
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    const url = new URLSearchParams(search).get("url");
    // console.log(url);
    const payload = new URLSearchParams(search).get("payload");
    // console.log(payload);
    // console.log(url + "?" + payload);
    if (url == null || payload == null) {
      setIsLoading(false);
    } else {
      fetch(url + "?" + payload, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            setIsValid(true);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
    title.innerText = "Skylark Labs: Verify User";
    return () => {
      console.log("title changed");
    };
  }, []);
  if (isLoading) {
    return (
      <LoadingOverlay active={isLoading} spinner text="Verifying account....">
        <section
          className="aboutpage aboutuone_glass"
          style={{ height: "100vh" }}
        >
          <div className="container">
            <div className="thankspage">
              <img src="images/thanks_illustration.png" alt="" />
            </div>
            <div className="thankstitle">
              <h3>Verifying Your Email ....</h3>
            </div>
          </div>
        </section>
      </LoadingOverlay>
    );
  }
  if (isValid)
    return (
      <>
        <section className="aboutpage aboutuone_glass">
          <div className="container">
            <div className="thankspage">
              <img src="images/thanks_illustration.png" alt="" />
            </div>
            <div className="thankstitle">
              <h3>Your email has been verified. Thanks!</h3>

              <span>You may close this window!!</span>

              {/* <p>
              We truly appreciate the chance to offer you our high quality
              services & solutions.
            </p>

            <a href="/" className="btn">
              Go Back
            </a> */}
            </div>
          </div>
        </section>
      </>
    );
  else
    return (
      <>
        <section className="aboutpage aboutuone_glass">
          <div className="container">
            <div className="thankspage">
              <img src="images/failed_illustration.png" alt="" />
            </div>
            <div className="thankstitle">
              <h3>Email address verification failed.</h3>
              <span>You may close this window!!</span>
            </div>
          </div>
        </section>
      </>
    );
};

export default Verifyuser;
