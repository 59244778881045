import React from 'react';

const RequestDemo = () => {
    const [prod, prodtype] = React.useState("")
    const valuehandler = e => {
        prodtype(e);
    }
    return <>RequestDemo</>;
}

export default RequestDemo