import SnackbarContent from "@material-ui/core/SnackbarContent";
import Snackbar from "@material-ui/core/Snackbar";
import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(({ palette, ...theme }) => ({
  circleIndeterminate: {
    animation: "none",
    strokeDasharray: "80px, 200px",
    strokeDashoffset: "0px",
  },
}));

const Notification = (props) => {
  const classes = useStyles();
  const [notif, setNotif] = useState(props.notif);

  const handleNotifClose = () => {
    setNotif({
      notifOpen: false,
    });
  };
  useEffect(() => {
    setNotif(props.notif);
  }, [props]);

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoHideDuration={10000}
      open={notif.notifOpen}
      onClose={handleNotifClose}
      onClick={handleNotifClose}
      TransitionComponent={notif.Transition}
      style={{ height: "100%" }}
      action={
        <React.Fragment>
          <div>
            <Button color="secondary" size="small" onClick={handleNotifClose}>
              CLOSE
            </Button>
          </div>
        </React.Fragment>
      }
    >
      {/* <Alert onClose={handleClose} severity="success">
            This is a success message!
          </Alert> */}
      <SnackbarContent
        style={{
          width: "auto",
          minWidth: "100px",
          height: "200px",
          borderRadius: "10px",
          marginTop: "100px",
          backgroundColor: "rgba(52, 52, 52, 0.8)",
        }}
        message={
          <div style={{ justifyContent: "center" }}>
            <div style={{ justifyContent: "center", display: "flex" }}>
              {notif.typeLoader ? (
                <CircularProgress
                  className={classes.circleIndeterminate}
                  shrinkAnimation={false}
                  style={{ color: "white" }}
                />
              ) : (
                <span
                  className="material-icons"
                  style={{
                    marginTop: "10px",
                    padding: "5px",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  announcement
                </span>
              )}
            </div>
            <span
              align="center"
              style={{
                fontSize: "25px",
                width: "100%",
                paddingTop: "50px",
                textAlign: "center",
              }}
              id="client-snackbar"
            >
              {notif.message}
            </span>
          </div>
        }
      />
    </Snackbar>
  );
};
export default Notification;
