import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player"; 
import Industrialbanner from "../../videofiles/children-safety.mp4";  
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
const Childrensafety = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Industrial Parks`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [showTrial, setShowTrial] = React.useState(false);
  const [expanded, setExpanded] = React.useState("panel2");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };
  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "D.S. Jeweller",
      desc: "The incidents happened in the locality where robbers used to come at night and loot the jewellery shops.",
      url: "ds-jewellers",
      img: "images/cases-jewellers.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      // if (section4.current && offsetY >= section4.current.offsetTop)
      //   addActiveClass(navOption4);
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChangenew = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>Children safety</h1>
              <p>
              Skylark lab’s advanced AI solutions enhance the safety and security of Children by detecting potential threats such as kidnapping, child violence, child trafficking, intrusion, etc in real-time with instant reactive alerts.
              </p>
              <div className="subtages"><a href="#/">Child Trafficking </a>  <a href="#/">Child Violence </a> <a href="#/">Kidnapping 
 </a>  
</div>
     
            </div>
          </div>
        </div>

        <ReactPlayer
          style={{
            zIndex: "-1",
            pointerEvents: "none",
          }}
          controls={false}
          volume={0}
          width="100%"
          height="100%"
          loop={true}
          muted={true}
          playsinline
          playing={true}
          stopOnUnmount={true}
          config={{
            file: {
              attributes: {
                autoplay: true,
                controls: false,
                loop: true,
                muted: true,
              },
            },
          }}
          url={Industrialbanner}
        />
      </div>

      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>Children Safety</h2>
            <p>
            Skylark lab’s advanced AI solutions enhance the safety and security of Children by detecting potential threats such as kidnapping, child violence, child trafficking, intrusion, etc in real-time with instant reactive alerts.
            </p>
          </div>
        </div>
      </div>

      <div className="plateformsub-page">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>


        <div className="container pt-5">
          <div className="plateformabout  mt-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="aboutimgblock">
                  {" "}
                  <img src="images/industrial-Parks.png" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 pb-0">
                      <span className="spanfirstword">Enhance </span>  Children safety
                    </h2>
                    <p className="pt-0 abouttext-pblock">
                    Our solutions enhance the safety and security of children by searching for missing children, detecting the kidnapping of children,  child violence, etc in real-time. It ensures the safety of kids in various other spaces by running a search for kids missing from school, monitoring school premises, etc.

                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>




      

      <section className="homeprodctlist blackrarkoutr "> 
        <div className="container">
          <Box sx={{ width: "100%" }}>
            <TabPanel value={value} index={0}>
              <div className="productitembox ">
              <img src="images/child-trafficking.png" alt="" />

                <div className="productitemboxdis">
                  <h4> Child Trafficking</h4>
                  <p>
                  Our system can help identify and rescue child victims of trafficking by performing age-invariant face recognition by comparing the images when they were retrieved and when they were originally kidnapped. 

                  </p>
                
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="productitembox ">
               
                  <img src="images/child-violence.png" alt="" />

                <div className="productitemboxdis">
                  <h4>Child Violence</h4>
                  <p>
                  Our system detects any kind of child abuse or violence and sends immediate alerts to the teachers, parents, law-enforcements etc.

                  </p>
                  
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="productitembox ">
              <img src="images/child_kidnapping.png" alt="" />

                <div className="productitemboxdis">
                  <h4>Child Kidnapping</h4>
                  <p>
                  Our system detects kidnapping incidents by analysing the behaviour and pose of kidnappers in real-time and alerts the authorities to prevent the incidents

                  </p>
                 
                </div>
              </div>
            </TabPanel>

            <div className="poductlisttabs">
              <Box sx={{ borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChangenew}
                  aria-label="basic tabs example"
                >
                  <Tab label="Child Trafficking" {...a11yProps(0)} />
                  <Tab label="Child Violence" {...a11yProps(1)} />
                  <Tab label="Child Kidnapping" {...a11yProps(2)} />
                </Tabs>
              </Box>
            </div>
          </Box>
        </div>
      </section>
 

      <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
         
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Child</span>{" "}
                       Rescue System
                    </h4>
                    <p>
                    Our system performs age-invariant face recognition by comparing the images when they were retrieved and when they were originally kidnapped and also predicts if a child is minor.

                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Age Invariant  Analysis
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Kinship  Analysis  
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Sketch   Analysis  
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>

              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                <img src="images/child-sescue-system.png" alt=""/>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

 
      <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
         
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/transport-abondended-bags.jpg" alt="" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Child</span>  Monitoring System
                    </h4>
                    <p>
                    The child Monitoring system allows the parents or teachers to keep a track of their children when they are out of their sight, by sending immediate alerts.

                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Children Tracking           
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Children Kidnapping
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

      
      <section className="caseList readmorecsases ">
        <div className="container">
          <div className="platformCamera_dis">
            <h2>Read More Cases</h2>
          </div>

          <div className="industriesListblock pt-5">
            <ul>
              {otherCases.map((ele, idx) => (
                <li>
                  <a href="#contact" className="industriesListcol">
                    <div className="solutionListimg">
                      <img src={ele.img} alt="" />
                    </div>
                    <div className="industriesListcoltext">
                      <h4>{ele.title} </h4>
                      <p>{ele.desc}</p>
                      <div className="quicklinks casesquicklinks">
                        {" "}
                        View full case{" "}
                        <img src="images/right-arrow.png" alt="" />{" "}
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      
      </div>
    </>
  );
};

export default Childrensafety;
