import getWebsocketToken from "./sections/WebsocketConnection";
import React, { useState, useEffect, useRef } from "react";
import Webcam from "react-webcam";
import $ from "jquery";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import TopBar from "./sections/TopBar1";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import "react-circular-progressbar/dist/styles.css";
import Divider from "@material-ui/core/Divider";
import { Icon } from "@material-ui/core";
import * as faceapi from "face-api.js";
import Notification from "./sections/Notification";
import Slide from "@material-ui/core/Slide";
import Scanner from "./Scanner";

import { getLocation } from "./sections/services";
import ResultGrid from "./sections/resultGrid";

import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  fab: {
    width: "100%",
    background: "white",
  },
  extendedIcon: {
    marginRight: theme.spacing.unit,
  },
  section: {
    padding: "20px",
  },
  sectionHeader: {
    textAlign: "center",
    fontSize: "40px",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginBottom: "10px",
    fontFamily: "'Roboto', sans-serif",
    fontWeight: "normal !important",
  },
  crop_result: {
    height: "300px",
    marginBottom: "60px",
    marginLeft: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    marginTop: "60px",

    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginBottom: "0px",
      width: "100%",
      height: "auto",
    },
  },
}));

const UnconstrainedDemo = () => {
  const classes = useStyles();
  var initialValues = {
    image: "./assets/images/face_mesh.jpg",
    blob: "",
  };
  const size =
    $(window).width() <= 1302
      ? { width: $(window).width(), height: "600" }
      : { width: "900", height: "720" };
  const aspect = $(window).width() <= 1199 ? 0.8 : 1.78;

  const videoConstraints = {
    aspectRatio: aspect,
    facingMode: "user",
  };

  const [source, setSource] = useState(0);
  const [faceList, setFaceList] = useState([]);

  const [matched, setMatched] = useState({
    reference: -1,
    image: "./assets/images/face_mesh.jpg",
    last_found: null,
  });

  //   const [referenceTime, setReferenceTime] = useState(null);
  //   const [dummyTime, setDummyTime] = useState(null);
  // eslint-disable-next-line
  const [number, setNumber] = useState("");

  const [referenceImage, setReferenceImage] = useState(initialValues);
  const [socketUrl, setSocketUrl] = useState(0);
  const [socket, setSocket] = useState(0);
  const [key, setKey] = useState(0);
  const [dummyImage, setDummyImage] = useState(initialValues);
  const [loading, setLoading] = useState(true);
  const [referenceToUpload, setReferenceToUpload] = useState(0);
  const [referenceDummyToUpload, setReferenceDummyToUpload] = useState(0);
  const [sendFaces, setSendFaces] = useState(true);
  const [responseState, setResponseState] = useState(null);

  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);
  const [cameraHash, setCameraHash] = useState(1);

  const [startStream, setStartStream] = useState(false);
  const [compressionFactor, setCompressionFactor] = useState(0.2);
  const [similarity, setSimilarity] = useState({
    reference: -1,
    value: 0,
  });
  const [location, setLocation] = useState("");
  const scanner = useRef();

  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    console.log("captured");

    let imageSrc = webcamRef.current.getScreenshot();

    var base64result = imageSrc.split(",")[1];
    var raw = window.atob(base64result);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    var byteLen = array.byteLength;

    if (30000 / byteLen < 1) {
      var val = 30000 / byteLen;
      console.log("compressino factor" + val.toFixed(1));
      if (val > 0) {
        setCompressionFactor(val.toFixed(1));
      }
    }

    setSource(imageSrc);
  }, [webcamRef]);

  useEffect(() => {
    async function loadModels() {
      await faceapi.nets.tinyFaceDetector.loadFromUri("/models");
      handleNotifClose();
      console.log("models loaded");
      setLoading(false);
    }
    loadModels();

    function saveLocation(position) {
      console.log("saved location");
      setLocation(
        "Latitude: " +
          position.coords.latitude +
          " Longitude: " +
          position.coords.longitude
      );
    }
    getLocation(saveLocation);
  }, []);

  const [notif, setNotif] = useState({
    notifOpen: true,
    transition: Slide,
    message: "Starting Demo",
    typeLoader: true,
  });

  const openNotif = (m, type) => {
    setNotif({
      notifOpen: true,
      message: m,
      typeLoader: type,
    });
    console.log(location);
  };
  const handleNotifClose = () => {
    console.log("closing");
    setNotif({
      notifOpen: false,
    });
  };

  const handleDevices = React.useCallback(
    (mediaDevices) =>
      setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
    [setDevices]
  );
  const changeCamera = () => {
    setCameraHash((cameraHash) => cameraHash + 1);
  };

  React.useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => {
    if (devices.length > 0) {
      console.log(cameraHash % devices.length);
      setDeviceId(devices[cameraHash % devices.length].deviceId);
      if (scanner.current) {
        console.log(
          "sending this:" + devices[cameraHash % devices.length].deviceId
        );
        scanner.current.changeDeviceId(
          devices[cameraHash % devices.length].deviceId
        );
      }
    }
    // eslint-disable-next-line
  }, [cameraHash]);

  const handleStart = () => {
    setStartStream((prevItems) => !prevItems);
  };

  useEffect(() => {
    function sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    }
    console.log("reference :");

    if (
      referenceToUpload !== 0 &&
      referenceDummyToUpload !== 0 &&
      socket === 0
    ) {
      console.log("reconnect here");

      setSocket(new WebSocket(socketUrl));
    }

    if (
      source !== null &&
      source !== 0 &&
      socket !== 0 &&
      referenceToUpload !== 0 &&
      referenceDummyToUpload !== 0
    ) {
      try {
        console.log("use websockets");

        console.log(referenceToUpload);

        let initParams = {
          action: "start_reference",
          fileSize: 140945,
        };
        socket.send(JSON.stringify(initParams));
        setStartStream(true);
      } catch (err) {
        sleep(2000).then(() => {
          let initParams = {
            action: "start_reference",
            fileSize: 140945,
          };
          socket.send(JSON.stringify(initParams));
          setStartStream(true);
        });
        console.log(err);
      }
    }
  }, [
    source,
    referenceToUpload,
    socket,
    referenceDummyToUpload,
    sendFaces,
    socketUrl,
  ]);

  useEffect(() => {
    if (startStream && source !== 0) {
      document.getElementById("upload2").style.pointerEvents = "none";
      document.getElementById("btn-upload2").disabled = "true";
      document.getElementById("upload1").style.pointerEvents = "none";
      document.getElementById("btn-upload").disabled = "true";
      document.getElementById("btn").style.display = "none";
      setTimeout(() => {
        document.getElementById("webcam").style.visibility = "hidden";
      }, 500);
    } else if (source !== 0) {
      setSource(0);
      document.getElementById("btn").style.display = "";
      document.getElementById("upload2").style.pointerEvents = "";
      document.getElementById("btn-upload2").disabled = "";
      document.getElementById("upload1").style.pointerEvents = "";
      document.getElementById("btn-upload").disabled = "";
      document.getElementById("webcam").style.visibility = "";
    }
    if (scanner.current) {
      console.log("changing");
      scanner.current.handleToggle();
    } else {
      console.log("null found");
    }
    // eslint-disable-next-line
  }, [startStream]);

  useEffect(() => {
    setKey(getWebsocketToken());
    // eslint-disable-next-line
  }, [getWebsocketToken]);

  useEffect(() => {
    if (key !== 0) {
      console.log("got key" + key);
      setSocketUrl(
        "wss://api.skylarklabs.ai/ws/face-mask-recog?authorization=" + key
      );
    }
  }, [key]);

  useEffect(() => {
    const initializeSocket = () => {
      if (socket !== 0) {
        socket.onopen = (e) => {
          console.log("opened" + e);
        };
        socket.onmessage = function (event) {
          console.log(`Message Received: ${event.data}`);
          handleResponse(event.data);
        };

        socket.onclose = function (event) {
          if (event.wasClean) {
            console.log(
              `Connection closed cleanly, code=${event.code} reason=${event.reason}`
            );
            setSocket(0);
          } else {
            setSocket(0);
            console.log("WebSocket Connection died");
          }
        };

        socket.onerror = function (error) {
          initializeSocket();
          console.log(`Error: ${error.message}`);
        };
      }
    };
    initializeSocket();
    // eslint-disable-next-line
  }, [socket]);

  const handleResponse = (responseData) => {
    var data = JSON.parse(responseData);
    setResponseState(data);
  };

  useEffect(() => {
    function sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    // const submit = async (_body) => {
    //   const res = await fetch("./api/sendMessage", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify({ to: number, body: _body }),
    //   });

    //   const data = await res.json();

    //   if (data.success) {
    //     console.log("success msg sent");
    //   } else {
    //     console.log("fail msg ");
    //   }
    //   console.log("submitting");
    // };

    async function sendResponse() {
      var data = responseState;
      if (data !== null) {
        if (data.hasOwnProperty("state") && data["state"] === "send_ref") {
          var sendValues = {
            action: "add_face",
            reference_face: referenceToUpload,
          };
          await sleep(2000);
          socket.send(JSON.stringify(sendValues));
          await sleep(1000);

          sendValues = {
            action: "add_face",
            reference_face: referenceDummyToUpload,
          };
          socket.send(JSON.stringify(sendValues));
          await sleep(1000);

          socket.send(JSON.stringify({ action: "start_query" }));
        }
        if (
          (data.hasOwnProperty("state") && data["state"] === "send_query") ||
          (data.hasOwnProperty("results") && startStream)
        ) {
          console.log("sendng!");

          if (data.hasOwnProperty("results")) {
            var result = data["results"];
            if (result !== null) {
              var recognitions = result["recognitions"];

              if (recognitions !== null) {
                var faces = [];
                for (var i = 0; i < recognitions.length; i++) {
                  var recog = recognitions[i];
                  if (recog[0] === 0) {
                    faces.push({
                      id: 0,
                      value: recog[1] * 100,
                    });
                  } else if (recog[0] === 1) {
                    faces.push({
                      id: 1,
                      value: recog[1] * 100,
                    });
                  }
                }

                setTimeout(() => {
                  setFaceList((faceList) =>
                    faceList.splice(0, 0).concat(faces)
                  );
                }, 100);
                // var value = recognitions[0];

                // if (value[0] === 0) {
                //   console.log("matches 0");

                //   setMatched({
                //     reference: 0,
                //     image: referenceToUpload,
                //   });

                //   if (referenceTime === null) {
                //     setReferenceTime(new Date());
                //     console.log("ALERT FOR 0");
                //     submit("matched with 0" + location);
                //   } else {
                //     var seconds =
                //       (new Date().getTime() - referenceTime.getTime()) / 1000;
                //     if (seconds > 300) {
                //       setReferenceTime(new Date());
                //       console.log("ALERT FOR 0");
                //       submit("matched with 0" + location);
                //     }
                //   }

                //   setSimilarity({
                //     reference: 0,
                //     value: value[1].toFixed(2) * 100,
                //   });
                // } else if (value[0] === 1) {
                //   console.log("matches 1");
                //   if (dummyTime === null) {
                //     setDummyTime(new Date());
                //     console.log("ALERT FOR 1");
                //     submit("matched with 1" + location);
                //   } else {
                //     seconds =
                //       (new Date().getTime() - dummyTime.getTime()) / 1000;
                //     if (seconds > 301) {
                //       setDummyTime(new Date());
                //       console.log("ALERT FOR 1");
                //       submit("matched with 1" + location);
                //     }
                //   }
                //   setMatched({
                //     reference: 1,
                //     image: referenceDummyToUpload,
                //     last_found: null,
                //   });

                //   setSimilarity({
                //     reference: 1,
                //     value: value[1].toFixed(2) * 100,
                //   });
                // } else {
                //   console.log("matches -1");
                //   setSimilarity({ reference: -1, value: 0 });
                //   setMatched({
                //     reference: -1,
                //     image: "./assets/images/face_mesh.jpg",
                //     last_found: null,
                //   });
                // }
              } else {
                setSimilarity({ reference: -1, value: 0 });
                setMatched({
                  reference: -1,
                  image: "./assets/images/face_mesh.jpg",
                  last_found: null,
                });
              }
            } else {
              setSimilarity({ reference: -1, value: 0 });
              setMatched({
                reference: -1,
                image: "./assets/images/face_mesh.jpg",
                last_found: null,
              });
            }
          }
          var imageSrc = webcamRef.current.getScreenshot();

          sendValues = {
            action: "query",
            query_image: imageSrc,
          };

          socket.send(JSON.stringify(sendValues));
          imageSrc = undefined;
        }

        if (data.hasOwnProperty("alert")) {
          setSendFaces(false);
        }
        if (data.hasOwnProperty("state")) {
          if (data["state"] === "ready") {
            console.log("start");
          }
        }
      }
    }
    if (socket !== 0 || socket !== null) sendResponse();
    // eslint-disable-next-line
  }, [responseState]);

  useEffect(() => {
    if (socketUrl !== 0) {
      setSocket(new WebSocket(socketUrl));
      console.log("socket set");
    }
  }, [socketUrl]);

  useEffect(() => {
    if (referenceImage.blob !== "") {
      main();
      // eslint-disable-next-line
    }
    // eslint-disable-next-line
  }, [referenceImage]);

  useEffect(() => {
    if (dummyImage.blob !== "") {
      countFacesDummy();
      // eslint-disable-next-line
    }
    // eslint-disable-next-line
  }, [dummyImage]);

  async function countFacesDummy() {
    console.log("called dummy");
    var img = document.getElementById("mtest");
    img.src = dummyImage.image;

    var options = new faceapi.TinyFaceDetectorOptions({ scoreThreshold: 0.4 });
    let predictions = await faceapi.detectAllFaces(img, options);

    handleNotifClose();
    if (predictions.length !== 1) {
      setDummyImage({
        image: "./assets/images/face_mesh.jpg",
        blob: "",
      });
      openNotif("Exactly one face allowed here", false);
      setTimeout(() => {
        handleNotifClose();
      }, 1000);
    } else if (predictions.length === 1) {
      getCroppedFace(predictions[0], 2);
    } else {
      console.log("more");
    }
  }

  async function main() {
    console.log("called");

    var img = document.getElementById("mtest");
    img.src = referenceImage.image;

    var options = new faceapi.TinyFaceDetectorOptions({ scoreThreshold: 0.4 });
    let predictions = await faceapi.detectAllFaces(
      document.getElementById("mtest"),
      options
    );

    handleNotifClose();
    if (predictions.length !== 1) {
      setReferenceImage({
        image: "./assets/images/face_mesh.jpg",
        blob: "",
      });
      console.log("error");
      openNotif("Exactly one face allowed here", false);
      setTimeout(() => {
        handleNotifClose();
      }, 1000);
    } else if (predictions.length === 1) {
      document.getElementById("mtest").src = referenceImage.image;
      getCroppedFace(predictions[0], 1);
    } else {
      console.log("more");
    }
  }

  function getCroppedFace(predictions, where) {
    if (predictions === null) {
      console.log("got null");
      return;
    }
    console.log("cropping");
    try {
      const start = [
        predictions.box.x + 0.1 * predictions.box.width,
        predictions.box.y - 0.16 * predictions.box.height,
      ];
      const size = [predictions.box.width * 0.8, predictions.box.height * 0.98];
      var img1_src = document.getElementById("mtest").src;

      var canvas = document.getElementById("can_res_1");

      var ctx = canvas.getContext("2d");

      console.log("pred");
      console.log(start[0], start[1]);
      console.log(size[0], size[1]);
      var img = new Image();

      img.onload = function () {
        var imgWidth = img.naturalWidth;
        var imgHeight = img.naturalHeight;
        canvas.width = size[0];
        canvas.height = size[1];
        var aspect = imgWidth / imgHeight;
        console.log("aspect" + aspect);
        ctx.drawImage(
          img,
          start[0],
          start[1],
          size[0],
          size[1],
          0,
          0,
          size[0],
          size[1]
        );
        if (where === 1) {
          setReferenceToUpload(canvas.toDataURL());
        } else {
          setReferenceDummyToUpload(canvas.toDataURL());
        }
      };
      img.src = img1_src;
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {}, [matched]);

  const handleUpload1 = (event) => {
    openNotif("Loading Image", true);
    console.log("called");
    try {
      console.log("onchange input 1");

      setReferenceImage({
        image: URL.createObjectURL(event.target.files[0]),
        blob: event.target.files[0],
      });
      $("#btn-upload")[0].value = "";
      console.log("changed");
    } catch (error) {
      openNotif("Some error occurred" + error, false);
    }
  };
  const handleUpload2 = (event) => {
    openNotif("Loading Image", true);
    console.log("called");
    try {
      console.log("onchange input 1");

      setDummyImage({
        image: URL.createObjectURL(event.target.files[0]),
        blob: event.target.files[0],
      });
      $("#btn-upload2")[0].value = "";
      console.log("changed");
    } catch (error) {}
  };

  return loading ? (
    <div>
      <Notification notif={notif}></Notification>
    </div>
  ) : (
    <section className={classes.section}>
      <canvas
        id="can_res_1"
        style={{
          background: "black",
          display: "none",
        }}
      />
      <img alt="ss" style={{ display: "" }} id="ss"></img>
      <div>
        <Notification notif={notif}></Notification>
      </div>
      <TopBar />
      <div>
        <h2 className={classes.sectionHeader}>
          Disguised Face Identification (DFI) Demo
        </h2>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {startStream ? (
          <Fab
            variant="extended"
            size="large"
            color="primary"
            onClick={handleStart}
            aria-label="Buy"
            className="px-20 py-10 text-24 m-2"
          >
            <Icon className="mr-4">laptop-code</Icon>
            Stop
          </Fab>
        ) : (
          <Fab
            variant="extended"
            size="large"
            color="primary"
            onClick={capture}
            aria-label="Buy"
            className="px-20 py-10 text-24 m-2"
          >
            <Icon className="mr-4">laptop-code</Icon>
            Start
          </Fab>
        )}
        <Fab
          variant="extended"
          size="large"
          color="primary"
          id="btn"
          onClick={changeCamera}
          aria-label="Buy"
          className="px-20 py-10 text-24 m-2"
        >
          <Icon className="mr-4">laptop-code</Icon>
          Change Camera
        </Fab>
      </div>

      <div
        style={{ width: "100%", justifyContent: "center", display: "flex" }}
        id="image_upload"
      >
        <Grid container lg={4} md={8} sm={12} spacing={3}>
          <Grid item md={6} sm={4}>
            <Card className="card" style={{ height: "375px" }}>
              <img
                className="w-full img-fluid"
                id="result1"
                style={{
                  height: "200px",
                  objectFit: "contain",
                  paddingTop: "10px",
                }}
                src={referenceImage.image}
                alt="portfolio"
              />
              <div className="flex-column justify-between">
                <div className="px-4 pt-4">
                  <h5 className="m-0 text-16 font-bold">Reference Image</h5>
                  <p className="mb-4">Image should have single face</p>
                  <Divider />
                </div>
                <div className="px-4 py-2">
                  <label htmlFor="btn-upload">
                    <input
                      id="btn-upload"
                      name="btn-upload"
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={handleUpload1}
                    />
                    <Fab
                      variant="extended"
                      component="span"
                      id="upload1"
                      aria-label="Delete"
                      className={classes.fab}
                    >
                      <Icon style={{ padding: "5px" }}>link</Icon>
                      Upload
                    </Fab>
                  </label>
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={6} sm={4}>
            <Card className="card" style={{ height: "375px" }}>
              <img
                className="w-full img-fluid"
                id="result2"
                style={{
                  height: "200px",
                  objectFit: "contain",
                  paddingTop: "10px",
                }}
                src={dummyImage.image}
                alt="portfolio"
              />
              <div className="flex-column justify-between">
                <div className="px-4 pt-4">
                  <h5 className="m-0 text-16 font-bold">Other Image</h5>
                  <p className="mb-4">Image should have single face</p>
                  <Divider />
                </div>
                <div className="px-4 py-2">
                  <label htmlFor="btn-upload2">
                    <input
                      id="btn-upload2"
                      name="btn-upload2"
                      style={{ display: "none" }}
                      type="file"
                      accept="image/*"
                      onChange={handleUpload2}
                    />
                    <Fab
                      variant="extended"
                      component="span"
                      id="upload2"
                      aria-label="Delete"
                      className={classes.fab}
                    >
                      <Icon style={{ padding: "5px" }}>link</Icon>
                      Upload
                    </Fab>
                  </label>
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
      <div>
        <h3>Enter Number to Receive Notifications</h3>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            id="number"
            label="Enter number"
            color="secondary"
          />
        </form>
      </div>
      <Grid container spacing={3} style={{ marginTop: "10px" }}>
        <img id="mtest" src="" style={{ display: "none" }} alt=""></img>

        <Grid item lg={9} md={12} sm={4}>
          {devices.length > 0 ? (
            <div>
              <Webcam
                id="webcam"
                height={size.height}
                width={size.width}
                style={{ position: "absolute", visibility: "" }}
                audio={false}
                screenshotQuality={compressionFactor}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{ ...videoConstraints, deviceId: deviceId }}
              />
              <Scanner
                ref={scanner}
                deviceId={devices[cameraHash % devices.length].deviceId}
                similarity={similarity.value}
              ></Scanner>

              <canvas
                id="can_res_1"
                style={{
                  position: "absolute",
                  background: "",
                  marginTop: "100px",
                  display: "none",
                }}
              />
            </div>
          ) : null}
        </Grid>
        <Grid item md={3}>
          <Grid container spacing={3}>
            <Grid
              item
              md={12}
              sm={4}
              style={{
                justifyContent: "center",
                display: "flex",
                marginTop: "50px",
              }}
            >
              <Card
                className="card"
                style={{
                  zIndex: "100",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <ResultGrid
                  faces={faceList}
                  referenceImage={referenceToUpload}
                  otherImage={referenceDummyToUpload}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </section>
  );
};
export default UnconstrainedDemo;
