import React, { useEffect, useState } from "react";
import { Box, Grid } from "@material-ui/core";
import { exampleData } from "usecases/exampleData";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import MailIcon from "@material-ui/icons/Mail";
import LinkIcon from "@material-ui/icons/Link";
import { Link } from "react-router-dom";

const ExampleCase = () => {
  const [exampleCase, setExampleCase] = useState({});
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    const example = window.location.pathname.split("/case").pop();
    console.log(example);
    if (Object.keys(exampleData).includes(example))
      setExampleCase(exampleData[example]);
    else setExampleCase(exampleData["Apple"]);
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: ${exampleCase['title']}`;
    return () => {
      console.log('title changed');
    }
  });

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
  }, []);
  return (
    <>
      <section className="examplecase">
        <div className="container">
          <Grid container spacing={3} justify="center" alignItems="center">
            <Grid justifySelf="center" item sm={6}>
              <div className="logoBox">
                <img src={exampleCase["logo"]} alt="logo" />
              </div>
            </Grid>
            <Grid item sm={6}>
              <div className="platformCamera_dis">
                <h2>{exampleCase["title"]}</h2>
                <div className="desc">
                  <p>{exampleCase["desc1"]}</p>
                </div>
              </div>
              <div className="shareBox">
                <p>Share this case</p>
                <div className="share">
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                    target="_blank"
                    rel="noopener"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    href={`https://twitter.com/intent/tweet?text=${window.location.href}`}
                    target="_blank"
                    rel="noopener"
                  >
                    <TwitterIcon />
                  </a>
                  <a href="" target="_blank" rel="noopener">
                    <MailIcon />
                  </a>
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      navigator.clipboard.writeText(window.location.href);
                      setCopied(true);
                      setTimeout(() => {
                        setCopied(false);
                      }, 1000);
                    }}
                  >
                    <LinkIcon />{" "}
                    {copied && <span className="info">Link Copied</span>}
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>

          <div className="about_sec">
            <div className="platformCamera_dis">
              <h2>Summary</h2>
            </div>
            <p className="about_desc">{exampleCase["about"]}</p>
          </div>
        </div>

        {/* <div className="container">
          <Grid container spacing={5} wrap="wrap-reverse">
            <Grid item sm={6}>
              <div className="">
                <p>After adapting</p>
                <div className="before_cnt">
                  {exampleCase["before"] &&
                    exampleCase["before"].map((item) => (
                      <div>
                        <div className="platformCamera_dis">
                          <h2>{item.cnt}</h2>
                          <span>{item.des}</span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </Grid>
            <Grid item sm={6}>
              <div className="platformCamera_dis before_desc">
                <span></span>
              </div>
            </Grid>
          </Grid>
        </div> */}
        <div className="container ">
          <div className="usecases_count exampleredults">
            <ul>
              {exampleCase["before"] && exampleCase["after"].map((i) => {
                return (
                  <>
                    <li>
                      <div className="countBOx">
                        <h4>{i.cnt}</h4>
                        <p>{i.des}</p>
                      </div>
                    </li>
                  </>
                );

              })}
            </ul>
          </div>
        </div>
        <img className="ellipse-1" src="../images/ellipse4.png" alt="" />
      </section>

      <section className="problem">
        <div className="container">
          <Grid
            container
            spacing={5}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item sm={6}>
              <div className="platformCamera_dis">
                <h2>Problem</h2>
                <div className="desc">
                  <p>{exampleCase["longDescProblem"]}</p>
                </div>
              </div>
            </Grid>
            <Grid item sm={6}>
              <img
                className="problem_img"
                src={exampleCase["imgProblem"]}
                alt="problem"
              />
            </Grid>
          </Grid>
        </div>
        <img className="ellipse2" src="../images/ellipse2.svg" alt="" />
      </section>

      <section className="solution">
        <div className="container">
          <Grid
            container
            spacing={5}
            alignItems="center"
            justifyContent="center"
            wrap="wrap-reverse"
          >
            <Grid item sm={6}>
              <img
                className="solution_img"
                src={exampleCase["imgSolution"]}
                alt="problem"
              />
            </Grid>
            <Grid item sm={6}>
              <div className="platformCamera_dis">
                <h2>Solution</h2>
                <div className="desc">
                  <p>{exampleCase["longDescSolution"]}</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <img className="ellipse3" src="../images/ellipse3.png" alt="" />
      </section>

      <section className="result">
        <div className="container">
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item sm={6}>
              <div className="platformCamera_dis">
                <h2>Results</h2>
                <div className="desc">
                  <p>{exampleCase["longDescResult"]}</p>
                </div>
              </div>
              <Box pt={3} pb={5} className="ml-auto requestbtn">
                <Link
                  to=""
                  className="requestdemo btn"
                  style={{ color: "#000000" }}
                >
                  I want the same result
                </Link>
              </Box>
              <div className="before_cnt">
                <div className="usecases_count exampleredults">
                  <ul>
                    {exampleCase["before"] && exampleCase["after"].map((i) => {
                      return (
                        <>
                          <li>
                            <div className="countBOx">
                              <h4>{i.cnt}</h4>
                              <p>{i.des}</p>
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>

              <div className="shareBox">
                <p>Share this case</p>
                <div className="share">
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                    target="_blank"
                    rel="noopener"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    href={`https://twitter.com/intent/tweet?text=${window.location.href}`}
                    target="_blank"
                    rel="noopener"
                  >
                    <TwitterIcon />
                  </a>
                  <a href="" target="_blank" rel="noopener">
                    <MailIcon />
                  </a>
                  <a
                    href="javascript:void(0)"
                    onClick={() => {
                      navigator.clipboard.writeText(window.location.href);
                      setCopied(true);
                      setTimeout(() => {
                        setCopied(false);
                      }, 1000);
                    }}
                  >
                    <LinkIcon />{" "}
                    {copied && <span className="info">Link Copied</span>}
                  </a>
                </div>
              </div>
            </Grid>
            <Grid item sm={6}>
              <div className="solution">
                <img width="100%" src={exampleCase["imgResult"]} alt="" />
              </div>
            </Grid>
          </Grid>
        </div>
        <img className="ellipse4" src="../images/ellipse4.png" alt="" />
      </section>

      <section className="caseList">
        <div className="container">
          <div className="platformCamera_dis">
            <h2>Read More Cases</h2>
          </div>
          <ul className="acquisitionLab">
            {exampleCase.otherCases &&
              exampleCase.otherCases.map((ele, idx) => (
                <li>
                  <Link to={`/case${ele.url}`} className="similarcases">
                    <h6>
                      {ele.title}
                      <span>{ele.desc}</span>
                    </h6>
                    <div className="arrowLine">
                      <img src="../images/Right_long.svg" alt="" />{" "}
                    </div>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </section>
    </>
  );
};

export default ExampleCase;
