import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import videobanner from "../videofiles/miras-main-banner.mp4";
import perimeter_monitoring from "../videofiles/miras-monitoring-water-borders.mp4";
import Wnderwater from "../videofiles/wnderwater-vessels.mp4";
import etosvideo from "../videofiles/etos-page-banner.mp4";
import ariesvideo from "../videofiles/aries-banner.mp4";
import mirasvideo from "../videofiles/miras-main-banner.mp4";
import Suspicioussituations from "../videofiles/etosnew-lurking.mp4";
import satellitevideo from "../videofiles/dros-banner.mp4";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import Etosviolencedetection from "../videofiles/etos_violence_detection.mp4";
import { Box } from "@material-ui/core";
import Mirastestimonial from "home/sections/Miras-testimonial";

const Miras = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs Land Systems:  Land to Sea System -  Marine Reconnaissance System`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [expanded, setExpanded] = React.useState("");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>
                <span className="mainsubtitle">MIRAS</span>
                Marine Reconnaissance System
              </h1>
              <p>
                MIRAS monitors coastal and marine transports and their movements
                to detect suspicious critical threats.
              </p>
              <div className="subtages">
                <a>Unknown Vessels </a> <a>Fisherman Vessels</a>{" "}
                <a>Ship Traffic</a>
              </div>
            </div>
          </div>
        </div>

        <div className="imgcenter bannerimgblock ">
          <ReactPlayer
            controls={true}
            volume={0}
            width="100%"
            playsinline
            height="100%"
            loop={true}
            muted={false}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url="https://d1nbq27kwttg28.cloudfront.net/blog/miras-main-banner.dd4e023a.mp4"

          />
        </div>
      </div>
      {/* 
      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>   Marine Reconnaissance System</h2>
            <p>
              MIRAS monitors coastal and marine transports and their movements to detect suspicious critical threats.

            </p>
          </div>
        </div>
      </div> */}
      <div className="plateformsub-page">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>

        <div className="container pt-5">
          <div className="plateformabout  mt-5">
            <Grid container spacing={3} className="miras-about-container">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="aboutimgblock">
                  {" "}
                  <img src="images/infographics-miras.png" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 pb-0">
                      <span className="spanfirstword">Detect </span> Marine
                      Threats
                    </h2>
                    <p className="pt-0 abouttext-pblock">
                      MIRAS (Marine Reconnaissance System) utilizes
                      state-of-the-art computer vision and deep learning
                      algorithms to detect and proactively alert against
                      suspicious behavior near coastal areas and water
                      boundaries. It safeguards marine environments by tracking
                      vessels and identifying critical threats.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container">
            <div className="seactionblocktitles">
              <h2 className="seactiontitles">Guarding Coastal Frontiers </h2>
              <p>
                MIRAS detects unknown vessels at sea to identify potentially
                illegal activities
              </p>
            </div>
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Maritime</span> Border
                      Monitoring Module
                    </h4>
                    <p>
                      Focused on securing maritime borders and coastal areas
                      against unauthorized activities, smuggling, and security
                      threats.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Maritime Intrusion Detection
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Suspicious Vessel Tracking
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Underwater Movement Detection
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Coastal Activity Analysis
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/Platforms/iris-insight/miras/Strategic Maritime Surveillance.png" />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/Platforms/iris-insight/miras/Coastal Border Security.png" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Port</span> and Harbor
                      Security Module
                    </h4>
                    <p>
                      Aimed at ensuring the safety and security of commercial
                      and military ports, including cargo and personnel
                      movements.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Vessel Traffic Monitoring
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Container and Cargo Inspection
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Unauthorized Access Reporting
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Critical Infrastructure and Asset Protection
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Illegal</span> Fishing
                      Detection and Prevention Module
                    </h4>
                    <p>
                      Dedicated to identifying and preventing illegal fishing
                      activities, preserving marine ecosystems, and enforcing
                      fishing regulations.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Unauthorized Fishing Vessel Identification
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Overfishing Activity Monitoring
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Protected Species and Habitat Surveillance
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Cross-Border Fishing Activity Tracking
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/Platforms/iris-insight/miras/Environmental Defense Intelligence.png" />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/Platforms/iris-insight/miras/Critical Infrastructure Protection.png" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Search</span>, Rescue, and
                      Legal Enforcement
                    </h4>
                    <p>
                      Provides critical capabilities for search and rescue
                      operations, enforces maritime laws, and supports
                      underwater archaeological research and exploration,
                      ensuring legal compliance and safety at sea.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Search and Rescue Operations
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Maritime Legal Enforcement
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Archaeological and Historical Research
                        </div>{" "}
                      </li>
                      {/* <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Disaster Preparedness and Response

                        </div>{" "}
                      </li> */}
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <h4 className="pb-4">
                      <span className="spanfirstword">Maritime</span>{" "}
                      Environmental Monitoring
                    </h4>
                    <p>
                      Dedicated to safeguarding marine ecosystems, this module
                      focuses on conducting environmental research, assessing
                      climate change impacts, managing oil spill responses, and
                      monitoring aquaculture.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Maritime Environmental Research
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Oil Spill Detection and Response
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Climate Change Impact Assessment
                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Aquaculture Monitoring
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/Platforms/iris-insight/miras/5_1.png" />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="configurations">
          <div className="container">
            <div className="">
              <h3>MIRAS: Advanced Marine Surveillance</h3>
              <p>
              MIRAS (Marine Reconnaissance System) leverages cutting-edge computer vision and deep learning to detect suspicious activities near coastal areas and water boundaries. It enhances marine security by tracking vessels and promptly identifying critical threats, ensuring proactive protection of maritime environments.
              </p>
            </div>

            <div className="boxouterproduct">
              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>BVLOS Threat Detection</h4>
                <p>
                  Detect threats beyond visual line of sight (BVLOS) across all
                  domains, ensuring comprehensive security in any operational
                  context.
                </p>
              </div>
              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Real-time Processing</h4>
                <p>
                  Our solutions are built on top of computationally efficient
                  algorithms, allowing us to process Data from multiple sensors
                  in real time.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Hardware Agnostic</h4>
                <p>
                  Our solutions seamlessly integrate with a wide range of
                  sensors, without the need for specific hardware
                  specifications.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Economical & Configurable</h4>
                <p>
                  Our solution adapts to existing hardware with low compute
                  demands and simple configuration.
                </p>
              </div>

              {/* <div className="boxouterproductItem">
            <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt=""/> </div>

              <h4>Stress Free Environment</h4>
              <p>
              Receiving only pertinent alerts relieves soldiers from constant video wall monitoring, allowing them to focus when an audio-visual alert is triggered.
              </p>
            </div> */}

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Assisted Border Planning</h4>
                <p>
                  Our platform identifies areas with recurring alerts, aiding in
                  strategic troop deployment and fortification of perimeter
                  infrastructure.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon">
                  <img src="../images/icon.svg" alt="" />{" "}
                </div>

                <h4>Efficient On-Ground Communication</h4>
                <p>
                  Our system offers a comprehensive map view, enabling precise
                  threat tracking from outer to internal perimeters.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Other</span> System
                    Configurations
                  </h2>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured">
                  <a href="/bros">
                    <h5>
                      BorderSentinel
                      <br />
                      <br />
                    </h5>
                    <div className="imgblockfeatured">
                      <ReactPlayer
                        controls={true}
                        playsinline
                        volume={0}
                        width="100%"
                        height="100%"
                        loop={true}
                        muted={false}
                        playing={true}
                        stopOnUnmount={true}
                        config={{
                          file: {
                            attributes: {
                              autoplay: true,
                              controls: false,
                              loop: true,
                              muted: true,
                            },
                          },
                        }}
                        url={etosvideo}
                      />
                      <div className="readmore-btns">
                        <span>
                          Learn More{" "}
                          <img
                            src="/images/productSlider/nextarrow.svg"
                            alt=""
                          />
                        </span>{" "}
                      </div>
                    </div>
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured">
                  <a href="aries">
                    <h5>
                      Aerial Reconnaissance and <br></br> Elimination System
                    </h5>

                    <div className="imgblockfeatured">
                      <ReactPlayer
                        controls={true}
                        playsinline
                        volume={0}
                        width="100%"
                        height="100%"
                        loop={true}
                        muted={false}
                        playing={true}
                        stopOnUnmount={true}
                        config={{
                          file: {
                            attributes: {
                              autoplay: true,
                              controls: false,
                              loop: true,
                              muted: true,
                            },
                          },
                        }}
                        url={ariesvideo}
                      />
                    </div>
                    <div className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}
                    </div>
                  </a>
                </div>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured">
                  <a href="dros">
                    <h5>
                      Drone Reconnaissance and <br></br> Observation System
                    </h5>

                    <div className="imgblockfeatured">
                      <ReactPlayer
                        controls={true}
                        playsinline
                        volume={0}
                        width="100%"
                        height="100%"
                        loop={true}
                        muted={false}
                        playing={true}
                        stopOnUnmount={true}
                        config={{
                          file: {
                            attributes: {
                              autoplay: true,
                              controls: false,
                              loop: true,
                              muted: true,
                            },
                          },
                        }}
                        url={satellitevideo}
                      />
                    </div>
                    <div className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}
                    </div>
                  </a>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
      </div>

      <Mirastestimonial />
    </>
  );
};

export default Miras;
