import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef } from "react";

const Ashokapurestudy = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Ashoka Purestudy`;
  }, []);

  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);

  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "Guria NGO",
      desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
      url: "Guria-varanasi-human-trafficking",
      img: "images/cases-guria.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);

  return (
    <>
      <div className="plateformsub-page clientpage-outer">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>

        <div className="container">
          <div className="clientnavgations">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} md={4} lg={4}>
                <h3>Ashoka Purestudy</h3>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                md={8}
                lg={8}
                className="customer-page-nav-links-grid"
              >
                <div className="customer-page-nav-links-wrap">
                  <ul>
                    <li>
                      <a href="#overview">Overview</a>{" "}
                    </li>
                    <li>
                      <a href="#problem">Problem</a>{" "}
                    </li>
                    <li>
                      <a href="#solution">Solution</a>{" "}
                    </li>
                    <li>
                      <a href="#result">Result</a>{" "}
                    </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <div className="text-left logoclient">
                <img src="images/productSlider/ashokapurestudy.png" alt="" />
              </div>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9}>
              <div className="client" id="overview">
                <h3>Overview</h3>
                <p>
                  Ashoka PureStudy stands at the forefront of educational innovation, continuously exploring the potential of Artificial Intelligence (AI) to enhance the learning experience. As a leader in digital education solutions, they are committed to leveraging AI to transform the educational landscape. Their approach involves integrating cutting-edge technology to revolutionize teaching, learning, and administrative processes. By embracing AI, Ashoka PureStudy aims to set new standards in educational efficiency, transparency, and inclusivity, thereby ensuring their educational initiatives are well-aligned with the evolving demands of the digital age and maintaining their position as a trailblazer in the sector.
                </p>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="problem">
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <h3>Problem</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={9}>
              <div className="client">
                <p>
                  Ashoka PureStudy faced the formidable task of tracking attendance across 3000 Skill Development Centers in Rajasthan. Traditional attendance methods were not only time-consuming but also prone to inaccuracies and inefficiencies. This large-scale operational challenge required a solution that could handle the complexity of monitoring numerous students across widespread locations, ensuring accurate and secure attendance records. The need was for a system that could provide real-time data, reduce manual labor, and integrate seamlessly with existing educational processes, all while maintaining data privacy and security standards.
                </p>
              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="solution">
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <h3>Solutions</h3>
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={9}>
              <div className="client">
                <p>
                  To address these challenges, Skylark Labs implemented an AI-based face recognition system for attendance. This state-of-the-art solution provided swift, accurate, and secure tracking of student attendance across multiple centers. The system's real-time recognition capabilities significantly reduced the administrative burden, allowing for more focus on educational activities. It offered centralized control and data analysis, ensuring consistency and reliability in attendance records. Moreover, the AI-driven system enhanced security measures, minimizing the risk of fraudulent activities and maintaining a high standard of operational integrity in attendance management.
                </p>

              </div>
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-5 mt-5" id="result">
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <h3>Conclusion</h3>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9}>
              <div className="client">
                <p>
                  The implementation of Skylark Labs' AI-based face recognition system in Ashoka PureStudy's centers exemplifies the synergy between technology and education. This initiative not only solved a critical operational challenge but also set a new benchmark for technological integration in the educational sector, enhancing both efficiency and security.
                </p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="plateformsub-page">
        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Related</span> More Cases
                  </h2>
                </div>
              </Grid>
            </Grid>

            <div className="industriesListblock pt-5">
              <ul>
                {otherCases.map((ele, idx) => (
                  <li>
                    <a href="#contact" className="industriesListcol">
                      <div className="solutionListimg">
                        <img src={ele.img} alt="" />
                      </div>
                      <div className="industriesListcoltext">
                        <h4>{ele.title} </h4>
                        <p>{ele.desc}</p>
                        <div className="quicklinks casesquicklinks">
                          {" "}
                          View full case{" "}
                          <img src="images/right-arrow.png" alt="" />{" "}
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Ashokapurestudy;
