import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ palette, ...theme }) => ({
   intro: {
      padding: "180px 0 0 !important",
      overflow: "visible !important",

      [theme.breakpoints.down("sm")]: {
         padding: "100px 0 0 !important",
      },
   },
   product: {
      textAlign: "center",
      position: "relative",
      top: "auto",
      left: "auto",
      right: "auto",
      margin: "auto",
      bottom: -80,
      maxWidth: 780,
      marginBottom: "5rem",
      "& img": {
         borderRadius: "0.5rem",
         boxShadow: "0 10px 16px rgba(0, 0, 0, 0.2)",
      },

      [theme.breakpoints.down("sm")]: {
         bottom: -63,
      },

      [theme.breakpoints.down("xs")]: {
         bottom: -39,
      },
   },
   title: {
      textShadow: "0 4px 4px rgba(0, 0, 0, 0.22)",
   },

}));

const Productlogo = () => {
   var settings = {
      autoplay: true,
      autoplaySpeed: 3500,
      arrows: true,
      centerMode: false,
      slidesToShow: 5,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 1024,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               infinite: true,
               dots: true
            }
         },
         {
            breakpoint: 600,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 2,
               initialSlide: 2
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 2.5,
               slidesToScroll: 2.5
            }
         }
      ]
   };

   var rtl = {
      ...settings,
      slidesToScroll: 1,
   }

   var ltr = {
      ...settings,
      slidesToScroll: -1,
   }

   const classes = useStyles();

   return (
      <div>



         <div className="neuralslider">
            <Slider {...rtl}>

            </Slider>
            <Slider {...ltr}>
               <div className="p-2 latestnewshome">
                  <div className="newsbox-home productslider logosliderproduct">
                     <img src="images/productSlider/product-logo/ap.png" alt="" />
                  </div>
                  {/* <div className="logonams">Ashoka Purestudy</div> */}

               </div>

               <div className="p-2 latestnewshome">
                  <div className="newsbox-home productslider logosliderproduct">
                     <img src="images/productSlider/product-logo/artrabia.png" alt="" />
                  </div>
                  {/* <div className="logonams">Artrabia</div> */}
               </div>

               <div className="p-2 latestnewshome">
                  <div className="newsbox-home productslider logosliderproduct">
                     <img src="images/productSlider/product-logo/bsf.png" alt="" />
                  </div>
                  {/* <div className="logonams">Border Security Force</div> */}
               </div>


               <div className="p-2 latestnewshome">
                  <div className="newsbox-home productslider logosliderproduct">
                     <img src="images/productSlider/product-logo/ideaforge.png" alt="" />
                  </div>
                  {/* <div className="logonams">ideaForge Technology</div> */}
               </div>

               <div className="p-2 latestnewshome">
                  <div className="newsbox-home productslider logosliderproduct">
                     <img src="images/productSlider/product-logo/indian-navi.png" alt="" />
                  </div>
                  {/* <div className="logonams">Indian Navy</div> */}
               </div>

               <div className="p-2 latestnewshome">
                  <div className="newsbox-home productslider logosliderproduct">
                     <img src="images/productSlider/product-logo/Inkrytis.png" alt="" />
                  </div>
                  {/* <div className="logonams">Inkryptis AI</div> */}
               </div>


               <div className="p-2 latestnewshome">
                  <div className="newsbox-home productslider logosliderproduct">
                     <img src="images/productSlider/product-logo/jll-logo.png" alt="" />
                  </div>
                  {/* <div className="logonams">JLL (Jones Lang LaSalle)</div> */}
               </div>


               <div className="p-2 latestnewshome">
                  <div className="newsbox-home productslider logosliderproduct">
                     <img src="images/productSlider/product-logo/mantech.png" alt="" />
                  </div>
                  {/* <div className="logonams"> ManTech</div> */}
               </div>

               <div className="p-2 latestnewshome">
                  <div className="newsbox-home productslider logosliderproduct">
                     <img src="images/productSlider/product-logo/mercedes-benz.png" alt="" />
                  </div>
                  {/* <div className="logonams">Mercedes-Benz</div> */}
               </div>

               <div className="p-2 latestnewshome">
                  <div className="newsbox-home productslider logosliderproduct">
                     <img src="images/productSlider/product-logo/nec.png" alt="" />
                  </div>
                  {/* <div className="logonams">NEC Corporation</div> */}
               </div>






            </Slider>
         </div>


      </div>
   );
};

export default Productlogo;
