import React from "react";
import ReactPlayer from "react-player";
import Grid from "@material-ui/core/Grid";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";

const FeatureSection = () => {
  return (
    <section className="">
      <div className="container pb-5 mb-5 mt-5 pt-5">
        <div className="  homeprodctlisttop">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={7}>
              <h4>Key Features</h4>
            </Grid>
            {/* <Grid item xs={12} sm={6} md={5}>
                            <p className=" py-0 my-0">
                                We provide two distinct products, each specifically designed for the tactical and intelligence communities


                            </p>
                        </Grid> */}
          </Grid>
        </div>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <div className="key-features">
              <div className="iconsfeatucher">
                <img src="images/icons/alert.png" alt="" />
              </div>
              <h3>20X Smaller Training Datasets </h3>
              {/* <h5>BVLOS Threats</h5> */}
              <p>Efficiently train supervised models using datasets that are 20 times smaller.</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <div className="key-features">
              <div className="iconsfeatucher">
                <img src="images/icons/situational-awareness.png" alt="" style={{ width: "60px", height: "60px" }} />
              </div>
              <h3>Single Image Model Adaptation</h3>
              {/* <h5>Unknown Threats</h5> */}
              <p>Enable real-time model learning and adaptation with just one image. </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <div className="key-features">
              <div className="iconsfeatucher">
                <img src="images/productSlider/Analytics.svg" alt="" />
              </div>

              <h3>80% More Early Warnings</h3>
              {/* <h5>Situational Awareness</h5> */}
              <p>Improve early warning capabilities by 80%.</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <div className="key-features">
              <div className="iconsfeatucher">
                <img src="images/icons/cost reduction.png" style={{ width: "60px", height: "60px" }} />
              </div>
              <h3>24 by 7 Mission Readiness</h3>
              {/* <h5>Legacy Devices</h5> */}
              <p>Achieve around the clock mission readiness with a 60% reduction in operating costs.</p>
            </div>
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

export default FeatureSection;
