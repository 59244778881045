import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import videobanner from "../videofiles/dummy.mp4";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Etostestimonial from "home/sections/Etos-testimonial";
import Developcustom from "./productslider/Developcustom";
import Customizeai from "./productslider/Customizeai";
import RoundedTabSwitcher from "./components/RoundedTabSwitcher";
import ImageSlider from "./components/ImageSlider";
const Turing = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Product Page:  Turing -   Turing is a cutting-edge AI suite tailored for Intelligence Organizations`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [expanded, setExpanded] = React.useState("");
  const [activeTabCustomPipeline, setActiveTabCustomPipeline] =
    React.useState(0);
  const [activeTabIntelligenceGathering, setActiveTabIntelligenceGathering] =
    React.useState(0);
  const [activeTabCustomAI, setActiveTabCustomAI] = React.useState(0);
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  let otherCases = [
    {
      title: "Use Cases",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/productSlider/usecases.png",
    },

    {
      title: "News",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/productSlider/skylarklabs-news.png",
    },

    {
      title: "Events",
      desc: "The incidents happened in the locality where robbers used to come at night and loot the jewellery shops.",
      url: "ds-jewellers",
      img: "images/productSlider/swavlamban-delhi.png",
    },
  ];
  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner newproductbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner ">
              <h1>TURING </h1>
              <p>
                Turing equips intelligence agencies to analyze data, foreseeing threats from linguistic and visual sources.
              </p>
              <div className="subtages">
                <a href="social-media-intel">Social Media Intel</a>  <a href="news-watch">NewsWatch </a> <a href="supply-intel">SupplyIntel </a>
                <a href="financial-flux">FinancialFlux </a>
                {" "}
              </div>

            </div>
          </div>
        </div>

        <div className="imgcenter bannerimgblock ">
          <ReactPlayer
            controls={true}
            playsinline
            volume={0}
            width="100%"
            height="100%"
            loop={true}
            muted={false}
            playing={true}
            stopOnUnmount={true}
            config={{
              file: {
                attributes: {
                  autoplay: true,
                  controls: false,
                  loop: true,
                  muted: true,
                },
              },
            }}
            url="https://d1nbq27kwttg28.cloudfront.net/blog/dummy.e2306b3f.mp4"
          />
        </div>
      </div>
      {/* 
      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>TURING </h2>
            <p>
              I Turing equips intelligence agencies with the capability to analyze linguistic and visual data from diverse areas, enabling them to foresee emerging threats.
            </p>
          </div>
        </div>
      </div> */}

      <div className="topplatformsblock">
        {/* <section className="productlogosouter ">
          <div className="container">
            <div className="prpductlogosblock">
              <div>
                <img src="images/productSlider/product-logo/three.png" alt="" />
                <div className="logonams">Three Letter Agency</div>
              </div>
              <div>
                <img src="images/productSlider/product-logo/indianarmy.png" alt="" />
                <div className="logonams">Border Security Force</div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="productoverviewblock section-productoverviewblock section-productoverviewblockfirstbox">
          <div className="container">
            <Grid container spacing={3} className="turing-about-container">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoleftblock bordernone">
                  <div>
                    {" "}
                    <img
                      src="images/productSlider/predict-emerging-threats.png"
                      alt=""
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 mb-0 pb-0">
                      <span className="spanfirstword">About</span> Turing
                    </h2>
                    <p className="pt-0">
                      {" "}
                      Turing presents a unique value proposition to the
                      intelligence community by offering advanced capabilities
                      to preemptively counter emerging threats. This adaptive
                      intelligence platform not only facilitates the analysis of
                      linguistic and visual data sources but also includes
                      features to flag both known and unknown cyphers. Through
                      its low-code, self-designed machine learning pipelines, it
                      ensures confidentiality and bespoke adaptability, making
                      it an essential tool for maintaining security and gaining
                      strategic advantage.
                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <div className="imgplatformsbg  ">
          <img src="images/productSlider/bg_plateform.png" alt="" />
        </div>
      </div>

      <section
        ref={section1}
        className="product-featured section-productoverviewblock Featuredstikeblock"
      >
        <div className="container pb-5 mb-5">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className="">
                <h2 className="pb-2 ">
                  <span className="spanfirstword">Featured</span> Turing
                  Configurations
                </h2>
                <p className="whitetext pb-5 mb-2">
                  Turing boasts a range of innovative features tailored to
                  meet the dynamic needs of Intelligence Organizations.
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="productfeatured productfeatured_advis">
                <a href="social-media-intel">
                  <h5>Social Media Intel</h5>
                  <div className="imgblockfeatured">
                    <img src="images/productSlider/01.png" alt="" />
                    <div className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}
                    </div>
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="productfeatured productfeatured_advis">
                <a href="news-watch">
                  <h5>NewsWatch</h5>

                  <div className="imgblockfeatured">
                    <img src="images/productSlider/02.png" alt="" />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="productfeatured productfeatured_advis">
                <a href="supply-intel">
                  <h5>SupplyIntel</h5>
                  <div className="imgblockfeatured">
                    <img src="images/productSlider/03.png" alt="" />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} lg={3}>
              <div className="productfeatured productfeatured_advis">
                <a href="financial-flux">
                  <h5>FinancialFlux</h5>
                  <div className="imgblockfeatured">
                    <img src="images/productSlider/04.png" alt="" />
                  </div>
                  <div className="readmore-btns">
                    <span>
                      Learn More{" "}
                      <img src="/images/productSlider/nextarrow.svg" alt="" />
                    </span>{" "}
                  </div>
                </a>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="dashboardfeatures section-productoverviewblock">
        <div className="container plateformblock aboutbgblock">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <div className="imgroundbox">
                {/* <Developcustom /> */}
                <ImageSlider
                  slideIndex={activeTabCustomPipeline}
                  images={[
                    "images/social.png",
                    "images/Scrutiny.png",
                    "images/threatD.png",
                    "images/productSlider/develop-custom_01.png",
                  ]}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <div className="centerboxblock">
                <div className="dashboardfeatures-list pl-5">
                  <h4>
                    <span className="spanfirstword">Comprehensive</span> Online
                    Data Analysis
                  </h4>
                  <RoundedTabSwitcher
                    getActiveTab={(activeTab) =>
                      setActiveTabCustomPipeline(activeTab)
                    }
                    hideImage={true}
                    imagePosition="left"
                    tabs={[
                      {
                        id: 0,
                        text: "Data Sources",
                        content: (
                          <>
                            <p>
                              Harness the power of Turing to analyze vast
                              online data sources, from social media platforms
                              to global news outlets. Understand emerging
                              trends, sentiments, and potential threats in
                              real-time.
                            </p>
                          </>
                        ),
                        image: "",
                      },
                      {
                        id: 1,
                        text: "AI Models",
                        content: (
                          <>
                            <p>
                              Harness the power of Turing to analyze vast
                              online data sources, from social media platforms
                              to global news outlets. Understand emerging
                              trends, sentiments, and potential threats in
                              real-time.
                            </p>
                          </>
                        ),
                        image: "",
                      },
                      {
                        id: 2,
                        text: "Data Import",
                        content: (
                          <>
                            <p>
                              Harness the power of Turing to analyze vast
                              online data sources, from social media platforms
                              to global news outlets. Understand emerging
                              trends, sentiments, and potential threats in
                              real-time.
                            </p>
                          </>
                        ),
                        image: "",
                      },
                      {
                        id: 3,
                        text: "Audio",
                        content: (
                          <>
                            <p>
                              Harness the power of Turing to analyze vast
                              online data sources, from social media platforms
                              to global news outlets. Understand emerging
                              trends, sentiments, and potential threats in
                              real-time.
                            </p>
                          </>
                        ),
                        image: "",
                      },
                    ]}
                  />
                  <ul>
                    <li>
                      <div className="features-box">
                        <h6>Multi-Platform Integration</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box">
                        <h6>Real-time Data Scrutiny</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box">
                        <h6>Sentiment Analysis</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box">
                        <h6>Threat Detection</h6>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className="imgplatformsmainbg">
            <img src="images/productSlider/blockbg-01.svg" alt="" />
          </div>
        </div>
      </section>

      <section className="dashboardfeatures dashboardfeaturesbg-two section-productoverviewblock">
        <div className="container plateformblock">
          <div className="imgplatformsbg imgplatformsbgleft">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <div className="centerboxblock pr-0">
                <div className="dashboardfeatures-list  pl-5">
                  <h4>
                    <span className="spanfirstword">In-depth</span> Supply Chain
                    & Economic Insights
                  </h4>
                  <RoundedTabSwitcher
                    getActiveTab={(activeTab) =>
                      setActiveTabIntelligenceGathering(activeTab)
                    }
                    hideImage={true}
                    imagePosition="left"
                    tabs={[
                      {
                        id: 0,
                        text: "Publication Monitoring",
                        content: (
                          <>
                            <p>
                              Dive deep into global supply chains and economic
                              trends. Gain a holistic understanding of
                              dependencies, vulnerabilities, and market shifts
                              that can impact your operations.
                            </p>
                          </>
                        ),
                        image: "",
                      },
                      {
                        id: 1,
                        text: "Image Analysis",
                        content: (
                          <>
                            <p>
                              Dive deep into global supply chains and economic
                              trends. Gain a holistic understanding of
                              dependencies, vulnerabilities, and market shifts
                              that can impact your operations.
                            </p>
                          </>
                        ),
                        image: "",
                      },
                      {
                        id: 2,
                        text: "Relationship Analysis",
                        content: (
                          <>
                            <p>
                              Dive deep into global supply chains and economic
                              trends. Gain a holistic understanding of
                              dependencies, vulnerabilities, and market shifts
                              that can impact your operations.
                            </p>
                          </>
                        ),
                        image: "",
                      },
                      {
                        id: 3,
                        text: "Audio",
                        content: (
                          <>
                            <p>
                              Dive deep into global supply chains and economic
                              trends. Gain a holistic understanding of
                              dependencies, vulnerabilities, and market shifts
                              that can impact your operations.
                            </p>
                          </>
                        ),
                        image: "",
                      },
                    ]}
                  />

                  <ul>
                    <li>
                      <div className="features-box  ">
                        <h6>Supply Chain Visualization</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box  ">
                        <h6>Economic Trend Analysis</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box ">
                        <h6>Vulnerability Assessment</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box ">
                        <h6>Market Shift Predictions</h6>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              {/* <Intelligencegathering /> */}
              <ImageSlider
                slideIndex={activeTabIntelligenceGathering}
                images={[
                  "images/Predictive Supply Chain Analytics11.png",
                  "images/supply-chain-metrics.png",
                  "images/productSlider/intelligence-gathering_01.png",
                  "images/productSlider/intelligence-gathering_02.png",
                ]}
              />
            </Grid>
          </Grid>
          <div className="imgplatformsmainbg">
            <img src="images/productSlider/blockbg-01.svg" alt="" />
          </div>
        </div>
      </section>



      <section className="dashboardfeatures section-productoverviewblock">
        <div className="container plateformblock aboutbgblock">
          <div className="imgplatformsbg  ">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <div className="imgroundbox">
                {/* <Customizeai /> */}
                <ImageSlider
                  slideIndex={activeTabCustomAI}
                  images={[
                    "images/Image analysis.png",
                    "images/Geopolitical.png",
                    "images/productSlider/self-learn_02C.png",
                  ]}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <div className="dashboardfeatures-list  pl-5">
                <h4>
                  <span className="spanfirstword">Customizable</span>{" "}
                  Intelligence Modules
                </h4>
                <RoundedTabSwitcher
                  getActiveTab={(activeTab) => setActiveTabCustomAI(activeTab)}
                  hideImage={true}
                  imagePosition="left"
                  tabs={[
                    {
                      id: 0,
                      text: "Self Label",
                      content: (
                        <>
                          <p>
                            Description: Tailor Turing's intelligence
                            modules to your specific needs. Whether you're
                            focusing on geopolitical shifts or global supply
                            chain intricacies, customize your analysis
                            parameters for precise insights.
                          </p>
                        </>
                      ),
                      image: "",
                    },
                    {
                      id: 1,
                      text: "Auto Training",
                      content: (
                        <>
                          <p>
                            Description: Tailor Turing's intelligence
                            modules to your specific needs. Whether you're
                            focusing on geopolitical shifts or global supply
                            chain intricacies, customize your analysis
                            parameters for precise insights.
                          </p>
                        </>
                      ),
                      image: "",
                    },
                    {
                      id: 2,
                      text: "Self Categorizations",
                      content: (
                        <>
                          <p>
                            Description: Tailor Turing's intelligence
                            modules to your specific needs. Whether you're
                            focusing on geopolitical shifts or global supply
                            chain intricacies, customize your analysis
                            parameters for precise insights.
                          </p>
                        </>
                      ),
                      image: "",
                    },
                  ]}
                />
                <ul>
                  <li>
                    <div className="features-box">
                      <h6>Module Customization</h6>
                    </div>
                  </li>
                  <li>
                    <div className="features-box">
                      <h6>Query-based Analysis</h6>
                    </div>
                  </li>
                  <li>
                    <div className="features-box">
                      <h6>Geopolitical Monitoring</h6>
                    </div>
                  </li>
                  <li>
                    <div className="features-box">
                      <h6>Industry-specific Insights </h6>
                    </div>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
          <div className="imgplatformsmainbg">
            <img src="images/productSlider/blockbg-01.svg" alt="" />
          </div>
        </div>
      </section>

      <section className="dashboardfeatures dashboardfeaturesbg-two section-productoverviewblock">
        <div className="container plateformblock">
          <div className="imgplatformsbg imgplatformsbgleft">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={8} lg={6}>
              <div className="centerboxblock pr-0">
                <div className="dashboardfeatures-list  pl-5">
                  <h4>
                    <span className="spanfirstword">Advanced</span> AI-Powered
                    Threat Detection
                  </h4>
                  <RoundedTabSwitcher
                    getActiveTab={(activeTab) =>
                      setActiveTabIntelligenceGathering(activeTab)
                    }
                    hideImage={true}
                    imagePosition="left"
                    tabs={[
                      {
                        id: 0,
                        text: "Image",
                        content: (
                          <>
                            <p>
                              Stay ahead of potential threats with Turing's
                              advanced AI algorithms. From unknown threats
                              emerging online to potential vulnerabilities in
                              your supply chain, ensure comprehensive
                              protection.
                            </p>
                          </>
                        ),
                        image: "",
                      },
                      {
                        id: 1,
                        text: "Maping",
                        content: (
                          <>
                            <p>
                              Stay ahead of potential threats with Turing's
                              advanced AI algorithms. From unknown threats
                              emerging online to potential vulnerabilities in
                              your supply chain, ensure comprehensive
                              protection.
                            </p>
                          </>
                        ),
                        image: "",
                      },
                      {
                        id: 2,
                        text: "Text",
                        content: (
                          <>
                            <p>
                              Stay ahead of potential threats with Turing's
                              advanced AI algorithms. From unknown threats
                              emerging online to potential vulnerabilities in
                              your supply chain, ensure comprehensive
                              protection.
                            </p>
                          </>
                        ),
                        image: "",
                      },
                      {
                        id: 3,
                        text: "Audio",
                        content: (
                          <>
                            <p>
                              Stay ahead of potential threats with Turing's
                              advanced AI algorithms. From unknown threats
                              emerging online to potential vulnerabilities in
                              your supply chain, ensure comprehensive
                              protection.
                            </p>
                          </>
                        ),
                        image: "",
                      },
                    ]}
                  />

                  <ul>
                    <li>
                      <div className="features-box  ">
                        <h6>AI-driven Threat Analysis</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box  ">
                        <h6>Unknown Threat Identification</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box ">
                        <h6>Proactive Alert System</h6>
                      </div>
                    </li>
                    <li>
                      <div className="features-box ">
                        <h6>Comprehensive Protection</h6>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={6}>

              <ImageSlider
                slideIndex={activeTabIntelligenceGathering}
                images={[
                  "images/productSlider/intelligence-gathering.png",
                  "images/productSlider/intelligence-gathering_01.png",
                  "images/productSlider/intelligence-gathering_02.png",
                  "images/productSlider/audio-analysis.png",
                ]}
              />
            </Grid>
          </Grid>
          <div className="imgplatformsmainbg">
            <img src="images/productSlider/blockbg-01.svg" alt="" />
          </div>
        </div>
      </section>


      <section className="configurations">
        <div className="container">
          <div className="">
            <h3>Turing: Next-gen Features for Modern Intelligence</h3>

          </div>

          <div className="boxouterproduct">


            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>
              <h4>Semantics Analysis</h4>
              <p>
                Unlock the power of semantics with our intuitive platform.
                Seamlessly analyze text using advanced semantic techniques,
                providing you with valuable insights and a deeper understanding
                of your data.
              </p>
            </div>
            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Data Filtering</h4>
              <p>
                Efficiently sift through extensive datasets using advanced
                customizable filtering techniques. Tailor your filters based on
                criticality, hashtags, and more, giving you precise control over
                the information you need.
              </p>
            </div>

            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Unknown Threats</h4>
              <p>
                The Platform possesses the capability to analyze data sources
                and accurately identify both known and previously unidentified
                new threats. This ensures comprehensive threat detection and
                protection.
              </p>
            </div>

            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Relationship Analysis</h4>
              <p>
                This feature performs in-depth analysis to uncover various
                connections and relationships associated with the identified
                targets of interest. It provides valuable insights into networks
                and associations within the monitored data.
              </p>
            </div>

            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Intelligent Threat Verification</h4>
              <p>
                Leverage our platform's integration of physical sensors and
                online data for swift and precise threat detection. Navigate
                with ease using our user-friendly UI, ensuring top-tier
                security.
              </p>
            </div>

            <div className="boxouterproductItem">
              <div className="boxouterproductItem-icon">
                <img src="../images/icon.svg" alt="" />{" "}
              </div>

              <h4>Future Risk Anticipation</h4>
              <p>
                Harness the power of our advanced UI's entity profiling to
                anticipate potential threats. Through meticulous future
                simulations, we elevate an entity's risk profile, equipping you
                with insights into probable threats down the road. Stay a step
                ahead and prepare effectively.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="caseList readmorecsases ">
        <div className="container">
          <div className="platformCamera_dis">
            <h2>Read More Cases</h2>
          </div>

          <div className="industriesListblock pt-5">
            <ul>
              {otherCases.map((ele, idx) => (
                <li>
                  <a href="#contact" className="industriesListcol">
                    <div className="solutionListimg">
                      <img src={ele.img} alt="" />
                    </div>
                    <div className="industriesListcoltext">
                      <h4>{ele.title} </h4>
                      <p>{ele.desc}</p>
                      <div className="quicklinks casesquicklinks">
                        {" "}
                        View Details <img
                          src="images/right-arrow.png"
                          alt=""
                        />{" "}
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
      {/* <Etostestimonial /> */}
    </>
  );
};

export default Turing;
