import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";
import Cabviolence from "../../videofiles/transport-cab-violence.mp4";
import Busviolence from "../../videofiles/transport-bus-violence.mp4";
import Numberplaterecognition from "../../videofiles/transport-weapons-detection.mp4";
import Transportticket from "../../videofiles/transport-ticket.mp4";
import Transportbanner from "../../videofiles/transport-banner.mp4";

import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Tab } from "@material-ui/core";
import { Tabs } from "@material-ui/core";
const Transport = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: Transportation`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [showTrial, setShowTrial] = React.useState(false);
  const [expanded, setExpanded] = React.useState("panel2");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };
  let otherCases = [
    {
      title: "D1 Security",
      desc: "Previously, everyone had to get their ID card checked before entering the park.",
      url: "d1-security",
      img: "images/cases-hotel.png",
    },

    {
      title: "AH1 Hotel",
      desc: "The basic CCTV cameras were unable to detect any violence incidents.",
      url: "ah1-hotel",
      img: "images/ah1-hotel.jpg",
    },

    {
      title: "Guria NGO",
      desc: "The ChildrEN SafEty and Rescue (CENSER) system can identify children, in real-time using a single image. ",
      url: "Guria-varanasi-human-trafficking",
      img: "images/cases-guria.png",
    },
  ];
  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      // if (section4.current && offsetY >= section4.current.offsetTop)
      //   addActiveClass(navOption4);
      if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChangenew = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>Transportation </h1>
              <p>
                Advanced AI-powered solutions are made for the transportation
                industry which enhances public security by detecting illegal
                activities in real-time such as violence, weapons, criminals, or
                any other suspicious activity.
              </p>
              <div className="subtages"><a href="#/">Railways</a>  <a href="#/">Buses </a> <a href="#/">Airports</a> 
</div> 
     
            </div>
          </div>
        </div>

        <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Transportbanner}
                />
      
       
      </div>

      <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>Transportation</h2>
            <p>
              Advanced AI-powered solutions are made for the transportation
              industry which enhances public security by detecting illegal
              activities in real-time such as violence, weapons, criminals, or
              any other suspicious activity.
            </p>
          </div>
        </div>
      </div>
      <section className="homeourproduct poductCatename productpag_section">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="videoleftblock fistblock bordernone">
                <div>
                  {" "}
                  <img src="images/transport-commerical.png" alt="" />
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block fistblockight homeright-block-auto">
                <div>
                  <h4 className="mb-0">Advanced Transit Security System</h4>
                  <p className="pt-0">
                    Vandalism, theft, drug selling, fire, and terrorist attacks
                    are all security and safety threats at a transit station.
                    Managing them is a difficult task, and many people have died
                    in terrorist attacks due to the lack of a visible
                    surveillance system in crowded public locations. Skylark
                    contributes to the safety and security of key transportation
                    zones for travelers by detecting suspicious behavior,
                    preventing crimes, minimizing human interference, and terror
                    activities, and increasing public safety. The goal is to
                    reduce public fatalities and serious injuries.
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="homeprodctlist blackrarkoutr lightgray">
        <div className="container homeprodctlisttop whitetext">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={7}>
              <h4>Intelligent Transit Security</h4>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <p className=" py-0 my-0">
              The solutions covered everything from ticket administration, abandoned things, and moving vehicles to suspicious behavior on buses, trains, and automobiles.


              </p>
            </Grid>
          </Grid>
        </div>
        <div className="container">
          <Box sx={{ width: "100%" }}>
            <TabPanel value={value} index={0}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Cabviolence}
                />

                <div className="productitemboxdis">
                  <h4> Cab Violence</h4>
                  <p>
                    The system detects weapons, violent activity, or aggressive
                    behavior between passengers or staff such as aggression,
                    pushing, punching, etc. in real-time and gives an instant
                    alert to the control room and security authorities.
                  </p>
                  
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Busviolence}
                />

                <div className="productitemboxdis">
                  <h4>Bus Violence</h4>
                  <p>
                  Detects the violence activities in bus, and between passengers or staff and provide instant alerts to the transport authorities in real-time


                  </p>
                 
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="productitembox ">
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  playsinline
                  volume={0}
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Numberplaterecognition}
                />

                <div className="productitemboxdis">
                  <h4>Weapon Detection</h4>
                  <p>
                  Detects the weapon such as guns, knives, rods, etc in a bus, car, or any vehicle and provide the real-time alerts to the authorities in real-time

                  </p>
                  
                </div>
              </div>
            </TabPanel>

            <div className="poductlisttabs">
              <Box sx={{ borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChangenew}
                  aria-label="basic tabs example"
                >
                  <Tab label="Cab Violence" {...a11yProps(0)} />
                  <Tab label="Bus Violence" {...a11yProps(1)} />
                  <Tab label="Weapons Detection" {...a11yProps(2)} />
                </Tabs>
              </Box>
            </div>
          </Box>
        </div>
      </section>

      <section
        ref={section1}
        className="homeourproduct poductCatename productpag_section"
        id="surveillance"
      >
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
            
                <ReactPlayer
                  style={{
                    zIndex: "-1",
                    pointerEvents: "none",
                  }}
                  controls={false}
                  volume={0}
                  playsinline
                  width="100%"
                  height="100%"
                  loop={true}
                  muted={true}
                  playing={true}
                  stopOnUnmount={true}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        controls: false,
                        loop: true,
                        muted: true,
                      },
                    },
                  }}
                  url={Transportticket}
                />
              
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block">
                <h4>
                  <span>Product Capabilities</span>Ticket Management
                </h4>
                <p>
                  The system detects persons in a vehicle and offers an exact
                  count of passengers within the vehicle, which aids in ticket
                  management.
                </p>

                <ul className="mb-4">
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-people-fighting.svg"
                          alt=""
                        />
                      </span>
                      Face Recognition 
                    </div>
                  </li>
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-person-with-weapon.svg"
                          alt=""
                        />
                      </span>
                      People Counting

                    </div>
                  </li>
                </ul>

               
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      <section className="homeourproduct poductCatename productpag_section lightgray">
        <div className="container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <div className="homeright-block homeright-blockleft">
                <div>
                  <h4>Abandoned Bag Detection System</h4>
                  <p className="pt-0 mt-0">
                    Our abandoned bag system detects suspicious objects or bags
                    left unattended in a public area, such as airport terminals
                    or train stations. Abandoned objects or bags may contain
                    dangerous items and pose a severe security threat.
                  </p>
                </div>
                <ul className="mb-4">
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-facial-description.svg"
                          alt=""
                        />
                      </span>{" "}
                      Abandoned <br></br>Objects
                    </div>{" "}
                  </li>
                  <li>
                    <div className="boxinner">
                      <span>
                        <img
                          src="images/nav/industries/icon-attire-description.svg"
                          alt=""
                        />
                      </span>
                      Abandoned <br></br>Bags

                    </div>
                  </li>
                </ul>
                <div class="bottmaligh">
                  <Link to="/face-recognition" className="btn">
                    Try Our Demo
                  </Link>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <div className="videoleftblock">
                <img src="images/transport-abondended-bags.jpg" alt="" />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>

      

      <section className="benefitmain industriesuses bggray latticeos_faq white-bg">
        <div className="container">
          <div className="benefitssection  ">
            <h2 className="mb-0 text-center">Key Features</h2>
          </div>

          <div className="listofindust skylarksuite">
            <ul>
              <li className="text-center">
                <div className="featuresblock featuresblockbgthree">
                  <span>
                    <img src="images/object-detection.svg" alt="" />
                  </span>
                  <h5>Easily integrates with pre-installed Cameras or DVRs</h5>
                  <p>
                    Easily integrates with the existing cameras and works with
                    most of the standard retail cameras or DVRs in the market.
                  </p>
                </div>
              </li>

              <li className="text-center">
                <div className="featuresblock ">
                  <span>
                    <img src="images/identity-detection.svg" alt="" />
                  </span>
                  <h5>Multiple Behaviours</h5>
                  <p>
                    Recognizes multiple behaviours like violence, intrusion,
                    lurking, aggression.
                  </p>
                </div>
              </li>
              <li className="text-center">
                <div className="featuresblock">
                  <span>
                    <img src="images/icon-threat-detection.svg" alt="" />
                  </span>
                  <h5>Economical</h5>
                  <p>
                    The product has been optimized to use a minimal amount of
                    memory for running.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="caseList readmorecsases">
        <div className="container">
          <div className="platformCamera_dis">
            <h2>Read More Cases</h2>
          </div>

          <div className="industriesListblock pt-5">
            <ul>
              {otherCases.map((ele, idx) => (
                <li>
                  <a href="#contact" className="industriesListcol">
                    <div className="solutionListimg">
                      <img src={ele.img} alt="" />
                    </div>
                    <div className="industriesListcoltext">
                      <h4>{ele.title} </h4>
                      <p>{ele.desc}</p>
                      <div className="quicklinks casesquicklinks">
                        {" "}
                        View full case{" "}
                        <img src="images/right-arrow.png" alt="" />{" "}
                      </div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Transport;
