import React from "react";

import WebcamComponent from "./sections/WebcamComponent";

const Landing1 = () => {
  return (
    <div className="landing">
      <WebcamComponent />
    </div>
  );
};

export default Landing1;
