import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";

const Press = () => {
  const [showBody, setBody] = React.useState("body1");
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = "Skylark Labs: Press";
  }, []);
  return (
    <>
      <div className="platform_banner aboutouter">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner solutionbenner_dis aboutbanner">
              <h1>Press</h1>
              <p>
                Our work is appreciated worldwide & got coverage in
                international channels such as BBC World News, Discovery
                Channel, ABC News Australia. Also got attention by world’s
                leading Tech. Magazines & Websites.
              </p>
              {/* <a
                  href="#tryourdemo"
                  // href="#trial"
                  // onClick={() => showTrialHandler()}
                  className="btn btntrydemo"
                >
                  Try it now
                </a> */}
            </div>
          </div>
        </div>
        <svg
          className="bottomShape"
          width="100%"
          // height="167"
          viewBox="0 0 1920 167"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0.199997V166.9H1920V0.199997C1616 91.3 1293.7 140.3 960 140.3C626.3 140.3 304 91.3 0 0.199997Z"
            fill="#10101B"
          />
        </svg>
        <img src="../images/banner/Press.webp" alt="" style={{
          minHeight: "60vh",
        }} />
      </div>
      <div className="platform_banner_dis banner_dis_mob">
        <div className="container">
          <div className="dismanbanner">
            <h1>Press</h1>
            <p>
              Our work is appreciated worldwide & got coverage in international
              channels such as BBC World News, Discovery Channel, ABC News
              Australia. Also got attention by world’s leading Tech. Magazines &
              Websites.
            </p>
          </div>
        </div>
      </div>
      <div className="pressmain_cat">
        <div class="btn-container">
          <input
            type="radio"
            name="food"
            id="upload"
            checked={showBody === "body1"}
            hidden
          />
          <label
            onClick={() => setBody("body1")}
            for="upload"
            className={showBody == "body1" ? "activepresstab" : ""}
          >
            Facial Recognition
          </label>
          <input
            type="radio"
            name="body2"
            id="video"
            checked={showBody === "body2"}
            hidden
          />
          <label
            onClick={() => setBody("body2")}
            for="video"
            className={showBody == "body2" ? "activepresstab" : ""}
          >
            Eye in the Sky
          </label>
          <input
            type="radio"
            name="body3"
            id="newtab"
            checked={showBody === "body3"}
            hidden
          />
          <label
            onClick={() => setBody("body3")}
            for="newtab"
            className={showBody == "body3" ? "activepresstab" : ""}
          >
            Covid-19
          </label>
        </div>
      </div>
      {showBody === "body1" && <Body1 />}
      {showBody === "body2" && <Body2 />}
      {showBody === "body3" && <Body3 />}
    </>
  );
};

const Body1 = () => {
  const navOption1 = React.useRef(null);
  const navOption2 = React.useRef(null);
  const navOption3 = React.useRef(null);
  const navOption4 = React.useRef(null);
  const navOption5 = React.useRef(null);
  const section1 = React.useRef(null);
  const section2 = React.useRef(null);
  const section3 = React.useRef(null);
  const section4 = React.useRef(null);
  const section5 = React.useRef(null);

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };
      const offsetY = window.scrollY + 200;
      if (section5.current && offsetY >= section5.current.offsetTop)
        addActiveClass(navOption5);
      else if (section4.current && offsetY >= section4.current.offsetTop)
        addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
        console.log("occured");
      }
    } catch (e) { }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", scrollCheck);
  }, []);

  return (
    <>
      <AppBar className="platformTab solutionTab" position="sticky">
        <ul>
          <li ref={navOption1} className="active">
            <a href="#videocoverage">Youtube Video Coverage</a>
          </li>
          <li ref={navOption2}>
            <a href="#newsmedia">News Media</a>
          </li>
          <li ref={navOption3}>
            <a href="#techmagazines">Tech Magazines</a>
          </li>
          <li ref={navOption4}>
            <a href="#magazines">Popular Magazines</a>
          </li>
          <li ref={navOption5}>
            <a href="#facialotherLanguages">Other Languages</a>
          </li>
        </ul>
      </AppBar>
      {/* <section className="aboutpage aboutuone_glass">
      <div className="container">
        <div className="aboutpagetitle">
          <h2>Press</h2>
        </div>
        <div className="aboutdis">
          Deploy powerful AI applications, designed to be customized by
          your teams using your data to solve your unique needs.
        </div>
      </div>
    </section> */}

      <section
        ref={section1}
        className="aboutteam aboututow_glass"
        id="videocoverage"
      >
        <div className="container">
          <div className="Newcategory">
            <h4>Youtube Video Coverage</h4>
          </div>

          <ul className="pressList presscatspace">
            <li>
              {" "}
              <div className="single-services">
                <div class="wrapper">
                  <div class="video-main">
                    <div class="promo-video">
                      <div class="waves-block">
                        <div class="waves wave-1"></div>
                        <div class="waves wave-2"></div>
                        <div class="waves wave-3"></div>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: "https://www.youtube.com/watch?v=-KdSomkIaek",
                      }}
                      target="_blank"
                      className="video"
                    >
                      <img src="images/play.svg" alt="" />{" "}
                    </Link>
                  </div>
                </div>

                <div className="videothumb">
                  <img src="images/facial_recognition.png" alt="" />
                </div>
                <div className="services-caption">
                  <span>Sep 3, 2018</span>
                  <p>Next Gen Facial Recognition May See Through Disguises</p>
                </div>
              </div>
            </li>

            <li>
              {" "}
              <div className="single-services">
                <div class="wrapper">
                  <div class="video-main">
                    <div class="promo-video">
                      <div class="waves-block">
                        <div class="waves wave-1"></div>
                        <div class="waves wave-2"></div>
                        <div class="waves wave-3"></div>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: "https://www.youtube.com/watch?v=hWEp5PkBZxQ",
                      }}
                      target="_blank"
                      className="video"
                    >
                      <img src="images/play.svg" alt="" />{" "}
                    </Link>
                  </div>
                </div>

                <div className="videothumb">
                  <img src="images/videthumb_02.png" alt="" />
                </div>
                <div className="services-caption">
                  <span>Sep 14, 2017</span>
                  <p>
                    Disguised Face Identification (DFI) on Discovery Channels'
                    Daily Planet
                  </p>
                </div>
              </div>
            </li>

            <li>
              {" "}
              <div className="single-services">
                <div class="wrapper">
                  <div class="video-main">
                    <div class="promo-video">
                      <div class="waves-block">
                        <div class="waves wave-1"></div>
                        <div class="waves wave-2"></div>
                        <div class="waves wave-3"></div>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: "https://www.youtube.com/watch?v=4aZmUwQ-WjU",
                      }}
                      target="_blank"
                      className="video"
                    >
                      <img src="images/play.svg" alt="" />{" "}
                    </Link>
                  </div>
                </div>

                <div className="videothumb">
                  <img src="images/discovery.png" alt="" />
                </div>
                <div className="services-caption">
                  <span>Sep 20, 2017</span>
                  <p>
                    Discovery Channel's Seeker coverage of Disguised Face
                    Identification (DFI)
                  </p>
                </div>
              </div>
            </li>

            <li>
              {" "}
              <div className="single-services">
                <div class="wrapper">
                  <div class="video-main">
                    <div class="promo-video">
                      <div class="waves-block">
                        <div class="waves wave-1"></div>
                        <div class="waves wave-2"></div>
                        <div class="waves wave-3"></div>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: "https://www.youtube.com/watch?v=2h-iP-QsSNI",
                      }}
                      target="_blank"
                      className="video"
                    >
                      <img src="images/play.svg" alt="" />{" "}
                    </Link>
                  </div>
                </div>

                <div className="videothumb">
                  <img src="images/videthumb_03.png" alt="" />
                </div>
                <div className="services-caption">
                  <span>Sep 5, 2018</span>
                  <p>
                    Our Disguised Face Identification was on the cover of the
                    Economist
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section
        ref={section2}
        className="aboutteam aboututow_glass"
        id="newsmedia"
      >
        <div className="container">
          <div className="Newcategory">
            <h4>News Media</h4>
          </div>

          <ul className="pressList presscatspace">
            <li>
              <Link
                to={{
                  pathname:
                    "https://www.businessinsider.com/facial-recognition-controversy-improvement-2017-9?IR=T",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/Insider.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 08, 2017</span> */}
                  <p>
                    Facial recognition is getting incredibly powerful, and even
                    more controversial
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "https://www.ibtimes.co.uk/this-ai-powered-facial-recognition-system-could-soon-identify-disguised-criminals-1638221",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/ibtimes.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 6, 2017</span> */}
                  <p>
                    This AI-powered facial recognition system could soon
                    identify disguised criminals
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "https://www.dailymail.co.uk/sciencetech/article-4860908/Facial-recognition-software-soon-ID-covered-faces.html#comments",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/dailymail.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 07, 2017</span> */}
                  <p>
                    Facial recognition software can now identify people even if
                    their face is COVERED in a breakthrough that will help
                    quickly unmask criminals
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.nbcnews.com/mach/tech/how-facial-recognition-systems-will-reshape-your-daily-life-ncna801336",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/mach.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 14, 2017</span> */}
                  <p>
                    How Facial Recognition Systems Will Reshape Your Daily Life
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.pressreader.com/sri-lanka/sunday-times-sri-lanka/20170910/282419874410317",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/pressreader.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 10, 2017</span> */}
                  <p>
                    Facial recognition software can now identify people even if
                    face is covered
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.risingbd.com/english/Software-can-now-identify-unmask-criminals/47862",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/risingbd.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 07, 2017</span> */}
                  <p>Software can now identify unmask criminals</p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "https://antyweb.pl/rozpoznawanie-zakrytej-twarzy-dfi",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/antyweb.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 07, 2017</span> */}
                  <p>
                    Even if you cover your face, face recognition systems will
                    do just fine
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.version2.dk/artikel/ansigtsgenkendelsessoftware-gaar-skridtet-videre-nu-virker-forklaedning-ikke-1080071",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/Teknologiensmediehus.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 06, 2017</span> */}
                  <p>
                    Beard and blue glasses do not do: New face recognition sees
                    everything
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.heise.de/tp/features/Teilweise-verhuellte-Gesichter-koennten-mit-KI-unterstuetzter-Gesichtserkennung-identifiziert-werden-3824239.html",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/heiseonline.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 08, 2017</span> */}
                  <p>
                    Partially veiled faces could be identified with AI-assisted
                    face recognition
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.thehindu.com/todays-paper/tp-national/tp-andhrapradesh/nit-warangal-students-develop-app-to-detect-disguised-faces/article19674379.ece",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/thehindu.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 13, 2017</span> */}
                  <p>
                    NIT Warangal students develop App to detect disguised faces
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://technews.tw/2017/09/14/dfi-facial-recognition-software/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/technews.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 14, 2017</span> */}
                  <p>
                    It’s useless to hide or avoid facial recognition software.
                    The new facial recognition software will help catch
                    criminals faster
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://day.kyiv.ua/ru/news/110917-iskusstvennyy-intellekt-nauchili-identificirovat-prestupnika-s-zamaskirovannoy",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/day_kyiv.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 11, 2017</span> */}
                  <p>
                    Artificial intelligence taught to identify a criminal with a
                    disguised appearance
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.01net.com/actualites/porter-un-foulard-ou-des-lunettes-n-empechera-bientot-plus-la-reconnaissance-faciale-1251471.html",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/01net.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>August 07,2017</span> */}
                  <p>
                    Wearing a scarf or glasses will soon no longer prevent
                    facial recognition
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.cbinsights.com/research/facial-recognition-privacy-ai/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/cbInsights.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 13, 2017</span> */}
                  <p>
                    AI-Driven Facial Recognition Is Coming And Brings Big Ethics
                    And Privacy Concerns
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://naturalnews.com/2017-09-14-latest-facial-recognition-software-can-identify-you-even-if-your-face-is-covered-exchanging-even-more-privacy-for-safety.html",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/naturalnews.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 14, 2017</span> */}
                  <p>
                    Latest facial recognition software can identify you even if
                    your face is COVERED, exchanging even more privacy for
                    “safety”
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.newsmax.com/Newsfront/facial-recognition-revolution-iphone-x-apple/2017/09/14/id/813696/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/newsmax.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 14, 2017</span> */}
                  <p>US on Verge of 'Facial Recognition' Revolution</p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://sputniknews.com/20170907/facial-recognition-covered-tech-1057188387.html",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/sputniknews.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 07, 2017</span> */}
                  <p>
                    Tech Capable of Identifying Individuals With Covered Faces
                    Under Development
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://chinadigitaltimes.net/2017/09/facial-recognition-adoption-raises-eyebrows/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/chinadigitaltimes.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 08, 2017</span> */}
                  <p>FACIAL RECOGNITION ADOPTION RAISES EYEBROWS</p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.antenam.net/index.php/razonoda/mozaik/49903-novi-program-za-prepoznavanje-identifikuje-iako-je-lice-prekriveno-maskom?__cf_chl_managed_tk__=gcuPKalbCaktDGw4afQXoiHTYr98RkW_tjD0T2qr7VI-1636192383-0-gaNycGzNBuU",
                }}
                target={"_blank"}
              >
                <div className="pressLogo">
                  <img src="../images/antenam.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 08, 2017</span> */}
                  <p>
                    New recognition program: Wear a mask in vain, the system
                    will recognize you!
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </section>

      <section
        ref={section3}
        className="aboutteam aboututow_glass"
        id="techmagazines"
      >
        <div className="container">
          <div className="Newcategory">
            <h4>Tech Magazines</h4>
          </div>

          <ul className="pressList presscatspace">
            <li>
              <Link
                to={{
                  pathname:
                    "https://www.newscientist.com/article/2146703-even-a-mask-wont-hide-you-from-the-latest-face-recognition-tech/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/the_new_scientist.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 14, 2017</span> */}
                  <p>
                    Even a mask won't hide you from the latest face recognition
                    tech
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "https://www.economist.com/leaders/2017/09/09/what-machines-can-tell-from-your-face",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/economist.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 09, 2017</span> */}
                  <p>What machines can tell from your face</p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "https://www.seeker.com/tech/artificial-intelligence/this-artificial-intelligence-system-can-id-faces-even-if-they-are-disguised",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/seeker.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 08, 2017</span> */}
                  <p>
                    This Artificial Intelligence System Can ID Faces Even If
                    They Are Disguised
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.technologyreview.com/2017/09/08/149250/facial-recognition-is-getting-incredibly-powerful-and-ever-more-controversial/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/MIT_technology_review.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 08, 2017</span> */}
                  <p>
                    Facial Recognition Is Getting Incredibly Powerful—and Ever
                    More Controversial
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://mashable.com/article/facial-recognition-masks-protesters#H3x0ArbSKaqp",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/businesswire.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 11, 2017</span> */}
                  <p>
                    Facial recognition could soon be used to identify masked
                    protesters
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.vice.com/en/article/mbby88/ai-will-soon-identify-protesters-with-their-faces-partly-concealed",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/vice.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 06, 2017</span> */}
                  <p>
                    AI Will Soon Identify Protesters With Their Faces Partly
                    Concealed
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.digitaltrends.com/cool-tech/facial-recognition-in-disguise/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/digital_rrends.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 11, 2017</span> */}
                  <p>
                    Facial recognition could help identify people even when they
                    wear a disguise
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.theverge.com/2017/9/6/16254476/facial-recognition-masks-diguises-ai",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/theverge.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 06, 2017</span> */}
                  <p>
                    The next challenge for facial recognition is identifying
                    people whose faces are covered
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.theregister.com/2017/09/06/ai_to_identify_protesters_in_disguise/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/theregister.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 06, 2017</span> */}
                  <p>
                    Dear rioters: Hiding your face with scarves, hats can't fool
                    this AI system
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "https://qz.com/1069756/using-artificial-intelligence-to-identify-protestors-wearing-hats-or-scarves-is-entirely-possible/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/quartz.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 5, 2017</span> */}
                  <p>
                    Using AI to identify protestors hiding behind hats or
                    scarves is entirely possible
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://futurism.com/are-engineers-responsible-for-the-consequences-of-their-algorithms",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/futurism.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>August 09, 17</span> */}
                  <p>
                    Are Engineers Responsible for the Consequences of Their
                    Algorithms? The software can ID partially covered faces,
                    making it potentially useful for persecuting dissidents.
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.inverse.com/article/36138-facial-recognition-and-disguise",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/inverse.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 06, 2017</span> */}
                  <p>
                    MASKED PROTESTERS CAN'T HIDE FROM THIS NEW FACIAL
                    RECOGNITION AI
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://expressdigest.com/facial-recognition-software-will-soon-id-covered-faces/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/expressdigest.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 06, 2017</span> */}
                  <p>Facial recognition software will soon ID covered faces</p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://jack-clark.net/2017/09/04/import-ai-issue-58-ai-makes-facial-identification-systems-see-through-masks-creating-yelp-foolin-fake-reviews-and-automated-creativity-with-pix2pix/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/IMPORTAI.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 05, 2017</span> */}
                  <p>
                    Import AI: Issue 58: AI makes facial identification systems
                    see through masks, creating Yelp-foolin’ fake reviews, and
                    automated creativity with pix2pix
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://gcn.com/articles/2017/09/07/facial-recognition-disguises.aspx",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/gcnlogo.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 07, 2017</span> */}
                  <p>Facial recognition penetrates disguises</p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.biometricupdate.com/201709/researchers-developing-ai-powered-facial-recognition-to-identify-concealed-persons",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/biometricupdate.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 07, 2017</span> */}
                  <p>
                    Researchers developing AI-powered facial recognition to
                    identify concealed persons
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://techxplore.com/news/2017-09-disguised-deep-convolutional-network.html",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/techx.b-cdn.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 09, 2017</span> */}
                  <p>
                    Meeting the disguised face challenge via deep convolutional
                    network
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://gearsofbiz.com/facial-recognition-software-will-soon-id-covered-faces/59513",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/gearsofbiz.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Oct 01, 2017</span> */}
                  <p>Passenger Drone lives up to its name with manned flight</p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "https://www.techgenyz.com/2017/09/07/new-ai-disguised-protesters-rallies/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/techgenyz.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 07, 2017</span> */}
                  <p>
                    New AI Comes in that Can Point Out Disguised Protesters at
                    Rallies
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </section>

      <section
        ref={section4}
        className="aboutteam aboututow_glass"
        id="magazines"
      >
        <div className="container">
          <div className="Newcategory">
            <h4>Popular Magazines and Tech Websites</h4>
          </div>
          <ul className="pressList presscatspace">
            <li>
              <Link
                to={{
                  pathname:
                    "https://www.cbinsights.com/research/facial-recognition-privacy-ai/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/cbInsights.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 13, 2017</span> */}
                  <p>
                    AI-Driven Facial Recognition Is Coming And Brings Big Ethics
                    And Privacy Concerns
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </section>

      <section
        ref={section5}
        className="aboutteam aboututow_glass"
        id="facialotherLanguages"
      >
        <div className="container">
          <div className="Newcategory">
            <h4>Other Languages</h4>
          </div>

          <ul className="pressList presscatspace">
            <li>
              <Link
                to={{
                  pathname:
                    "https://www.france24.com/fr/20170907-une-intelligence-artificielle-permet-didentifier-personnes-meme-lorsquelles-portent-masque-lunettes",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/france24.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 07, 2017</span> */}
                  <p>
                    Une intelligence artificielle permet d'identifier des
                    personnes même lorsqu'elles portent un masque ou des
                    lunettes
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.vice.com/fr/article/43ayb3/lintelligence-artificielle-pourra-bientot-identifier-les-manifestants-partiellement-masques",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/vice.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>Sept 14, 2017</span> */}
                  <p>
                    L’intelligence artificielle pourra bientôt identifier les
                    manifestants partiellement masqués
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://sains.sindonews.com/berita/1237417/124/peneliti-kembangkan-teknologi-pendeteksi-wajah",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/Sindonews.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>September 07, 2017</span> */}
                  <p>Peneliti Kembangkan Teknologi Pendeteksi Wajah</p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

const Body2 = () => {
  const navOption1 = React.useRef(null);
  const navOption2 = React.useRef(null);
  const navOption3 = React.useRef(null);
  const navOption4 = React.useRef(null);

  const navOption7 = React.useRef(null);
  const section1 = React.useRef(null);
  const section2 = React.useRef(null);
  const section3 = React.useRef(null);
  const section4 = React.useRef(null);

  const section7 = React.useRef(null);

  const scrollCheck = (e) => {
    const addActiveClass = (option) => {
      if (option === undefined) return;
      [navOption1, navOption2, navOption3, navOption4, navOption7]
        .filter((ele) => ele !== option)
        .map((ele) => ele.current.classList.remove("active"));
      option.current.classList.add("active");
    };
    const offsetY = window.scrollY + 200;
    if (section7.current && offsetY >= section7.current.offsetTop)
      addActiveClass(navOption7);
    else if (section4.current && offsetY >= section4.current.offsetTop)
      addActiveClass(navOption4);
    else if (section3.current && offsetY >= section3.current.offsetTop)
      addActiveClass(navOption3);
    else if (section2.current && offsetY >= section2.current.offsetTop)
      addActiveClass(navOption2);
    else if (section1.current && offsetY >= section1.current.offsetTop)
      addActiveClass(navOption1);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", scrollCheck);
  }, []);

  return (
    <>
      <AppBar className="platformTab solutionTab" position="sticky">
        <ul>
          <li ref={navOption1} className="active">
            <a href="#youtube">Youtube Video Coverage</a>
          </li>
          <li ref={navOption2}>
            <a href="#news">News </a>
          </li>
          <li ref={navOption3}>
            <a href="#radio">Radio</a>
          </li>
          <li ref={navOption4}>
            <a href="#popularmagazines">Popular Magazines</a>
          </li>

          <li ref={navOption7}>
            <a href="#languages">Other Languages</a>
          </li>
        </ul>
      </AppBar>

      <section
        ref={section1}
        className="aboutteam aboututow_glass"
        id="youtube"
      >
        <div className="container">
          <div className="Newcategory">
            <h4>Youtube Video Coverage</h4>
          </div>{" "}
          <ul className="pressList presscatspace">
            <li>
              <div className="single-services">
                <div class="wrapper">
                  <div class="video-main">
                    <div class="promo-video">
                      <div class="waves-block">
                        <div class="waves wave-1"></div>
                        <div class="waves wave-2"></div>
                        <div class="waves wave-3"></div>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: "https://www.youtube.com/watch?v=9eLcqYYiyqA",
                      }}
                      target="_blank"
                      className="video"
                    >
                      <img src="images/play.svg" alt="" />
                    </Link>
                  </div>
                </div>

                <div className="videothumb">
                  <img src="images/videthumb_04.png" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>Nov 25, 2018</span> */}
                  <p>
                    Discovery Channel's Seeker covered our Drone Violent
                    Individuals Identification
                  </p>
                </div>
              </div>
            </li>

            <li>
              <div className="single-services">
                <div class="wrapper">
                  <div class="video-main">
                    <div class="promo-video">
                      <div class="waves-block">
                        <div class="waves wave-1"></div>
                        <div class="waves wave-2"></div>
                        <div class="waves wave-3"></div>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: "https://www.youtube.com/watch?v=xD6RR3Om8bc",
                      }}
                      target="_blank"
                      className="video"
                    >
                      <img src="images/play.svg" alt="" />
                    </Link>
                  </div>
                </div>

                <div className="videothumb">
                  <img src="images/BBCWorld_thumb.png" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>16 Jun 2018</span> */}
                  <p>
                    BBC World News Interview of Dr. Amarjot Singh about the
                    Drone Surveillance System
                  </p>
                </div>
              </div>
            </li>

            <li>
              <div className="single-services">
                <div class="wrapper">
                  <div class="video-main">
                    <div class="promo-video">
                      <div class="waves-block">
                        <div class="waves wave-1"></div>
                        <div class="waves wave-2"></div>
                        <div class="waves wave-3"></div>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname:
                          "https://www.youtube.com/watch?v=zYypJPJipYc&t=1s",
                      }}
                      target="_blank"
                      className="video"
                    >
                      <img src="images/play.svg" alt="" />
                    </Link>
                  </div>
                </div>

                <div className="videothumb">
                  <img src="images/eyethesky.png" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>3 Jun 2018</span> */}
                  <p>
                    Eye in the Sky: Real-time Drone Surveillance System (DSS)
                    for Violent Individuals Identification
                  </p>
                </div>
              </div>
            </li>

            <li>
              <div className="single-services">
                <div class="wrapper">
                  <div class="video-main">
                    <div class="promo-video">
                      <div class="waves-block">
                        <div class="waves wave-1"></div>
                        <div class="waves wave-2"></div>
                        <div class="waves wave-3"></div>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname:
                          "https://www.youtube.com/watch?v=5O2fmbHpjcg&t=1s",
                      }}
                      target="_blank"
                      className="video"
                    >
                      <img src="images/play.svg" alt="" />
                    </Link>
                  </div>
                </div>

                <div className="videothumb">
                  <img src="images/telegraph_thumb.png" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>29 Aug 2018</span> */}
                  <p>
                    Video Coverage of Telegraph of the 'Eye in the Sky' Project
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section ref={section2} className="aboutteam aboututow_glass" id="news">
        <div className="container">
          <div className="Newcategory">
            <h4>News</h4>
          </div>

          <ul className="pressList presscatspace">
            <li>
              <Link
                to={{
                  pathname: "https://www.youtube.com/watch?v=xD6RR3Om8bc",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/BBCLogo.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>16 Jun 2018</span> */}
                  <p>
                    BBC World News Interview of Dr. Amarjot Singh about the
                    Drone Surveillance
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "https://mobile.abc.net.au/news/2018-06-30/drones-are-being-taught-to-spot-violence-in-crowds/9920472?pfmredir=sm",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/abcNews.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 29, 2018</span> */}
                  <p>
                    Eyes in the sky: Drones are being taught to spot violence in
                    crowds
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.telegraph.co.uk/technology/2018/06/15/surveillance-drones-could-break-fights-happen/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/telegraph.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 18, 2018</span> */}
                  <p>
                    Surveillance drones taught to spot fights and suspicious
                    behavior
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://inews.co.uk/news/uk/eye-in-the-sky-drone-capable-of-spotting-violence-in-crowds-raises-questions-about-hi-tech-policing-170696",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/Inews.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 29, 2018</span> */}
                  <p>
                    Most British police forces now have drones – and they’re
                    getting better at watching us. Is this the future we want?
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.cambridgeindependent.co.uk/education/the-eye-in-the-sky-drone-surveillance-system-from-cambridge-university-researchers-that-can-spot-violent-individuals-9050800/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/cambridge_independent.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 20, 2018</span> */}
                  <p>
                    The Eye in the Sky drone surveillance system from Cambridge
                    University researchers that can spot violent individuals
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.newindianexpress.com/cities/hyderabad/2018/jun/10/hyderabad-big-brother-to-keep-a-tab-on-violent-behaviour-from-the-sky-1826130.html",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/the_newindian_express.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>13 Jun 2018</span> */}
                  <p>
                    Hyderabad: Big Brother to keep a tab on violent behaviour
                    from the sky
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.deccanherald.com/city/drones-cctv-cameras-alert-cops-681054.html",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/dhlogo.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>JUL 13 2018</span> */}
                  <p>Drones, CCTV cameras to alert cops to violence, crimes</p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section ref={section3} className="aboutteam aboututow_glass" id="radio">
        <div className="container">
          <div className="Newcategory">
            <h4>Radio</h4>
          </div>
          <ul className="pressList presscatspace">
            <li>
              <Link
                to={{
                  pathname:
                    "https://www.abc.net.au/radionational/programs/sundayextra/drones-are-being-taught-to-spot-violent-behaviour/9859406",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/abc_radio_australia.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 17, 2018</span> */}
                  <p>Drones are being taught to spot violent behaviour</p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.rnz.co.nz/news/world/360827/eye-in-the-sky-drones-are-being-taught-to-spot-violence-in-crowds",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/new_zealand_radio.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>July 01, 2018</span> */}
                  <p>
                    Eye in the sky: Drones are being taught to spot violence in
                    crowds
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://soundcloud.com/amarjot-singh-436023899/dr-singhs-ceo-skylark-labs-interview-with-abc-australia-about-their-drone-surveillance-system",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/radio_australia.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 2018</span> */}
                  <p>
                    Dr. Singh's (CEO SkyLark Labs) interview with ABC Australia
                    about their Drone Surveillance System
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://soundcloud.com/amarjot-singh-436023899/dr-singhs-ceo-skylark-labs-interview-with-bbc-radio-about-their-drone-surveillance-system",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/BBC_radio.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 2018</span> */}
                  <p>
                    Dr. Singh's (CEO Skylark Labs) interview with BBC Radio
                    about their Drone Surveillance System
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section
        ref={section4}
        className="aboutteam aboututow_glass"
        id="popularmagazines"
      >
        <div className="container">
          <div className="Newcategory">
            <h4>Popular Magazines and Tech Websites</h4>
          </div>

          <ul className="pressList presscatspace">
            <li>
              <Link
                to={{
                  pathname:
                    "https://www.science.org/content/article/surveillance-drones-can-identify-violence-sky-it-happens",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/ScienceAAAS.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 08, 2018</span> */}
                  <p>
                    Surveillance drones can identify violence from the sky as it
                    happens
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.newscientist.com/article/2171052-surveillance-drones-can-now-spot-violent-attacks-as-they-happen/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/the_new_scientist.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 07, 2018</span> */}
                  <p>
                    Surveillance drones can now spot violent attacks as they
                    happen
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://spectrum.ieee.org/ai-drone-learns-to-detect-brawls",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/IEEE_Spectrum.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 14, 2018</span> */}
                  <p>
                    A drone surveillance system trains to watch out for humans
                    stabbing or punching each other
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.inc.com/jemima-mcevoy/pizza-delivery-wedding-crashing-more-weird-things-businesses-are-doing-with-drones.html",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/inc.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 14, 2018</span> */}
                  <p>
                    5 Crazy Ways You've Never Thought About Using Drones (but
                    You'll Want To) Drone technology has immense potential, and
                    a number of businesses are exploiting that in weird and
                    wonderful ways
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.cnet.com/news/ai-drones-are-being-trained-to-spot-violence-from-the-sky/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/cnet.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 6, 2018</span> */}
                  <p>
                    Drones are now being trained to spot violent people in
                    crowds
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.theverge.com/2018/6/6/17433482/ai-automated-surveillance-drones-spot-violent-behavior-crowds",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/theverge.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 6, 2018</span> */}
                  <p>
                    Drones taught to spot violent behavior in crowds using AI
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.theregister.com/2018/06/06/ai_drone_system_violent_behaviour/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/theregister.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 6, 2018</span> */}
                  <p>
                    RoboCop-ter: Boffins build drone to pinpoint brutal thugs in
                    crowds
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://qz.com/1299947/this-is-the-week-that-the-drone-surveillance-state-became-real/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/quartz.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 08, 2018</span> */}
                  <p>
                    This is the week that the drone surveillance state became
                    real
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://thewire.in/the-sciences/drones-ai-violent-behaviour-india-test-nit-warangal",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/wire.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 10, 2018</span> */}
                  <p>
                    Drones That Can Spot 'Violent Behaviour' in Crowds to Be
                    Tested in India
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.fastcompany.com/40581669/watch-this-drone-use-ai-to-spot-violence-from-the-sky",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/the_fast_company.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 06, 2018</span> */}
                  <p>
                    Watch this drone use AI to spot violence in crowds from the
                    sky
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.engadget.com/2018-06-06-drones-use-ai-to-spot-violence-in-crowds.html",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/engadget.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 07, 2018</span> */}
                  <p>Experimental drone uses AI to spot violence in crowds</p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.digitaltrends.com/cool-tech/drones-predict-violent-individuals-from-sky/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/digital_rrends.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 23, 2018</span> */}
                  <p>
                    Like a vice principal in the sky, this A.I. spots fights
                    before they happen
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://sciencenode.org/feature/What%20does%20violence%20look%20like.php",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/Science-node.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>July 23, 2018</span> */}
                  <p>
                    A view to a brawl Can AI and video surveillance prevent mob
                    violence?
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.gearbrain.com/drone-ai-spots-crowd-violence-2575923363.html",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/GearBrain.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 07, 2018</span> */}
                  <p>AI and drone camera used to spot violence in a crowd</p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://beebom.com/ai-detects-violence-crowd-india/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/become.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 07, 2018</span> */}
                  <p>
                    AI System That Detects Violence in Crowds To Be Tested in
                    NIT Warangal
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://www.thedrive.com/tech/21374/eye-in-the-sky-research-project-uses-drones-to-spot-violence-in-crowds",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/tribunecontentagency.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>June 07, 2018</span> */}
                  <p> Project Uses Drones to Spot Violence in Crowds</p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </section>

      <section
        ref={section7}
        className="aboutteam aboututow_glass"
        id="languages"
      >
        <div className="container">
          <div className="Newcategory">
            <h4>Other Languages</h4>
          </div>

          <ul className="pressList presscatspace">
            <li>
              <Link
                to={{
                  pathname:
                    "https://www.futura-sciences.com/tech/actualites/drole-tech-drole-tech-premier-clip-realise-chats-ordinateur-plus-petit-monde-robots-disney-cascadeurs-bouledogue-chasseur-drones-71812/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/futura_tech.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>July 01, 2018</span> */}
                  <p> This AI spots brawls before they start</p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
            <li>
              <Link
                to={{
                  pathname:
                    "https://netz.de/trends/news/forscher-tuefteln-an-drohne-die-straftaten-aus-der-luft-erkennt?utm_source=Twitter&utm_medium=Link&utm_campaign=TW_drohne-straftat",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/netde.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>July 20, 2018</span> */}
                  <p>
                    Researchers are working on drones that can detect crime from
                    the air
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://mundoconectado.com.br/noticias/v/5912/pesquisadores-querem-utilizar-drones-para-detectar-violencia-em-multidoes#/pagina/1",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/mundoconectado_logo.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>July 06, 2018</span> */}
                  <p>
                    Pesquisadores querem utilizar drones para detectar violência
                    em multidões
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>

            <li>
              <Link
                to={{
                  pathname:
                    "https://legaleraindonesia.com/university-of-cambridge-ciptakan-teknologi-drone-untuk-identifikasi-kekerasan/",
                }}
                target="_blank"
              >
                <div className="pressLogo">
                  <img src="../images/legaleraindonesia.png" alt="" />
                </div>
                <div className="pressdis">
                  {/* <span>07 June 2018</span> */}
                  <p>
                    {" "}
                    University of Cambridge Ciptakan Teknologi Drone untuk
                    Identifikasi Kekerasan
                  </p>
                </div>
                <span className="viewpress">
                  <img src="../images/rightlong.svg" alt="" />
                </span>
              </Link>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

const Body3 = () => {
  const navOption1 = React.useRef(null);
  const navOption2 = React.useRef(null);
  const navOption3 = React.useRef(null);
  // const navOption4 = React.useRef(null);
  // const navOption5 = React.useRef(null);
  const section1 = React.useRef(null);
  const section2 = React.useRef(null);
  const section3 = React.useRef(null);

  const scrollCheck = (e) => {
    const addActiveClass = (option) => {
      if (option === undefined) return;
      [navOption1, navOption2, navOption3]
        .filter((ele) => ele !== option)
        .map((ele) => ele.current.classList.remove("active"));
      option.current.classList.add("active");
    };
    const offsetY = window.scrollY + 200;

    if (section3.current && offsetY >= section3.current.offsetTop)
      addActiveClass(navOption3);
    else if (section2.current && offsetY >= section2.current.offsetTop)
      addActiveClass(navOption2);
    else if (section1.current && offsetY >= section1.current.offsetTop) {
      addActiveClass(navOption1);
      console.log("occured");
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", scrollCheck);
  }, []);

  return (
    <>
      <AppBar className="platformTab solutionTab" position="sticky">
        <ul>
          <li ref={navOption1} className="active">
            <a href="#videocoverage">Youtube Video Coverage</a>
          </li>
          <li ref={navOption2}>
            <a href="#newspaperinterviews">Newspaper Interviews</a>
          </li>
          <li ref={navOption3}>
            <a href="#appreciations">Appreciations</a>
          </li>
        </ul>
      </AppBar>

      <section
        ref={section1}
        className="aboutteam aboututow_glass"
        id="videocoverage"
      >
        <div className="container">
          <div className="Newcategory">
            <h4>Youtube Video Coverage</h4>
          </div>
          <ul className="pressList presscatspace">
            <li>
              <div className="single-services">
                <div class="wrapper">
                  <div class="video-main">
                    <div class="promo-video">
                      <div class="waves-block">
                        <div class="waves wave-1"></div>
                        <div class="waves wave-2"></div>
                        <div class="waves wave-3"></div>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: "https://www.youtube.com/watch?v=A41BASN9TA8",
                      }}
                      target="_blank"
                      className="video"
                    >
                      <img src="images/play.svg" alt="" />{" "}
                    </Link>
                  </div>
                </div>

                <div className="videothumb">
                  <img src="images/social_distancing.png" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>April 25, 2020</span> */}
                  <p>
                    COVID-19 Curfew and Social Distancing Enforcement using
                    Skylark Labs' AI Enabled Drones in India
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="single-services">
                <div class="wrapper">
                  <div class="video-main">
                    <div class="promo-video">
                      <div class="waves-block">
                        <div class="waves wave-1"></div>
                        <div class="waves wave-2"></div>
                        <div class="waves wave-3"></div>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname:
                          "https://www.youtube.com/watch?v=y6jbmpWC5Y4&t=1s",
                      }}
                      target="_blank"
                      className="video"
                    >
                      <img src="images/play.svg" alt="" />{" "}
                    </Link>
                  </div>
                </div>

                <div className="videothumb">
                  <img src="images/thumb_ptc.png" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>May 27, 2020</span> */}
                  <p>
                    PTC News Interview on our AI enabled Drones for COVID-19
                    Monitoring in India
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="single-services">
                <div class="wrapper">
                  <div class="video-main">
                    <div class="promo-video">
                      <div class="waves-block">
                        <div class="waves wave-1"></div>
                        <div class="waves wave-2"></div>
                        <div class="waves wave-3"></div>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: "https://www.youtube.com/watch?v=YKGTo-ruJ6c",
                      }}
                      target="_blank"
                      className="video"
                    >
                      <img src="images/play.svg" alt="" />{" "}
                    </Link>
                  </div>
                </div>

                <div className="videothumb">
                  <img src="images/thumb_tribune.png" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>Jun 2, 2020</span> */}
                  <p>
                    Tribune News Interview on our AI enabled Drones for COVID-19
                    Monitoring in India
                  </p>
                </div>
              </div>
            </li>

            <li>
              <div className="single-services">
                <div class="wrapper">
                  <div class="video-main">
                    <div class="promo-video">
                      <div class="waves-block">
                        <div class="waves wave-1"></div>
                        <div class="waves wave-2"></div>
                        <div class="waves wave-3"></div>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname:
                          " https://www.youtube.com/watch?v=QsFUJ7gCf1Q",
                      }}
                      target="_blank"
                      className="video"
                    >
                      <img src="images/play.svg" alt="" />{" "}
                    </Link>
                  </div>
                </div>

                <div className="videothumb">
                  <img src="images/thumb_dailypost.png" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>May 27, 2020</span> */}
                  <p>
                    Daily Post News Interview on our AI enabled Drones for
                    COVID-19 Monitoring in India
                  </p>
                </div>
              </div>
            </li>

            <li>
              <div className="single-services">
                <div class="wrapper">
                  <div class="video-main">
                    <div class="promo-video">
                      <div class="waves-block">
                        <div class="waves wave-1"></div>
                        <div class="waves wave-2"></div>
                        <div class="waves wave-3"></div>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: "https://www.youtube.com/watch?v=ZwUeZAn5fOc",
                      }}
                      target="_blank"
                      className="video"
                    >
                      <img src="images/play.svg" alt="" />{" "}
                    </Link>
                  </div>
                </div>

                <div className="videothumb">
                  <img src="images/thumb_new18.png" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>Jun 17, 2020</span> */}
                  <p>
                    News18 Interview on our AI enabled Drones for COVID-19
                    Monitoring in India
                  </p>
                </div>
              </div>
            </li>

            <li>
              <div className="single-services">
                <div class="wrapper">
                  <div class="video-main">
                    <div class="promo-video">
                      <div class="waves-block">
                        <div class="waves wave-1"></div>
                        <div class="waves wave-2"></div>
                        <div class="waves wave-3"></div>
                      </div>
                    </div>
                    <Link
                      to={{
                        pathname: "https://www.youtube.com/watch?v=rKy6XhPyL2Y",
                      }}
                      target="_blank"
                      className="video"
                    >
                      <img src="images/play.svg" alt="" />{" "}
                    </Link>
                  </div>
                </div>

                <div className="videothumb">
                  <img
                    src="images/video_thumb_artificial_intelligence.png"
                    alt=""
                  />
                </div>
                <div className="services-caption">
                  {/* <span>Jun 17, 2020</span> */}
                  <p>
                    Artificial Intelligence Festival Talk: COVID Social
                    Distancing Enforcement using AI Enabled Drones
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section
        ref={section2}
        className="aboutteam aboututow_glass"
        id="newspaperinterviews"
      >
        <div className="container">
          <div className="Newcategory">
            <h4>Newspaper Interviews</h4>
          </div>
          <ul className="pressList presscatspace">
            <li>
              {" "}
              <Link
                className="single-services newspaper"
                to={{
                  pathname: "/images/newspaper/newspaper1.jpg",
                }}
                target="_blank"
              >
                <div className="videothumb">
                  <img src="/images/newspaper/newspaper1.jpg" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>May 31, 2020</span> */}
                  <p>City lad develops software to monitor social distancing</p>
                </div>
              </Link>
            </li>

            <li>
              {" "}
              <Link
                className="single-services newspaper"
                to={{
                  pathname: "/images/newspaper/newspaper11.jpg",
                }}
                target="_blank"
              >
                <div className="videothumb">
                  <img src="/images/newspaper/newspaper11.jpg" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>May 31, 2020</span> */}
                  <p>Skylark labs assist police in apprehending defaulters</p>
                </div>
              </Link>
            </li>

            <li>
              {" "}
              <Link
                className="single-services newspaper"
                to={{
                  pathname: "/images/newspaper/newspaper10.jpg",
                }}
                target="_blank"
              >
                <div className="videothumb">
                  <img src="/images/newspaper/newspaper10.jpg" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>May 12, 2020</span> */}
                  <p>
                    Skylark Labs AI Drones Keep a Vigilant Eye on Individuals
                    Violating the Curfew: Tickets to be Issued
                  </p>
                </div>
              </Link>
            </li>

            <li>
              {" "}
              <Link
                className="single-services newspaper"
                to={{
                  pathname: "/images/newspaper/newspaper7.jpg",
                }}
                target="_blank"
              >
                <div className="videothumb">
                  <img src="/images/newspaper/newspaper7.jpg" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>May 11, 2020</span> */}
                  <p>Punjab cops deploy NITW-made drones</p>
                </div>
              </Link>
            </li>

            <li>
              {" "}
              <Link
                className="single-services newspaper"
                to={{
                  pathname: "/images/newspaper/newspaper6.jpg",
                }}
                target="_blank"
              >
                <div className="videothumb">
                  <img src="/images/newspaper/newspaper6.jpg" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>May 11, 2020</span> */}
                  <p>NIT-W alumnus’ software with AI watches Amritsar</p>
                </div>
              </Link>
            </li>

            <li>
              {" "}
              <Link
                className="single-services newspaper"
                to={{
                  pathname: "/images/newspaper/newspaper2.jpg",
                }}
                target="_blank"
              >
                <div className="videothumb">
                  <img src="/images/newspaper/newspaper2.jpg" alt="" />
                </div>
                <div className="services-caption">
                  {/* <span>May 31, 2020</span> */}
                  <p>
                    ਭਾਰਤੀ ਮੂਲ ਦੇ ਅਮਰੀਕਾ ਨਿਵਾਸੀ ਨੌਜਵਾਨ ਵਲੋਂ ਕੋਰੋਨਾ ਵਾਇਰਸ ਨੂੰ ਫ਼ੈਲਣ
                    ਤੋਂ ਰੋਕਣ ਲਈ ਤਿਆਰ ਕੀਤਾ “ਐਪ ”
                  </p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </section>

      <section
        ref={section3}
        className="aboutteam aboututow_glass"
        id="appreciations"
      >
        <div className="container">
          <div className="Newcategory">
            <h4>Appreciations By Government</h4>
          </div>{" "}
          <ul className="pressList presscatspace">
            <li>
              {" "}
              <a
                href="images/newspaper/appreciation1.jpeg"
                target="blank"
                className="single-services"
              >
                <div className="videothumb">
                  <img src="images/appreciations_th01.png" alt="" />
                </div>
                <div className="services-caption">
                  <p>
                    Appreciation by S. Karamjit Singh Rintu Mayor, Amritsar
                    (Punjab)
                  </p>
                </div>
              </a>
            </li>

            <li>
              {" "}
              <a
                href="images/newspaper/appreciation2.jpg"
                target="blank"
                className="single-services"
              >
                <div className="videothumb">
                  <img src="images/newspaper/appreciation02.png" alt="" />
                </div>
                <div className="services-caption">
                  <p>
                    Appreciation by S. Gurjeet Singh Aujla MP- LokSabha,
                    Amritsar (Punjab)
                  </p>
                </div>
              </a>
            </li>
            <li>
              {" "}
              <a
                href="images/newspaper/appreciation3.jpeg"
                target="blank"
                className="single-services"
              >
                <div className="videothumb">
                  <img src="images/newspaper/appreciation03.png" alt="" />
                </div>
                <div className="services-caption">
                  <p>
                    Appreciation by Shri Anil Joshi Former Cabinet Minister,
                    Punjab.
                  </p>
                </div>
              </a>
            </li>

            <li>
              {" "}
              <a
                href="images/newspaper/appreciation4.jpeg"
                target="blank"
                className="single-services"
              >
                <div className="videothumb">
                  <img src="images/newspaper/appreciation04.png" alt="" />
                </div>
                <div className="services-caption">
                  <p>
                    Appreciation by Shri Sunil Dutti MLA, Ex. Mayor, Amritsar
                    (Punjab)
                  </p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default Press;
