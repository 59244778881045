import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  intro: {
    padding: "180px 0 0 !important",
    overflow: "visible !important",

    [theme.breakpoints.down("sm")]: {
      padding: "100px 0 0 !important",
    },
  },
  product: {
    textAlign: "center",
    position: "relative",
    top: "auto",
    left: "auto",
    right: "auto",
    margin: "auto",
    bottom: -80,
    maxWidth: 780,
    marginBottom: "5rem",
    "& img": {
      borderRadius: "0.5rem",
      boxShadow: "0 10px 16px rgba(0, 0, 0, 0.2)",
    },

    [theme.breakpoints.down("sm")]: {
      bottom: -63,
    },

    [theme.breakpoints.down("xs")]: {
      bottom: -39,
    },
  },
  title: {
    textShadow: "0 4px 4px rgba(0, 0, 0, 0.22)",
  },
}));

const settings = {
  autoplay: true,
  autoplaySpeed: 3500,
  arrows: true,
  centerMode: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const Alertsproactive = ({ slideIndex }) => {
  const sliderRef = React.useRef();

  React.useEffect(() => {
    sliderRef.current.slickGoTo(slideIndex);
  }, [slideIndex]);

  return (
    <div>
      <div className="neuralslider">
        <Slider ref={sliderRef} {...settings} initialSlide={slideIndex}>
          <div className="p-2 latestnewshome">
            <div className="newsbox-home productslider">
              <a href="/" target="blank">
                <img src="images/productSlider/proactive-alert.png" alt="" />
                {/* <img src="images/productSlider/alerts-proactive-reactive-01.png" alt=""/> */}
              </a>
            </div>
          </div>
          <div className="p-2 latestnewshome ">
            <div className="newsbox-home productslider">
              <a href="/" target="blank">
                <img src="images/productSlider/reactive-alert.png" alt="" />
                {/* <img src="images/productSlider/alerts-proactive-reactive-02.png" alt=""/> */}
              </a>
            </div>
          </div>
          {/* <div className="p-2 latestnewshome ">
   <div className="newsbox-home productslider">
      <a href="/" target="blank">
      <img src="images/productSlider/sensor-fusion-03.png" alt=""/>
       
      </a>
   </div>
</div> */}
        </Slider>
      </div>
    </div>
  );
};

export default Alertsproactive;
