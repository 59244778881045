import React from "react";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import { Link } from "react-router-dom";
const Aboutskylak = () => {


  return (




    <section className="aboutmission" >

      <div className="container">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={7} lg={7}>
            <div className="aboutmissionleft">
              <h4>Advancing<br></br>  <span className="aboutsubtitle">Land, Sea, & Air </span>Defenses   with <br></br>
                <a href="self-learning-ai">   Self-learning AI</a>
              </h4>
              <div className="subtages">
                <a href="self-learning-ai">Self-learning AI</a>

                <a href="kepler">Kepler </a>
                <a href="bros">Border  </a>
                <a href="aries">Counter Aerial  </a>
                <a href="miras">Marine  </a>
                <a href="dros">Airborne  </a>


                <br></br>
                <a href="turing">Turing </a>
                <a href="social-media-intel">Social Media  </a>
                <a href="news-watch">News </a>
                <a href="supply-intel">Supply Chain </a>
                <a href="financial-flux"> Financial Investments </a>


              </div>
            </div>


          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>

            {/* <h3>Battlefields Threats Emerge Beyond Visual Line of Sight and can have fatal consequences. Proactive Response is Inevitable.</h3> */}
            <p className=" mt-0 mb-0">
              Skylark provides cutting-edge safety and security solutions, utilizing our exclusive, self-evolving AI technology, specifically crafted for the intelligence and tactical sectors. Our systems offer immediate, edge-based actionable insights, essential for comprehensive situational understanding on new and legacy devices. Our offerings are customized for a variety of missions on land, sea, and air. They excel in recognizing and adjusting to novel, unpredictable threats outside the visual range, enabling rapid, informed decision-making in ever-changing security scenarios
            </p>


            {/* <div className="btnsmore">
  <a href="/about" className="readmore-btns">
                      <span>
                        Learn More{" "}
                        <img src="/images/productSlider/nextarrow.svg" alt="" />
                      </span>{" "}
                    </a>
                    </div> */}

          </Grid>
        </Grid>


      </div>
    </section>
  );
};

export default Aboutskylak;
