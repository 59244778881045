import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import { Divider, Grid, makeStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MainComponent from "demo/mainComponent";
import useModels from "demo/models";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: "Libre Baskerville",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "120%",
  },
  divider: {
    background: "white",
    margin: "30px 0px",
  },
}));

const Solutions = () => {
  const classes = useStyles();
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const [expanded, setExpanded] = React.useState("");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = "Skylark Labs: Solutions";
    return () => {
      console.log("title changed");
    };
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;
      if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);

  return (
    <>
      <div className="platform_banner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>Solutions</h1>
              <p>
                Advanced video analytics solutions to detect threats accurately
                and in real-time for enhanced physical security suitable for
                Large, medium and small enterprises
              </p>
            </div>
          </div>
        </div>
        <svg
          className="bottomShape"
          width="100%"
          viewBox="0 0 1920 167"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0.199997V166.9H1920V0.199997C1616 91.3 1293.7 140.3 960 140.3C626.3 140.3 304 91.3 0 0.199997Z"
            fill="#10101B"
          />
        </svg>

        <img src="../images/updated_banner_images/Solutions.webp" alt="" />
      </div>
{/* 
      <div className="platform_banner_dis banner_dis_mob">
        <div className="container">
          <div className="dismanbanner">
            <h1>Solutions</h1>
            <p>
              Advanced video analytics solutions to detect threats accurately
              and in real-time for enhanced physical security suitable for
              Large, medium and small enterprises
            </p>
          </div>
        </div>
      </div> */}

      <AppBar className="platformTab" position="sticky">
        <ul>
          <li
            onClick={(e) => {
              valuehandler("aimodules");
            }}
            ref={navOption1}
            className={prod === "aimodules" ? "active" : ""}
          >
            <a href="#aimodules">AI Modules</a>
          </li>
          <li
            onClick={(e) => {
              valuehandler("solutionsuite");
            }}
            ref={navOption2}
            className={prod === "solutionsuite" ? "active" : ""}
          >
            <a href="#solutionsuite">Security Suite</a>
          </li>
          <li
            onClick={(e) => {
              valuehandler("products");
            }}
            ref={navOption3}
            className={prod === "products" ? "active" : ""}
          >
            <a href="#products">Solutions</a>
          </li>
        </ul>
      </AppBar>

      <section ref={section1} className="platformproactive" id="aimodules">
        <div className="container">
          <div className="platformCamera_dis solutiondisblock">
            <h2>AI Modules</h2>
            <p className="mt-0 pt-0">
              Deploy proactive, reactive, and insights modules for your unique
              needs.
            </p>
          </div>

          <div className="solutionlist">
            <div className="subtitleblock">PROACTIVE</div>
            <ul>
              <li>
                <Link to="/lurking-detection">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img src="../images/nav/Lurking.svg" alt="Camera" />
                    </div>
                  </div>

                  <div>
                    <div className="subtitlesolution">
                      Lurking Detection
                      <span>
                        The system detects suspicious individuals or activity
                        around the property and provides real-time alerts to the
                        security team.
                      </span>
                      <div className="viewmorearrow">
                        <img src="../images/Right_long.svg" alt="Right Long" />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>

              <li>
                <Link to="/intrusion-detection">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img src="../images/nav/Lurking.svg" alt="Camera" />
                    </div>
                  </div>

                  <div>
                    <div className="subtitlesolution">
                      Intrusion Detection
                      <span>
                        Proactively detects unauthorized Intrusions in
                        restricted areas in real time & alerts instantly.
                      </span>
                      <div className="viewmorearrow">
                        <img src="../images/Right_long.svg" alt="Right Long" />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>

          <div className="solutionlist">
            <div className="subtitleblock">REACTIVE</div>
            <ul>
              <li>
                <Link to="/violence-detection">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img src="../images/nav/Violence.svg" alt="Camera" />
                    </div>
                  </div>

                  <div>
                    <div className="subtitlesolution">
                      Violence Detection
                      <span>
                        Detects violence such as fighting, pushing, hitting,
                        etc. before it becomes fatal. Accurately informs
                        authorities about the incident in real time to respond
                        quickly.
                      </span>
                      <div className="viewmorearrow">
                        <img src="../images/Right_long.svg" alt="Right Long" />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>

              <li>
                <Link to="/weapon-detection">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img src="../images/nav/Weapon.svg" alt="Camera" />
                    </div>
                  </div>

                  <div>
                    <div className="subtitlesolution">
                      Weapon detection
                      <span>
                        Detects weapons like guns, knives or sharp objects at
                        venues such as hotels, schools, music festivals,
                        airports, borders etc. in real time using standard CCTV
                        cameras.
                      </span>
                      <div className="viewmorearrow">
                        <img src="../images/Right_long.svg" alt="Right Long" />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>

          <div className="solutionlist insightsolution">
            <div className="subtitleblock">INSIGHT</div>
            <ul>
              <li>
                <Link to="/face-recognition">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img
                        src="../images/nav/face_recognition.svg"
                        alt="Camera"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="subtitlesolution">
                      Face Recognition
                      <span>
                        Recognize faces in images or videos to detect
                        individuals in various disguises from different
                        distances at venues in real time using CCTV cameras
                      </span>
                      <div className="viewmorearrow">
                        <img src="../images/Right_long.svg" alt="Right Long" />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/night-to-day">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img
                        src="../images/th_intrusion-detection.png"
                        alt="Camera"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="subtitlesolution">
                      Night to Day
                      <span>
                        AI solution that enables users to enhance the view of
                        night video and photo in live streaming as day like.
                      </span>
                      <div className="viewmorearrow">
                        <img src="../images/Right_long.svg" alt="Right Long" />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>

              <li>
                <Link to="/crowd-counting">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img
                        src="../images/homePage/crowd-counting.jpg"
                        alt="Camera"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="subtitlesolution">
                      Crowd Counting
                      <span>
                        An AI solution that detects and counts the number of
                        pedestrians, providing a precise count of people in a
                        crowd from ground & aerial views.
                      </span>
                      <div className="viewmorearrow">
                        <img src="../images/Right_long.svg" alt="Right Long" />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/number-plate-recognition">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img src="../images/nav/number_plate.svg" alt="Camera" />
                    </div>
                  </div>

                  <div>
                    <div className="subtitlesolution">
                      Number Plate Recognition
                      <span>
                        AI Technology that detects the number plates and
                        identifies the vehicles in real-time.
                      </span>
                      <div className="viewmorearrow">
                        <img src="../images/Right_long.svg" alt="Right Long" />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <Link to="/face-detection">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img
                        src="../images/the_face_detection.png"
                        alt="Camera"
                      />
                    </div>
                  </div>

                  <div>
                    <div className="subtitlesolution">
                      Face Detection
                      <span>
                        Capable of detecting the faces of the pedestrians for
                        more insights and identification.
                      </span>
                      <div className="viewmorearrow">
                        <img src="../images/Right_long.svg" alt="Right Long" />
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <img
          src="../images/proactive_ellipse.svg"
          alt=""
          className="effectgalss"
        />
      </section>

      <section ref={section2} className="platformproactive" id="solutionsuite">
        <div className="container">
          <div className="platformCamera_dis solutiondisblock">
            <h2>Security Suite</h2>
            <p className="mt-0 pt-0">
              Deploy proactive, reactive, and insights modules for your unique
              needs.
            </p>
          </div>

          <ul className="suitelist">
            <li>
              <Link to="/security-suite" className="boxsuite">
                <img
                  src="images/skylarklabs-security-suite-features.svg"
                  alt=""
                />
                <h4> Security Suite</h4>
                <p>
                  Uses advanced artificial intelligence to analyze video feeds &
                  provide the fastest protection against potential threats.
                </p>

                <div className="viewmorearrow">
                  <img src="../images/Right_long.svg" alt="Right Long" />
                </div>
              </Link>
            </li>

            <li>
              <Link to="/perimeter-security-suite" className="boxsuite">
                <img
                  src="images/skylarklabs-perimeter-suite-features.svg"
                  alt=""
                />
                <h4>Perimeter Security Suite</h4>
                <p>
                  Ensures the security perimeters for fulfilling workers/
                  staff's security.
                </p>
                <div className="viewmorearrow">
                  <img src="../images/Right_long.svg" alt="Right Long" />
                </div>
              </Link>
            </li>

            <li>
              <Link to="/safety-management-suite" className="boxsuite">
                <img src="images/skylarklabs-safety-management.svg" alt="" />
                <h4> Safety Management Suite</h4>
                <p>
                  Designed to manage safety risk in the workplace and reduction
                  of risk
                </p>

                <div className="viewmorearrow">
                  <img src="../images/Right_long.svg" alt="Right Long" />
                </div>
              </Link>
            </li>

            <li>
              <Link to="/operational-management-suite" className="boxsuite">
                <img
                  src="images/skylarklabs-operational-management-suite.svg"
                  alt=""
                />
                <h4>Operational Management Suite</h4>
                <p>
                  The system detects suspicious individuals or activity around
                  the property and provides real-time alerts to the security
                  team.
                </p>

                <div className="viewmorearrow">
                  <img src="../images/Right_long.svg" alt="Right Long" />
                </div>
              </Link>
            </li>

            <li>
              <Link to="/facility-Management-suite" className="boxsuite">
                <img
                  src="images/skylarklabs-facility-management-suite.svg"
                  alt=""
                />
                <h4>Facility Management Suite</h4>
                <p>
                  The system detects suspicious individuals or activity around
                  the property and provides real-time alerts to the security
                  team.
                </p>

                <div className="viewmorearrow">
                  <img src="../images/Right_long.svg" alt="Right Long" />
                </div>
              </Link>
            </li>

            <li>
              <Link to="/face-recognition-suite" className="boxsuite">
                <img
                  src="images/skylarklabs-facility-management-suite.svg"
                  alt=""
                />
                <h4>Face recognition Suite</h4>
                <p>
                  AI Based Face Recognition solution detects individuals without
                  requiring personnel.
                </p>

                <div className="viewmorearrow">
                  <img src="../images/Right_long.svg" alt="Right Long" />
                </div>
              </Link>
            </li>

            <li>
              <Link to="/productivity-suite" className="boxsuite">
                <img src="images/skylarklabs-productivity-suite.svg" alt="" />
                <h4>Productivity Suite</h4>
                <p>
                  {" "}
                  The system detects suspicious individuals or activity around
                  the property and provides real-time alerts to the security
                  team.
                </p>

                <div className="viewmorearrow">
                  <img src="../images/Right_long.svg" alt="Right Long" />
                </div>
              </Link>
            </li>

            <li>
              <Link to="/road-safety-traffic-management" className="boxsuite">
                <img
                  src="images/skylarklabs-road-safety-traffic-management.svg"
                  alt=""
                />
                <h4>Road Safety and Traffic Management</h4>
                <p>
                  {" "}
                  AI solutions for road safety and smooth traffic management.
                </p>

                <div className="viewmorearrow">
                  <img src="../images/Right_long.svg" alt="Right Long" />
                </div>
              </Link>
            </li>

            <li>
              <Link to="/transport-suite" className="boxsuite">
                <img src="images/skylarklabs-transport-suite.svg" alt="" />
                <h4>Transport Suite</h4>
                <p>
                  {" "}
                  AI based solutions to detect any unscheduled halt, theft, or
                  any other suspicious activity for in-transit vehicles.
                </p>

                <div className="viewmorearrow">
                  <img src="../images/Right_long.svg" alt="Right Long" />
                </div>
              </Link>
            </li>

            <li>
              <Link to="/covid-safety-suite" className="boxsuite">
                <img
                  src="images/skylarklabs-road-safety-traffic-management.svg"
                  alt=""
                />
                <h4>Covid Safety Suite</h4>
                <p>
                  {" "}
                  System that ensures safety of workers, staff and visitors in
                  real time with high accuracy.
                </p>

                <div className="viewmorearrow">
                  <img src="../images/Right_long.svg" alt="Right Long" />
                </div>
              </Link>
            </li>
          </ul>
        </div>
        <img
          src="../images/proactive_ellipse.svg"
          alt=""
          className="effectgalss"
        />
      </section>

      <section ref={section3} className="platformproactive" id="products">
        <div className="container">
          <div className="platformCamera_dis solutiondisblock">
            <h2>Solutions </h2>
            <p className="mt-0 pt-0">
              Deploy proactive, reactive, and insights modules for your unique
              needs.
            </p>
          </div>

          <div className="solutionslisting">
            <ul>
              <li>
                <a href="/defence-security-system">
                  <div className="solutionlisttitle"> 
                    Defence Security System
                  </div>
                  <p> 
                    Advanced AI solutions that enhance national security,
                    protects civilians from threats and other external dangers
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                </a>
              </li>

              <li>
                <a href="/ariel-security-system">
                  <div className="solutionlisttitle"> 
                  Aerial Security System
                  </div>
                  <p> 
                    Advanced AI solutions that enhance national security,
                    protects civilians from threats and other external dangers
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                </a>
              </li>


              <li>
                <a href="/parking-toll-management-system">
                  <div className="solutionlisttitle"> 
                  Parking-toll-management-system
                  </div>
                  <p> 
                  Skylark labs' advanced AI for resolving traffic problems
                        and riot management problems in the cities
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                </a>
              </li>

              
              <li>
              <Link to="/theft-detection-system">
                  <div className="solutionlisttitle"> 
                  Parking-toll-management-system
                  </div>
                  <p> 
                  Skylark labs' advanced AI for resolving traffic problems
                        and riot management problems in the cities
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                  </Link>
              </li>

              <li>
              <Link to="/crowd-monitoring-counting-system">
                  <div className="solutionlisttitle"> 
                  Crowd Monitoring and Counting System
                  </div>
                  <p> 
                  Skylark labs' advanced AI for resolving traffic problems
                        and riot management problems in the cities
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                  </Link>
              </li>

              
              <li>
              <Link to="/visitors-cars-management-system">
                  <div className="solutionlisttitle"> 
                  Visitor Car Management System
                  </div>
                  <p> 
                  AI Advanced Solutions to prevent abnormalities inside
                        and outside the premises and enhance healthcare’s
                        safety.
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                  </Link>
              </li>

              <li>
              <Link to="/trespassing-detection">
                  <div className="solutionlisttitle"> 
                  Trespassing Detection System
                  </div>
                  <p> 
                  AI Advanced Solutions to prevent abnormalities inside
                        and outside the premises and enhance healthcare’s
                        safety.
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                  </Link>
              </li>


              <li>
              <Link to="/shoplifting-detection-system">
                  <div className="solutionlisttitle"> 
                  Shoplifting Detection System (Inside)
                  </div>
                  <p> 
                  AI Advanced Solutions to prevent abnormalities inside
                        and outside the premises and enhance healthcare’s
                        safety.
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                  </Link>
              </li>

              <li>
              <Link to="/campus-security-system">
                  <div className="solutionlisttitle"> 
                  Campus Security System
                  </div>
                  <p> 
                  AI Advanced Solutions to prevent abnormalities inside
                        and outside the premises and enhance healthcare’s
                        safety.
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                  </Link>
              </li>

              <li>
              <Link to="/transit-anti-violence-system">
                  <div className="solutionlisttitle"> 
                  Transit Anti Violence System (Inside)
                  </div>
                  <p> 
                  Advanced AI technology that provides safety as well as
                        freedom at the places where millions of people commute
                        every day.
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                  </Link>
              </li>
 
              <li>
              <Link to="/child-abduction-alert-system">
                  <div className="solutionlisttitle"> 
                  Child Abduction System
                  </div>
                  <p> 
                  Advanced AI security solutions for providing a safe &
                        secure environment to the civilians by 24x7 monitoring.
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                  </Link>
              </li>


              <li>
              <Link to="/public-safety-system">
                  <div className="solutionlisttitle"> 
                  Public Safety System
                  </div>
                  <p> 
                  Advanced AI security solutions for providing a safe &
                        secure environment to the civilians by 24x7 monitoring.
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                  </Link>
              </li>

              <li>
              <Link to="/violence-detection-system">
                  <div className="solutionlisttitle"> 
                  Violence Detection System (Mid Range)
                  </div>
                  <p> 
                  Advanced AI security solutions for providing a safe &
                        secure environment to the civilians by 24x7 monitoring.
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                  </Link>
              </li>
              <li>
              <Link to="/city-wide-surveillance-system">
                  <div className="solutionlisttitle"> 
                  City Wide Surveillance System
                  </div>
                  <p> 
                  Advanced AI security solutions for providing a safe &
                        secure environment to the civilians by 24x7 monitoring.
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                  </Link>
              </li>
 
              <li>
              <Link to="/work-place-security-system">
                  <div className="solutionlisttitle"> 
                  Workplace Security System
                  </div>
                  <p> 
                  Advanced AI security solutions for providing a safe &
                        secure environment to the civilians by 24x7 monitoring.
                  </p>
                  <div className="viewmorearrow">
                    <img src="../images/Right_long.svg" alt="Right Long" />
                  </div>
                  </Link>
              </li>
 
 
 
              
              
            </ul>
          </div>

          {/* <div className="solutionlist industryouter produclist">
            <div className="subtitleblock">LARGE ENTERPRISE</div>

            <ul>
              <li>
                <div className="blockinnerProduct producttype">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img src="../images/nav/Lurking.svg" alt="Camera" />
                    </div>
                  </div>
                  <div className="blockfull">
                    <div className="subtitlesolution industryname">
                      National Security
                      <p>
                        Advanced AI solutions that enhance national security,
                        protects civilians from threats and other external
                        dangers
                      </p>
                      <Link to="/defence-security-system" className="btnlink">
                        <span>
                          Defence Security System{" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                      <Link to="/ariel-security-system" className="btnlink">
                        <span>
                          Aerial Security System{" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div className="blockinnerProduct producttype">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img src="../images/nav/Cities.svg" alt="Camera" />
                    </div>
                  </div>
                  <div className="blockfull">
                    <div className="subtitlesolution industryname">
                      Cities
                      <p>
                        Skylark labs' advanced AI for resolving traffic problems
                        and riot management problems in the cities
                      </p>
                      <Link
                        to="/parking-toll-management-system"
                        className="btnlink"
                      >
                        <span>
                          Parking/Toll Management System{" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />{" "}
                        </span>
                      </Link>
                      <Link to="/theft-detection-system" className="btnlink">
                        <span>
                          Theft Detection System{" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                      <Link
                        to="/crowd-monitoring-counting-system"
                        className="btnlink"
                      >
                        <span>
                          Crowd Monitoring and Counting System{" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                      <div className="viewmorearrow"></div>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div className="blockinnerProduct producttype">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img
                        src="../images/nav/healthcare_facilities.svg"
                        alt="Camera"
                      />
                    </div>
                  </div>
                  <div className="blockfull">
                    <div className="subtitlesolution industryname">
                      Hospitality / Healthcare
                      <p>
                        AI Advanced Solutions to prevent abnormalities inside
                        and outside the premises and enhance healthcare’s
                        safety.
                      </p>
                      <Link
                        to="/visitors-cars-management-system"
                        className="btnlink"
                      >
                        <span>
                          Visitor Car Management System{" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                      <Link to="/trespassing-detection" className="btnlink">
                        <span>
                          Trespassing Detection System{" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="solutionlist industryouter produclist">
            <div className="subtitleblock">Small & Medium Enterprises</div>

            <ul>
              <li>
                <div className="blockinnerProduct producttype">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img src="../images/nav/Finance.svg" alt="Camera" />
                    </div>
                  </div>
                  <div className="blockfull">
                    <div className="subtitlesolution industryname">
                      Finance
                      <p>
                        Skylark provides advanced AI solutions which helps to
                        resolve the high alert vandalism threats and robbery in
                        finance areas.
                      </p>
                      <Link
                        to="/shoplifting-detection-system"
                        className="btnlink"
                      >
                        <span>
                          Shoplifting Detection System (Inside){" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div className="blockinnerProduct producttype">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img src="../images/nav/education.svg" alt="Camera" />
                    </div>
                  </div>
                  <div className="blockfull">
                    <div className="subtitlesolution industryname">
                      Education
                      <p>
                        Advanced AI security solutions for providing a safe &
                        secure environment in Educational Institutes such as
                        Universities, Colleges & Schools.
                      </p>
                      <Link to="/campus-security-system" className="btnlink">
                        <span>
                          Campus Security System{" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div className="blockinnerProduct producttype">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img src="../images/nav/transit.svg" alt="Camera" />
                    </div>
                  </div>
                  <div className="blockfull">
                    <div className="subtitlesolution industryname">
                      Transit
                      <p>
                        Advanced AI technology that provides safety as well as
                        freedom at the places where millions of people commute
                        every day.
                      </p>
                      <Link
                        to="/transit-anti-violence-system"
                        className="btnlink"
                      >
                        <span>
                          Transit Anti Violence System (Inside){" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div className="solutionlist industryouter produclist">
            <div className="subtitleblock">Integrators</div>

            <ul>
              <li>
                <div className="blockinnerProduct producttype">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img src="../images/nav/public_safety.svg" alt="Camera" />
                    </div>
                  </div>
                  <div className="blockfull">
                    <div className="subtitlesolution industryname">
                      Public Safety
                      <p>
                        Advanced AI security solutions for providing a safe &
                        secure environment to the civilians by 24x7 monitoring.
                      </p>
                      <Link
                        to="/child-abduction-alert-system"
                        className="btnlink"
                      >
                        <span>
                          Child Abduction System{" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                      <Link to="/public-safety-system" className="btnlink">
                        <span>
                          Public Safety System{" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                      <Link to="/violence-detection-system" className="btnlink">
                        <span>
                          Violence Detection System (Mid Range){" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div className="blockinnerProduct producttype">
                  <div className="pr-0">
                    <div className="solutionImg">
                      <img src="../images/nav/CCTV.svg" alt="Camera" />
                    </div>
                  </div>
                  <div className="blockfull">
                    <div className="subtitlesolution industryname">
                      Security Companies
                      <p>
                        Advanced AI solutions that helped Guard, Drone and CCTV
                        companies integrate their drones or CCTV to increase
                        nation-wide safety and help the victims in various
                        situations.
                      </p>
                      <Link to="/ariel-security-system" className="btnlink">
                        <span>
                          Aerial Security System{" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                      <Link
                        to="/city-wide-surveillance-system"
                        className="btnlink"
                      >
                        <span>
                          City Wide Surveillance System{" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                      <Link
                        to="/work-place-security-system"
                        className="btnlink"
                      >
                        <span>
                          Workplace Security System{" "}
                          <img src="../images/rightlong.svg" alt="Right Long" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div> */}
        </div>
        <img
          src="../images/proactive_ellipse.svg"
          alt=""
          className="effectgalss"
        />
      </section>
    </>
  );
};

export default Solutions;
