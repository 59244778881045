import { useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import $ from 'jquery';

function ScrollToTop({ history }) {
    const location = useLocation()
    useEffect(() => {
        // const unlisten = history.listen(() => {
            if (window.location.href.indexOf("#") != -1) {
                console.log(window.location.href, "#")
            //     // // document.location.reload(true)
            //     // document.getElementById("contact").scrollIntoView()
            //     // window.scrollTo(0, 200)
            //     // window.location.replace("/#contact")
                // $('html, body').animate({
                //     scrollTop: $("#contact").offset().top
                // }, 1000);
                // setTimeout(() => {
                    // document.querySelector(window.lpocation.hash)?.scrollIntoView()
                // }, 1200)
              } else {
                console.log(window.location.href)
                  window.scrollTo(0, 0);
              }
            // HeaderTop
        // });
        // return () => {
        //     unlisten();
        // }
    }, [location]);

    return (null);
}

// export default withRouter(ScrollToTop);
export default ScrollToTop