import React, { useEffect, useState } from "react";

const Blogdetail = ({ data, html }) => {
  
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Skylark Labs: ${data.title}`;
    return () => {
      console.log("title changed");
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <>
      <div className="blogdetail">
        <div className="breadcrumbs-nav">
          <div className="container">
            <ul className="breadcrumbsList">
              <li>
                <a href="/blog">Blogs</a>
              </li>
              <li>
                <a href="#/">{ data.category_name }</a>
              </li>
              <li>
                <span>{ data.tag_name }</span>
              </li>
            </ul>

            <a href="/blog" className="backbtn">
              Back
            </a>
          </div>
        </div>
        <div className="container">
          <div className="blogouter">
            <div className="blogdetailLeft">
              <div className="blogdetail-outer">
                <h1>{ data.title }</h1>

                <div className="blogPostName">
                  {/* By Abhishek Amin Dec 23, 2021, 3:08:00 PM ,{" "} */}
                  { new Date(data.published).toDateString() }&nbsp;&nbsp;|
                  <span>&nbsp;&nbsp;{ data.views } Views&nbsp;&nbsp;|&nbsp;&nbsp;{ data.read_time ?? 0 } mins read</span>
                  
                  <span className="namepost">By { data.writer ?? 'Skylark Labs' }</span>
                </div>
                <div className="socilamediaShare shareicons_detail">
                  <a href="#/">
                    <img src="../images/facebook-share.svg" alt="" />
                  </a>
                  <a href="#/">
                    <img src="../images/linkdin-share.svg" alt="" />
                  </a>
                  <a href="#/">
                    <img src="../images/twitter-share.svg" alt="" />
                  </a>
                  <a href="#/">
                    <img src="../images/email-share.svg" alt="" />
                  </a>
                </div>

                <div
                  className="blog-sample"
                  dangerouslySetInnerHTML={{
                    __html: data.content,
                  }}
                />
              </div>
            </div>
            {/* <div className="blogdetailRight">
              <div className="righttryOn">
                <div className="titletryon">Try Skylark Labs For Free</div>
                <p>
                  5-day trial includes camera and full access to management
                  platform.
                </p>
                <a href="#/" className="btn">
                  Request a Demo
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogdetail;
