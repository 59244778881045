import React, { useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Etostestimonial from "home/sections/Etos-testimonial";

const Financialflux = () => {
  useEffect(() => {
    const title = document.getElementById("mainTitle");
    title.innerText = `Macro-Economy Analysis`;
    return () => {
      console.log("title changed");
    };
  }, []);
  const [expanded, setExpanded] = React.useState("");
  const [priceSection, setPriceSection] = useState([true, false, false]);
  const navOption1 = useRef(null);
  const navOption2 = useRef(null);
  const navOption3 = useRef(null);
  const navOption4 = useRef(null);
  const navOption5 = useRef(null);
  const navOption6 = useRef(null);
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const togglePriceSection = (idx) => {
    // setPriceSection([false, false, false]);
    const arr = [false, false, false];
    arr[idx] = true;
    setPriceSection(arr);
    console.log(priceSection);
  };

  const scrollCheck = (e) => {
    try {
      const addActiveClass = (option) => {
        if (option === undefined) return;
        [navOption1, navOption2, navOption3, navOption4, navOption5, navOption6]
          .filter((ele) => ele !== option)
          .map((ele) => ele.current.classList.remove("active"));
        option.current.classList.add("active");
      };

      const offsetY = window.scrollY + 200;

      const checkSec = (ele) => {
        return ele && offsetY >= ele.current.offsetTop;
      };

      if (checkSec(section6)) addActiveClass(navOption6);
      else if (checkSec(section5)) addActiveClass(navOption5);
      else if (checkSec(section4)) addActiveClass(navOption4);
      else if (section3.current && offsetY >= section3.current.offsetTop)
        addActiveClass(navOption3);
      else if (section2.current && offsetY >= section2.current.offsetTop)
        addActiveClass(navOption2);
      else if (section1.current && offsetY >= section1.current.offsetTop) {
        addActiveClass(navOption1);
      }
    } catch (e) { }
  };

  useEffect(() => {
    // window.scrollTo({
    //   top: 0,
    // });
    window.addEventListener("scroll", scrollCheck);
  }, []);
  const [prod, prodtype] = React.useState("");
  const valuehandler = (e) => {
    prodtype(e);
  };
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div className="platform_banner productbanner">
        <div className="platform_banner_dis banner_dis_desktop">
          <div className="container">
            <div className="dismanbanner">
              <h1>
                {/* <span className="mainsubtitle">MEA</span> */}
                FinancialFlux

              </h1>
              <p>
                Stay ahead in the ever-evolving global economy with in-depth insights into trends, financial data, and market shifts.



              </p>
              <div className="subtages"><a href="#/">Financial Health Insights</a>  <a href="#/">Risk Radar </a> <a href="#/">Tailored Analysis</a>  <a href="#/">Market Wisdom</a> </div>

            </div>
          </div>
        </div>

        <div className="imgcenter bannerimgblock ">
          <img src="../images/productSlider/macro-economy-analysis.jpg" alt="" />
        </div>
      </div>

      {/* <div className="platform_banner_dis banner_dis_mob  pt-5">
        <div className="container">
          <div className="dismanbanner">
            <h2>FinancialFlux</h2>
            <p>
              Stay ahead in the ever-evolving global economy with in-depth insights into trends, financial data, and market shifts.


            </p>
          </div>
        </div>
      </div> */}
      <div className="plateformsub-page">
        <div className="noise-bg"></div>
        <div className="main-shape"></div>
        <div className="container pt-5">
          <div className="plateformabout  mt-5">
            <Grid container spacing={3} className="financialflux-about-container">
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="aboutimgblock" style={{
                  padding: "0",
                }}>
                  {" "}
                  <img src="images/Macro-Economy Analysis.png" alt="" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="homeright-block fistblockight homeright-block-auto product-featurednew">
                  <div>
                    <h2 className="mb-0 pb-0">
                      <span className="spanfirstword"> Macro-Economy </span> Macro-Economy Analysis (MEA)

                    </h2>
                    <p className="pt-0 abouttext-pblock">
                      In the ever-evolving global economy, staying informed is crucial. Turing's FinancialFlux module offers in-depth insights into global economic trends, financial data, and market shifts, ensuring organizations are well-equipped to make informed decisions.

                    </p>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>


        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container">
            <div className="seactionblocktitles">
              <h2 className="seactiontitles"> Microeconomy Insights
              </h2>
              <p>
                Navigating Trends, Guiding Investments, Seizing Opportunities for Informed Prosperity

              </p>
            </div>
          </div>
          <div className="container plateformblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Financial</span> Health & Performance


                    </h4>
                    <p>
                      Assess and enhance your organization's financial health with comprehensive analytics and performance metrics.

                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>

                          Financial Health Assessment


                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Performance Metrics Analysis

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Profitability and Revenue Growth


                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Cost Reduction Strategies


                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/Global Trend analytics.png" />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>
        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/Financial Data Insights.png" alt="" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Macro</span> & Micro Economic Analysis

                    </h4>
                    <p>
                      Navigate the economic landscape with in-depth macro and microeconomic analysis for informed financial decision-making.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Global Economic Trends

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Policy Impact Evaluation

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Consumer Market Insights

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Industry-Specific Economic Analysis


                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock ">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5  pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Investment</span> Analytics & Portfolio Management

                    </h4>
                    <p>
                      Optimize your investments and manage portfolios effectively with cutting-edge analytics and strategies.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Portfolio Diversification Techniques

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Algorithmic Trading Analysis

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Risk Management Frameworks


                        </div>{" "}
                      </li>

                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Investment Compliance Monitoring


                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <img src="images/Risk and Assessment.png" />
                </div>
              </Grid>
            </Grid>
          </div>
        </section>

        <section className="dashboardfeatures section-productoverviewblock flatformblocdiv">
          <div className="imgplatformsbg">
            <img src="images/productSlider/bg_plateform.png" alt="" />
          </div>
          <div className="container plateformblock aboutbgblock">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="videoblock-plateforms">
                  <div className="videoleftblock">
                    <img src="images/Global Trend analytics.png" alt="" style={{
                      transform: "rotateY(180deg)",
                    }} />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <div className="centerboxblock platformscontent-block-center">
                  <div className="dashboardfeatures-list pl-5 pt-0">
                    <div className="flatformtages">
                      <span>Product Capabilities</span>
                    </div>
                    <h4 className="pb-4">
                      <span className="spanfirstword">Market</span> Insights & Strategy


                    </h4>
                    <p>
                      Gain a competitive edge with deep insights into market trends and strategic foresight.
                    </p>
                    <ul className="mb-4 pointsplatforls">
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Market Trend Forecasting

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Strategic Investment Insights

                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Competitive Analysis


                        </div>{" "}
                      </li>
                      <li>
                        <div className="boxinner">
                          <span>
                            <img
                              src="images/productSlider/bullet-icon.png"
                              alt=""
                            />
                          </span>
                          Economic Impact Studies
                        </div>{" "}
                      </li>
                    </ul>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </section>


        <section className="configurations">
          <div className="container">
            <div className="">
              <h3>Turing: Next-gen Features for Modern Intelligence
              </h3>
              {/* <p>
            ADIVS boasts a range of innovative features tailored to meet the dynamic needs of Intelligence Organizations.

            </p> */}
            </div>

            <div className="boxouterproduct">
              {/* <div className="boxouterproductItem">
            <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt=""/> </div>

              <h4>Low Code Querying</h4>
              <p>
              Users, regardless of technical background, can swiftly create and execute custom social media searches without the need for coding expertise
              </p>
            </div>
            <div className="boxouterproductItem">
            <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt=""/> </div>

              <h4>Identify Targets of Interest</h4>
              <p>
              This feature enables the analysis of online or custom data sources to efficiently identify activities, persons, or organizations of interest.
              </p>
            </div> */}

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt="" /> </div>
                <h4>Semantics Analysis</h4>
                <p>
                  Unlock the power of semantics with our intuitive platform. Seamlessly analyze text using advanced semantic techniques, providing you with valuable insights and a deeper understanding of your data.
                </p>
              </div>
              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt="" /> </div>

                <h4>Data Filtering</h4>
                <p>
                  Efficiently sift through extensive datasets using advanced customizable filtering techniques. Tailor your filters based on criticality, hashtags, and more, giving you precise control over the information you need.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt="" /> </div>

                <h4>Unknown Threats</h4>
                <p>
                  The Platform possesses the capability to analyze data sources and accurately identify both known and previously unidentified new threats. This ensures comprehensive threat detection and protection.
                </p>
              </div>

              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt="" /> </div>

                <h4>Relationship Analysis</h4>
                <p>
                  This feature performs in-depth analysis to uncover various connections and relationships associated with the identified targets of interest. It provides valuable insights into networks and associations within the monitored data.
                </p>
              </div>


              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt="" /> </div>

                <h4>Intelligent Threat Verification</h4>
                <p>
                  Leverage our platform's integration of physical sensors and online data for swift and precise threat detection. Navigate with ease using our user-friendly UI, ensuring top-tier security.

                </p>
              </div>


              <div className="boxouterproductItem">
                <div className="boxouterproductItem-icon"><img src="../images/icon.svg" alt="" /> </div>

                <h4>Future Risk Anticipation</h4>
                <p>
                  Harness the power of our advanced UI's entity profiling to anticipate potential threats. Through meticulous future simulations, we elevate an entity's risk profile, equipping you with insights into probable threats down the road. Stay a step ahead and prepare effectively.


                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          ref={section1}
          className="product-featured section-productoverviewblock Featuredstikeblock bgnoneblock"
        >
          <div className="container pb-5 mb-5">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <div className="">
                  <h2>
                    <span className="spanfirstword">Other</span> System
                    Configurations
                  </h2>

                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured productfeatured_advis">
                  <a href="social-media-intel">
                    <h5>Social Media Intel</h5>
                    <div className="imgblockfeatured">

                      <img src="images/productSlider/01.png" alt="" />
                      <div className="readmore-btns"><span>Learn More  <img src="/images/productSlider/nextarrow.svg" alt="" /></span>   </div>
                    </div>
                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured productfeatured_advis">
                  <a href="news-watch">

                    <h5>NewsWatch

                    </h5>

                    <div className="imgblockfeatured">
                      <img src="images/productSlider/02.png" alt="" />

                    </div>
                    <div className="readmore-btns"><span>Learn More <img src="/images/productSlider/nextarrow.svg" alt="" /></span> </div>

                  </a>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <div className="productfeatured productfeatured_advis">
                  <a href="supply-intel">
                    <h5>SupplyIntel</h5>
                    <div className="imgblockfeatured">
                      <img src="images/productSlider/03.png" alt="" />
                    </div>
                    <div className="readmore-btns"><span>Learn More <img src="/images/productSlider/nextarrow.svg" alt="" /></span>  </div>
                  </a>
                </div>
              </Grid>

            </Grid>
          </div>
        </section>


      </div>

      <Etostestimonial />


    </>
  );
};

export default Financialflux;
