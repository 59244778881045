import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@material-ui/core";
import LoadingOverlay from "react-loading-overlay";
import { useParams } from 'react-router-dom';

const SpecificBlog = () => {
    const params = useParams();
    const [blogData, setBlogData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [hasConclusion, setHasConclusion] = useState(false);
    const options = { year: "numeric", month: "short", day: "numeric" };

    useEffect(() => {
        if (params.blogId) fetch(`https://prodserver.skylarklabs.ai/skylarklabsblog/article-detail/${params.blogId}/`)
            .then((res) => res.json())
            .then((data) => {
                setLoading(false);
                setBlogData(data);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, [params.blogId]);

    useEffect(() => {
        if (blogData) {
            const title = document.getElementById("mainTitle");
            title.innerText = "Skylark - Blog - " + (blogData["title"] ? blogData["title"] : "No Blog Found");
            // document.getElementById("meta-title")?.setAttribute("content", "Skylark - Blog - " + (blogData["title"] ? blogData["title"] : "No Blog Found"));
            // document.getElementById("og-title")?.setAttribute("content", "Skylark - Blog - " + (blogData["title"] ? blogData["title"] : "No Blog Found"));
            // document.getElementById("meta-description")?.setAttribute("content", blogData["title"] ? blogData["title"] : "No Blog Found");
            // document.getElementById("og-image")?.setAttribute("content", blogData["image"] ? blogData["image"] : "No Blog Found");

            // let titleMeta = document.createElement("meta");
            // titleMeta.setAttribute("property", "og:title");
            // titleMeta.content = "Skylark - Blog - " + (blogData["title"] ? blogData["title"] : "No Blog Found");
            // document.getElementsByTagName("head")[0].appendChild(titleMeta);

            // let descriptionMeta = document.createElement("meta");
            // descriptionMeta.setAttribute("property", "og:description");
            // descriptionMeta.content = blogData["title"] ? blogData["title"] : "No Blog Found";
            // document.getElementsByTagName("head")[0].appendChild(descriptionMeta);

            // let imageMeta = document.createElement("meta");
            // imageMeta.setAttribute("property", "og:image");
            // imageMeta.content = blogData["image"] ? blogData["image"] : "No Blog Found";
            // document.getElementsByTagName("head")[0].appendChild(imageMeta);

            // let urlMeta = document.createElement("meta");
            // urlMeta.setAttribute("property", "og:url");
            // urlMeta.content = window.location.href;
            // document.getElementsByTagName("head")[0].appendChild(urlMeta);

            // let typeMeta = document.createElement("meta");
            // typeMeta.setAttribute("property", "og:type");
            // typeMeta.content = "article";
            // document.getElementsByTagName("head")[0].appendChild(typeMeta);

            // let siteNameMeta = document.createElement("meta");
            // siteNameMeta.setAttribute("property", "og:site_name");
            // siteNameMeta.content = "Skylark Labs";
            // document.getElementsByTagName("head")[0].appendChild(siteNameMeta);

            if (blogData["title"]) {
                var h3Elements = document.getElementsByTagName('h3');

                for (var i = 0; i < h3Elements.length; i++) {
                    if (h3Elements[i].innerText.trim() === 'Overview') {
                        h3Elements[i].id = 'blog-overview';
                    }
                    else if (h3Elements[i].innerText.trim() === 'Problem') {
                        h3Elements[i].id = 'blog-problem';
                    }
                    else if (h3Elements[i].innerText.trim() === 'Solution') {
                        h3Elements[i].id = 'blog-solution';
                    }
                    else if (h3Elements[i].innerText.trim() === 'Result') {
                        h3Elements[i].id = 'blog-result';
                    }
                    else if (h3Elements[i].innerText.trim() === 'Conclusion') {
                        h3Elements[i].id = 'blog-conclusion';
                        setHasConclusion(true);
                    }
                }

                var imgElements = document.getElementsByTagName('img');
                for (var i = 0; i < imgElements.length; i++) {
                    var parentElement = imgElements[i].parentNode;
                    if (parentElement.tagName.toLowerCase() === 'strong') {
                        parentElement.classList.add('images-center');
                    }
                }
            }
        }
    }, [blogData]);

    const scrollToSection = (id) => {
        var element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
    };

    const renderBlogData = () => {
        return (
            <>
                <div className="bloglanding bloglistingnew">
                    <div className="bloglanding-subblock">
                        <img src="../images/productSlider/gradient-circle.svg" alt="" className="uk-blur-large leftimg rightimgblur-img" />
                        <img src="../images/productSlider/gradient-circle.svg" alt="" className="uk-blur-large rightimg leftimgblur-img" />
                    </div>
                    <div className="bloglanding-subblock bloglanding-subblocktwo">
                        <div className="topgradint"></div>
                    </div>
                    <section className="section-hero-banner-top" style={{
                        paddingTop: "50px"
                    }}>
                        <div className="container">
                            <a href="/use-cases" className="back-btn-blog"><span><img src="/images/productSlider/nextarrow.svg" alt="" /> Go back</span></a>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={5}>
                                    <div className="blogdetailspage-banner-img">
                                        <img src={blogData["image"]} alt="" />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={6} md={7}>
                                    <div className="blogdetailspage-banner-titles">

                                        <h1 className="news-post-h">{blogData["title"]}</h1>
                                        <div className="authore">
                                            <ul style={{
                                                flexWrap: "wrap"
                                            }}>
                                                <li>
                                                    Author:<span>{blogData["author"]}</span>
                                                </li>
                                                <li>
                                                    Post :<span>{new Date(blogData["created_at"]).toLocaleDateString("en-US", options)}</span>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className="clientnavgations" style={{
                                            padding: "0"
                                        }}>
                                            <div className="overviewlinks leftoverviewlinks">
                                                <ul style={{
                                                    flexWrap: "wrap"
                                                }}>
                                                    <li>
                                                        <a onClick={() => scrollToSection("blog-overview")}>Overview</a>{" "}
                                                    </li>
                                                    <li>
                                                        <a onClick={() => scrollToSection("blog-problem")}>Problem</a>{" "}
                                                    </li>
                                                    <li>
                                                        <a onClick={() => scrollToSection("blog-solution")}>Solution</a>{" "}
                                                    </li>
                                                    <li>
                                                        <a onClick={() => scrollToSection("blog-result")}>Result</a>{" "}
                                                    </li>
                                                    {hasConclusion && <li>
                                                        <a onClick={() => scrollToSection("blog-conclusion")}>Conclusion</a>{" "}
                                                    </li>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>

                    </section>

                    {/* <section className="main-top pt-0 bgnone">
                        <div className="container">
                            <div className="main-page-title">
                                <div class="top-breadcrumbs">
                                    <ul>
                                        <li>
                                            <a href="/blogs">Home</a>{" "}
                                        </li>
                                        <li> {blogData["title"]} </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    <section class="main-top pt-0 bgnone">
                        <div class="container">
                            <Grid container spacing={3} className="bottom-pb-6">
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className="bottomspace-item">
                                    <div className="blog-detailpage">
                                        <div className="singleblogtext" dangerouslySetInnerHTML={{ __html: blogData["content"] }} />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </section>
                </div>
            </>
        );
    };

    const LoadingPage = () => {
        return (
            <LoadingOverlay active={loading} spinner styles={{
                overlay: (base) => ({
                    ...base,
                    background: "#10101b",
                }),
            }}>
                <div style={{
                    height: "100vh", background: "#10101b", display: "flex",
                    alignContent: "center",
                    flexWrap: "wrap",
                }}>
                    <Grid container xs={12} style={{
                        display: "flex",
                        alignContent: "center",
                        flexWrap: "wrap",
                        flexDirection: "column",
                    }}>
                        <Typography variant="h3">No Blog Found</Typography>
                    </Grid>
                </div>
            </LoadingOverlay>
        );
    };

    return (
        <>
            <div className="blogouter">
                <section className="main-top pt-0">{blogData && blogData["title"] ? renderBlogData() : LoadingPage()}</section>
            </div>
        </>
    );
};

export default SpecificBlog;